import * as _ from 'lodash';
import { USER_NOTIFICATION_TYPES } from "./constants";
import { Reminder } from '../api/queries/loans/useReminders';

export function isScheduled (reminder: Reminder) {
  return reminder.state === 'SCHEDULED';
}

export function isSent (reminder: Reminder) {
  return reminder.state === 'SENT';
}

export function isPending (reminder: Reminder) {
  return reminder.state === 'PENDING';
}

export function hasFutureNotificationInSchedule (reminders: Array<Reminder>, type: string) {
  return reminders && reminders.find(r => r.type === type && !isSent(r))
}

export function findFirstRepaymentReminder (reminders: Array<Reminder>) {
  const repaymentReminders = (reminders || []).filter((r) => {
    return [USER_NOTIFICATION_TYPES.REPAYMENT_REMINDER_LETTER, USER_NOTIFICATION_TYPES.REPAYMENT_REMINDER].includes(r.type);
  });
  return repaymentReminders.sort((r1, r2) => (new Date(r1.createdAt) as any) - (new Date(r2.createdAt) as any))[0];
}

export function digPausedReasonFor (reminders: Array<Reminder>) {
  return _.get((reminders || []).filter(x => x.is_paused).pop(), 'is_paused', '');
}

export function qualifiesAsFirstNotice (reminder: Reminder) {
  return (
    [
      USER_NOTIFICATION_TYPES.REPAYMENT_REMINDER_LETTER,
      USER_NOTIFICATION_TYPES.REPAYMENT_REMINDER,
    ].includes(reminder.type)
    && !reminder.is_paused
  );
}
