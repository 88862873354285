import React, { useState } from 'react';
import * as _ from 'lodash';
import { Tabs, Tab } from 'react-bootstrap';
import { useNavigate, useMatch } from 'react-router-dom';
import CardsBankAccountsContent from './CardsBankAccountsContent';
import CollectionsContent from './CollectionsContent';
import Activity from './Activity';
import PaymentSchedule from './PaymentSchedule';
import UsageOverview from './UsageOverview';
import PaymentOverview from './PaymentOverview';
import { LOAN_STATES } from '../../../utils/constants';
import { Loan } from '../../../api/queries/types';
import { useAPIData } from '../../../utils';
import { PurchaseMethod } from '../../../utils/transactionUtils';
import RepaymentReminders from '../../../views/RepaymentReminders';

export default function ServicingTab ({ loan }: { loan: Loan }) {
  const navigate = useNavigate();
  const mainTabRouteMatch = useMatch('/users/:userId/loan/:id/servicing/*');
  const tabRouteMatch = useMatch('/users/:userId/loan/:id/servicing/:tab');
  const loanApplicationMatch = useMatch('/loan-applications/:loanApplicationId/servicing/*');
  const loanApplicationTabRouteMatch = useMatch('/loan-applications/:loanApplicationId/servicing/:tab');

  const activeCard = _.get(mainTabRouteMatch, 'params.id');
  const activeTab = _.get(tabRouteMatch, 'params.tab');
  const loanApplicationActiveTab = _.get(loanApplicationTabRouteMatch, 'params.tab');
  const loanApplicationId = _.get(loanApplicationMatch, 'params.loanApplicationId');
  const defaultTab = 'activity';
  const [activeServicingTab,  setActiveServicingTab] = useState(activeTab || loanApplicationActiveTab || defaultTab);

  const purchaseMethodsReq = useAPIData<Array<PurchaseMethod>>(`/notebook/loans/${ loan._id }/purchase-methods`);

  const handleActiveTab = (tab: string) => {
    setActiveServicingTab(tab);
    const baseUrl = loanApplicationId ? `/loan-applications/${loanApplicationId}` : `/users/${ loan.userId }/loan/${ activeCard }`;
    navigate(`${ baseUrl }/servicing/${ tab }`, { replace: true });
  };

  const activelyServicing = loan && [
    LOAN_STATES.FUNDED,
    LOAN_STATES.COMPLETED,
    LOAN_STATES.REPAID,
    LOAN_STATES.PASTDUE,
  ].includes(loan.status);

  return (
    <>
      <UsageOverview loan={ loan } />
      { loan.isInstallment && (<PaymentOverview loan={ loan } />) }
      <Tabs
        id='servicing-tabs'
        activeKey={ activeServicingTab }
        defaultActiveKey= { defaultTab }
        onSelect={ handleActiveTab }
        className="custom-tabs"
      >
        <Tab eventKey="activity" title="Activity">
          <Activity loan={ loan } purchaseMethodsReq={ purchaseMethodsReq } />
        </Tab>
        <Tab eventKey="cards_accounts" title="Cards & Accounts">
          <CardsBankAccountsContent loan={ loan } purchaseMethodsReq={ purchaseMethodsReq } />
        </Tab>
        <Tab eventKey="payment_schedule" title="Payment Schedule">
          <PaymentSchedule loan={ loan } />
        </Tab>
        { activelyServicing &&
          <Tab eventKey="collections" title="Collections">
            <CollectionsContent loan={ loan } />
          </Tab>

        }
        { !loan.isInstallment && activelyServicing &&
          <Tab eventKey="reminders" title="Reminders">
            <RepaymentReminders loan={ loan } />
          </Tab>
        }
      </Tabs>
    </>
  );
};
