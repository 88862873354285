import useQuery from '../../shared/useQuery';

type User = {
  _id: string;
  fullName: string;
  deletedAt: Date | null;
}

export default function useLoanApplicationUser (loanApplicationId?: string) {
  return useQuery<User>(loanApplicationId ? `/notebook/loan-applications/${ loanApplicationId }/user` : null);
}
