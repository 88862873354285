import useQuery from '../../shared/useQuery';

export type ReportedDebt = {
  _id: string;
  loan_application_id: string;
  account_type: string;
  borrower_indicated_active: boolean;
  high_credit_cents?: number,
  credit_limit_cents?: number,
  current_balance_cents?: number,
  monthly_payment_cents?: number,
  date_opened?: string,
  lender?: string,
  status: string;
  date_effective?: string;
  payment_frequency?: string;
  payment_schedule_month_count?: string;
  portfolio_type?: string;
  ecoa_designator?: string;
  is_heloc: boolean;
};

export default function useReportedDebts (loanApplicationId: string) {
  return useQuery<Array<ReportedDebt>>(`/notebook/loan-applications/${ loanApplicationId }/reported-debts`);
}
