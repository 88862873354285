// 30-year mortgage rates: year\tlow\t\high\taverage
const RATES_TSV = `2019	3.63%	5.34%	4.25%
2018	3.95%	4.94%	4.54%
2017	3.78%	4.30%	3.99%
2016	3.41%	4.32%	3.65%
2015	3.59%	4.09%	3.85%
2014	3.80%	4.53%	4.17%
2013	3.34%	4.58%	3.98%
2012	3.31%	4.08%	3.66%
2011	3.91%	5.05%	4.45%
2010	4.17%	5.21%	4.69%
2009	4.71%	5.59%	5.04%
2008	5.10%	6.63%	6.03%
2007	5.96%	6.74%	6.34%
2006	6.10%	6.80%	6.41%
2005	5.53%	6.37%	5.87%
2004	5.38%	6.34%	5.84%
2003	5.21%	6.44%	5.83%
2002	5.93%	7.18%	6.54%
2001	6.45%	7.24%	6.97%
2000	7.13%	8.64%	8.05%
1999	6.74%	8.15%	7.44%
1998	6.49%	7.22%	6.94%
1997	6.99%	8.18%	7.60%
1996	6.94%	8.42%	7.81%
1995	7.11%	9.22%	7.93%
1994	6.97%	9.25%	8.38%
1993	6.74%	8.07%	7.31%
1992	7.84%	9.03%	8.39%
1991	8.35%	9.75%	9.25%
1990	9.56%	10.67%	10.13%
1989	9.68%	11.22%	10.32%
1988	9.84%	10.77%	10.34%
1987	9.03%	11.58%	10.21%
1986	9.29%	10.99%	10.19%
1985	11.09%	13.29%	12.43%
1984	13.14%	14.68%	13.88%
1983	12.55%	13.89%	13.24%
1982	13.57%	17.66%	16.04%
1981	14.80%	18.63%	16.64%
1980	12.18%	16.35%	13.74%
1979	10.38%	12.90%	11.20%
1978	8.98%	10.38%	9.64%
1977	8.65%	9.00%	8.85%
1976	8.70%	9.10%	8.87%
1975	8.80%	9.60%	9.05%
1974	8.40%	10.03%	9.19%
1973	7.43%	8.85%	8.04%
1972	7.23%	7.46%	7.38%
1971	7.29%	7.73%	7.54%
`;

type Rate = {
  low_30: number;
  high_30: number;
  avg_30: number;
};

const RATES: { [key: number]: Rate } = {};
RATES_TSV.split('\n').forEach((row) => {
  const [year, low_30, high_30, avg_30] = row.split('\t').map(v => parseFloat(v));
  RATES[year] = { low_30, high_30, avg_30 };
});
export default RATES;
