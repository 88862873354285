import React, { useEffect } from 'react';
import { Tabs, Tab, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useUser from '../../api/queries/users/useUser';
import { ChargeOffInfo, Loan, PROGRAMS } from '../../api/queries/types';
import { LegacyLoanApplication } from '../../api/queries/users/useLoanApplications';
import IdentityCard from '../../components/LoanOverview/IdentityCard';
import ContactInfoCard from '../../components/LoanOverview/ContactCard';
import useAddresses from '../../api/queries/users/useAddresses';
import ShippingAddressCard from '../../components/LoanOverview/ShippingAddressCard';
import LoanApplicationOverviewHeader from '../../components/LoanOverview/LoanApplicationOverviewHeader';
import useLoanApplicationContactUser from '../../api/queries/loan-applications/useLoanApplicationContactUser';
import styles from './LoanApplicationShowLayout.module.scss';
import { getDateInTimezone } from '../../utils/datetime';

export default function LoanApplicationShowLayout ({ selectedTab, children, loan, loanApplication }: { selectedTab: string, children: React.ReactNode, loan?: Loan, loanApplication: LegacyLoanApplication }) {
  const userReq = useUser(loanApplication.userId);
  const addressesReq = useAddresses(loanApplication.userId);
  const contactUserReq = useLoanApplicationContactUser(loanApplication._id);
  const navigate = useNavigate();

  const addresses = addressesReq.data ?? [];
  const currentAddress = addresses.find(address => address._id === 'HOME');
  const previousAddress = addresses.find(address => address._id === userReq.data?.previousAddressId);
  const shippingAddress = addresses.find(address => address._id === loanApplication.mailingAddressId);

  useEffect(() => {
    if (loanApplication.firstName && loanApplication.lastName) {
      document.title = `${loanApplication.firstName} ${loanApplication.lastName} | Notebook`
    }
  }, [loanApplication.firstName, loanApplication.lastName]);

  const LoanApplicationTabs = () => {
    // Only show Lens once the borrower has completed all the pre-submission fields.
    const showLensTab = loanApplication &&
      (Boolean(loanApplication.submittedAt) || Boolean(loanApplication.loan)) &&
      loanApplication.propertyOwnershipType === 'selling';
    const showUnderwritingTab = loanApplication && loanApplication.program === PROGRAMS.HOME_CARD;

    return <Tabs
      defaultActiveKey={selectedTab}
      id='loan-tabs'
      onSelect={(selected: string) => navigate(`/loan-applications/${loanApplication._id}${selected ? `/${selected}` : ''}`) }
      className='mb-3'
    >
      { !!loan ? <Tab eventKey='loan' title='Summary'/> : null }
      <Tab eventKey='' title='Application' selected/>
      { showUnderwritingTab ?  <Tab eventKey='underwriting' title='Underwriting'/> : null }
      { showLensTab ? <Tab eventKey='lens' title='Lens'/> : null }
      <Tab eventKey='documents' title='Documents'/>
      { !!loan ? <Tab eventKey='servicing' title='Servicing'/> : null }
      <Tab eventKey='timeline' title='Timeline'/>
    </Tabs>
  }

  return <>
    <LoanApplicationOverviewHeader user={userReq.data} contactUser={contactUserReq.data} loan={loan} mutateContactUser={contactUserReq.mutate} loanApplication={loanApplication} />
    <Row>
      <Col sm={8} className={styles.applicationColumn}>
        { loan?.chargeOffInfo &&
          <ChargeOffAlert info={ loan.chargeOffInfo }/>
        }
        <LoanApplicationTabs/>
        { children }
      </Col>
      <Col sm={4} className={`pb-3 ${styles.usersColumn}`}>
        <IdentityCard user={userReq.data} previousAddress={previousAddress} currentAddress={currentAddress}/>
        <ContactInfoCard user={contactUserReq.data} className='mt-3' loanApplication={loanApplication}/>
        <ShippingAddressCard address={shippingAddress} className='mt-3'/>
      </Col>
    </Row>
  </>;
}

function ChargeOffAlert ({ info }: { info: ChargeOffInfo }) {
  return (
    <Alert variant='danger' className='mb-4 d-flex flex-column'>
      <>
        <div>{ info.chargedOffBy ?? '—' } marked this loan charged-off as of { getDateInTimezone(info.effectiveDate).format('MMMM D, YYYY') }.</div>
        { info.notes &&
          <div>"{ info.notes }"</div>
        }
      </>
    </Alert>
  );
}
