import React from 'react';
import { useParams } from 'react-router-dom';
import SystemLayout from '../../Layouts/SystemLayout';
import { useInvestor } from '../../../api/queries/investors/useInvestors';
import ShowInvestorLayout from '../../Layouts/ShowInvestorLayout';
import { LabelValueGrouping } from '../../../components';
import { titleCase } from '../../../utils/string';

export default function InvestorsShowInfoPage () {
  const params = useParams<{ investorKey: string }>();
  const investorKey = params.investorKey as string;
  const investor = useInvestor(investorKey);

  return (
    <SystemLayout selectedTab='investors' heading={titleCase(investorKey)}>
      <ShowInvestorLayout
        investorKey={ investorKey }
        selectedTab='info'
      >
        { investor.data ? <div>
          <LabelValueGrouping condensed data={investor.data} />
        </div> : <div>Loading…</div> }
      </ShowInvestorLayout>
    </SystemLayout>
  );
}
