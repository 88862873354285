import React from 'react';
import './SplitView.css';

const SplitView: any = ({ className, ...props }: React.ComponentPropsWithoutRef<"div">) => {
  const cx = ['SplitView', 'd-flex', className].filter(Boolean);
  return <div className={ cx.join(' ') } { ...props }/>;
}

SplitView.PrimarySection = function PrimarySection ({ className, ...props }: React.ComponentPropsWithoutRef<"div">) {
  const cx = ['_Primary', className].filter(Boolean);
  return <div className={ cx.join(' ') } { ...props }/>;
}

SplitView.SecondarySection = ({ className, ...props }: React.ComponentPropsWithoutRef<"div">) => {
  const cx = ['_Secondary', className].filter(Boolean);
  return <div className={ cx.join(' ') } { ...props }/>;
}

export default SplitView;
