import React from 'react';
import { Badge } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import * as _ from 'lodash';

import ExplainerPopover from '../../ExplainerPopover';
import LabelValueGrouping from '../../LabelValueGrouping';
import { underwritingRulesVersion } from '../../../utils/underwriting';
import formatDollars, { formatDollarsWithColor } from '../../../utils/formatDollars';
import { LegacyLoanApplication } from '../../../api/queries/users/useLoanApplications';

export default function CreditReport ({ loanApplication }: { loanApplication: LegacyLoanApplication }) {
  let avm;

  if (loanApplication.zestimateFound) {
    avm = <>{ formatDollars(loanApplication.avmMinimum) }&thinsp;&ndash;&thinsp;{ formatDollars(loanApplication.avmMaximum) }</>;
  } else {
    avm = (
      <ExplainerPopover explanation='Zestimate not found. Using synthetic AVM from borrower-provided listing price.'>
        <Badge variant='warning'>Not found</Badge>
      </ExplainerPopover>
    );
  }

  return (
    <Card>
      <Card.Header>
        Underwriting Summary
      </Card.Header>
      <Card.Body>
        <LabelValueGrouping
          data={{
            'Ownership status (TheNumber)': loanApplication.listingOwnershipStatus,
          }}
          condensed={ true }
          highlight={ true }
          removeBlanks
        />
        <LabelValueGrouping
          data={{
            'Stated List Price': formatDollars(loanApplication.listingPrice) || '–',
            'Verified List Price': formatDollars(loanApplication.verifiedListingPrice),
            AVM: avm,
            'Stated Property Debt': formatDollars(loanApplication.propertyDebt) || '–',
            'Stated Available Equity': formatDollarsWithColor(loanApplication.availableEquityCents, { cents: true }) || '–',
          }}
          className="mb-4"
          condensed={ true }
          removeBlanks
        />
        {
          !_.isNil(loanApplication.verifiedPropertyDebtCents) || !_.isNil(loanApplication.verifiedAvailableEquityCents) ?
            (
              <LabelValueGrouping
                data={{
                  'Verified Property Debt': formatDollars(loanApplication.verifiedPropertyDebtCents, { cents: true }),
                  'Verified Available Equity': formatDollarsWithColor(loanApplication.verifiedAvailableEquityCents, { cents: true }),
                }}
                className="mb-4"
                condensed={ true }
                removeBlanks
              />
            ) : null
        }
        <LabelValueGrouping
          data={{
            'Requested Amount': formatDollars(loanApplication.principal) || '–',
            'Maximum Amount': formatDollars(loanApplication.maximumAmount) || '–',
            'Underwriting Rules Version': underwritingRulesVersion(loanApplication.underwritingResult) || '–',
          }}
          condensed={ true }
          removeBlanks
        />
      </Card.Body>
    </Card>
  );
}
