import React from 'react';
import _ from 'lodash';
import { PROPERTY_DEBT_HELOC_CALCULATOR_KEYS, TIMELINETYPE } from '../../../utils/constants';

export default function useDebtCalculation (latestVerification: any, parsedRecordsById: any = {}) {
  const { initialSelectedRecordIDs, initialManualDebtEntries, defaultHelocKey } = React.useMemo(() => {
    let _initialSelected = [];
    let _initialManual = [];
    // Default to creditLimit going forward
    let _defaultKey = PROPERTY_DEBT_HELOC_CALCULATOR_KEYS.CREDIT_LIMIT;
    if (latestVerification) {
      _initialSelected = latestVerification.selectedReportedDebts.map((r: any) => r.key)
      _initialManual = latestVerification.selectedReportedDebts.map((r: any) => r.manual_entry).filter(Boolean);
      // If the latest verification had key saved, use it
      // Otherwise, latest verification is old (and we should default to using old property)
      _defaultKey = latestVerification.helocCalculatorKey || PROPERTY_DEBT_HELOC_CALCULATOR_KEYS.CURRENT_BALANCE;
    }
    return { initialSelectedRecordIDs: _initialSelected, initialManualDebtEntries: _initialManual, defaultHelocKey: _defaultKey };
  }, [latestVerification]);

  const [selectedRecordIDs, setSelectedRecords] = React.useState(initialSelectedRecordIDs);
  const [manualDebtEntries, setManualDebtEntries] = React.useState(initialManualDebtEntries);

  const allRecordsById = React.useMemo(() => {
    let _records = { ...parsedRecordsById };
    // Add manually recorded debt to mapping but exclude from timeline
    // TODO: include in timeline?
    manualDebtEntries.forEach((item: any, i: any) => {
      _records[item._id] = item;
    });
    return _records;
  }, [parsedRecordsById, manualDebtEntries]);

  const toggleCalculatorRecord = React.useCallback((...recordIds) => {
    let _selected = [...selectedRecordIDs];
    for (let recordId of recordIds) {
      if (_selected.includes(recordId)) {
        _selected = _.without(_selected, recordId);
      } else {
        _selected.push(recordId);
      }
    }
    setSelectedRecords(_selected);
  }, [selectedRecordIDs]);

  const addManualDebtRecord = React.useCallback((values) => {
    const record = {
      item: values,
      date: values.date_opened ? new Date(values.date_opened) : null,
      dateStr: values.date_opened ? values.date_opened.split('T')[0] : '',
      type: TIMELINETYPE.MANUAL,
      _id: `${ TIMELINETYPE.MANUAL }_${ values.debt_type }_${ Date.now().toString() }`,
    }
    setManualDebtEntries([...manualDebtEntries, record]);
    setSelectedRecords([...selectedRecordIDs, record._id]);
  }, [selectedRecordIDs, manualDebtEntries]);

  const [helocCalculatorKey, setHelocCalculatorKey] = React.useState(defaultHelocKey);

  return {
    toggleCalculatorRecord,
    selectedRecordIDs,
    allRecordsById,
    initialSelectedRecordIDs,
    addManualDebtRecord,
    isChanged: () => {
      return _.difference(selectedRecordIDs, initialSelectedRecordIDs).length > 0;
    },
    helocCalculatorKey,
    setHelocCalculatorKey,
  };
}

