import React from 'react';
import GenericModal from '../GenericModal';
import useExpandableState from '../../../utils/useExpandableState';
import { Loan } from '../../BorrowerTabs/ServicingTab/Activity';
import useIdempotencyKey from '../../../utils/useIdempotencyKey';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import Decimal from 'decimal.js';
import RefundRequestForm, { RefundRequestFormFields } from './RefundRequestForm';

type Props = {
  loan: Loan;
  modal: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
};

export function CreateRefundRequestModal (props: Props) {
  const { loan, modal, onSubmit } = props;
  const requestKey = useIdempotencyKey([modal.isExpanded]);

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loans/${ loan._id }/refunds/create`,
    method: 'POST',
  });

  const defaultValues: RefundRequestFormFields = {
    amount: '',
    effective_date: '',
    purchase_method_id: '',
    type: 'ach', // TODO: refactor when allowing for refunds other than ACH
    notes: '',
  };

  const closeModal = () => {
    modal.hideExpand();
  }

  const handleSubmit = async (form: RefundRequestFormFields) => {
    await callAPI({ data: {
      ...form,
      requestKey,
      amount: new Decimal(form.amount).times(100).toNumber(),
    }})
    onSubmit();
    closeModal();
  };

  return (
    <GenericModal expandable={ modal } title='Refund Details' onClose={ closeModal }>
      <RefundRequestForm
        loanId={ loan._id }
        handleSubmit={ handleSubmit }
        handleCancel={ closeModal }
        defaultValues={ defaultValues }
      />
    </GenericModal>
  );
};
