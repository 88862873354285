import useQuery from '../../shared/useQuery';

export type AccountingBalancesResponse = {
  balances: {
    principal: number;
    investor_principal: number;
    interest_receivable: number;
    deferred_interest: number;
    fees_receivable: number;
    investor_uncollected_interest: number;
    notable_uncollected_interest: number;
    overpayments: number;
  };
  valid_through_date: string;
};

export default function useAccountingBalanceBreakdown (loanId: string) {
  return useQuery<AccountingBalancesResponse>(`/notebook/loans/${loanId}/accounting/balances`);
}
