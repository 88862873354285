import useQuery from '../../shared/useQuery';

export enum KycStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
  PENDING = 'pending',
}

export type KycResult = {
  _id: string;
  address_line1: string;
  address_line2: string;
  city: string;
  createdAt: string;
  dob: string;
  firstName: string;
  lastName: string;
  notes: string;
  isManualVerification: boolean;
  isPreviousAddress: boolean;
  failureCodes: Array<string>;
  reason: string;
  status: KycStatus;
  state: string;
  ssnLastFour: string;
  supportingDocuments: Array<{
    filename: string;
    type: string;
  }>;
  zip: string;
  marqetaProgramSlug: string;
};

type Response = Array<KycResult>;

export default function useKycResults (userId?: string) {
  return useQuery<Response>(userId ? `/notebook/users/${userId}/kyc-results` : null);
}
