import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import SubmitError from '../../Form/SubmitError';

import InlineButton from '../../InlineButton';
import { putAPIEndpoint } from '../../../utils/useAdminAPIData';
import { useExpandableState } from '../../../utils';
import ButtonWithSpinner from '../../ButtonWithSpinner';

type FormModalProps = {
  fieldName: string;
  fieldValidation?: yup.Schema;
  fieldInitialValue: any;
  loanId: string;
  onHide: () => void;
  show: boolean;
  fieldTitle: string;
  renderControl: React.FC<{ value: any }>;
  onChange: () => void;
};

function FormModal (props: FormModalProps) {
  const handleSubmit = async (data: any) => {
    await putAPIEndpoint(`/notebook/loans/${ props.loanId }/servicing`, {
      [props.fieldName]: data[props.fieldName] || null, // Convert empty strings to null
      notes: data.notes,
    });
    props.onHide();
    props.onChange();
  };

  const schema = yup.object({
    [props.fieldName]: props.fieldValidation || yup.string(),
    notes: yup.string(),
  });

  const methods = useForm({
    defaultValues: {
      [props.fieldName]: props.fieldInitialValue || '',
      notes: '',
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty }, reset, watch } = methods;
  const values = watch();

  const hideAndReset = () => {
    reset();
    props.onHide();
  };
  return (
    <Modal show={ props.show } onHide={ hideAndReset }>
      <Modal.Header closeButton>
        <Modal.Title>Edit { props.fieldTitle }</Modal.Title>
      </Modal.Header>
      <Form {...methods} onSubmit={handleSubmit}>
        <Modal.Body>
          { props.renderControl({ value: values[props.fieldName] }) }

          <Field name='notes' label='Notes' controlProps={{ as: 'textarea' }} />

          <SubmitError/>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant   = 'secondary'
            onClick   = { hideAndReset }
            disabled  = { isSubmitting }
          >
            Discard Changes
          </Button>
          <ButtonWithSpinner
            type      = 'submit'
            variant   = 'primary'
            disabled  = { !isDirty }
            loading   = { isSubmitting }
          >
            Update { props.fieldTitle }
          </ButtonWithSpinner>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

type Props = {
  loanId: string;
  onChange: () => void;
  name: string;
  title: string;
  validation?: yup.Schema;
  initial_value: any;
  renderControl: React.FC<{ value: any }>;
};

export default function EditableServicingField (props: Props) {
  const { loanId, onChange } = props;
  const modal = useExpandableState(false);

  return (
    <>
      <InlineButton onClick={ modal.showExpand }>edit</InlineButton>
      <FormModal
        loanId            = { loanId }
        show              = { modal.isExpanded }
        onHide            = { modal.hideExpand }
        onChange          = { onChange }
        fieldName         = { props.name }
        fieldTitle        = { props.title }
        fieldValidation   = { props.validation }
        fieldInitialValue = { props.initial_value }
        renderControl     = { props.renderControl }
      />
    </>
  );
}
