import { useState } from 'react';
import { AxiosResponse } from 'axios';
import { axiosInstanceAPI } from './axiosInstanceAPI';

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';
type State<Response> = {
  data?: Response;
  error: unknown;
  isLoading: boolean;
  isSuccess: boolean;
};

export default function useMutation<Payload, Response> (method: Method, url: string, onSuccess?: (data: AxiosResponse<Response>) => void) {
  const [state, setState] = useState<State<Response>>({
    data: undefined,
    error: false,
    isLoading: false,
    isSuccess: false,
  });

  function mutate (data: Payload) {
    setState({ data: undefined, error: false, isSuccess: false, isLoading: true });

    const key = method === 'GET' ? 'params' : 'data';
    const req = axiosInstanceAPI.request<Response>({ method, url, [key]: data });

    req.then(response => {
      setState({ data: response.data, error: false, isSuccess: true, isLoading: false });
      onSuccess?.(response);
    }).catch(error => {
      setState({ data: undefined, error, isSuccess: false, isLoading: false });
    });

    return req;
  }

  return { ...state, mutate };
}
