import React from 'react';
import GenericModal from '../GenericModal';
import ModifyPortfolioForm from './ModifyPortfolioForm';
import useExpandableState from '../../../utils/useExpandableState';
import { Loan } from '../../../api/queries/types';

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  loan: Loan;
  onSubmit: (data: unknown) => void;
};

export default function ModifyPortfolioModal (props: Props) {
  const { expand, loan, onSubmit } = props;
  return (
    <GenericModal expandable={expand} title="Modify Current Portfolio" size="lg">
      <ModifyPortfolioForm loan={loan} onHide={expand.hideExpand} onSubmit={onSubmit} />
    </GenericModal>
  );
};
