import React from 'react';
import { LegacyLoanApplication } from '../../../../api/queries/users/useLoanApplications';
import { useAPIData } from '../../../../utils/index';
import { Product, Rule } from '../index';
import { Card } from 'react-bootstrap';
import ProductsTable from './ProductsTable';
import RulesTable from '../RulesTable';
import { RULE_GROUPS, RULE_TYPE } from '../constants';

function byGroup (rules: Array<Rule>, group: string) {
  return rules.filter(rule => rule.notebookGroup === group);
}

export default function UnderwritingSummary ({ loanApplication }: { loanApplication: LegacyLoanApplication }) {
  const rulesReq = useAPIData<Array<Rule>>(`/notebook/loan-applications/${loanApplication._id}/underwriting-rules`);
  const productsReq = useAPIData<Array<Product>>(`/notebook/loan-applications/${loanApplication._id}/offered-plan-summaries`);
  const rules = rulesReq.data || [];
  const products = productsReq.data || [];

  const requiredRules = rules.filter(rule => rule.required); // Hide non-required rules from UI
  const verificationRules = byGroup(requiredRules, RULE_GROUPS.VERIFICATION);
  const flagRules = byGroup(requiredRules, RULE_GROUPS.FLAG);
  const creditRules = byGroup(requiredRules, RULE_GROUPS.CREDIT);
  const counterOfferRules = byGroup(requiredRules, RULE_GROUPS.COUNTEROFFER);
  const eligibilityRules = byGroup(requiredRules, RULE_GROUPS.ELIGIBILITY);
  const otherEligibilityRules = byGroup(requiredRules, RULE_GROUPS.OTHER_ELIGIBILITY);

  return (
    <>
      <div className="mb-5">
        <div className="border-bottom">
          <h4>Underwriting: Rules</h4>
        </div>
        <Card className="mt-4">
          <Card.Header>
            <ProductsTable products={products} />
          </Card.Header>
          <Card.Body>
            <RulesTable
              title="SUSPICIOUS ACTIVITY"
              type={RULE_TYPE.FLAG}
              rules={flagRules}
            />
            <RulesTable
              title="VERIFICATION"
              description="Facts are derived from underwriting data. All verification rules must pass for a loan to be approved."
              type={RULE_TYPE.VERIFICATION}
              rules={verificationRules}
            />
            <RulesTable
              title="CREDIT"
              description="Facts are derived from underwriting data. All verification rules must pass for a loan to be approved."
              type={RULE_TYPE.CREDIT}
              rules={creditRules}
            />
            <RulesTable
              title="COUNTEROFFER"
              description="Counteroffer rules."
              type={RULE_TYPE.COUNTEROFFER}
              rules={counterOfferRules}
            />
            <RulesTable
              title="ELIGIBILITY"
              description="Facts are derived from underwriting data. All verification rules must pass for a loan to be approved."
              type={RULE_TYPE.ELIGIBILITY}
              rules={eligibilityRules}
            />
            <RulesTable
              title="OTHER ELIGIBILITY"
              description="Other system checks that must pass for a borrower to be eligible."
              type={RULE_TYPE.ELIGIBILITY}
              rules={otherEligibilityRules}
            />
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
