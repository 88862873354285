import React, { useContext } from 'react';

import KustomerLink from '../KustomerLink';
import styles from './LoanApplicationOverviewHeader.module.scss'
import useScrollPositionReached from '../../utils/useScrollPositionReached';
import { Col, Container, Navbar, Row } from 'react-bootstrap';
import { User } from '../../api/queries/users/useUser';
import ClarityLink from '../ClarityLink';
import PauseUserDropdown from '../PauseUserDropdown';
import PausedUserAlert from './PausedUserAlert';
import AdminContext, { ROLES } from '../../adminContext';
import MarqetaLinks from '../MarqetaLinks';
import { LOAN_APPLICATION_SOURCES, LOAN_APPLICATION_STATUSES, LOAN_STATES, UNDERWRITING_OUTCOMES } from '../../utils/constants';
import LoanSlug from '../LoanSlug';
import PausedBadge from '../Badges/PausedBadge';
import LoanPartnerBadge from '../Badges/LoanPartnerBadge';
import { formatDollars } from '../../utils';
import LoanModificationBadge from '../Badges/LoanModificationBadge';
import LoanStatusBadge from '../Badges/LoanStatusBadge';
import FundingPeriodBadge from '../Badges/FundingPeriodBadge';
import LoanPastDueDaysBadge from '../Badges/LoanPastDueDaysBadge';
import LoanCollectionsStatusBadge from '../Badges/LoanCollectionsStatusBadge';
import LoanSpecialProvisionsBadge from '../Badges/LoanSpecialProvisionsBadge';
import LoanPortfolioBadge from '../Badges/LoanPortfolioBadge';
import { Loan } from '../../api/queries/types';
import { LegacyLoanApplication } from '../../api/queries/users/useLoanApplications';
import LoanApplicationStatusBadge from '../Badges/LoanApplicationStatusBadge';
import { get } from 'lodash';
import LoanApplicationFromAgentBadge from '../Badges/LoanApplicationFromAgentBadge';
import useLatest from '../../api/queries/underwriting-decisions/useLatest';
import LoanApplicationUnderwritingDecisionBadge from '../Badges/LoanApplicationUnderwritingDecisionBadge';
import { LoanSummary } from '../../api/queries/users/useLoansSummaries';
import POABadge from '../Badges/POABadge';
import TrelloLinks from '../TrelloLinks';

type Props = {
  user?: User;
  mutateContactUser: () => void;
  loan?: Loan
  loanApplication: LegacyLoanApplication;
  contactUser?: User;
};

export default function LoanApplicationOverviewHeader ({ user, mutateContactUser, loan, loanApplication, contactUser }: Props) {
  const adminContext = useContext(AdminContext);
  const isNavStuck = useScrollPositionReached(75);
  const { data: underwritingDecision } = useLatest(loanApplication._id, loanApplication.status === LOAN_APPLICATION_STATUSES.CLOSED);
  const ineligibleLoanApp = underwritingDecision && underwritingDecision.outcome === UNDERWRITING_OUTCOMES.INELIGIBLE;

  if (!user || !contactUser) { return null; }

  const agentLoanApp = get(loanApplication,'underwritingResult.LOAN_APPLICATION_FROM_AGENT.failed', false);

  return (
    <>
      <Navbar
        sticky="top"
        className={ `${styles.navBar} ${isNavStuck ? '' : 'border-bottom'}` }
        data-stuck={ isNavStuck }
      >
        <Container className={`d-block pb-3 ${isNavStuck ? 'pt-3' : ''}`}>
          <Row className='w-100 ml-0'>
            <Col className='d-flex px-0'>
              <div className='my-auto'>
                <div>
                  <h4>
                  { loanApplication.firstName } { loanApplication.lastName }
                  { loan ? <> • <LoanSlug>{ loan.slug }</LoanSlug></> : null }
                  { !!loanApplication.principal ? <> • {formatDollars(loanApplication.principal)}</> : null }
                  </h4>
                </div>
                <div className={`mt-1 ${styles.badges}`} data-qa='badges'>
                  <LoanPartnerBadge partnerId={ loanApplication.partner._id } source={loanApplication.isSourceBetter ? LOAN_APPLICATION_SOURCES.BETTER : LOAN_APPLICATION_SOURCES.STATED} />
                  { loan ? <LoanStatusBadge status={ loan.status } /> : <LoanApplicationStatusBadge status={ loanApplication.status } /> }
                  { ineligibleLoanApp && <LoanApplicationUnderwritingDecisionBadge outcome={underwritingDecision.outcome} /> }
                  { loan && <LoanBadges loan={ loan } /> }
                  { agentLoanApp && // LEGACY BADGE
                    <LoanApplicationFromAgentBadge />
                  }
                  { loanApplication.isUserPaused && <PausedBadge/> }
                  { loanApplication.isPOA && <POABadge/> }
                </div>
              </div>
              <div className='d-flex ml-auto my-auto'>
                <ClarityLink block={ true } user={ contactUser } className={`ml-2 mt-0 ${styles.buttonLink}`} />
                <KustomerLink block={ true } user={ contactUser } onSyncToKustomer={ mutateContactUser } className={`ml-2 mt-0 ${styles.buttonLink}`} />
                <MarqetaLinks marqetaUsers={user.marqetaUsers} className={`ml-2 mt-0 ${styles.buttonLink}`}/>
                <TrelloLinks loanApplication={ loanApplication} className={`ml-2 mt-0 ${styles.buttonLink}`} />
                { adminContext.adminRole === ROLES.SUPERADMIN &&
                  <PauseUserDropdown user={ contactUser } className={`ml-2 mt-0 ${styles.buttonLink}`} />
                }
              </div>
            </Col>
          </Row>

          { contactUser.is_paused &&
            <Row className='w-100 ml-0 pb-2'>
              <Col className='px-0 pt-3'>
                <PausedUserAlert user={ contactUser } />
              </Col>
            </Row>
          }

        </Container>
      </Navbar>
    </>
  );
}

export function LoanBadges ({ loan }: { loan: Loan | LoanSummary }) {
  const showPastDueBadge = !!(loan.daysPastDue && loan.daysPastDue > 0 && loan.status !== LOAN_STATES.REPAID);

  return (<>
    { loan.hasModifications && <LoanModificationBadge /> }
    <FundingPeriodBadge status={ loan.status } />
    { showPastDueBadge && <LoanPastDueDaysBadge days={ loan.daysPastDue } /> }
    <LoanCollectionsStatusBadge collectionsStatus={ loan.collectionsStatus } />
    <LoanSpecialProvisionsBadge specialProvisions={ loan.specialProvisions } />
    <LoanPortfolioBadge portfolio={ loan.portfolio! } />
  </>);
}
