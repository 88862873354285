import React from 'react';
import useAdminAPICall from '../utils/useAdminAPICall';
import ButtonWithSpinner from './ButtonWithSpinner';
import OutboundButton from './OutboundButton';

type Props = {
  user: { _id: string, kustomerId?: string };
  className?: string;
  block?: boolean;
  onSyncToKustomer: () => void;
}

export default function KustomerLink (props: Props) {
  const { user, onSyncToKustomer, className } = props;
  const { callAPI, loadPending } = useAdminAPICall({ endpoint: `/notebook/users/${user._id}/sync-kustomer-profile`, method: 'POST' });

  const onSyncClick = async () => {
    await callAPI();
    await onSyncToKustomer();
  }

  if (!user.kustomerId) {
    return (
      <ButtonWithSpinner
        onClick={onSyncClick}
        loading={loadPending}
        className={className}
        size="sm"
      >
        Sync to Kustomer
      </ButtonWithSpinner>
    )
  } else return (
    <OutboundButton
      href={ `https://slatelending.kustomerapp.com/app/customers/${user.kustomerId}` }
      disabled={ !user }
      block={ props.block }
      className={className}
      size="sm"
    >
      Kustomer
    </OutboundButton>
  );
}

