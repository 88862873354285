import React from 'react';
import GenericModal from './GenericModal';
import useAdminAPICall from "../../utils/useAdminAPICall";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { capitalize } from 'lodash';
import * as yup from "yup";
import Form from '../Form/Form';
import Field from '../Form/Field';
import SubmitError from '../Form/SubmitError';
import useExpandableState from '../../utils/useExpandableState';
import useIncomes from '../../api/queries/loan-applications/useIncomes';
import DocumentsField from '../BorrowerTabs/DocumentsTab/DocumentsField';
import ButtonWithSpinner from '../ButtonWithSpinner';

type DocumentType = {
  s3Filename: string | null,
  originalFilename: string | null,
};

type FieldValues = {
  type: string;
  referenceId?: string;
  documents: Array<DocumentType>;
};

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
  loanApplicationId: string;
};

export default function AddDocumentModal (props: Props) {
  const { expand, onSubmit, loanApplicationId } = props;
  const { data: incomes } = useIncomes(loanApplicationId);

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loan-applications/${loanApplicationId}/documents`,
    method: 'POST',
  });

  async function handleSubmit(data: FieldValues) {
    const validDocuments = data.documents.filter((document) => document.s3Filename);
    await callAPI({
      data: {
        type: data.type,
        documents: validDocuments,
        referenceId: data.type === 'income' ? data.referenceId : undefined,
      },
    });
    onSubmit();
    expand.hideExpand();
  }

  const schema = yup.object().shape({
    type: yup.string().required('Please select a type'),
    referenceId: yup.string(),
    documents: yup.array(yup.object({
      s3Filename: yup.string(),
      originalFilename: yup.string(),
    })).min(2, 'Please Upload a document'),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      type: '',
      referenceId: '',
      documents: [{ s3Filename: '', originalFilename: '' }],
    },
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });
  const { formState: { isSubmitting }, watch } = methods;
  const type = watch('type');

  return (
    <GenericModal expandable={expand} title={'Add a Document'} size="lg">
      <Form {...methods} onSubmit={handleSubmit}>
        <div className='border-bottom pb-2'>
          <Field
            label='What was verified with this document?'
            name='type'
            controlProps={{ as: 'select', className: 'addSelectCaret' }}
          >
            <option disabled hidden value=''>Select</option>
            <option value='debt'>Debt</option>
            <option value='income'>Income</option>
            <option value='listing_agreement'>Listing Agreement</option>
            <option value='other'>Other</option>
          </Field>

          { type === 'income' &&
            <Field
              label='Income Source'
              name='referenceId'
              controlProps={{ as: 'select', className: 'addSelectCaret' }}
            >
              <option value=''>Select</option>
              {incomes && incomes.map((income) =>
                <option value={income.id} key={income.id}>{`${capitalize(income.type || income.source)} - $${income.monthlyAmountDollars}`}</option>
              )}
            </Field>
          }

          <DocumentsField />

          <SubmitError/>
        </div>
        <div className="d-flex justify-content-end mt-4">
          <Button variant="secondary" onClick={expand.hideExpand} className="mr-2">
            Cancel
          </Button>
          <ButtonWithSpinner variant="primary" type="submit" loading={isSubmitting}>
            Confirm
          </ButtonWithSpinner>
        </div>
      </Form>
    </GenericModal>
  );
}
