import React from 'react';
import _ from 'lodash';
import { Alert } from 'react-bootstrap';
import InlineLoadingIndicator from '../../InlineLoadingIndicator';
import LabelValueGrouping from '../../LabelValueGrouping';
import formatDollars from '../../../utils/formatDollars';

export default function PropertyLienTransactionSummary (props: { lienReq: any }) {
  const { lienReq } = props;

  let numLRLiens = '–';
  let sumLRDebt = '–';
  const lienReport = _.get(lienReq.data, ['Data', 'Transactions']);

  if (lienReport) {
    numLRLiens = lienReport.length;
    sumLRDebt = formatDollars(
      _.sumBy(lienReport, (t: any) => t.Amount)
    );
  }

  return lienReq.loadSuccess ? (
    lienReq.data.error ? (
      <Alert variant='warning'>
        { lienReq.data.message }
      </Alert>
    ) : (
      <LabelValueGrouping data={{
        '# liens': numLRLiens,
        'Sum of debt': sumLRDebt,
      }} />
    )
  ) : lienReq.loadPending ? (
    <><InlineLoadingIndicator /> Loading lien report…</>
  ) : lienReq.loadError || (lienReq.data && lienReq.data.error) ? (
    <Alert variant='danger'>
      { _.get(lienReq.data, 'message', 'An error occured. Please try again or contact Engineering if the error persists') }
    </Alert>
  ) : null;
}

