import React from 'react';
import { Card } from 'react-bootstrap';
import { LegacyLoanApplication } from '../../../api/queries/users/useLoanApplications';

import UnderwritingFactors from '../../UnderwritingFactors';

export default function UnderwritingRules ({ loanApplication }: { loanApplication: LegacyLoanApplication }) {
  return (
    <Card>
      <Card.Header>
        Underwriting Rules
      </Card.Header>
      <Card.Body>
        <UnderwritingFactors loanApplication={loanApplication} />
      </Card.Body>
    </Card>
  );
}
