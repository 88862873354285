import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SystemLayout from '../../Layouts/SystemLayout';
import ShowInvestorLayout from '../../Layouts/ShowInvestorLayout';
import { titleCase } from '../../../utils/string';
import { Portfolio, usePortfolios } from '../../../api/queries/investors/usePortfolios';
import { Table } from 'react-bootstrap';

export default function InvestorsShowInfoPage () {
  const params = useParams<{ investorKey: string }>();
  const investorKey = params.investorKey as string;
  const portfolios = usePortfolios(investorKey);

  return (
    <SystemLayout selectedTab='investors' heading={titleCase(investorKey)}>
      <ShowInvestorLayout
        investorKey={ investorKey }
        selectedTab='portfolios'
      >
        { portfolios.data ? <div>
          <PortfoliosTable portfolios={portfolios.data} investorKey={investorKey} />
        </div> : <div>Loading…</div> }
      </ShowInvestorLayout>
    </SystemLayout>
  );
}

function PortfoliosTable ({ portfolios, investorKey }: { portfolios: Array<Portfolio>, investorKey: string }) {
  const navigate = useNavigate();

  return <Table>
    <tbody>
      {
        portfolios.map(portfolio => (
          <tr key={portfolio.key} className='cursor-pointer' onClick={ () => navigate(`/system/investors/${investorKey}/portfolios/${portfolio.key}`) }>
            <td>{portfolio.name}</td>
          </tr>
        ))
      }
    </tbody>
  </Table>
}
