import { CollectionStatuses } from '../../../utils/constants';
import useQuery from '../../shared/useQuery';
import { Portfolio } from '../partners/usePortfolios';
import { ChargeOffInfo } from '../types';

export type LoanSummary = {
  _id: string;
  authorizedAt?: Date;
  chargeOffInfo: ChargeOffInfo | null;
  collectionsStatus?: CollectionStatuses;
  daysPastDue: number;
  hasModifications?: boolean;
  isUserPaused: boolean;
  loanApplicationSource: string;
  originationDate: string;
  partnerId: string;
  portfolio: Portfolio | null;
  principle: string;
  program: string;
  slug: string;
  specialProvisions?: string;
  status: string;
  isActive: boolean;
  user: { _id: string, name: string },
  loanApplicationId: string;
  isPOA: boolean;
  createdAt: Date;
}

export default function useLoansSummaries (userId?: string) {
  return useQuery<Array<LoanSummary>>(userId ? `/notebook/users/${userId}/loans/summaries` : null);
}
