import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SystemLayout from '../../Layouts/SystemLayout';
import ShowInvestorLayout from '../../Layouts/ShowInvestorLayout';
import { useInvestorReports } from '../../../api/queries/investors/useReports';
import { Pagination, Table } from 'react-bootstrap';
import { titleCase } from '../../../utils/string';

export default function InvestorsShowReportsPage () {
  const params = useParams<{ investorKey: string }>();
  const [queryParams] = useSearchParams({ page: '1' });
  const navigate = useNavigate();
  const currentPage = parseInt(queryParams.get('page') as string, 10) || 1;
  const investorKey = params.investorKey as string;
  const reports = useInvestorReports(investorKey, currentPage);

  const nextPage = currentPage + 1;
  const prevPage = currentPage === 1 ? null : currentPage - 1;
  return (
    <SystemLayout selectedTab='investors' heading={titleCase(investorKey)}>
      <ShowInvestorLayout
        investorKey={ investorKey }
        selectedTab='reports'
      >
        <Pagination>
          <Pagination.Prev onClick={() => navigate(`/system/investors/${investorKey}/reports?page=${ prevPage }`)} disabled={!prevPage} />
          <Pagination.Next onClick={() => navigate(`/system/investors/${investorKey}/reports?page=${ nextPage }`)} disabled={!nextPage} />
        </Pagination>
        <Table>
          <tbody>
            {
              reports.data?.map(report => (
                <tr key={ report._id } className='cursor-pointer' onClick={ () => navigate(`/system/investors/${investorKey}/reports/${report._id}`)}>
                  <td>{ report.report_date }</td>
                  <td>{ report.type }</td>
                  <td>{ report.isDelivered ? 'Delivered' : 'Undelivered' }</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </ShowInvestorLayout>
    </SystemLayout>
  );
}
