import Decimal from 'decimal.js';
import formatDollars, { formatDollarsAccountingNotation, formatDollarsWithColor } from './formatDollars';

export function formatCentsWithColor (cents: Decimal.Value | null | undefined, fallback='') {
  if (cents == null) {
    return fallback;
  }
  const centsDecimal = new Decimal(cents);
  const dollarsDecimal = centsDecimal.div(100);
  return formatDollarsWithColor(dollarsDecimal);
}

export function formatCentsAccountingNotation (cents: Decimal.Value | number | null | undefined) {
  let dollarsDecimal;
  if (cents == null) {
    dollarsDecimal = '—';
  } else {
    const centsDecimal = new Decimal(cents);
    dollarsDecimal = centsDecimal.div(100);
  }

  return formatDollarsAccountingNotation(dollarsDecimal);
}

export default function formatCents (cents: Decimal.Value | null | undefined, fallback='') {
  if (cents == null) {
    return fallback;
  }
  const centsDecimal = new Decimal(cents);
  const dollarsDecimal = centsDecimal.div(100);
  return formatDollars(dollarsDecimal);
}
