import React from 'react';
import GenericModal from './GenericModal';
import useAdminAPICall from "../../utils/useAdminAPICall";
import { Button } from "react-bootstrap";
import ApiError from "../Form/ApiError";
import useExpandableState from '../../utils/useExpandableState';
import ButtonWithSpinner from '../ButtonWithSpinner';

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
  loanApplication: { _id: string };
};

export default function PullCreditReportModal (props: Props) {
  const { expand, loanApplication, onSubmit } = props;
  return (
    <GenericModal expandable={expand} title={'Pull Credit Report'}>
      <PullCreditReportForm loanApplication={loanApplication} onHide={expand.hideExpand} onSubmit={onSubmit} />
    </GenericModal>
  );
}

type PullCreditReportFormProps = {
  onHide: () => void;
  onSubmit: () => void;
  loanApplication: { _id: string };
};

function PullCreditReportForm (props: PullCreditReportFormProps) {
  const { loanApplication, onHide, onSubmit } = props

  const { callAPI, data, loadError, loadPending: submitting } = useAdminAPICall({
    endpoint: `/notebook/loan-applications/${loanApplication._id}/pull-credit-report`,
    method: 'POST',
  })

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    await callAPI();
    onSubmit();
    onHide();
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <p>Are you sure you want to Pull Credit?</p>
        <div className="d-flex justify-content-end mt-4">
          <Button variant="secondary" onClick={onHide} className="mr-2">Cancel</Button>
          <ButtonWithSpinner variant="primary" type="submit" loading={submitting}>Confirm</ButtonWithSpinner>
        </div>
      </form>
      <ApiError className="mt-3" error={loadError ? data : null} />
    </>
  )
}
