import React from 'react';
import useAdminAPICall from "../../utils/useAdminAPICall";
import { Button, Modal } from "react-bootstrap";
import useExpandableState from '../../utils/useExpandableState';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { User } from '../../api/queries/users/useUser';
import { mutate } from 'swr';
import * as yup from 'yup';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Form from '../Form/Form';
import Field from '../Form/Field';

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  user: User;
};

export default function ArchiveUserModal (props: Props) {
  const { expand, user } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/users/${user._id}/archive`,
    method: 'POST',
  });

  async function handleSubmit (form: FieldValues) {
    await callAPI({ data: form });
    mutate(`/notebook/users/${user._id}`);
    expand.hideExpand();
  }

  const schema = yup.object().shape({ notes: yup.string() });
  const methods = useForm<FieldValues>({ defaultValues: { notes: undefined }, resolver: yupResolver(schema) });

  return (
    <Modal show={expand.isExpanded} onHide={expand.hideExpand}>
      <Modal.Header>
        <Modal.Title>
          Archive User
        </Modal.Title>
      </Modal.Header>

      <Form { ...methods } onSubmit={ handleSubmit }>
        <Modal.Body>
          <Field name='notes' label='Notes' placeholder="Add notes" controlProps={{ as: 'textarea', rows: '4' }}/>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          <div className="d-flex w-100">
            <Button variant="secondary" onClick={expand.hideExpand} className="mr-2 w-50">
              Cancel
            </Button>
            <div className='w-50'>
              <ButtonWithSpinner
                type="submit"
                variant="danger"
                block
                loading={false}
              >
                Archive
              </ButtonWithSpinner>
            </div>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
