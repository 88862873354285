import useQuery from '../../shared/useQuery';

export enum DisbursementRequestPageBucket {
  OPEN = 'open',
  PENDING = 'pending',
  CLEARED = 'cleared',
  CANCELED = 'canceled',
}

export type PendingTransaction = {
  bucket: DisbursementRequestPageBucket;
  loan_id: string;
  user_id: string;
  user_name: string;
  amount: number;
  updated_date: string;
  created_date: string;
  status: string;
  loan_slug: string;
  loan_principal: number;
  effective_date: string;
  disbursement_request_id: string;
  request_reason: string;
  quick_deposit: boolean;
  vendor_type: string;
  invoice_status: string;
  is_user_paused: boolean;
  type: string;
  bank_account_verified: boolean;
  bank_account_not_added: boolean;
  program: string;
  partner_id: string;
  disburse_via_jpm: boolean;
  loan_application_source: string;
  loan_application_id: string;
};

export default function usePending () {
  return useQuery<Array<PendingTransaction>>('/notebook/disbursement-requests/pending');
}
