import React from 'react';
import cx from 'classnames';
import { Badge } from 'react-bootstrap';

import styles from './LoanPartnerBadge.module.scss'
import { usePartner } from '../../api/queries/partners/usePartner';

type Props = {
  partnerId: string;
  source: string;
};

export default function LoanPartnerBadge ({ partnerId, source }: Props) {
  const partner = usePartner(partnerId)?.data;

  return (<>
    { partner &&
      <Badge
        className={cx('text-uppercase', styles[partner.slug])}
        pill
      >
        { `${partner.short_name}${source === 'better' ? ' (bundled)' : ''}` }
      </Badge>
    }
  </>);
}
