import React from 'react';
import { Alert } from 'react-bootstrap';

import AdminUserBadge from '../../AdminUserBadge';
import { LegacyLoanApplication } from '../../../api/queries/users/useLoanApplications';

export default function DeniedByAdminAlert ({ loanApplication }: { loanApplication: LegacyLoanApplication }) {
  return loanApplication.approvedByAdminId ? (
      <Alert variant='info'>
        { (loanApplication as any).approvedAt }
        <span> 
          { loanApplication.denialDate && '-'} Approved <AdminUserBadge admin_id={ loanApplication.approvedByAdminId } addBy={ true } /> 
        </span>
        { loanApplication.approvalNotes && <span> : { loanApplication.approvalNotes }</span> }
      </Alert>
    ) : null
}
