import React from 'react';
import GenericModal from '../GenericModal';
import MarkLoanAuthorizedForm from './MarkLoanAuthorizedForm';
import useExpandableState from '../../../utils/useExpandableState';
import { Loan } from '../../../api/queries/types';

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  loan: Loan;
  onSubmit: () => void;
};

export default function MarkLoanAuthorizedModal(props: Props) {
  const { expand, loan, onSubmit } = props;
  return (
    <GenericModal expandable={expand} title="Authorize Loan" size="lg">
      <MarkLoanAuthorizedForm loan={loan} onHide={expand.hideExpand} onSubmit={onSubmit} />
    </GenericModal>
  );
}
