import { useAPIData, useExpandableState } from "../../../utils/index";
import { LOAN_APPLICATION_STATUSES, UNDERWRITING_OUTCOMES } from "../../../utils/constants";
import { Button } from "react-bootstrap";
import RunUnderwritingModal from "../../Modals/RunUnderwritingModal";
import React from "react";
import { UNDERWRITING_DECISION_TYPES } from "./constants";
import DecisionConfirmationModal from "../../Modals/DecisionConfirmationModal";
import CancelApplicationModal from '../../Modals/CancelApplicationModal';
import { LegacyLoanApplication } from '../../../api/queries/users/useLoanApplications';
import PullCreditReportModal from "../../Modals/PullCreditReportModal";
import { KycStatus } from "../../../api/queries/users/useKycResults";
import ButtonWithTooltip from "../../ButtonWithTooltip";

const PREQUAL_CONFIRMATIONS = {
  PREQUALIFIED: {
    title: 'Conditionally Approve Application',
    submitUrl: '/pre-qualification/confirm/approval',
    submitText: 'Confirm',
    notifyBorrowerOption: true,
    notifyAgentOption: false,
    redirectTab: 'underwriting',
  },
  DENIED: {
    title: 'Deny Application',
    submitUrl: '/pre-qualification/confirm/denial',
    submitText: 'Deny',
    notifyBorrowerOption: true,
    notifyAgentOption: true,
    redirectTab: 'underwriting',
  },
  INELIGIBLE: {
    title: 'Mark as Ineligible',
    submitUrl: '/pre-qualification/confirm/ineligible',
    submitText: 'Confirm',
    notifyBorrowerOption: true,
    notifyAgentOption: true,
    redirectTab: 'underwriting',
  },
};

const STRICT_CONFIRMATIONS = {
  APPROVED: {
    title: 'Approve Application',
    submitUrl: '/strict-decision/confirm/approval',
    submitText: 'Confirm',
    notifyBorrowerOption: true,
    notifyAgentOption: true,
    redirectTab: 'loan',
  },
  COUNTEROFFER: {
    title: 'Counteroffer',
    submitUrl: '/strict-decision/confirm/counter-offer',
    submitText: 'Confirm',
    notifyBorrowerOption: true,
    notifyAgentOption: false,
    redirectTab: 'underwriting',
  },
  DENIED: {
    title: 'Deny Application',
    submitUrl: '/strict-decision/confirm/denial',
    submitText: 'Deny',
    notifyBorrowerOption: true,
    notifyAgentOption: true,
    redirectTab: 'underwriting',
  },
  INELIGIBLE: {
    title: 'Mark as Ineligible',
    submitUrl: '/strict-decision/confirm/ineligible',
    submitText: 'Confirm',
    notifyBorrowerOption: true,
    notifyAgentOption: true,
    redirectTab: 'underwriting',
  },
};

type Decisionable = { // A representation of either an UnderwritingDecision or an UnderwritingRun record
  type: string;
  outcome: string;
  confirmed_at: string;
};

function isDecisionTypeAndOutcome (type: string, decision: Decisionable, outcome: string) {
  return decision.type === type && decision.outcome === outcome;
}

function confirmationOptions (decision: Decisionable) {
  if (decision.type === UNDERWRITING_DECISION_TYPES.PRE_QUALIFICATION) {
    return PREQUAL_CONFIRMATIONS[decision.outcome as keyof typeof PREQUAL_CONFIRMATIONS];
  } else {
    return STRICT_CONFIRMATIONS[decision.outcome as keyof typeof STRICT_CONFIRMATIONS];
  }
}

function DecisionActionButtons ({ loanApplication }: { loanApplication: LegacyLoanApplication }) {
  const decisionReq = useAPIData<Decisionable>(`/notebook/loan-applications/${loanApplication._id}/underwriting-decisions/latest`);
  const decision = decisionReq.data;
  const runUnderwritingModal = useExpandableState();
  const pullCreditReportModal = useExpandableState();
  const decisionConfirmationModal = useExpandableState();
  const cancelApplicationModal = useExpandableState();
  const deniedConfirmedCounterofferModal = useExpandableState();

  if (decisionReq.loadPending || decisionReq.loadError) { return null; }
  if (loanApplication.status === LOAN_APPLICATION_STATUSES.CLOSED) { return null; }

  return <>
    {
      (loanApplication.status !== LOAN_APPLICATION_STATUSES.CLOSED && !loanApplication.loan) &&
      !(decision && decision.outcome === UNDERWRITING_OUTCOMES.COUNTEROFFER && decision.confirmed_at) &&
      <>
        <Button
          className = 'mr-3'
          variant   = 'secondary'
          onClick   = { cancelApplicationModal.showExpand }
        >
          Cancel Application
        </Button>
      </>
    }
    {
      decision &&
      decision.outcome === UNDERWRITING_OUTCOMES.COUNTEROFFER &&
      decision.confirmed_at &&
      !loanApplication.loan
        ?
        <ButtonWithTooltip
          variant='danger'
          onClick={ deniedConfirmedCounterofferModal.showExpand }
        >
          Deny Counteroffer
        </ButtonWithTooltip>
        : null
    }

    {
      !decision || isDecisionTypeAndOutcome(UNDERWRITING_DECISION_TYPES.PRE_QUALIFICATION, decision, UNDERWRITING_OUTCOMES.UNDECIDED) ?
        <Button onClick={ runUnderwritingModal.showExpand }>Run Pre-qualification</Button>
        : null
    }
    {
      decision &&
      decision.type === UNDERWRITING_DECISION_TYPES.STRICT_DECISION &&
      [UNDERWRITING_OUTCOMES.INCOMPLETE, UNDERWRITING_OUTCOMES.UNDECIDED].includes(decision.outcome) ?
        <Button onClick={ runUnderwritingModal.showExpand }>Run Final Decision</Button>
        : null
    }
    {
      decision &&
      decision.outcome === UNDERWRITING_OUTCOMES.PREQUALIFIED &&
      !decision.confirmed_at ?
        <Button variant='success' onClick={ decisionConfirmationModal.showExpand }>
          Conditionally Approve
        </Button>
        : null
    }
    {
      decision &&
      decision.outcome === UNDERWRITING_OUTCOMES.COUNTEROFFER &&
      !decision.confirmed_at ?
        <Button variant='success' onClick={ decisionConfirmationModal.showExpand }>
          Counteroffer
        </Button>
        : null
    }
    {
      decision &&
      decision.outcome === UNDERWRITING_OUTCOMES.APPROVED &&
      !decision.confirmed_at ?
        <ButtonWithTooltip
          variant='success'
          onClick={ decisionConfirmationModal.showExpand }
          disabled={loanApplication.isUserPaused}
          tooltip={loanApplication.isUserPaused ? 'This action is not available because this user is currently paused.' : ''}
        >
          Approve
        </ButtonWithTooltip>
        : null
    }
    {
      decision &&
      decision.outcome === UNDERWRITING_OUTCOMES.DENIED &&
      !decision.confirmed_at ?
        <ButtonWithTooltip
          variant='danger'
          onClick={ decisionConfirmationModal.showExpand }
          disabled={loanApplication.isUserPaused}
          tooltip={loanApplication.isUserPaused ? 'This action is not available because this user is currently paused.' : ''}
        >
          Deny
        </ButtonWithTooltip>
        : null
    }
    {
      decision &&
      decision.outcome === UNDERWRITING_OUTCOMES.INELIGIBLE &&
      !decision.confirmed_at ?
        <ButtonWithTooltip
          variant='dark'
          onClick={ decisionConfirmationModal.showExpand }
          disabled={loanApplication.isUserPaused}
          tooltip={loanApplication.isUserPaused ? 'This action is not available because this user is currently paused.' : ''}
        >
          Ineligible
        </ButtonWithTooltip>
        : null
    }
    {
      loanApplication && loanApplication.lastKyc && loanApplication.lastKyc.status === KycStatus.SUCCESS && !loanApplication.ficoScoreGivenAt &&
        <Button onClick={ pullCreditReportModal.showExpand }>Pull Credit Report</Button>
    }

    <RunUnderwritingModal
      type       = { (!decision || ( decision && decision.type === UNDERWRITING_DECISION_TYPES.PRE_QUALIFICATION)) ? 'pre-qualification' : 'strict-decision' }
      expand     = { runUnderwritingModal }
      onSubmit   = { () => { window.location.reload(); } }
      loanApplication = { loanApplication }
    />
    <PullCreditReportModal
      expand     = { pullCreditReportModal }
      onSubmit   = { () => { window.location.reload(); } }
      loanApplication = { loanApplication }
    />
    <CancelApplicationModal
      show            = { cancelApplicationModal.isExpanded }
      onHide          = { cancelApplicationModal.hideExpand }
      onSubmit        = { () => window.location.reload() }
      loanApplication = { loanApplication }
    />

    {
      decision &&
      confirmationOptions(decision) &&
      <DecisionConfirmationModal
        submitUrl  = { `/notebook/loan-applications/${loanApplication._id}/underwriting-decisions/${confirmationOptions(decision).submitUrl}` }
        title      = { confirmationOptions(decision).title }
        submitText = { confirmationOptions(decision).submitText }
        expand     = { decisionConfirmationModal }
        onSubmit   = { () => { window.location.href = `/loan-applications/${loanApplication._id}/${confirmationOptions(decision).redirectTab}` } }
        notifyBorrowerOption = { confirmationOptions(decision).notifyBorrowerOption }
        notifyAgentOption = { confirmationOptions(decision).notifyAgentOption && !!loanApplication.agent }
      />
    }

      {
        decision &&
        decision.outcome === UNDERWRITING_OUTCOMES.COUNTEROFFER &&
        decision.confirmed_at &&
        <DecisionConfirmationModal
          submitUrl  = { `/notebook/loan-applications/${loanApplication._id}/underwriting-decisions/strict-decision/deny-counter-offer` }
          title      = 'Deny Counteroffer'
          submitText = 'Deny'
          expand     = { deniedConfirmedCounterofferModal }
          onSubmit   = { () => { window.location.href = `/loan-applications/${loanApplication._id}/${confirmationOptions(decision).redirectTab}` } }
          notifyBorrowerOption = { true }
          notifyAgentOption = { true }
        />
      }
  </>;
}

export default function ActionButtons ({ loanApplication }: { loanApplication: LegacyLoanApplication }) {
  return <>
    <div className="float-right d-flex">
      <DecisionActionButtons loanApplication={loanApplication}/>
    </div>
  </>
}
