import React from 'react';
import humanize from 'humanize';

const TWO_DAYS_MS = 1000 * 60 * 60 * 24 * 2;

export default function RelativeTimestamp (props: { date: string | Date, className?: string }) {
  let date = props.date;
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  const dateStr = date.toISOString();

  let timeText;
  if (Date.now() - date.getTime() > TWO_DAYS_MS) {
    timeText = humanize.date('Y-m-d', date);
  } else {
    timeText = humanize.relativeTime(date.getTime()/1000);
  }

  return (
    <time
      className   = { props.className }
      dateTime    = { dateStr }
      title       = { dateStr }
    >
      { timeText }
    </time>
  );
}
