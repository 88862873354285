import React from 'react';
import { Alert } from 'react-bootstrap';

type Props = {
  className?: string;
  children: React.ReactNode;
}

export default function InlineWarning (props: Props) {
  return (
    <Alert as='span' variant='warning' className={ props.className }>
      { props.children }
    </Alert>
  );
}
