import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import CardStatusBadge from '../../Badges/CardStatusBadge';
import FulfillmentStatusBadge from '../../Badges/FulfillmentStatusBadge';
import InlineError from '../../InlineError';
import OutboundLink from '../../OutboundLink';
import ReissueCardModal from '../../Modals/ReissueCardModal';
import { PURCHASE_METHODS, PURCHASE_METHOD_LABELS, PURCHASE_METHOD_STATUS } from '../../../utils/constants';
import useAddresses from '../../../api/queries/users/useAddresses';
import { useAPIData, useExpandableState } from '../../../utils';
import ConfirmModal from '../../Modals/ConfirmModal/ConfirmModal';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import AdminContext, { ROLES } from '../../../adminContext';
import { PurchaseMethod } from '../../../utils/transactionUtils';

type Props = {
  cards: Array<PurchaseMethod>;
  purchaseMethodsReq: ReturnType<typeof useAPIData>;
  userId: string;
  allowReissue: boolean;
};

export default function Cards ({ cards, purchaseMethodsReq, userId, allowReissue }: Props) {
  const [reissueCard, setReissueCard] = useState<PurchaseMethod | null>(null);
  const [actionCard, setActionCard] = useState<PurchaseMethod | null>(null);
  const suspendModal = useExpandableState();
  const activateModal = useExpandableState();
  const addressesReq = useAddresses(userId);
  const addresses = addressesReq.data || [];
  const adminContext = React.useContext(AdminContext);

  const reloadPurchaseMethodsAndAddresses = () => {
    purchaseMethodsReq.loadData();
    addressesReq.mutate();
  }

  const addressLine1 = (card: PurchaseMethod) => {
    let address
    if (card.fulfillmentAddressId) {
      address = addresses.find(a => a._id === card.fulfillmentAddressId)
    } else {
      address = addresses.find(a => a._id === 'HOME')
    }
    return address && address.address_line1
  }
  const isPhysicalCard = (card: PurchaseMethod) => card.type === PURCHASE_METHODS.PHYSICAL_CARD;
  const statusCreated = (card: PurchaseMethod) => card.status === PURCHASE_METHOD_STATUS.CREATED;
  const statusActive = (card: PurchaseMethod) => card.status === PURCHASE_METHOD_STATUS.ACTIVE;
  const statusSuspended = (card: PurchaseMethod) => card.status === PURCHASE_METHOD_STATUS.SUSPENDED;
  const statusTerminated = (card: PurchaseMethod) => card.status === PURCHASE_METHOD_STATUS.TERMINATED;
  const cardNotShipped = (card: PurchaseMethod) =>
    statusCreated(card) &&
    (!card.fulfillmentStatus || card.fulfillmentStatus === 'ISSUED' || card.fulfillmentStatus === 'ORDERED')

  const shippingText = (card: PurchaseMethod) => cardNotShipped(card) ? 'Shipping' : 'Sent'
  const showTrackingLink = (card: PurchaseMethod) => isPhysicalCard(card) && statusCreated(card) && card.fulfillmentStatus === 'SHIPPED' && card.trackingLink
  const manageInMarqetaURL = (card: PurchaseMethod) => `https://app.marqeta.com/program/card?program=${card.marqetaProgramSlug}&tab=Card+details&token=${card.marqetaCardToken}`

  const suspendCardReq = useAdminAPICall({
    endpoint: `/notebook/cards/${actionCard?._id}/suspend`,
    method: 'PUT',
  });

  const activateCardReq = useAdminAPICall({
    endpoint: `/notebook/cards/${actionCard?._id}/reactivate`,
    method: 'PUT',
  });

  const handleSuspendCard = (card: PurchaseMethod) => {
    setActionCard(card);
    suspendModal.showExpand();
  };

  const handleActivateCard = (card: PurchaseMethod) => {
    setActionCard(card);
    activateModal.showExpand();
  };

  const clearAndClose = () => {
    setActionCard(null);
    suspendModal.hideExpand();
    activateModal.hideExpand();
  }

  const confirmSuspend = async () => {
    suspendModal.hideExpand();
    await suspendCardReq.callAPI();
    window.location.reload();
  };

  const confirmActivate = async () => {
    activateModal.hideExpand();
    await activateCardReq.callAPI();
    window.location.reload();
  };

  return (
    purchaseMethodsReq.loadPending ? (
      <>Loading...</>
    ) : purchaseMethodsReq.loadError ? (
      <InlineError>Error loading cards</InlineError>
    ) : cards.length > 0 ? (
      <div className="d-flex flex-row flex-wrap">
        {cards.map(card => (
          <div className="card mr-4 mb-4" style={{ width: 'fit-content' }} key={card.marqetaCardToken}>
            <div className="card-body" style={{ width: 325 }}>
              <h6><CardStatusBadge card={card} /></h6>
              <h5 className="mb-0">{ PURCHASE_METHOD_LABELS[card.type] }</h5>
              <p className="text-muted font-weight-bolder">Ending in **{ card.lastFour }</p>
              {isPhysicalCard(card) && statusCreated(card) && (<>
                <FulfillmentStatusBadge card={ card } />
              </>)}
              {isPhysicalCard(card) && (<>
                <span className="text-muted mb-2"><small>{shippingText(card)} to <strong>{addressLine1(card)}</strong></small></span>
              </>)}
              <div className='d-flex flex-column align-items-start mt-3'>
                <OutboundLink href={ manageInMarqetaURL(card) } className="btn btn-link text-left p-0 d-block">Manage in Marqeta</OutboundLink>
                {showTrackingLink(card) && (
                  <OutboundLink href={card.trackingLink} className="btn btn-link text-left m-0 p-0 d-block">View Tracking Info</OutboundLink>
                )}
                {isPhysicalCard(card) && adminContext.adminRole === ROLES.SUPERADMIN &&
                  <>
                    {allowReissue && (
                      <Button variant="link" className="p-0 m-0" onClick={() => { setReissueCard(card) }}>
                        { !(statusTerminated(card) || statusSuspended(card)) && <>Terminate &amp; </> }Reissue
                      </Button>
                    )}
                    {statusActive(card) && (
                      <Button variant="link" className="p-0 m-0" onClick={() => { handleSuspendCard(card) }}>
                        Suspend
                      </Button>
                    )}
                    {statusSuspended(card) && (
                      <Button variant="link" className="p-0 m-0" onClick={() => { handleActivateCard(card) }}>
                        Activate
                      </Button>
                    )}
                  </>
                }
              </div>
            </div>
          </div>
        ))}

        {reissueCard && (
          <ReissueCardModal
            card={reissueCard}
            addresses={addresses}
            closeModal={() => { setReissueCard(null) }}
            afterSubmit={reloadPurchaseMethodsAndAddresses}
          />
        )}
        <ConfirmModal
          title="Suspend Card"
          children='Are you sure you want to suspend this card?'
          expand={suspendModal}
          onConfirm={() => { confirmSuspend() }}
          onCancel={() => { clearAndClose() }}
          confirmButtonVariant="danger"
        />
        <ConfirmModal
          title="Activate Card"
          children='Are you sure you want to activate this card?'
          expand={activateModal}
          onConfirm={() => { confirmActivate() }}
          onCancel={() => { clearAndClose() }}
          confirmButtonVariant="primary"
        />
      </div>
    ) : <>No Cards</>
  )
}
