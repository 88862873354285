import React from 'react';
import { Pagination as PaginationBootstrap } from 'react-bootstrap';

export type PaginationParams = {
  page: number;
  maxPages: number;
  onPageChange: (pageNumber: number) => void;
};

export default function Pagination ({ page, maxPages, onPageChange}: PaginationParams) {

  if (maxPages === 1) { return(<></>); }

  return (
    <PaginationBootstrap>
      {page > 2 ? <PaginationBootstrap.First onClick={() => onPageChange(1)} /> : null}
      {(page - 1) >= 1 ? <PaginationBootstrap.Item onClick={() => onPageChange(page - 1)}>{ page - 1 }</PaginationBootstrap.Item> : null}
      <PaginationBootstrap.Item active>{ page }</PaginationBootstrap.Item>
      {(page + 1) <= maxPages ? <PaginationBootstrap.Item onClick={() => onPageChange(page + 1)}>{ page + 1 }</PaginationBootstrap.Item> : null}
      {page < maxPages - 1 ? <PaginationBootstrap.Last onClick={() => onPageChange(maxPages)} /> : null}
    </PaginationBootstrap>
  );
}
