import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import moment from 'moment'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import FileUploadField from '../../Form/FileUploadField';
import { FILE_TYPES } from '../../Form/S3FileUploadField';
import SubmitError from '../../Form/SubmitError';
import useAdminAPICall from '../../../utils/useAdminAPICall'
import ButtonWithSpinner from '../../ButtonWithSpinner';

const TERM_MONTHS = [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];

type Props = {
  onHide: () => void,
  onSubmit: () => void,
  loan: { _id: string, originationDate: string, termDescription: string },
};

type FieldValues = {
  reason: string;
  contract: string;
  term: string;
  notes: string;
  acceptedAt: string;
};

function ModifyLoanForm(props: Props) {
  const { loan, onHide, onSubmit } = props

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loans/${loan._id}/term`,
    method: 'PUT',
  })

  const handleSubmit = async (form: FieldValues) => {
    await callAPI({ data: { ...form, contract: { content: form.contract }, term: { months: form.term } } });
    onSubmit();
    onHide();
  }

  const schema = yup.object({
    reason: yup.string().required(),
    contract: yup.string().required('Please upload a PDF-type document'),
    term: yup.string().required(),
    notes: yup.string().test('notes', 'Please explain the reason for this loan modification', (v, context) => context.parent.reason !== 'OTHER' || Boolean(v && v.length)),
    acceptedAt: yup.string(),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      reason: '',
      contract: '',
      term: '',
      notes: '',
      acceptedAt: '',
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting }, watch } = methods;
  const values = watch();
  const newTermDate = values.term ? moment(loan.originationDate).add({ months: Number(values.term) }).format('MM/DD/YYYY') : null;

  return (
    <>
      <Form {...methods} onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="form-label">Current Contract Terms</label>
          <p>Term length: {loan.termDescription}</p>
        </div>

        <Field name='term' label='New Term' required
          controlProps={{ as: 'select' }}
          secondaryLabel={<>Please select the new <strong>total</strong> term length for this loan.{newTermDate && <p className="font-size-2 mt-1 mb-0">Due: {newTermDate}</p>}</>}
        >
          <option value="" disabled hidden>Please select</option>
          {TERM_MONTHS.map(months => <option key={months} value={`${months}`}>{months} months</option>)}
        </Field>

        <Field name='reason' label='Reason for modification' required controlProps={{ as: 'select' }}>
          <option value="" disabled hidden>Please select</option>
          <option value='COVID'>Covid-19</option>
          <option value='NATURAL_DISASTER'>Natural Disaster</option>
          <option value='MEDICAL_HARDSHIP'>Medical Hardship</option>
          <option value='OTHER'>Other (explain in notes)</option>
        </Field>

        <Field name='acceptedAt' label='Signature Date' required controlProps={{ type: 'date' }}/>
        <FileUploadField name='contract' label='Loan Modification Agreement' required type={FILE_TYPES.PDF} placeholder='Upload Agreement'/>

        <Field name='notes' label='Notes' required={values.reason === 'OTHER'} controlProps={{ as: 'textarea', rows: '4' }}/>

        <SubmitError/>

        <div className="d-flex justify-content-end mt-4">
          <Button variant="secondary" onClick={onHide} className="mr-2">
            Cancel
          </Button>
          <ButtonWithSpinner variant="primary" type="submit" loading={isSubmitting}>
            Modify Loan
          </ButtonWithSpinner>
        </div>
      </Form>
    </>
  )
}

ModifyLoanForm.propTypes = {
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loan: PropTypes.object.isRequired,
}

export default ModifyLoanForm
