import React from 'react';
import { Link } from 'react-router-dom';
import Arrow from '../images/arrow';
import cx from 'classnames';
import styles from './Breadcrumbs.module.scss';

const Breadcrumbs = ({ className, children }: { className: string, children: React.ReactNode }) => (
  <div className={ cx(styles.scope, className) }>{children}</div>
);

Breadcrumbs.Link = ({ to = '/', children }: { to?: string, children: React.ReactNode }) => (
  <Link className={ cx(styles.button, 'text') } to={ to }>
    <Arrow className={ styles.arrow } />
    <div>{ children }</div>
  </Link>
);

Breadcrumbs.Button = ({ onClick, children }: { onClick: () => void, children: React.ReactNode }) => (
  <button className={ cx(styles.button, 'text') } onClick={onClick}>
    <Arrow />
    <div>{ children }</div>
  </button>
);

export default Breadcrumbs;
