import React from 'react';
import { Table, FormLabel } from 'react-bootstrap';
import { useFormContext } from "react-hook-form";
import StaticAddress from '../StaticAddress';
import { getDateInTimezone } from '../../utils/datetime';
import Field from '../Form/Field';
import { personalFields, homeAddressFields, previousAddressFields, FieldValues } from './FormSteps';

function PreviewRow ({ label, initialValue, newValue }: { label: string, initialValue: React.ReactNode, newValue: React.ReactNode }) {
  return <tr>
    <td>{label}</td>
    <td>{initialValue}</td>
    <td>{newValue}</td>
  </tr>;
}

export default function ReviewSubmit ({ initialValues }: { initialValues: FieldValues }) {
  const { watch, formState: { dirtyFields } } = useFormContext();
  const values = watch();
  const isPreviousAddress = values.isPreviousAddress === 'previous';

  return (
    <>
      <h4 className='mt-4'>Review &amp; Submit</h4>
      <p className='text-muted small'>Manual verification will fail if user is not yet verified on Marqeta.</p>

      <FormLabel>Review Modifications</FormLabel>
      <Table bordered>
        <thead>
          <tr>
            <th></th>
            <th>Current</th>
            <th>Changes</th>
          </tr>
        </thead>
        <tbody>
          {dirtyFields.firstName && <PreviewRow label='First Name' initialValue={initialValues.firstName} newValue={values.firstName} />}
          {dirtyFields.lastName && <PreviewRow label='Last Name' initialValue={initialValues.lastName} newValue={values.lastName} />}
          {dirtyFields.dob && <PreviewRow label='Date of Birth' initialValue={getDateInTimezone(initialValues.dob).format('MM/DD/Y')} newValue={getDateInTimezone(values.dob).format('MM/DD/Y')} />}

          {!isPreviousAddress && homeAddressFields.some(k => dirtyFields[k]) && <PreviewRow label='Home Address' initialValue={<StaticAddress address={initialValues as any}/>} newValue={<StaticAddress address={values}/>} />}
          {isPreviousAddress && previousAddressFields.some(k => dirtyFields[k]) && <PreviewRow label='Previous Address' initialValue={<StaticAddress prefix='previous_' address={initialValues as any}/>} newValue={<StaticAddress prefix='previous_' address={values}/>} />}

          {dirtyFields.ssn && <PreviewRow label='SSN' initialValue={`***-**-${initialValues.ssn.slice(-4)}`} newValue={`***-**-${values.ssn.slice(-4)}`} />}
        </tbody>
        {
          ![...personalFields, ...homeAddressFields, ...previousAddressFields].some(k => dirtyFields[k]) &&
          <tfoot>
            <tr>
              <td></td>
              <td colSpan={2} className='text-muted text-center'>No changes</td>
            </tr>
          </tfoot>
        }
      </Table>

      <Field name='reason' label='Reasons for update' controlProps={{ as: 'select' }}>
        <option value='previous_kyc_failure'>Previous KYC Failure (Default)</option>
        <option value='dob_bug'>DOB Bug</option>
      </Field>

      <Field name='notes' label='Notes (optional)' controlProps={{ as: 'textarea', rows: '3' }}/>
    </>
  );
}
