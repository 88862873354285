import React, { useEffect, useState } from 'react';
import useAdminAPICall from "../../utils/useAdminAPICall";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../Form/Form';
import SubmitError from '../Form/SubmitError';
import useExpandableState from '../../utils/useExpandableState';
import ButtonWithSpinner from '../ButtonWithSpinner';
import AgentItem from '../AgentItem';
import { LegacyLoanApplication } from '../../api/queries/users/useLoanApplications';

type FieldValues = {
  agentId: string;
};

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
  loanApplication: LegacyLoanApplication;
};

type Agent = {
  _id: string;
  imagePath: string;
  displayName: string;
  agentProfile: { profilePages: Array<{ geography: string }> };
};

export default function EditAgentModal (props: Props) {
  const { expand, onSubmit, loanApplication } = props;
  const [options, setOptions] = useState<Array<Agent>>([]);
  const [selectedAgent, setSelectedAgent] = useState<Agent | null | undefined>();

  const fetchAgent = useAdminAPICall({
    endpoint: `/notebook/partners/${loanApplication.partner._id}/agents`
  });

  useEffect(() => {
    if (selectedAgent || !loanApplication) {
      return;
    }
    setSelectedAgent({
      _id: loanApplication.agent?.externalId,
      displayName: loanApplication.agent?.displayName,
      ...(loanApplication.agent?.geo && {
        agentProfile: {
          profilePages: [
            {
              geography: loanApplication.agent.geo
            }
          ]
        }
      }),
} as Agent);
  }, [selectedAgent, loanApplication]);

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loan-applications/${loanApplication._id}/agent`,
    method: 'PUT',
  });

  const schema = yup.object().shape({
    agentId: yup.string().required('required'),
  });

  const form = useForm<FieldValues>({
    defaultValues: {
      agentId: loanApplication.agent?.externalId ,
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty }, reset } = form;

  const handleSearch = async (q: string) => {
    const result = await fetchAgent.callAPI({ params: { q, loanApplicationId: loanApplication._id }});
    setOptions(result.data);
  };

  const handleChange = (a: any) => {
    const agent = a[0] as Agent;
    setSelectedAgent(agent);
    if (agent) {
      form.setValue('agentId', agent._id, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
    }
  }

  const getAgentLabel = (a: any) => {
    const agent = a as Agent;
    let label = agent.displayName;
    if (agent.agentProfile?.profilePages[0]?.geography) {
      label += ` (${agent.agentProfile.profilePages[0].geography.toUpperCase()})`;
    }
    return label;
  }

  async function handleSubmit(data: FieldValues) {
    await callAPI({ data });
    onSubmit();
    expand.hideExpand();
  }

  const onModalHide = () => {
    expand.hideExpand();
    reset();
  }

  return (
    <Modal show={expand.isExpanded} onHide={onModalHide}>
      <Modal.Header>
        <Modal.Title>
          Edit Agent
        </Modal.Title>
      </Modal.Header>
      <Form {...form} onSubmit={handleSubmit}>
        <Modal.Body>
          <AsyncTypeahead
            id                     = 'searchbar-agents'
            promptText             = 'Searching...'
            isLoading              = { fetchAgent.loadPending }
            useCache               = { false }
            minLength              = { 1 }
            labelKey               = { getAgentLabel }
            options                = { options }
            defaultSelected        = { selectedAgent ? [selectedAgent] : [] }
            placeholder            = 'Enter the name of your agent'
            filterBy               = { a => Boolean(a) /* pass through all values, filtering is done server-side */ }
            renderMenuItemChildren = { AgentItem as any }
            onSearch               = { handleSearch }
            onChange               = { handleChange }
          />
          <SubmitError/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onModalHide} className="mr-2">
            Cancel
          </Button>
          <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
            Confirm
          </ButtonWithSpinner>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
