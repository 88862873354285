import useSWR, { Key, SWRConfiguration } from 'swr';
import { axiosInstanceAPI } from './axiosInstanceAPI';

async function fetcher (endpoint: string) {
  const res = await axiosInstanceAPI.get(endpoint);
  return res.data;
}

export default function useQuery<Data, Error = any> (key: Key, swrConfig: SWRConfiguration<Data, Error>={}) {
  return useSWR<Data, Error>(key, fetcher, swrConfig);
}
