import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import LabelValueGrouping from '../../LabelValueGrouping';
import { titleCase } from "../../../utils/string";
import { LOAN_APPLICATION_PROPERTY_OWNERSHIP_TYPE } from "../../../utils/constants";
import { formatDollars } from '../../../utils';
import Decimal from 'decimal.js';
import { isNil } from 'lodash';
import moment from 'moment';

type Props = {
  className?: string,
  propertyOwnershipType: string,
  propertyType?: string,
  propertyOwnedForMoreThan90Days?: boolean,
  statedMortgageMonthlyPayment?: number,
  mortgageCloseDate?: string,
  isGettingNewMortgage?: boolean,
}

const prettyPropertyOwnership = {
  [LOAN_APPLICATION_PROPERTY_OWNERSHIP_TYPE.OWNED]: 'A home I own',
  [LOAN_APPLICATION_PROPERTY_OWNERSHIP_TYPE.BUYING]: 'A home I\'m buying',
  [LOAN_APPLICATION_PROPERTY_OWNERSHIP_TYPE.RENTAL]: 'A home I rent',
  [LOAN_APPLICATION_PROPERTY_OWNERSHIP_TYPE.SELLING]: 'A home I\'m selling',
};

export default function HousingInfo ({ className, propertyOwnershipType, propertyType, propertyOwnedForMoreThan90Days, statedMortgageMonthlyPayment, mortgageCloseDate, isGettingNewMortgage }: Props) {
  const is_property_owned = propertyOwnershipType === LOAN_APPLICATION_PROPERTY_OWNERSHIP_TYPE.OWNED;
  const dataForValueGrouping: Record<string, string> = {
    'What is the loan for?': prettyPropertyOwnership[propertyOwnershipType],
  };

  if (propertyType) {
    dataForValueGrouping['Property Type'] = titleCase(propertyType);
  }

  if (is_property_owned) {
    dataForValueGrouping['Owned more than 90 days'] = propertyOwnedForMoreThan90Days ? 'Yes' : 'No';
  }

  if (propertyOwnershipType === LOAN_APPLICATION_PROPERTY_OWNERSHIP_TYPE.BUYING) {
    dataForValueGrouping['Getting a mortgage'] = isGettingNewMortgage ? 'Yes' : 'No';
  }

  if (!isNil(statedMortgageMonthlyPayment)) {
    dataForValueGrouping[`${is_property_owned ? 'Monthly' : 'Expected monthly'} payment`] = formatDollars(new Decimal(statedMortgageMonthlyPayment).div(100).toNumber())
  }

  if (mortgageCloseDate) {
    dataForValueGrouping['Expected close date'] = moment(mortgageCloseDate).format('MM/DD/YYYY');
  }

  return (
    <Card className={className}>
      <Card.Header>
        Housing
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <LabelValueGrouping
              data={dataForValueGrouping}
              condensed={ true }
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
