import useQuery from '../../shared/useQuery';

export enum DISBURSEMENT_TYPE {
  ACH = 'ach',
  CHECK = 'check',
}

export enum VENDOR_TYPE {
  NONE = 'NONE',
  SINGLE = 'SINGLE',
  MULTI = 'MULTI',
}

export type DisbursementLimit = {
  alert: string;
  loanAvailableFundsCents: string;
  supportedTypes: DisbursementType;
};

export type DisbursementType = {
  SUPPORTED_PAY_TYPES: Array<DISBURSEMENT_TYPE>;
  SUPPORTED_VENDOR_TYPES: Array<VENDOR_TYPE>;
  MAX_QUICK_DEPOSIT_CENTS: number;
};

export default function useDisbursementLimits (loanId: string) {
  return useQuery<DisbursementLimit>(`/notebook/loans/${loanId}/disbursement-limits`);
}
