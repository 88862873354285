import React, { useEffect, useMemo } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import DollarField from '../../Form/DollarField';
import { isDateOnlyWithinReasonableBounds } from '../../../utils/datetime';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import { useExpandableStateType } from '../../../utils/useExpandableState';
import { Loan } from '../../BorrowerTabs/ServicingTab/Activity';
import Decimal from 'decimal.js';
import ButtonWithSpinner from '../../ButtonWithSpinner';
import useActiveInvoiceDueDate from '../../../api/queries/invoices/useActiveInvoiceDueDate';
import CardCheckbox from '../../Form/CardCheckbox';
import IntendedFinalRepayment from '../../IntendedFinalRepayment';

type Props = {
  modal: useExpandableStateType;
  loan: Loan;
  onChange: () => void;
};

type FieldValues = {
  type: string;
  accounting_date: string;
  effective_date: string;
  override_effective_date: boolean;
  amount: string;
  method: string;
  posting_bank: string;
  external_ref: string;
  intended_final_payment: boolean;
};

const CreateExternalActivityModal: React.FC<Props> = ({ modal, loan, onChange }) => {
  const { data: invoiceData } = useActiveInvoiceDueDate(loan._id);

  const schema = yup.object({
    type: yup.string().required('This field is required'),
    accounting_date: yup.string().test('accounting_date', 'Please enter a valid date within 10 years.', value => !!value && isDateOnlyWithinReasonableBounds(value)),
    effective_date: yup.string().test('effective_date', 'Please enter a valid date within 10 years.', value => !value || isDateOnlyWithinReasonableBounds(value)),
    amount: yup.string().test('amount', 'Must be a positive, non-zero dollar value.', value => !!value && new Decimal(value).greaterThan(0)),
    method: yup.string().required('This field is required'),
    posting_bank: yup.string().required('This field is required'),
    external_ref: yup.string(),

  });
  const methods = useForm<FieldValues>({
    defaultValues: {
      type: '',
      accounting_date: '',
      effective_date: '',
      override_effective_date: false,
      amount: '',
      method: '',
      posting_bank: '',
      external_ref: '',
      intended_final_payment: false,
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting }, reset, watch, setValue, resetField } = methods;

  const watchType = watch('type');
  const watchEffectiveDate = watch('effective_date');
  const watchOverride = watch('override_effective_date');
  const watchAmount = watch('amount');
  const isRepayment = watchType === 'repayment';

  const dueDateInPast = useMemo(() => {
    const dueDate = invoiceData?.active_invoice_due_date;
    return Boolean(dueDate) && invoiceData?.in_past;
  }, [invoiceData]);

  const setDefaultEffectiveDate = useMemo(() => {
    return watchType === 'repayment' && dueDateInPast && !watchOverride;
  }, [watchType, watchOverride, dueDateInPast]);

  useEffect(() => {
    if (setDefaultEffectiveDate) {
      setValue('effective_date', invoiceData!.active_invoice_due_date);
    }
    if (!setDefaultEffectiveDate && watchEffectiveDate === invoiceData?.active_invoice_due_date) {
      resetField('effective_date');
    }
  }, [setDefaultEffectiveDate, watchEffectiveDate, invoiceData]); // eslint-disable-line react-hooks/exhaustive-deps

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/external-activities/`,
    method: 'POST',
  });

  const handleSubmit = async (form: FieldValues) => {
    await callAPI({
      data: {
        ...form,
        effective_date: form.effective_date || undefined,
        amount: new Decimal(form.amount).times(100).toNumber(),
        loanId: loan._id,
      }
    });

    reset();
    onChange();
    modal.hideExpand();
  };

  function handleCancel() {
    reset();
    modal.hideExpand();
  }

  return (
    <Modal show={modal.isExpanded} onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Record New External Activity</Modal.Title>
      </Modal.Header>
      <Form {...methods} onSubmit={handleSubmit}>
        <Modal.Body>
          <Alert variant="warning">
            Note: this is only to record an existing transaction already processed by the bank. Do not
            use this to initiate new money movement.
          </Alert>

          <Field name='type' label='Type' controlProps={{ as: 'select' }}>
            <option value="">--</option>
            <option value="disbursement">Disbursement</option>
            <option value="repayment">Repayment</option>
            <option value="program_partner_repayment">Program Partner Repayment</option>
            <option value="refund_to_borrower">Refund To Borrower</option>
            <option value="returned_disbursement">Returned Disbursement</option>
          </Field>

          <Field name='accounting_date' label='Accounting Date' controlProps={{ type: 'date' }}/>
          <Field
            name='effective_date'
            label='Effective Date (optional)'
            secondaryLabel={
              'By default, the transaction will be effective the Accounting Date. ' +
              'For repayments, the effective date will be defaulted to the active invoice due date (if applicable). ' +
              'You can override this by checking the box below.'
            }
            controlProps={{ type: 'date' }}
            disabled={ setDefaultEffectiveDate }
          />
          {
            isRepayment && dueDateInPast &&
            <CardCheckbox name='override_effective_date' label={ 'Override default Effective Date' }/>
          }

          <DollarField name='amount' label='Amount'/>

          <Field name='method' label='Method' controlProps={{ as: 'select' }}>
            <option value="">--</option>
            <option value="ach">ACH</option>
            <option value="check">Check</option>
            <option value="wire">Wire</option>
            <option value="eft">Other EFT</option>
          </Field>

          <Field name='posting_bank' label='Bank' controlProps={{ as: 'select' }}>
            <option value="">--</option>
            <option value="jpm_chase">JPM Chase</option>
            <option value="ach_com">ACH.com</option>
            <option value="regions_bank">Regions</option>
          </Field>

          <Field name='external_ref' label='External Reference (optional)' secondaryLabel='A unique identifier of this transaction from the bank, eg Check Number, Trace Number'/>

          {
            isRepayment &&
            <IntendedFinalRepayment
              loanId={ loan._id }
              field='intended_final_payment'
              repaymentAmount={ watchAmount }
            />
          }
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <ButtonWithSpinner type='submit' variant="primary" loading={isSubmitting}>
            Create
          </ButtonWithSpinner>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CreateExternalActivityModal;
