import React from 'react';
import { LegacyLoanApplication } from '../../../../api/queries/users/useLoanApplications';
import { UnderwritingRun } from '../index';
import { Card } from 'react-bootstrap';
import PortfolioUnderwritingResults from './PortfolioUnderwritingResults';
import useLatestPreQualificationRun from '../../../../api/queries/underwriting-runs/useLatestPreQualificationRun';
import useLatestStrictRun from '../../../../api/queries/underwriting-runs/useLatestStrictRun';

function UnderwritingCard ({ title, children }: { title: string, children: React.ReactNode }) {
  return <Card className="mt-4">
    <Card.Header>
      {title}
    </Card.Header>
    <Card.Body className='pb-0'>
      {children}
    </Card.Body>
  </Card>;
}

function UnderwritingRunSummary ({ underwritingRun, title }: { underwritingRun: UnderwritingRun, title: string }) {
  return <div className={`mb-5 ${underwritingRun.type}`}>
    <div className="border-bottom">
      <h4>{title}</h4>
    </div>
    <UnderwritingCard title="Portfolio Evaluations">
      <PortfolioUnderwritingResults portfolioUnderwritingResults={underwritingRun.portfolioUnderwritingResults || []} />
    </UnderwritingCard>
  </div>;
}

export default function UnderwritingRuns ({ loanApplication }: { loanApplication: LegacyLoanApplication }) {
  const latestPreQualRunReq = useLatestPreQualificationRun(loanApplication._id);
  const latestFinalUWRunReq = useLatestStrictRun(loanApplication._id);
  const latestPreQualRun = latestPreQualRunReq.data;
  const latestFinalUWRun = latestFinalUWRunReq.data;

  return (
    <>
      {
        latestFinalUWRun && <UnderwritingRunSummary underwritingRun={latestFinalUWRun} title='Final Underwriting' />
      }
      {
        latestPreQualRun && <UnderwritingRunSummary underwritingRun={latestPreQualRun} title='Pre Qualification' />
      }
    </>
  );
}
