import React from 'react';
import { Button } from 'react-bootstrap';
import LabelValueGrouping from '../LabelValueGrouping';

type Props = {
  userId: string;
  results: {
    status?: string;
    isManualVerification?: boolean;
    failureCodes?: Array<string>;
  };
};

export default function Results ({ results: { status, isManualVerification, failureCodes }, userId }: Props) {
  const isVerified = status === 'success';

  return (
    <>
      { isVerified ?
        <LabelValueGrouping
          data={{ 'KYC Result': <span className='font-size-5 text-success'>{isManualVerification ? 'Pass: Manual' : 'Pass'}</span> }}
        /> : <>
          <LabelValueGrouping data={{ 'KYC Result': <span className='font-size-5 text-danger'>Fail</span> }} />
          <LabelValueGrouping data={{ 'Reason': (failureCodes || []).join(', ') }} />
        </>
      }

      <div className='border-top pt-4 mt-5 text-right'>
        {!isVerified && <Button variant="secondary" className="mr-3" href={`/users/${userId}/edit-identity`}>Start Over</Button>}
        <Button variant="primary" href={`/users/${userId}`}>Ok</Button>
      </div>
    </>
  );
}
