import React, { useState } from 'react';
import moment from 'moment';
import { Badge, Button, Container, Row, Col, Form, Table, ButtonGroup } from 'react-bootstrap';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import InlineError from '../components/InlineError';
import InlineLoadingIndicator from '../components/InlineLoadingIndicator';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SplitView from '../layouts/SplitView';
import { formatDate, useExpandableState } from '../utils';
import SendFirstNoticeModal, { SendRepaymentReminderModal } from '../components/Modals/Servicing/SendFirstNoticeModal';
import { LOAN_STATES, USER_NOTIFICATION_TYPES } from '../utils/constants';
import { putAPIEndpoint } from '../utils/useAdminAPIData';
import useReminders, { Reminder } from '../api/queries/loans/useReminders';
import useServicing from '../api/queries/loans/useServicing';
import { getDateInTimezone } from '../utils/datetime';
import './RepaymentReminders.css';
import SendPhysicalInvoiceModal from '../components/Modals/SendPhysicalInvoiceModal';
import { digPausedReasonFor, findFirstRepaymentReminder, hasFutureNotificationInSchedule, isPending, isScheduled, isSent, qualifiesAsFirstNotice } from '../utils/notifications';
import ButtonWithSpinner from '../components/ButtonWithSpinner';

type Loan = {
  _id: string;
  maturityDate: string;
  status: string;
  userId: string;
};

type Servicing = ReturnType<typeof useServicing>['data'];

// Returns a list of objects describing the schedule row, its associated
// reminder record if any, and flags indicating relevant milestones. Each row
// has zero or one reminders (thus dates may be repeated), and if zero they
// have at least one milestone flag.
export function assembleScheduleRows ({ loan, reminders, servicing }: { loan: Loan, reminders: Array<Reminder>, servicing: Servicing }) {
  const rows = [];
  if (servicing) {
    const today = getDateInTimezone().startOf('day');
    const dueDate = getDateInTimezone(loan.maturityDate).startOf('day');
    let firstNoticeDate = servicing.first_notice_date ? getDateInTimezone(servicing.first_notice_date).startOf('day') : null;
    const rowsByDate: Record<string, Array<any>> = {};
    if (reminders) {
      // Map the reminders out by date so the badges can be assigned below
      reminders.forEach((reminder,i) => {
        const row: any = {
          key: reminder._id,
          date: getDateInTimezone(reminder.createdAt).startOf('day'),
          reminder,
        }
        rows.push(row);
        if (!rowsByDate[row.date.format('ll')]) { rowsByDate[row.date.format('ll')] = [] };
        rowsByDate[row.date.format('ll')].push(row);
        if (!firstNoticeDate && qualifiesAsFirstNotice(reminder)) { // TODO: set this server-side?
          firstNoticeDate = row.date;
          row.isFirstNotice = true;
        }
      });
    }

    // Add badges or create milestone-only rows for badges that don't land on
    // a day with a reminder.

    if (firstNoticeDate) {
      if (!rowsByDate[firstNoticeDate.format('ll')]) { rowsByDate[firstNoticeDate.format('ll')] = [] };
      const onFirstNoticeDate = rowsByDate[firstNoticeDate.format('ll')];
      if (onFirstNoticeDate.length === 0) {
        const row = {
          key: 'milestone_1', // To use as key in list below
          date: firstNoticeDate,
          isFirstNotice: true,
        };
        onFirstNoticeDate.push(row); // Add back to the by-date mapping so later milestones get added to the same.
        rows.push(row);
      } else {
        onFirstNoticeDate[0].isFirstNotice = true;
      }
    }

    if (!rowsByDate[dueDate.format('ll')]) { rowsByDate[dueDate.format('ll')] = [] };
    const onDueDate = rowsByDate[dueDate.format('ll')];
    if (onDueDate.length === 0) {
      const row = {
        key: 'milestone_2',
        date: dueDate,
        isDueDate: true,
      };
      onDueDate.push(row);
      rows.push(row);
    } else {
      onDueDate.forEach((row: any) => { row.isDueDate = true });
    }

    if (!rowsByDate[today.format('ll')]) { rowsByDate[today.format('ll')] = [] };
    const onToday = rowsByDate[today.format('ll')];
    if (onToday.length === 0) {
      const row = {
        key: 'milestone_3',
        date: today,
        isToday: true,
      }
      rows.push(row);
      onToday.push(row);
    } else {
      onToday.forEach((row: any) => { row.isToday = true });
    }

    // Ensure the reminder list is sorted chronologically
    rows.sort((a,b) => a.date.valueOf() - b.date.valueOf());
  }
  return rows;
}

export default function RepaymentReminders (props: { loan: Loan }) {
  const { loan } = props;
  const loanId = loan && loan._id;
  const { data: servicing, error: errorServicing, mutate: mutateServicing } = useServicing(loanId);
  const { data: reminders, error: errorReminders, mutate: mutateReminders } = useReminders(loanId);

  function mutateDatas () {
    mutateServicing();
    mutateReminders();
  }

  if (errorServicing) {
    return <InlineError>There was an error loading the servicing details</InlineError>;
  }

  const rows = assembleScheduleRows({ loan, servicing, reminders: reminders! });

  return (
    <SplitView>
      <SplitView.PrimarySection>
        <Container className='pl-0'>
          {
            <Row className='pt-3'>
              <Col>
                <div style={{ float: 'right', marginBottom: '-1.5rem' }}>
                  {
                    loan ? (
                      <>Maturity Date (Day "0") is <strong>{ formatDate(loan.maturityDate) }</strong></>
                    ) : null
                  }
                </div>
                <Table size='sm' borderless responsive>
                  <caption style={{ captionSide: 'top' }} className='border-bottom pt-0 pb-1 mb-2'>Reminders Schedule</caption>
                  <tbody>
                    {
                      errorReminders ? (
                        <InlineError>There was an error loading the scheduled reminders</InlineError>
                      ) : !reminders ? (
                        <LoadingRow/>
                      ) : rows.length ? (
                        rows.map(r => (
                          <ReminderRow
                            key={ r.key }
                            loan={ loan }
                            row={ r }
                            reminders={ reminders }
                            servicing={ servicing }
                          />
                        ))
                      ) : <NoRemindersRow/>
                    }
                  </tbody>
                </Table>
              </Col>
            </Row>
          }
        </Container>
      </SplitView.PrimarySection>
      <SplitView.SecondarySection>
      {
        servicing ? (
          <Actions loan={ loan } reminders={ reminders! } servicing={ servicing } mutateDatas={ mutateDatas }/>
        ) : null
      }
      </SplitView.SecondarySection>
    </SplitView>
  );
}

function Actions (props: { loan: Loan, reminders: Array<Reminder>, servicing: NonNullable<Servicing>, mutateDatas: () => void }) {
  const { mutateDatas, loan, reminders, servicing } = props;
  const loanCompleted = [LOAN_STATES.COMPLETED, LOAN_STATES.PASTDUE].includes(loan.status);
  const sendReminderModal = useExpandableState(false);
  const [sendingFirstNoticeModal, setSendingFirstNoticeModal] = useState(false);
  const noticeModal = useExpandableState(false);
  const firstRepaymentReminder = findFirstRepaymentReminder(reminders);
  const letterIsEnabled = true; // hasFutureNotificationInSchedule(reminders, USER_NOTIFICATION_TYPES.REPAYMENT_REMINDER_LETTER);
  const toggleSwitch = async (prop: any, value: any) => {
    await putAPIEndpoint(`/notebook/loans/${ loan._id }/servicing`, {
      [prop]: value,
    });
    mutateDatas();
  };

  const isPaused = servicing.notifications_paused || servicing.repayment_reminders_disablement.disabled;

  return <>
    <ButtonGroup vertical className='d-block mb-3'>
      {
        loanCompleted && firstRepaymentReminder ? (
          <>
            <ButtonWithSpinner
              variant   = { !!servicing.first_notice_date ? 'secondary' : 'primary' }
              onClick   = { sendReminderModal.showExpand }
              disabled  = { !!servicing.first_notice_date && isPaused }
              loading   = { sendingFirstNoticeModal }
              block
            >
              {!!servicing.first_notice_date ? 'Send Repayment Reminder' : 'Send First Notice Manually'}
            </ButtonWithSpinner>
            {
              !!servicing.first_notice_date ? (
                <SendRepaymentReminderModal
                  show                   = { sendReminderModal.isExpanded }
                  onHide                 = { sendReminderModal.hideExpand }
                  onFormWillSubmit       = { () => setSendingFirstNoticeModal(true) }
                  onFormDidSubmit        = { () => { setSendingFirstNoticeModal(false); mutateDatas() } }
                  loan                   = { loan }
                  reminders              = { reminders }
                />
              ) : (
                <SendFirstNoticeModal
                  show                   = { sendReminderModal.isExpanded }
                  onHide                 = { sendReminderModal.hideExpand }
                  onFormWillSubmit       = { () => setSendingFirstNoticeModal(true) }
                  onFormDidSubmit        = { () => { setSendingFirstNoticeModal(false); mutateDatas() } }
                  loan                   = { loan }
                  date                   = { moment(firstRepaymentReminder.createdAt) }
                  includeLetterMessaging = { hasFutureNotificationInSchedule(reminders, USER_NOTIFICATION_TYPES.REPAYMENT_REMINDER_LETTER) }
                  allNotificationsPaused = { servicing.notifications_paused }
                  paymentRemindersPaused = { servicing.repayment_reminders_disablement.disabled }
                  pausedReason           = { digPausedReasonFor(reminders) }
                />
              )
            }
          </>
        ) : null
      }
      {
        letterIsEnabled ? (<>
          <Button
            variant   = 'secondary'
            block     = { true }
            onClick   = { noticeModal.showExpand }
          >
            Send Repayment Reminder Letter
          </Button>
          <SendPhysicalInvoiceModal
            isFirstNotice   = { !servicing.first_notice_date }
            loan            = { loan }
            modal           = { noticeModal }
            onChange        = { mutateDatas }
          />
        </>) : null
      }
    </ButtonGroup>
    <SwitchBox
      id='notifications_manually_paused'
      label='Pause All Electronic Notifications'
      checked={ servicing.notifications_paused }
      disabled={ servicing.notifications_paused_by_override }
      onChange={ () => toggleSwitch('notifications_manually_paused', !servicing.notifications_manually_paused) }
    />
    <SwitchBox
      id='repayment_reminders_manually_paused'
      label='Pause Electronic Payment Reminders'
      checked={ servicing.repayment_reminders_disablement.disabled }
      disabled={ servicing.repayment_reminders_disablement.by_override }
      onChange={ () => toggleSwitch('repayment_reminders_manually_paused', !servicing.repayment_reminders_manually_paused) }
    />
    {
      (servicing.notifications_paused || servicing.repayment_reminders_disablement.disabled) ? (
        <p className='small text-center text-pop'>Reason: { digPausedReasonFor(reminders) }</p>
      ) : null
    }
    {
      letterIsEnabled || servicing.repayment_letter_manually_paused /* So it can be re-enabled */ ? (
        <>
          <hr />
          <SwitchBox
            id='repayment_letter_manually_paused'
            label='Pause Repayment Reminder Letter'
            checked={ servicing.repayment_letter_manually_paused }
            onChange={ () => toggleSwitch('repayment_letter_manually_paused', !servicing.repayment_letter_manually_paused) }
          />
          {
            letterIsEnabled ? (
              <p className='small text-center text-pop'>
                The letter is mandatory. Only pause the letter if the legal
                obligation has already been fulfilled.
              </p>
            ) : null
          }
        </>
      ) : null
    }
  </>;
}

function SwitchBox (props: React.ComponentPropsWithoutRef<typeof Form.Check>) {
  return (
    <div className={`SwitchBox p-2 mb-2 ${ props.disabled ? '--disabled' : '' }`}>
      <Form.Check type='switch' { ...props }/>
    </div>
  );
}

function LoadingRow () {
  return <tr><td colSpan={6} className='text-center'><InlineLoadingIndicator/></td></tr>;
}

function NoRemindersRow () {
  return <tr><td colSpan={6} className='text-muted text-center'>No reminders are scheduled</td></tr>;
}

const PRETTY_REMINDER_TYPES = {
  [USER_NOTIFICATION_TYPES.ACCELERATION_CONFIRMATION_REMINDER]: 'Close Confirmation',
  [USER_NOTIFICATION_TYPES.ACCELERATION_HOME_CLOSE_INVOICE]: 'Home Close Reminder',
  [USER_NOTIFICATION_TYPES.REPAYMENT_REMINDER]: 'Repayment Reminder',
  [USER_NOTIFICATION_TYPES.REPAYMENT_REMINDER_LETTER]: 'Physical Reminder',
  [USER_NOTIFICATION_TYPES.TERM_REMINDER_0]: 'Term Reminder',
  [USER_NOTIFICATION_TYPES.TERM_REMINDER_30_14_5_3]: 'Term Reminder',
  [USER_NOTIFICATION_TYPES.TERM_REMINDER_90_60]: 'Term Reminder',
};

const PRETTY_REMINDER_CHANNELS = {
  'SMS': 'Text',
  'TRANSACTIONAL_EMAIL': 'Email',
  'PHYSICAL_LETTER': 'Letter',
}

function ReminderRow (props: { loan: Loan, row: any, servicing: Servicing; reminders?: Array<Reminder> }) {
  const { loan, row, servicing } = props;

  if (!servicing) {
    return <LoadingRow/>;
  }

  const cx = [];
  if (row.isDueDate || row.isToday) {
    cx.push('text-highlight');
  } else if (row.reminder && !isScheduled(row.reminder)) {
    cx.push('text-muted');
  }

  const keyMilestoneLabels = [];
  if (row.isFirstNotice) {
    keyMilestoneLabels.push({ label: 'First Notice', variant: 'primary' });
  }
  if (row.type === USER_NOTIFICATION_TYPES.REPAYMENT_REMINDER_LETTER) {
    keyMilestoneLabels.push({ label: 'Letter' , variant: 'primary'});
  }
  if (row.isDueDate) {
    keyMilestoneLabels.push({ label: 'Due Date', variant: 'highlight' });
  }

  let dateVal;
  if (row.isToday) {
    dateVal = (<strong>Today, { row.date.format('MMM D') }</strong>);
  } else {
    dateVal = row.date.format('ll');
  }

  function diffWithMaturityDate () {
    const diff = row.date.diff(loan.maturityDate, 'days');
    let prefix = '';
    let suffix = '';
    if (diff > 0) { // - is included in the diff
      prefix = '+';
    }
    if (Math.abs(diff) !== 1) {
      suffix = 's';
    }

    return `${ prefix }${ diff } Day${ suffix }`;
  }
  return (
    <tr className={cx.join(' ')}>
      <td>
        {
          row.isDueDate ? (
            <strong>&mdash;</strong>
          ) : (
            <strong>{ diffWithMaturityDate() }</strong>
          )
        }
      </td>
      <td>
        { dateVal }
      </td>
      <td>
        <FlagKeyMilestones milestones={ keyMilestoneLabels as any }/>
      </td>
      <td>
        { row.reminder ? <ReminderType reminder={ row.reminder }/> : null }
      </td>
      <td>
        { row.reminder ? <ReminderStatus reminder={ row.reminder } /> : null }
      </td>
    </tr>
  );
}

function ReminderType ({ reminder }: { reminder: Reminder }): any {
  return `${PRETTY_REMINDER_TYPES[reminder.type]} (${PRETTY_REMINDER_CHANNELS[reminder.channel as keyof typeof PRETTY_REMINDER_CHANNELS]})`;
}

function FlagKeyMilestones (props: { milestones: Array<{ variant: React.ComponentProps<typeof Badge>['variant'], label: string }> }) {
  return (
    <>
      {
        props.milestones.map( ({ variant, label }) => (
          <Badge variant={ variant } className='mr-2' key={ label }>
            { label }
          </Badge>
        ))
      }
    </>
  );
}

function ReminderStatus ({ reminder }: { reminder: Reminder }) {
  return (
    <>
      {
        isScheduled(reminder) ? (
          <ScheduledStatus reminder={ reminder }/>
        ) : isSent(reminder) ? (
          <SentStatus/>
        ) : isPending(reminder) ? (
          <PendingStatus />
        ) : (
          <FailedStatus/>
        )
      }
    </>
  );
}

function SentStatus () {
  return <span><ArrowForwardIcon/> Sent</span>;
}

function PendingStatus () {
  return <span className='text-warning'>Sending&hellip;</span>;
}

function FailedStatus () {
  return <span className='text-danger'><CloseIcon/> Not Sent (Error)</span>;
}

function ScheduledStatus ({ reminder }: { reminder: Reminder }) {
  if (reminder.is_paused) {
    return <span className='text-pop'><CloseIcon/> Paused ({ reminder.is_paused })</span>;
  }
  if (isSent(reminder)) {
    return <span><ArrowForwardIcon/> Sent</span>;
  }
  return <span><ScheduleIcon/> Scheduled</span>;
}
