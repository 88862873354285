import React, { useState } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import * as _ from 'lodash';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import ButtonWithSpinner from '../ButtonWithSpinner';
import ApiError from '../Form/ApiError';
import ReviewSubmit from './ReviewSubmitFields';
import SupportingDocumentation from './SupportingDocumentationFields';
import SuperadminAuthRequired from '../SuperadminAuthRequired';
import UserInformation from './UserInformationFields';
import { cleanSSN, validateDOB, validateSSN, validateZIPCode } from '../../utils/form-utilities';
import useAdminAPICall from '../../utils/useAdminAPICall'
import { User } from '../../api/queries/users/useUser';
import { Address } from '../../api/queries/users/useAddresses';
import Results from './Results';
import Form from '../Form/Form';

type Props = {
  user: User;
  addresses: Array<Address>;
};

export type FieldValues = {
  firstName: string;
  lastName: string;
  dob: string;
  ssn: string;
  isPreviousAddress: string;

  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  zip: string;

  previous_address_line1: string;
  previous_address_line2: string;
  previous_city: string;
  previous_state: string;
  previous_zip: string;

  supportingDocuments: Array<{ type: string, filename: { s3Filename: string, originalFilename: string } }>;

  notes: string;
  reason: string;
};

export const personalFields = [ 'dob', 'firstName', 'lastName', 'ssn' ];
export const homeAddressFields = ['address_line1', 'address_line2', 'city', 'state', 'zip'];
export const previousAddressFields = ['previous_address_line1', 'previous_address_line2', 'previous_city', 'previous_state', 'previous_zip'];

export default function FormSteps (props: Props) {
  const {
    user,
    addresses,
  } = props;

  const [currentStep, setCurrentStep] = useState(0);
  const [manualVerificationLoading, setManualVerificationLoading] = useState(false);

  const previousAddress = React.useMemo(() => {
    return (addresses || []).find(address => address._id === user.previousAddressId);
  }, [addresses, user]);

  const schema = yup.object({
    firstName: yup.string().required('Please enter First Name'),
    lastName: yup.string().required('Please enter Last Name'),
    dob: yup.string().test('dob', 'Please choose a date of birth', v => !!v && validateDOB(v)),
    ssn: yup.string().test('ssn', 'Please enter a valid SSN', v => !!v && validateSSN(v)),
    isPreviousAddress: yup.string().required(),

    address_line1: yup.string().required('Please provide an address'),
    address_line2: yup.string(),
    city: yup.string().required('Please provide a city'),
    state: yup.string().required('Please provide a state'),
    zip: yup.string().test('zip', 'Please provide a valid zip code', v => !!v && validateZIPCode(v!)),

    previous_address_line1: yup.string().required('Please provide an address'),
    previous_address_line2: yup.string(),
    previous_city: yup.string().required('Please provide a city'),
    previous_state: yup.string().required('Please provide a state'),
    previous_zip: yup.string().test('previous_zip', 'Please provide a valid zip code', v => !!v && validateZIPCode(v!)),

    supportingDocuments: yup.array(yup.object({
      type: yup.string().required('Please upload a document'),
      filename: yup.object({
        s3Filename: yup.string().required('Please upload a document'),
        originalFilename: yup.string(),
      }),
    })).required(),

    notes: yup.string(),
    reason: yup.string().required(),
  });

  const defaultValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    dob: user.dob,
    ssn: cleanSSN(user.ssnLastFour, true),
    isPreviousAddress: 'home',

    address_line1: user.address_line1,
    address_line2: user.address_line2,
    city: user.city,
    state: user.state,
    zip: user.zip,

    previous_address_line1: previousAddress ? previousAddress.address_line1 : '',
    previous_address_line2: previousAddress ? previousAddress.address_line2 : '',
    previous_city: previousAddress ? previousAddress.city : '',
    previous_state: previousAddress ? previousAddress.state : '',
    previous_zip: previousAddress ? previousAddress.zip : '',

    supportingDocuments: [{ type: '', filename: { s3Filename: '', originalFilename: '' } }],

    reason: 'previous_kyc_failure',
    notes: '',
  };

  const methods = useForm<FieldValues>({ defaultValues, resolver: yupResolver(schema) });
  const { formState: { dirtyFields }, getValues } = methods

  const { callAPI, data, loadError, loadPending } = useAdminAPICall({
    endpoint: `/notebook/users/${user._id}/identity`,
    method: 'PUT',
  });

  const submitHandler = (isManualVerification: boolean) => {
    setManualVerificationLoading(isManualVerification)

    const values = getValues();
    const isPreviousAddress = values.isPreviousAddress === 'previous';
    const dirtyKeys = _.keys(_.pickBy(dirtyFields, (value, key) => !!value));
    const changed = _.pick(values, dirtyKeys);
    const changedAddressFields = _.pick(changed, isPreviousAddress ? previousAddressFields : homeAddressFields);

    const payload =  {
      ..._.omit(changed, [...homeAddressFields, ...previousAddressFields]),
      // Discard prefix (covered by isPreviousAddress flag)
      ..._.mapKeys(changedAddressFields, (value, key) => key.replace('previous_', '')),
      isPreviousAddress,
      reason: values.reason,
      notes: values.notes,
      supportingDocuments: values.supportingDocuments.map(document => {
        return ({ type: document.type, filename: document.filename.s3Filename })
      }),
      isManualVerification,
    };

    callAPI({ data: payload }).then(() => {
      setCurrentStep(currentStep + 1);
    });
  }

  const validateStepAndProceed = async () => {
    let fieldsToValidate: Array<string> = [];
    switch (currentStep) {
      case 0:
        const fieldsToCheck = [ ...personalFields, ...(methods.getValues().isPreviousAddress === 'home' ? homeAddressFields : previousAddressFields) ];
        const dirtyKeys = _.keys(_.pickBy(dirtyFields, (value, key) => !!value));
        fieldsToValidate = _.intersection(fieldsToCheck, dirtyKeys);
        break;

      case 1:
        fieldsToValidate = ['supportingDocuments'];
        break;

      default:
        break;
    }
    if (await methods.trigger(fieldsToValidate as any)) {
      setCurrentStep(currentStep + 1);
    }
  }

  if (currentStep === 3) {
    return <Results results={loadError ? null : data} userId={user._id} />;
  }

  return (
    <Form {...methods}>
      <ProgressBar now={26 + (37 * currentStep)} />

      { currentStep === 0 &&
        <UserInformation />
      }
      { currentStep === 1 &&
        <SupportingDocumentation userId={user._id} />
      }
      { currentStep === 2 &&
        <ReviewSubmit initialValues={defaultValues} />
      }

      <ApiError className='mt-3' error={ loadError ? data : null } />

      <SuperadminAuthRequired>
        <div className='clearfix border-top pt-4 mt-5'>
          { currentStep < 2 &&
            <Button variant="primary" className="float-right" onClick={ validateStepAndProceed }>
              Next
            </Button>
          }

          { currentStep === 2 &&
            <>
              <ButtonWithSpinner
                loading={loadPending && !manualVerificationLoading}
                className="float-right"
                disabled={loadPending && manualVerificationLoading}
                onClick={ () => submitHandler(false) }
                variant="primary"
              >
                Auto-Verify
              </ButtonWithSpinner>
              <ButtonWithSpinner
                loading={loadPending && manualVerificationLoading}
                className="mr-3 float-right"
                disabled={loadPending && !manualVerificationLoading}
                onClick={ () => submitHandler(true) }
                variant="warning"
              >
                Manually Verify
              </ButtonWithSpinner>
            </>
          }

          <Button variant="secondary" href={`/users/${user._id}`} className="mr-3 float-right" disabled={loadPending}>
            Cancel
          </Button>
        </div>
      </SuperadminAuthRequired>
    </Form>
  );
}
