import React from 'react';
import { formatCents, useExpandableState } from '../../../../utils';
import { ServicingActivity } from '../../../../api/queries/loans/useActivity';
import styles from './ActivityDetail.module.scss';
import { formatDate } from '../../../../utils';
import { titleCase } from '../../../../utils/string';
import cx from 'classnames';
import { TimelineListLoader } from '../TimelineTab';
import InlineError from '../../../InlineError';
import useAccountingActivityDetail from '../../../../api/queries/loans/useAccountingActivityDetail';
import useAdminAPICall from '../../../../utils/useAdminAPICall';
import { FieldValues } from 'react-hook-form';
import { Dropdown } from 'react-bootstrap';
import ConfirmModal from '../../../Modals/ConfirmModal/ConfirmModal';
import { LedgerImpactsLoader } from '../LedgerImpacts';

type Props = {
  activity: ServicingActivity;
  loanId: string;
  onChange: () => void;
};

export default function AccountingActivityDetail ({ loanId, activity, onChange }: Props) {
  const detailReq = useAccountingActivityDetail(activity.base_id);
  const accountingActivity = detailReq.data;

  const actionModal = useExpandableState();
  const { callAPI: voidActivity, loadPending } = useAdminAPICall({ endpoint: `/notebook/accounting-activities/${ accountingActivity?._id }/void`, method: 'PUT' });

  if (!accountingActivity) {
    return <>Loading…</>;
  }

  if (detailReq.error) {
    return <InlineError>There was a problem loading the Accounting Activity. Please try again.</InlineError>
  }

  const handleConfirmVoid = async (form: FieldValues) => {
    await voidActivity({ data: form });
    await detailReq.mutate();
    onChange();
  }

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          variant='outline-dark'
          id='dropdown-basic'
          className={ cx(styles.actionButton, styles.dropdownToggler) }
        >
          Edit
        </Dropdown.Toggle>
        <Dropdown.Menu className={ styles.dropdownMenu }>
          <Dropdown.Item
            onClick={ () => { actionModal.showExpand(); } }
            disabled={ !!(accountingActivity.voided_at) || loadPending }
          >
            Mark as Voided
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <ConfirmModal
        title='Please Confirm'
        expand={ actionModal }
        onConfirm={ handleConfirmVoid }
        confirmButtonVariant='danger'
        notes={ true }
      >
        <p>Are you sure you want to void this adjustment?</p>
      </ConfirmModal>
      <div className={ cx(styles.line, 'mt-4') }>
        <span className={ styles.title }>Accounting Activity</span>
      </div>
      <div className={ styles.line }>
        <span>Type</span>
        <span>{ titleCase(accountingActivity.type) }</span>
      </div>
      <div className={ styles.line }>
        <span>Effective Date</span>
        <span>{ formatDate(accountingActivity.effective_date) }</span>
      </div>
      <div className={ styles.line }>
        <span>Amount</span>
        <span>{ formatCents(accountingActivity.amount) }</span>
      </div>
      <div className={ styles.line }>
        <span>Voided On</span>
        <span>{ formatDate(accountingActivity.voided_at, '—') }</span>
      </div>

      <TimelineListLoader
        scopeId={ loanId }
        entityId={ activity.base_id }
      />
      <LedgerImpactsLoader
        sourceId={ activity.base_id }
      />
    </>
  );
};
