import useAdminAPICall from './useAdminAPICall';


// Use as if it is doing directly to an endpoint in API:
//
//    const userReq = useAPIData('/users/:userId');
//
export default function useAPICall ({ endpoint, ...options }: Parameters<typeof useAdminAPICall>[0]) {
  return useAdminAPICall({ endpoint, ...options });

}
