import useQuery from '../../shared/useQuery';

export interface InvestorSummary {
  key: string;
  audit_trail_reporting_enabled: boolean;
  automated_reporting_enabled: boolean;
  automated_monthly_reporting_enabled: boolean;
  purchase_agreement_effective_date?: string;
}

export interface InvestorDetails extends InvestorSummary {
  numLoans: number;
  outstandingPrincipalCents: number;
}

export default function useInvestors () {
  return useQuery<Array<InvestorSummary>>('/notebook/investors');
}
export function useInvestor (investorKey: string) {
  return useQuery<InvestorDetails>(`/notebook/investors/${ investorKey }`);
}
