import React from 'react';
import { Alert } from 'react-bootstrap';
import _ from 'lodash';
import InlineLoadingIndicator from '../InlineLoadingIndicator';
import DebtCalculation from './DebtCalculation';
import useDebtCalculation from './DebtCalculation/useDebtCalculation';
import Summaries from './Summaries';
import CalculatorSourceSelect from './CalculatorSourceSelect';
import PropertyTransactions from './Timeline/PropertyTransactions';
import PropertyLienTransactions, { useLienReportForLoanApplicationIfRequested } from './Timeline/PropertyLienTransactions';
import ReportedDebts from './Timeline/ReportedDebts';
import { useExpandableState } from '../../utils';
import { TIMELINETYPE, REPORTED_DEBT_TYPES_OF_INTEREST, LOAN_APPLICATION_STATUSES } from '../../utils/constants';
import usePropertyTransactions from '../../api/queries/loan-applications/usePropertyTransactions';
import { PROGRAMS } from '../../api/queries/types';
import { LegacyLoanApplication, ReportedDebt } from '../../api/queries/users/useLoanApplications';

// To add a data source:
// 1. custom hook to load data
// 2. summary component
// 3. timeline row component
// 4. timeline parsing/grouping logic

// To add timeline rows to calculator
// 1. add IncludeCheckbox to row component
// 2. ensure record is parsed correctly by amortizeTransaction, if necessary
// 3. add record type to DebtCalculation selectRecordIDs forEach
//    parse and return object, something like:
        // _id: string;
        // record: any;
        // label: string;
        // amount: number;
        // saveable:
        //   key: string;
        //   reportedDate: string | Date;
        //   reportedType: string;
        //   source: string;
        //   amount_cents: number;
        //   raw_amount: string | number;
        //   amortization: any;


export default function Timeline(props: { loanApplication: LegacyLoanApplication, reportedDebts?: Array<ReportedDebt>, manualDebtVerification: any }) {
  const { loanApplication, reportedDebts, manualDebtVerification } = props;

  const showAll = useExpandableState(false);
  const propertyReq: any = usePropertyTransactions(loanApplication._id);
  const lienReq = useLienReportForLoanApplicationIfRequested(loanApplication);
  const disabledEdit = loanApplication.program === PROGRAMS.HOME_CARD && loanApplication.status !== LOAN_APPLICATION_STATUSES.PENDING;

  React.useEffect(function () {
    if (lienReq.loadUnstarted && _.get(manualDebtVerification, ['selectedReportedDebts'], []).find((x: any) => x.source === 'lien_report')) {
      console.info('Saved manualDebtVerifications includes a lien, requesting Lien Report…');
      lienReq.callAPI();
    }
  }, [manualDebtVerification, lienReq]);

  const sortedReportedDebts = _.sortBy(reportedDebts, (v) => -1 * Date.parse(v.date_opened ?? ''));
  const transactions = propertyReq.data && propertyReq.data.length ? _.sortBy(propertyReq.data, (v) => -1 * Date.parse(v.date)) : [];
  const lienReport = _.get(lienReq.data, ['Data', 'Transactions'], []);

  const grouped: any = {};
  const parsedRecordsById: any = {};

  function _parseItem(item: any, type: any, dateProp: any, i: any) {
    const dateStr = typeof dateProp === 'function' ? dateProp(item) : item[dateProp];
    let lists = grouped[dateStr];
    if (!lists) {
      lists = {
        [TIMELINETYPE.REPORTED_DEBT]: [],
        [TIMELINETYPE.TRANSACTION]: [],
        [TIMELINETYPE.LIENREPORT]: [],
        [TIMELINETYPE.CORELOGIC]: [],
        likelyRelevant: false,
      };
      grouped[dateStr] = lists;
    }
    if ([TIMELINETYPE.TRANSACTION, TIMELINETYPE.LIENREPORT, TIMELINETYPE.CORELOGIC].includes(type) || REPORTED_DEBT_TYPES_OF_INTEREST.has(item.account_type)) {
      lists.likelyRelevant = true;
    }
    item._loanApplication = loanApplication; // Pass through for name/address match highlighting

    if (type !== TIMELINETYPE.TRANSACTION) {
      item._id = `${type}_${dateStr}_${i}`;
    }

    const _record = {
      id: item._id,
      item,
      type,
      dateStr,
      date: new Date(dateStr),
    };
    lists[type].push(_record);
    parsedRecordsById[_record.id] = _record;
  }

  sortedReportedDebts.forEach((item, i) => {
    _parseItem(item, TIMELINETYPE.REPORTED_DEBT, 'date_opened', i);
  });

  transactions.forEach((item, i) => {
    _parseItem(item, TIMELINETYPE.TRANSACTION, 'date', i);
  });

  lienReport.forEach((item: any, i: any) => {
    _parseItem(item, TIMELINETYPE.LIENREPORT, 'TxDate', i);
  });

  const sortedDates = Array.from(Object.keys(grouped));
  sortedDates.sort((a, b) => Date.parse(b) - Date.parse(a));

  const calculator = useDebtCalculation(manualDebtVerification, parsedRecordsById);


  if (propertyReq.isValidating) {
    return <><InlineLoadingIndicator /> Loading credit report and property records…</>
  }

  function _rowStyle(dateLists: any) {
    if (!dateLists.likelyRelevant && !showAll.isExpanded) {
      return { opacity: 0.5 };
    }
    return {
      borderBottom: '1px solid #ccc',
      padding: '1rem 0',
    };
  }
  return (
    <>
      <DebtCalculation
        loanApplication   = { loanApplication }
        calculator        = { calculator }
        manualDebtVerification = { manualDebtVerification }
        disabledEdit = { disabledEdit }
      />
      <div>
        <div className='d-flex'>
          <h2 className='h3 flex-grow-1'>Summary</h2>
          <label className='d-flex align-items-center'>
            <span className='mr-2'>Use HELOC field</span>
            <CalculatorSourceSelect value={ calculator.helocCalculatorKey } onChange={ calculator.setHelocCalculatorKey }/>
          </label>
        </div>
        <Summaries
          loanApplication   = { loanApplication as any }
          transactions      = { transactions }
          reportedDebts     = { sortedReportedDebts }
          lienReq           = { lienReq }
          calculator        = { calculator }
        />

      </div>
      <hr />
      <h3>Timeline</h3>
      { propertyReq.error ? (
        <Alert variant='danger'>Error loading Property Record Transactions: { propertyReq.error.message }</Alert>
      ) : null }
      <div className='d-flex'>
        <div style={{ width: 120 }}>
          <h4>Date</h4>
        </div>
        <div className='flex-grow-1 d-flex'>
          <div style={{ width: '50%', paddingRight: '0.25rem' }}>
            <h4>Credit Report Trade Line</h4>
          </div>
          <div style={{ width: '50%', paddingLeft: '0.25rem' }}>
            <h4>Property Record Transaction</h4>
          </div>
        </div>
      </div>
      {
        sortedDates.map((dateStr, i) => (
          <div key={ dateStr } id={ dateStr }>
            <div className='d-flex mb-3' style={_rowStyle(grouped[dateStr])}>
              <div style={{ width: 120 }}><a href={ `#${ dateStr }`}>{ dateStr }</a></div>
              <div className='flex-grow-1 d-flex'>
                <div style={{ width: 'calc(50% - 1px)', paddingRight: '0.5rem', borderRight: '1px solid #ccc' }}>
                  <ReportedDebts calculator={calculator} records={grouped[dateStr]} showAll={showAll} disabledEdit={ disabledEdit } />
                </div>
                <div style={{ width: '50%', paddingLeft: '0.5rem' }}>
                  <PropertyTransactions calculator={calculator} records={grouped[dateStr][TIMELINETYPE.TRANSACTION]} disabledEdit={ disabledEdit } />
                  <PropertyLienTransactions calculator={calculator} records={grouped[dateStr]} disabledEdit={ disabledEdit } />
                </div>
              </div>
            </div>
          </div>
        ))
      }
    </>
  );
}
