import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export default function SmallMutedText (props: Props) {
  const cx = ['text-muted', 'small'];
  if (props.className) { cx.push(props.className); }
  return (
    <span className={ cx.join(' ') }>
      { props.children }
    </span>
  );
}
