import useQuery from '../../shared/useQuery';

enum ROLES {
  SUPERADMIN = 'superadmin',
  ADMIN = 'admin',
};

type Response = {
  _id: string;
  role: ROLES;
  email: string;
  canEditSystemSettings: boolean;
  created: string;
  updated: string;
};

export default function useAdmin (adminId?: string) {
  return useQuery<Response>(adminId ? `/notebook/admins/${adminId}` : null);
}

export function useIsSuperAdmin () {
  const req = useAdmin('self');
  return Boolean(req.data?.role === ROLES.SUPERADMIN);
}
