export default function cleanPhoneNumber (rawValue?: string) {
  if (!rawValue) { return '' }
  const nums = rawValue.replace(/[^\d]/g, '');
  const start = nums.length === 11 && nums[0] === '1' && rawValue[0] === '+' ? 1 : 0;
  const partA = nums.substring(start, start + 3);
  const partB = nums.substring(start + 3, start + 6);
  const partC = nums.substring(start + 6, start + 10);
  if (partC) {
    return `(${partA}) ${partB}-${partC}`;
  }
  if (partB) {
    if (rawValue.slice(-1) === '-') {
      return `(${partA}) ${partB}-`;
    }
    return `(${partA}) ${partB}`;
  }
  return rawValue;
}
