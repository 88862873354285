import React from 'react'
import { Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../../Form/Form';
import SubmitError from '../../Form/SubmitError';
import useAdminAPICall from '../../../utils/useAdminAPICall'
import { Loan } from '../../../api/queries/types';
import ButtonWithSpinner from '../../ButtonWithSpinner';
import usePortfolios from '../../../api/queries/partners/usePortfolios';
import Field from '../../Form/Field';

type Props = {
  onHide: () => void,
  onSubmit: (data: unknown) => void,
  loan: Loan,
};

type FieldValues = {
  portfolio: string;
};

function ModifyLoanForm(props: Props) {
  const { loan, onHide, onSubmit } = props;
  const portfolios = usePortfolios(loan.partnerId);

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loans/${loan._id}/portfolio`,
    method: 'PUT',
  });

  const handleSubmit = async (form: FieldValues) => {
    await callAPI({ data: form });
    onHide();
    onSubmit(form);
  };

  const schema = yup.object({
    portfolio: yup.string().required('Please select an eligible portfolio'),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      portfolio: loan.portfolio?.key,
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty }, getValues } = methods;
  const currentSelection = getValues().portfolio;

  return (
    <>
      { !!portfolios.data &&
        <Form {...methods} onSubmit={handleSubmit}>
          <Field label='Current Portfolio' name='portfolio' controlProps={{ as: 'select' }}>
            { portfolios.data.map(portfolio => <option value={portfolio.key} key={portfolio.key}>{ portfolio.name }</option>) }
          </Field>
          <SubmitError/>

          <div className="d-flex justify-content-end mt-4">
            <Button variant="secondary" onClick={onHide} className="mr-2">
              Cancel
            </Button>
            <ButtonWithSpinner variant="primary" type="submit" disabled={currentSelection === loan.portfolio?.key || !isDirty} loading={isSubmitting}>
              Apply
            </ButtonWithSpinner>
          </div>
        </Form>
      }
    </>
  )
}

export default ModifyLoanForm;
