import React from 'react';
import { Form } from 'react-bootstrap';
import { useController, useFormContext } from 'react-hook-form';
import Select, { Props, StylesConfig } from 'react-select';

export type OptionType = { label: string; value: string };

type SelectFieldProps<Option, IsMulti extends boolean> = Props<Option, IsMulti> & {
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
};

export default function SelectField<IsMulti extends boolean = true>({
  name,
  label,
  placeholder,
  required,
  isMulti,
  closeMenuOnSelect = true,
  ...rest
}: SelectFieldProps<OptionType, IsMulti>) {
  // ref: https://react-select.com/styles
  const customStyles: StylesConfig<OptionType, IsMulti> = {
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      color: state.isSelected ? 'white' : 'var(--theme-ink)',
      backgroundColor: state.isSelected ? 'var(--theme-ink)' : 'white',
      ':hover': {
        backgroundColor: !state.isSelected ? 'var(--gray-200)' : undefined,
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '4px',
    }),
    input: (provided) => ({
      ...provided,
      lineHeight: 1.428571429,
      padding: 0,
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: 6,
      boxShadow: 'none',
      cursor: 'pointer',
      minHeight: '40px',
      fontSize: 16,
      fontWeight: 400,
      outline: state.isFocused ? '3px auto -webkit-focus-ring-color' : undefined,
      outlineOffset: '-3px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      ' svg': {
        fill: 'var(--gray-900)',
      },
    }),
  };

  const { control } = useFormContext();
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name, control });

  const { Group, Label } = Form;

  return (
    <Group controlId={name}>
      <Label>{label}</Label>
      <Select
        {...rest}
        value={value}
        onChange={onChange}
        isMulti={isMulti}
        name={name}
        id={name}
        isClearable={false}
        styles={customStyles}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        closeMenuOnSelect={closeMenuOnSelect}
      />
      {error && error.message && <div className="invalid-feedback d-block">{error.message}</div>}
    </Group>
  );
}
