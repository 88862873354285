import * as _ from 'lodash';
import React from 'react';
import { Button, Modal, FormCheck } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../Form/Form';
import CurrentBadge from '../Badges/CurrentBadge';
import useAdminAPICall from '../../utils/useAdminAPICall';
import AddressFields from '../Form/AddressFields';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { PURCHASE_METHOD_STATUS } from '../../utils/constants';
import { PurchaseMethod } from '../../utils/transactionUtils';
import InlineWarning from '../InlineWarning';

type Address = {
  _id: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  zip: string;
};

type Props = {
  addresses: Array<Address>;
  afterSubmit: (() => void) | (() => Promise<void>);
  card: PurchaseMethod;
  closeModal: () => void;
};

type FieldValues = {
  _id: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  zip: string;
  shipping: 'expedited' | 'usps';
};

export default function ReissueCardModal ({ addresses, afterSubmit, card, closeModal }: Props) {
  const currentId = card.fulfillmentAddressId || 'HOME';

  const schema = yup.object({
    _id: yup.string(),
    address_line1: yup.string().test('address_line1', 'Please provide an address', (v, context) => context.parent._id || v),
    address_line2 : yup.string(),
    city: yup.string().test('city', 'Please provide a city', (v, context) => context.parent._id || v),
    state: yup.string().test('state', 'Please provide a state', (v, context) => context.parent._id || v),
    zip: yup.string().test('zip', 'Please provide a zip code', (v, context) => context.parent._id || v),
    shipping: yup.string(),
  });
  const methods = useForm<FieldValues>({
    defaultValues: { _id: currentId, address_line1: '', address_line2 : '', city: '', state: '', zip: '', shipping: 'expedited' },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting }, register, watch, setValue } = methods;
  const [showUSPSAlert, setShowUSPSAlert] = React.useState(false);
  const reissueCardReq = useAdminAPICall({
    endpoint: `/notebook/cards/${card._id}/reissue`,
    method: 'POST',
  });
  const handleSubmit = async (values: FieldValues) => {
    if (
      (values.address_line1 + (values.address_line2 || '')).replace(/[.\s]+/g,'').toLowerCase().includes('pobox')
      && values.shipping === 'expedited'
    ) {
      setValue('shipping', 'usps');
      setShowUSPSAlert(true);
      return;
    }
    await reissueCardReq.callAPI({
      data: {
        address: _.omit(values, 'shipping'),
        shipping: values.shipping,
      }
    });
    await afterSubmit();
    closeModal();
  };
  const values = watch();

  return (
    <Modal
      size={"md" as any}
      show={!!card}
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          { card.status !== PURCHASE_METHOD_STATUS.TERMINATED && card.status !== PURCHASE_METHOD_STATUS.SUSPENDED && <>Terminate &amp; </> }Reissue Card
        </Modal.Title>
      </Modal.Header>
      <Form {...methods} onSubmit={handleSubmit}>
        <Modal.Body>
          <h5 className='mb-3'>Select an Address</h5>

          {addresses.map(address => (
            <FormCheck
              {...register('_id')}
              id={`address-${address._id}`}
              key={address._id}
              type="radio"
              value={address._id}
              className="mb-3"
              label={(<>
                <strong>{address._id === 'HOME' ? 'Home Address' : 'Shipping Address'}</strong>
                {currentId === address._id && <CurrentBadge />}<br />
                <>
                  {address.address_line1}<br />
                  {address.address_line2}{address.address_line2 && <br />}
                  {address.city}, {address.state} {address.zip}
                </>
              </>)}
            />
          ))}

          <FormCheck
            {...register('_id')}
            id='address-new'
            type="radio"
            value=""
            className="mb-3"
            label={<span className="text-primary">Add Alternate Shipping Address</span>}
          />

          {values._id === "" &&
            <AddressFields
              addressLine1FieldName='address_line1'
              addressLine2FieldName='address_line2'
              cityFieldName='city'
              stateFieldName='state'
              zipFieldName='zip'
            />
          }

          <hr />
          <FormCheck
            {...register('shipping')}
            id='shipping_expedited'
            type="radio"
            value="expedited"
            className="mb-3"
            defaultChecked
            label={ <>Two-day via FedEx/UPS (default)<br /><small className="text-muted">~3–5 business days actual</small> </> }
          />
          <FormCheck
            {...register('shipping')}
            id='shipping_usps'
            type="radio"
            value="usps"
            className="mb-3"
            label={ <>Standard USPS Mail<br /><small className="text-muted">~5–7 business days actual</small></> }
          />
          {
            showUSPSAlert && (
              <InlineWarning>
                USPS must be used for PO Boxes and military addresses.
              </InlineWarning>
            )
          }
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <ButtonWithSpinner
            type='submit'
            variant="primary"
            loading={ isSubmitting }
          >
            Reissue Card
          </ButtonWithSpinner>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
