import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Agent } from '../../api/queries/agents/useAgents';
import { useExpandableState } from '../../utils';

type Props = {
  erroredAgents?: Array<Agent>;
  expand: ReturnType<typeof useExpandableState>;
};

export default function AgentSlugModal ({ expand, erroredAgents }: Props) {
  return (
    <Modal show={expand.isExpanded} onHide={expand.hideExpand}>
      <Modal.Header>
        <Modal.Title>
          { erroredAgents?.length } errors detected
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>The following landing pages could not be enabled because the generated URL would be a duplicate of another agent's URL -</p>

        {
          erroredAgents?.map((agent, index) => (
            <div key={index}>
              { agent.firstName } { agent.lastName }
            </div>
          ))
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' block onClick={expand.hideExpand}>Okay</Button>
      </Modal.Footer>
    </Modal>
  );
}
