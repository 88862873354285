import React from 'react'
import { Button } from 'react-bootstrap'
import { FormGroup, FormLabel } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import CardCheckbox from '../../Form/CardCheckbox';
import SubmitError from '../../Form/SubmitError';
import useAdminAPICall from '../../../utils/useAdminAPICall'
import { PROGRAMS } from '../../../api/queries/types';
import ButtonWithSpinner from '../../ButtonWithSpinner';

type FieldValues = {
  notes: string;
  notifyBorrower: boolean;
  notifyAgent: boolean;
};

type Props = {
  onHide: () => void;
  onSubmit: () => void;
  loan: { _id: string, program: string };
};

function MarkLoanAuthorizedForm(props: Props) {
  const { loan, onHide, onSubmit } = props
  const showAgent = loan.program === PROGRAMS.CONCIERGE;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loans/${loan._id}/mark-as-authorized`,
    method: 'PUT',
  })

  const handleSubmit = async (form: FieldValues) => {
    await callAPI({ data: form });
    onSubmit();
    onHide();
  };

  const schema = yup.object().shape({
    notes: yup.string(),
    notifyBorrower: yup.boolean().required(),
    notifyAgent: showAgent ? yup.boolean().required() : yup.boolean(),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      notes: '',
      notifyBorrower: true,
      notifyAgent: showAgent ? true : undefined,
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting } } = methods;

  return (
    <>
      <Form {...methods} onSubmit={handleSubmit}>
        <Field name='notes' label='Internal Notes' controlProps={{ as: 'textarea', rows: '4' }}/>

        <FormGroup>
          <FormLabel>Notifications</FormLabel>
          <CardCheckbox name='notifyBorrower' label='Notify Borrower (by text and email)' />
          {showAgent ? <CardCheckbox name='notifyAgent' label='Notify Agent (by email)' /> : null}
        </FormGroup>

        <SubmitError/>

        <div className="d-flex justify-content-end mt-4">
          <Button variant="secondary" onClick={onHide} className="mr-2">
            Cancel
          </Button>
          <ButtonWithSpinner variant="primary" type="submit" loading={isSubmitting}>
            Authorize Loan
          </ButtonWithSpinner>
        </div>
      </Form>
    </>
  )
}

export default MarkLoanAuthorizedForm
