import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext, useController } from "react-hook-form";
import cleanPhoneNumber from '../../utils/formatPhone';

type Props = { name: string; label: string; placeholder?: string };

export default function PhoneField ({ name, label, placeholder }: Props) {
  const { Group, Label, Control } = Form;

  const { control } = useFormContext();
  const { field, fieldState: { error } } = useController({ name, control });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(cleanPhoneNumber(e.target.value));
  };

  return <Group controlId={name}>
    <Label>{label}</Label>

    <Control
      type='text'
      placeholder={placeholder}
      isInvalid={!!error}
      {...field}
      onChange={handleChange as any} // React-bootstrap bug defining event type
    />

    { error && error.message && <Control.Feedback type="invalid">{error.message}</Control.Feedback> }
  </Group>;
}
