import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useExpandableState } from '../../utils';

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  onConfirm: () => void;
};

export default function EnableAllLandingPagesModal ({ expand, onConfirm }: Props) {
  const onClick = () => {
    onConfirm();
    expand.hideExpand();
  }

  return (
    <Modal show={expand.isExpanded} onHide={expand.hideExpand}>
      <Modal.Header>
        <Modal.Title>
          Please Confirm
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>This will create and enable custom landing pages for all agents listed below.</p>
        <p>Would you like to continue?</p>
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex w-100'>
          <Button variant='secondary' className='mr-2 w-100' onClick={expand.hideExpand}>No</Button>
          <Button onClick={onClick} className='ml-auto w-100'>Yes</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
