import React from 'react';
import GenericModal from './GenericModal';
import useAdminAPICall from "../../utils/useAdminAPICall";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../Form/Form';
import SubmitError from '../Form/SubmitError';
import useExpandableState from '../../utils/useExpandableState';
import ButtonWithSpinner from '../ButtonWithSpinner';
import Field from '../Form/Field';
import { ProductSummary } from '../../api/queries/products/useProductSummaries';
import { orderBy } from 'lodash';

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
  productId: string;
  products: Array<ProductSummary>;
};

type FieldValues = {
  templateProductId: string;
};

export default function ReplaceProductModal (props: Props) {
  const { expand, onSubmit, productId, products } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/products/${productId}/replace`,
    method: 'PUT',
  });

  const schema = yup.object().shape({
    templateProductId: yup.string().required('Please select a template product'),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {},
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });
  const { formState: { isSubmitting } } = methods;

  async function handleSubmit (values: FieldValues) {
    await callAPI({
      data: { templateProductId: values.templateProductId },
    });
    expand.hideExpand();
    onSubmit();
  }

  return (
    <GenericModal expandable={expand} title={'Replace product'}>
      <Form {...methods} onSubmit={handleSubmit}>
        <Field label='Template' name='templateProductId' controlProps={{ as: 'select' }}>
          { orderBy(products, ['partner'], ['asc']).map((product) => (
            <option key={product._id} value={product._id}>{`${product.partner} - ${product.name}`}</option>
          )) }
         </Field>

        <SubmitError/>
        <div className="d-flex justify-content-end mt-4">
          <Button variant="secondary" onClick={expand.hideExpand} className="mr-2">
            Cancel
          </Button>
          <ButtonWithSpinner variant="primary" type="submit" loading={isSubmitting}>
            Replace product
          </ButtonWithSpinner>
        </div>
      </Form>
    </GenericModal>
  );
}
