import { useEffect, useState } from 'react';

export default function useScrollPositionReached (yPosition: number) {
  const [positionReached, setPositionReached] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const navStuck = window.scrollY > yPosition;
      if (navStuck) {
        setPositionReached(true);
      } else {
        setPositionReached(false);
      }
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [yPosition]);
  
  return positionReached;
}
