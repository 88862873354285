import React from 'react';
import { Button, Modal } from "react-bootstrap";
import useExpandableState from '../../utils/useExpandableState';
import humanizeString from 'humanize-string';

type Props = {
  expandable: ReturnType<typeof useExpandableState>;
  issues: Array<string>;
  onHide: () => void;
};

export default function ActivatePartnerIssuesModal (props: Props) {
  const { expandable, issues, onHide } = props;

  const onModalHide = () => {
    onHide();
    expandable.hideExpand();
  }

  return (
    <Modal show={expandable.isExpanded} size='sm' onHide={onModalHide}>
      <Modal.Header>
        <h5>
          Please resolve issues
        </h5>
      </Modal.Header>
      <Modal.Body>
        <p>
          In order to activate this partner, the following fields must be completed:
        </p>
        <div>
          {issues.map((issue, index) => (
            <div className='font-weight-bold' key={index}>{humanizeString(issue)}</div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onModalHide} className="w-100">
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
