import React from 'react';
import { useNavigate } from 'react-router-dom';
import SystemLayout from '../../Layouts/SystemLayout';
import useInvestors, { InvestorSummary, useInvestor } from '../../../api/queries/investors/useInvestors';
import { Table } from 'react-bootstrap';
import { formatCents } from '../../../utils';

function InvestorRow ({ investor }: { investor: InvestorSummary }) {
  const navigate = useNavigate();
  const details = useInvestor(investor.key);
  return (
    <tr className='cursor-pointer' onClick={ () => navigate(`/system/investors/${ investor.key }/info`) }>
      <td>{ investor.key }</td>
      <td>{ investor.purchase_agreement_effective_date || '—' }</td>
      <td className='text-right'>
        { details.data ? (details.data.numLoans || '—') : '…' }
      </td>
      <td className='text-right'>
        {
          details.data
            ? (
              details.data.outstandingPrincipalCents
                ? formatCents(details.data.outstandingPrincipalCents)
                : '—'
            )
            : '…'
        }</td>
    </tr>
  );

}

export default function InvestorsPage () {
  const investors = useInvestors();
  return (
    <SystemLayout selectedTab='investors'>
      { investors.data ? <Table>
        <thead>
          <tr>
            <th>Investor Key</th>
            <th>Purchase Agreement Date</th>
            <th className='text-right'>Num Loans Purchased</th>
            <th className='text-right'>Outstanding Principal Balance</th>
          </tr>
        </thead>
        <tbody>
          {
            investors.data.map(investor => (
              <InvestorRow investor={ investor } key={ investor.key } />
            ))
          }
        </tbody>
      </Table> : 'Loading…' }
    </SystemLayout>
  );
}
