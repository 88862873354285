import useMutation from '../../shared/useMutation';

type Payload = {
  template: string;
  name: string;
  slug: string;
};

export default function useMutateCreatePartner () {
  return useMutation<Payload, any>('POST', '/notebook/partners');
}
