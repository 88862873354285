import React from "react";
import { Card } from "react-bootstrap";
import LabelValueGrouping from "../../LabelValueGrouping";
import formatDollars from "../../../utils/formatDollars";
import AddressDisplay from '../../AddressDisplay'
import { formatDateCommon } from "../../../utils/formatDate";
import { titleCase } from '../../../utils/string';
import { BetterUnderwritingData } from './HomeCardApplicationTab';
import { LOAN_APPLICATION_SOURCES } from "../../../utils/constants";

export default function BetterMortgageInfo({ className, betterUnderwritingData }: { className: string, betterUnderwritingData: BetterUnderwritingData }) {

  return (
    <Card className={className}>
      <Card.Header>Debt</Card.Header>
      <Card.Body>
        <LabelValueGrouping
          data={{
            Source: titleCase(LOAN_APPLICATION_SOURCES.BETTER),
            "Estimated Closing Date": formatDateCommon(betterUnderwritingData.estimatedClosingDate),
            "Closing Date": formatDateCommon(betterUnderwritingData.closingDate) || '-',
            'Funding Date': formatDateCommon(betterUnderwritingData.fundingDate) || '-',
            "Loan Purpose": betterUnderwritingData.loanPurpose,
            "Loan Amount": formatDollars(betterUnderwritingData.mortgageSize),
            "Mortgage Term": betterUnderwritingData.term && `${betterUnderwritingData.term / 12} year`,
            "Appraised Value": formatDollars(betterUnderwritingData.appraisedValue),
            AVM: formatDollars(betterUnderwritingData.avm),
            "Property Address": betterUnderwritingData.propertyAddress && (
              <AddressDisplay
                line1={betterUnderwritingData.propertyAddress.address1}
                line2={betterUnderwritingData.propertyAddress.address2}
                city={betterUnderwritingData.propertyAddress.city}
                state={betterUnderwritingData.propertyAddress.state}
                zip={betterUnderwritingData.propertyAddress.zip}
              />
            ),
          }}
          condensed={true}
        />
        {
          betterUnderwritingData.mortgagePayment ? (
            <>
              <LabelValueGrouping
                data={{
                  "Monthly Payment": formatDollars(betterUnderwritingData.mortgagePayment.total),
                  "Principal & Interest": formatDollars(betterUnderwritingData.mortgagePayment.principalAndInterest),
                  Taxes: formatDollars(betterUnderwritingData.mortgagePayment.tax),
                  Insurance: formatDollars(betterUnderwritingData.mortgagePayment.insurance),
                  "Hazard Insurance": formatDollars(betterUnderwritingData.mortgagePayment.hazardInsurance),
                  HOA: formatDollars(betterUnderwritingData.mortgagePayment.hoa),
                }}
                condensed={true}
              />
            </>
          ) : null
        }
      </Card.Body>
    </Card>
  );
}
