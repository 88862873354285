import useQuery from '../../shared/useQuery';

interface AccountSummary {
  disbursedAccrued: number | undefined;
  repaidApplied: number | undefined;
  balance: number | undefined;
}

export interface AccountingSummary {
  clearedPrincipal: AccountSummary;
  pendingPrincipal: AccountSummary;
  interest: AccountSummary;
  fees: AccountSummary;
  overpayments: AccountSummary;
  total: AccountSummary;
  cashBack: AccountSummary;
  adjustments: AccountSummary;
  cashRepayments: AccountSummary;
  unclaimedFunds: AccountSummary;
}

export default function useAccounting (loanId: string) {
  return useQuery<AccountingSummary>(`/notebook/loans/${ loanId }/accounting`);
}
