import moment from 'moment-timezone';

const DEFAULT_TZ = 'America/New_York';
export const DATEONLY_FORMAT = 'YYYY-MM-DD';

export function getDateInTimezone (date: moment.MomentInput = getDateInTimezone.now(), tz = DEFAULT_TZ) {
	// strict mode enabled to prevents broken ("best attempt") parsing that is likely in the wrong timezone anyway
  return moment.tz(date as any, moment.ISO_8601, true, tz);
}

// To facilitate mocking in tests
getDateInTimezone.now = function () {
  return new Date();
}

export function isDateOnlyWithinReasonableBounds(datestring: moment.MomentInput) {
  const parsedDate = parseDateOnlyString(datestring);
  if (!parsedDate) {
    return false;
  }
  const now = new Date(Date.now());
  return parsedDate.isBetween(moment(now).subtract(10, 'years'), moment(now).add(10, 'years'));
}

export const isTodayOrFutureDate = (date: moment.MomentInput | null | undefined) => {
  if (!date) { return false; }
  return moment.tz('America/New_York').isSameOrBefore(moment.tz(date, 'America/New_York'), 'day');
};

export function parseDateOnlyString (datestring: moment.MomentInput) {
  const parsed = moment(datestring, DATEONLY_FORMAT, true);
  if (!parsed.isValid()) {
    return false;
  }
  return parsed;
}
