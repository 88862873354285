import { SWRConfiguration } from 'swr';
import useQuery from '../../shared/useQuery';
import { ExternalActivity } from '../../../utils/transactionUtils';

/**
 * @param activityId an ExternalActivity _id
 * @param swrConfig
 */
export default function useExternalActivity (activityId: string, swrConfig: SWRConfiguration<ExternalActivity, any>={}) {
  return useQuery<ExternalActivity>(`/notebook/external-activities/${ activityId }`, swrConfig);
}
