import React from "react";
import { Badge } from "react-bootstrap";
import style from "../UnderwritingTab.module.scss";
import { FINAL_DECISION_RESULTS } from "../constants";

function FinalDecisionBadge({ finalDecision }: { finalDecision: string }) {
  switch (finalDecision) {
    case FINAL_DECISION_RESULTS.UNDECIDED:
      return (
        <Badge variant="dark">
          UNDECIDED
        </Badge>
      );
    case FINAL_DECISION_RESULTS.APPROVED:
      return (
        <Badge variant="success">
          APPROVED
        </Badge>
      );
    case FINAL_DECISION_RESULTS.DENY:
      return (
        <Badge variant="danger">
          DENY
        </Badge>
      );
    case FINAL_DECISION_RESULTS.INELIGIBLE:
      return (
        <Badge variant="secondary">
          INELIGIBLE
        </Badge>
      );
    case FINAL_DECISION_RESULTS.COUNTEROFFER:
      return (
        <Badge className={style.counterOfferBadge} variant="success">
          COUNTEROFFER
        </Badge>
      );
    default:
      return <Badge variant="dark">
        {finalDecision}
      </Badge>;
  }
}

export default FinalDecisionBadge;
