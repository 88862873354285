import React from 'react';
import DebtList from '../../DebtList';
import { usePropertyDebtCents } from '../DebtCalculation';
import LabelValueGrouping from '../../LabelValueGrouping';
import formatDollars from '../../../utils/formatDollars';
import useDebtCalculation from '../DebtCalculation/useDebtCalculation';
import { LegacyLoanApplication } from '../../../api/queries/users/useLoanApplications';

type Props = {
  loanApplication: LegacyLoanApplication;
  calculator: ReturnType<typeof useDebtCalculation>;
};

export default function StatedDebtDetails (props: Props) {
  const { loanApplication, calculator } = props;
  const propertyDebtCents = usePropertyDebtCents(loanApplication, calculator);
  return <>
    {
      loanApplication.statedDebts && loanApplication.statedDebts.length > 0 ? (
        <DebtList loanApplicationId={loanApplication._id} className='mb-4' />
      ) : null
    }
    <LabelValueGrouping data={{
      'Property debt': formatDollars(propertyDebtCents! / 100),
    }} />
  </>;
}
