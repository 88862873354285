import useAdminAPIData, { Options } from './useAdminAPIData';

/**
 * Hook to load data from an API endpoint. Fetches data on mount.
 *
 * @deprecated Create a typed queries in /api/queries instead.
 *
 * @param endpoint The API endpoint to query from.
 * @param options.abort If true, aborts the request and resets state.
 * @returns The state of the request and loadData function to invoke the request.
 * @example const userReq = useAPIData('/users/5e1234abcdef');
 */
export default function useAPIData<TRawData = any> (endpoint: string, options?: Options) {
  return useAdminAPIData<TRawData>(endpoint, options);
}
