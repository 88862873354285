import React from 'react'
import { Button } from 'react-bootstrap'
import { FormGroup, FormLabel } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import CardCheckbox from '../../Form/CardCheckbox';
import SubmitError from '../../Form/SubmitError';
import useAdminAPICall from '../../../utils/useAdminAPICall'
import ButtonWithSpinner from '../../ButtonWithSpinner';

type FieldValues = {
  repaidDate: string;
  notes: string;
  notifyBorrower: boolean;
};

type Props = {
  onHide: () => void,
  onSubmit: () => void,
  loan: { _id: string },
};

function MarkLoanRepaidForm(props: Props) {
  const { loan, onHide, onSubmit } = props

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loans/${loan._id}/mark-as-repaid`,
    method: 'PUT',
  })

  const handleSubmit = async (form: FieldValues) => {
    await callAPI({ data: form });
    onSubmit();
    onHide();
  }

  const schema = yup.object().shape({
    repaidDate: yup.string().required('Please choose repaid date'),
    notes: yup.string(),
    notifyBorrower: yup.boolean().required(),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      repaidDate: '',
      notes: '',
      notifyBorrower: true,
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting } } = methods;

  return (
    <>
      <Form {...methods} onSubmit={handleSubmit}>
        <Field name='repaidDate' label='Repaid date' controlProps={{ type: 'date' }}/>
        <Field name='notes' label='Internal Notes' controlProps={{ as: 'textarea', rows: '4' }}/>

        <FormGroup>
          <FormLabel>Notifications</FormLabel>
          <CardCheckbox name='notifyBorrower' label='Notify Borrower (by text and email)' />
        </FormGroup>

        <SubmitError/>

        <div className="d-flex justify-content-end mt-4">
          <Button variant="secondary" onClick={onHide} className="mr-2">
            Cancel
          </Button>
          <ButtonWithSpinner variant="primary" type="submit" loading={isSubmitting}>
            Mark Repaid
          </ButtonWithSpinner>
        </div>
      </Form>
    </>
  )
}

export default MarkLoanRepaidForm
