import React from 'react';
import Form from 'react-bootstrap/Form';
import { PROPERTY_DEBT_HELOC_CALCULATOR_KEYS } from '../../utils/constants';

type Props = {
  value: string;
  onChange: (v: PROPERTY_DEBT_HELOC_CALCULATOR_KEYS) => void;
};

export default function CalculatorSourceSelect (props: Props) {
  return (
    <Form.Group
      className = 'my-2'
      controlId = 'calc-source'
    >
      <Form.Control
        as       = 'select'
        name     = 'calc-source'
        style    = {{ width: 'auto' }}
        value    = { props.value }
        onChange = { (e: any) => props.onChange && props.onChange(e.target.value) }
      >
        <option value={PROPERTY_DEBT_HELOC_CALCULATOR_KEYS.CREDIT_LIMIT}>Credit Limit</option>
        <option value={PROPERTY_DEBT_HELOC_CALCULATOR_KEYS.CURRENT_BALANCE}>Current Balance</option>
      </Form.Control>
    </Form.Group>
  );
}
