import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import LabelValueGrouping from '../../LabelValueGrouping';
import { BUSINESS_ENTITIES, ENTITY_TYPE_LABELS } from "../../../utils/constants";
import { LegacyLoanApplication } from '../../../api/queries/users/useLoanApplications';
import { titleCase } from '../../../utils/string';
import { ENTITY_TYPE } from '../../../api/shared/types/LoanApplication';
import { isNil } from 'lodash';

export default function PropertyOwnership ({ className, loanApplication }: { className?: string, loanApplication: LegacyLoanApplication }) {
  return (
    <>
      <ReportedPropertyOwnership className={className} loanApplication={loanApplication}/>
      { !loanApplication.isPropertyOwnershipAutomaticallyVerified &&
        <StatedPropertyOwnership className={className} loanApplication={loanApplication}/>
      }
    </>
  );
}

function ReportedPropertyOwnership ({ className, loanApplication }: { className?: string, loanApplication: LegacyLoanApplication }) {
  const dataForValueGrouping: Record<string, any> = {
    'Ownership status': loanApplication.listingOwnershipStatus ? loanApplication.listingOwnershipStatus : '-',
    'Name of Owner': loanApplication.listingOwners ?
      loanApplication.listingOwners.map(owner => `${owner.firstName} ${owner.lastName}`)
    : '-',
  };

  return (
    <Card className={className}>
      <Card.Header>
        Reported Property Ownership
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <LabelValueGrouping
              data={dataForValueGrouping}
              condensed={ true }
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

function StatedPropertyOwnership ({ className, loanApplication }: { className?: string, loanApplication: LegacyLoanApplication }) {
  const dataForValueGrouping = getDataForValueGrouping(loanApplication);

  return (
    <Card className={className}>
      <Card.Header>
        Stated Property Ownership
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <LabelValueGrouping
              data={dataForValueGrouping}
              condensed={ true }
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

function getDataForValueGrouping (loanApplication: LegacyLoanApplication) {
  const dataForValueGrouping: Record<string, any> = {};

  if (!isNil(loanApplication.loanOwnershipType)) {
    dataForValueGrouping['Scenario'] = loanApplication.isPOA ? 'I want to apply on behalf of someone else' : 'I want to apply in my name';
    if (loanApplication.isPOA) {
      dataForValueGrouping['Applicant Name'] = `${loanApplication.poaFirstName} ${loanApplication.poaLastName}`;
    }
    dataForValueGrouping['Name of Owner'] = `${loanApplication.firstName} ${loanApplication.lastName}`;
    if (loanApplication.isPOA) {
      dataForValueGrouping['Relationship to Owner'] = isNil(loanApplication.ownerRelationship) ? '-' : titleCase(loanApplication.ownerRelationship);
    }
  } else {
    dataForValueGrouping['Direct Owner'] = isNil(loanApplication.ownedByBorrower) ? '-' : loanApplication.ownedByBorrower ? 'Yes' : 'No';
    if (!loanApplication.ownedByBorrower) {
      dataForValueGrouping['Name of Owner'] = loanApplication.ownerFullName ?? '-';
      dataForValueGrouping['Relationship to Owner'] = isNil(loanApplication.ownerRelationship) ? '-' : titleCase(loanApplication.ownerRelationship);
      dataForValueGrouping['POA'] = loanApplication.powerOfAttorney ? 'Yes' : 'No';
    }
  }

  dataForValueGrouping['Owned via Entity'] = isNil(loanApplication.ownedByEntity) ? '-' : loanApplication.ownedByEntity ? 'Yes' : 'No';

  if (loanApplication.ownedByEntity) {
    dataForValueGrouping['Type of Entity'] = ENTITY_TYPE_LABELS[loanApplication.entityType];
    if (loanApplication.entityType === ENTITY_TYPE.TRUST) {
      dataForValueGrouping['Name of Trust'] = loanApplication.trustName;
      dataForValueGrouping['Role'] = loanApplication.trustRole.map(t => titleCase(t));
    }
    if (BUSINESS_ENTITIES.includes(loanApplication.entityType) && loanApplication.businessName) {
      dataForValueGrouping['Name of Business'] = loanApplication.businessName;
    }
    if (loanApplication.entityType === ENTITY_TYPE.ESTATE) {
      dataForValueGrouping['Role'] = titleCase(loanApplication.estateRole);
    }
    if (loanApplication.entityType !== ENTITY_TYPE.TRUST) {
      dataForValueGrouping['Can act on behalf of entity'] = titleCase(loanApplication.canActOnBehalfOfPropertyEntity);
    }
  }

  return dataForValueGrouping;
}
