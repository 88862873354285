import useQuery from '../../shared/useQuery';

export type Data = {
  active: boolean;
  adminName: string;
  reason: string;
  notes: string;
};

export default function usePauseInfo (userId?: string) {
  return useQuery<Data>(userId ? `/notebook/users/${userId}/pause` : null);
}
