import React from "react";
import VerificationBadge from "../VerificationBadge";
import { Col, Row } from "react-bootstrap";
import { VERIFICATION_RESULTS } from '../constants';
import { Fact } from '../index';
import InlineButton from "../../../InlineButton";
import { useExpandableState } from "../../../../utils";
import EditAddressFactModal from '../../../Modals/EditAddressFactModal';

export type PropertyListingAddressFactData = {
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  zip: string;
  hash?: string;
};

export default function PropertyListingAddressFact ({ fact, showEditButton }: { fact: Fact<PropertyListingAddressFactData>, showEditButton?: boolean }) {
  const factModal = useExpandableState();

  return (
    <Row className="border-top py-3" data-qa='fact-row'>
      <Col sm={4} className="font-weight-bold pl-0">{fact.human_name}</Col>
      <Col sm={4}>{fact.value}</Col>
      <Col sm={4} className="pr-0">
        <VerificationBadge
          verificationResult={fact.verified ? VERIFICATION_RESULTS.VERIFIED : VERIFICATION_RESULTS.UNVERIFIED}
          unverifiedReasons={fact.unverified_reasons}
        />
        {
          showEditButton &&
          <InlineButton onClick={factModal.showExpand} className='float-right'>
            Edit
          </InlineButton>
        }
      </Col>
      <EditAddressFactModal
        expand={factModal}
        fact={fact}
        onSubmit={() => window.location.reload()}
        submitUrl='/property-listing-address'
      />
    </Row>
  );
}
