import { TheNumberPropertyLink } from '../TheNumberLink';
import React from 'react';
import Highlight from '../Highlight';
import { LegacyLoanApplication } from '../../api/queries/users/useLoanApplications';

function ListingOwnershipStatus (props: { loanApplication: LegacyLoanApplication; }) {
  const { loanApplication } = props;
  const shouldHighlight = loanApplication.listingOwnershipCode === 'TR' || loanApplication.listingOwnershipCode === 'SE';
  const label = `Ownership Status: ${loanApplication.listingOwnershipStatus}`;

  if (loanApplication.listingOwnershipStatus) {
    return <Highlight maybe={ shouldHighlight }>{ label }</Highlight>;
  } else {
    return null;
  };
}

export default function LensListingInfo (props: { loanApplication: LegacyLoanApplication; }) {
  const { loanApplication } = props;

  return (
    <div className='pr-5'>
      <h3>Listing</h3>
      <div>
        <TheNumberPropertyLink loanApplication={ loanApplication }>
          {loanApplication.listing_address_line1} {loanApplication.listing_address_line2}
          <br />{loanApplication.listing_city} {loanApplication.listing_state}, {loanApplication.listing_zip}
        </TheNumberPropertyLink>
      </div>
      <ListingOwnershipStatus loanApplication={ loanApplication } />
    </div>
  );
}
