import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useFormContext, useController } from "react-hook-form";
import Decimal from 'decimal.js';
import cx from 'classnames';

import styles from './Field.module.scss';

type Props = {
  name: string;
  label: string;
  subLabel?: React.ReactNode;
  footnote?: React.ReactNode;
  placeholder?: string;
  precision?: number;
  max?: number;
  disabled?: boolean;
  onBlur?: () => void;
};

export default function DollarField ({ name, label, subLabel, footnote, placeholder, max, disabled, onBlur: _onBlur, precision = 2 }: Props) {
  const { Group, Label, Control, Text } = Form;

  const { control } = useFormContext();
  const { field: { onChange, onBlur, value, ref }, fieldState: { error } } = useController({ name, control });

  const handleBlur = () => {
    onChange(value ? new Decimal(Math.min(Number(value), max ?? Number(value))).toFixed(precision) : value);
    onBlur();
    _onBlur?.();
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleanValue = e.target.value.replace(/[^\d.-]+/g,'');

    let [, dollars, cents] = cleanValue.match(`(^-?\\d*)(\\.\\d{0,${precision}})?`)!;

    if (!dollars && cents) { dollars = '0'; }

    onChange(`${dollars ? parseInt(dollars) : ''}${cents || ''}`);
  };

  return <Group controlId={name}>
    <Label>{label}</Label>

    { subLabel && <Text className={cx("text-muted", styles.indentLabel)}>{subLabel}</Text> }

    <InputGroup>
      <InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
      <Control
        type='text'
        name={name}
        isInvalid={!!error}
        ref={ref}
        value={value}
        disabled={disabled}
        onChange={handleChange as any} // React-bootstrap bug defining event type
        onBlur={handleBlur}
        placeholder={placeholder}
      />
    </InputGroup>

    { footnote && <Text className="text-muted">{ footnote }</Text> }

    {
      // Bug in Bootstrap: need to add d-block because they only show it if next to an invalid control,
      // and in this case we have the InputGroup
    }
    { error && error.message && <Control.Feedback className='d-block' type="invalid">{error.message}</Control.Feedback> }
  </Group>;
}
