import React from 'react';
import cx from 'classnames';
import { Card, Col, Row } from 'react-bootstrap';

import AddressDisplay from '../../AddressDisplay';
import LabelValueGrouping from '../../LabelValueGrouping';
import SectionHeader from '../../SectionHeader';
import formatDollars from '../../../utils/formatDollars';
import { ENTITY_TYPE_LABELS } from '../../../utils/constants';
import { FormattedDebtList } from '../../DebtList';
import { displayEntityRole } from '../../../utils/loanApplication';
import { titleCase } from '../../../utils/string';
import { LegacyLoanApplication } from '../../../api/queries/users/useLoanApplications';
import { useExpandableState } from '../../../utils/index';
import EditListingAddressModal from '../../Modals/EditListingAddressModal';
import EditAgentModal from '../../Modals/EditAgentModal';
import InlineButton from '../../InlineButton';

export default function ListingInfo ({ loanApplication }: { loanApplication: LegacyLoanApplication }) {
  const editListingAddress = useExpandableState();
  const editCompassAgent = useExpandableState();
  const compassAgentGeo = loanApplication.agent?.geo ? loanApplication.agent?.geo.toUpperCase() : ''
  const systemMatchedOwnedByBorrower = loanApplication.ownedByBorrower === null;
  const ownedByBorrower = systemMatchedOwnedByBorrower
    ? '—'
    : loanApplication.ownedByBorrower
      ? 'Yes'
      : 'No';
  // Hide power of attorney if listing is owned by borrower
  const powerOfAttorney = loanApplication.ownedByBorrower
    ? undefined
    : systemMatchedOwnedByBorrower
      ? ''
      : (loanApplication.powerOfAttorney ? 'Yes' : 'No')

  const ownedByEntity = systemMatchedOwnedByBorrower
      ? '—'
      : loanApplication.ownedByEntity
        ? 'Yes'
        : 'No';

  return (
    <Card>
      <Card.Header>
        Listing Info
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <SectionHeader>Basic Info</SectionHeader>
            <LabelValueGrouping
              data={{
                'Listing Address': (
                  <>
                    <AddressDisplay
                      line1={loanApplication.listing_address_line1}
                      line2={loanApplication.listing_address_line2}
                      city={loanApplication.listing_city}
                      state={loanApplication.listing_state}
                      zip={loanApplication.listing_zip}
                    />
                    <InlineButton onClick={editListingAddress.showExpand}>
                      { '\u00a0Edit' }
                    </InlineButton>
                    <EditListingAddressModal
                      expand={editListingAddress}
                      loanApplication={loanApplication}
                      onSubmit={() => window.location.reload()}
                    />
                  </>
                ),
                'Listing Price': formatDollars(loanApplication.listingPrice) || '–',
              }}
              condensed
            />

            <SectionHeader className="mt-4">Property Debt</SectionHeader>
            <LabelValueGrouping
              data={{
                'Total Property Debt': formatDollars(loanApplication.propertyDebt) || '–',
              }}
              condensed
            />
            <FormattedDebtList loanApplication={loanApplication} />
          </Col>

          <Col>
            {loanApplication.agent && (
              <>
                <SectionHeader>Agent</SectionHeader>
                <LabelValueGrouping data={{
                    'Compass Agent': (
                      <>
                        <span>{loanApplication.agent.displayName} ({compassAgentGeo})</span>
                        <InlineButton onClick={editCompassAgent.showExpand}>
                          { '\u00a0Edit' }
                        </InlineButton>
                      </>
                    ),
                    'Compass Agent Phone': loanApplication.agent.phone,
                    'Compass Agent Email': loanApplication.agent.email,
                  }}
                  condensed
                />
                <EditAgentModal
                  expand={editCompassAgent}
                  loanApplication={loanApplication}
                  onSubmit={() => window.location.reload()}
                />
              </>
            )}

            <SectionHeader className={cx({ 'mt-4': loanApplication.agent?.externalId })}>Ownership</SectionHeader>
            <LabelValueGrouping data={{
                'Direct Owner': ownedByBorrower,
                POA: powerOfAttorney,
                'Relationship to Owner': titleCase(loanApplication.ownerRelationship),
                'Owner Name': loanApplication.ownerFullName,
              }}
              condensed
              removeBlanks
              tooltip={ systemMatchedOwnedByBorrower ? 'Not stated by applicant due to an automated match' : false }
            />

            <LabelValueGrouping data={{
                'Owned via Entity': ownedByEntity,
                'Entity Type': ENTITY_TYPE_LABELS[loanApplication.entityType],
                'Entity Name': loanApplication.trustName,
                'Entity Role': displayEntityRole(loanApplication),
              }}
              className="mt-4"
              condensed
              removeBlanks
              tooltip={ systemMatchedOwnedByBorrower ? 'Not stated by applicant due to an automated match' : false }
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
