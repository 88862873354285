import React from 'react';
import { Partner } from '../../../api/queries/partners/usePartner';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import SubmitError from '../../Form/SubmitError';
import { Button } from 'react-bootstrap';
import ButtonWithSpinner from '../../ButtonWithSpinner';

type PartnerInfoFields = {
  android192IconUrl: string;
  android512IconUrl: string;
  appleTouchIconUrl: string;
  favicon16Url: string;
  favicon32Url: string;
  faviconUrl: string;
  socialImageUrl: string;
};

type Props = {
  partner: Partner;
  onSubmit: () => void;
  onHide: () => void;
}

export default function EditPartnerFavicons (props: Props) {
  const { partner, onSubmit, onHide } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/partners/${partner._id}`,
    method: 'PUT',
  });

  async function handleSubmit(data: PartnerInfoFields) {
    await callAPI({
      data: {
        android192IconUrl: data.android192IconUrl,
        android512IconUrl: data.android512IconUrl,
        appleTouchIconUrl: data.appleTouchIconUrl,
        favicon16Url: data.favicon16Url,
        favicon32Url: data.favicon32Url,
        faviconUrl: data.faviconUrl,
        socialImageUrl: data.socialImageUrl,
      },
    });
    onSubmit();
    onHide();
  }

  const schema = yup.object();

  const methods = useForm<PartnerInfoFields>({
    defaultValues: {
      android192IconUrl: partner.android192IconUrl,
      android512IconUrl: partner.android512IconUrl,
      appleTouchIconUrl: partner.appleTouchIconUrl,
      favicon16Url: partner.favicon16Url,
      favicon32Url: partner.favicon32Url,
      faviconUrl: partner.faviconUrl,
      socialImageUrl: partner.socialImageUrl,
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty } } = methods;

  return <div>
    <Form {...methods} onSubmit={handleSubmit}>
      <Field name='android192IconUrl' label='Android 192x192 Icon'/>
      <Field name='android512IconUrl' label='Android 512x512 Icon'/>
      <Field name='appleTouchIconUrl' label='Apple Touch Icon'/>
      <Field name='favicon16Url' label='Favicon 16x16'/>
      <Field name='favicon32Url' label='Favicon 32x32'/>
      <Field name='faviconUrl' label='Favicon'/>
      <Field name='socialImageUrl' label='Social Image'/>

      <SubmitError/>

      <div className="d-flex justify-content-end mt-4">
        <Button variant="secondary" onClick={onHide} className="mr-2">
          Cancel
        </Button>
        <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
          Confirm
        </ButtonWithSpinner>
      </div>
    </Form>
  </div>;
}
