import * as _ from 'lodash';
import React from 'react';
import { Button, Table, Modal } from 'react-bootstrap';
import MaskedAccountNumber from '../MaskedAccountNumber';
import StaticAddress from '../StaticAddress';
import OutboundLink from '../OutboundLink';
import { SUPPORTING_DOCUMENT_TYPES } from '../../utils/constants';
import { getDateInTimezone } from '../../utils/datetime';
import useExpandableState from '../../utils/useExpandableState';
import { KycResult } from '../../api/queries/users/useKycResults';
import styles from './KYCHistoryModal.module.scss';

const reasons = {
  previous_kyc_failure: 'Previous KYC Failure',
  dob_bug: 'DOB Bug',
};

function None () {
  return <span className="text-muted">None</span>;
}

function SupportingDocumentLinks ({ record }: { record: KycResult }) {
  const { supportingDocuments } = record;
  if (!supportingDocuments || supportingDocuments.length === 0) {
    return <None/>;
  }
  return <>{supportingDocuments.map((doc, index) =>
    <span key={ doc.filename }>
      <OutboundLink href={ doc.filename }>
        { _.get(_.find(SUPPORTING_DOCUMENT_TYPES, ({ value }) => value === doc.type), 'label', doc.type) }
      </OutboundLink>
      { index + 1 < supportingDocuments.length && ', ' }
    </span>
  )}</>;
}

function Results ({ record }: { record: KycResult }) {
  const { status, isManualVerification, failureCodes } = record;
  let label, resultClass;
  if (status === 'success') {
    label = isManualVerification ? 'Pass: Manual' : 'Pass';
    resultClass = styles.passResult;
  } else {
    label = 'Fail: ' + (failureCodes || ['N/A']).join(', ');
    resultClass = styles.failResult;
  }
  return <span className={ resultClass }>{ label }</span>;
}

type Props = {
  modalState: ReturnType<typeof useExpandableState>;
  kycResults?: Array<KycResult>;
};

export default function KYCHistoryModal ({ modalState, kycResults = [] }: Props) {
  return (
    <Modal show={ modalState.isExpanded } onHide={ modalState.hideExpand } dialogClassName={ styles.modal } scrollable>
      <Modal.Header>
        <Modal.Title>KYC Results</Modal.Title>
      </Modal.Header>
      <Modal.Body className={ styles.body }>
        <Table className={ styles.table }>
          <thead>
            <tr>
              <th>Date</th>
              <th>First</th>
              <th>Last</th>
              <th>DOB</th>
              <th className={ styles.ssnColumn }>SSN</th>
              <th className={ styles.addressColumn }>Address</th>
              <th>Documentation</th>
              <th>Notes</th>
              <th>Reason</th>
              <th>Marqeta Program Slug</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            { kycResults.map((record) => (
              <tr key={ `${record._id}` }>
                <td>{ getDateInTimezone(record.createdAt).format('M/D/Y') }</td>
                <td>{ record.firstName }</td>
                <td>{ record.lastName }</td>
                <td>{ getDateInTimezone(record.dob).format('M/D/Y') }</td>
                <td><MaskedAccountNumber number={ record.ssnLastFour } /></td>
                <td><StaticAddress address={ record as any } /></td>
                <td><SupportingDocumentLinks record={ record } /></td>
                <td>{ record.notes ? <span className="font-italic">{ record.notes }</span> : <None/> }</td>
                <td>{ record.reason ? _.get(reasons, record.reason, '-') : <None/> }</td>
                <td>{record.marqetaProgramSlug}</td>
                <td><Results record={ record } /></td>
              </tr>
            )) }
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant   = 'secondary'
          onClick   = { modalState.hideExpand }
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
