import React from 'react';
import { amortizeTransactionFormatted, IncludeCheckbox } from '../DebtCalculation';
import NameList from '../NameList';
import { TheNumberPR } from '../SourceTag';
import Highlight from '../../Highlight';
import LabelValueGrouping from '../../LabelValueGrouping';
import formatDollars from '../../../utils/formatDollars';
import useDebtCalculation from '../DebtCalculation/useDebtCalculation';

type Lien = {
  date: string;
  lender: string;
  value: number;
};

type PropertyTransaction = {
  _id: string;
  _loanApplication: { firstName: string; lastName: string; };
  amount: number;
  buyer: Array<{ name: string }>;
  debt: string;
  liens: Array<Lien>;
  seller: Array<{ name: string }>;
  transactionType: string;
  Amount: number;
  TxDate: string;
  mortgageAmount: number;
  mortgageOriginationDate: string;
};

type PropertyTransactionsProps = {
  calculator: ReturnType<typeof useDebtCalculation>;
  records: Array<{ id: string, item: PropertyTransaction }>;
  disabledEdit: boolean;
};

export default function PropertyTransactions ({ calculator, records, disabledEdit }: PropertyTransactionsProps): any {
  return records.map(({ item, id }) => (
    <PropertyTransactionItem
      transaction={item}
      key={id}
      calculator={calculator}
      disabledEdit={disabledEdit}
    />
  ));
}

type PropertyTransactionProps = {
  calculator: ReturnType<typeof useDebtCalculation>;
  transaction: PropertyTransaction;
  disabledEdit: boolean;
};

function PropertyTransactionItem (props: PropertyTransactionProps) {
  const { transaction, calculator, disabledEdit } = props;
  return (
    <div className='p-2'>
      <h4 className='d-flex'>
        <span className='flex-grow-1'>{ transaction.transactionType }</span>
        <TheNumberPR />
      </h4>

      <LabelValueGrouping data={{
        'Amount': formatDollars(transaction.amount),
        'Debt': <>
          <label><Highlight><strong>{formatDollars(transaction.debt)}</strong></Highlight>
            <IncludeCheckbox calculator={calculator} id={transaction._id} disabled={disabledEdit} />
          </label>
        </>,
        'Buyer': <NameList loanApplication={transaction._loanApplication} names={transaction.buyer && transaction.buyer.map(p => p.name)} />,
        'Seller': <NameList loanApplication={transaction._loanApplication} names={transaction.seller && transaction.seller.map(p => p.name)} />,
      }} condensed />

      <hr />

      {
        transaction.liens.length > 0 ? (
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Lender</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {
                transaction.liens.map((lien, index) => (
                  <tr key={index}>
                    <td>{lien.date}</td>
                    <td>{lien.lender}</td>
                    <td>{formatDollars(lien.value)}</td>
                  </tr>
                ))
              }

            </tbody>
          </table>
        ) : null
      }

      <hr />

      <LabelValueGrouping data={{
        'Estimated current balance': !transaction.debt || transaction.debt === '0' ? <>&mdash;</> : <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Rate</th>
              <th>30-yr</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>High</td>
              <td>
                <label>
                  {amortizeTransactionFormatted(transaction, 'high_30')}
                  <IncludeCheckbox id={transaction._id + '.high_30'} calculator={calculator} />
                </label>
              </td>
            </tr>
            <tr>
              <td>Avg</td>
              <td>
                <label>
                  {amortizeTransactionFormatted(transaction, 'avg_30')}
                  <IncludeCheckbox id={transaction._id + '.avg_30'} calculator={calculator} />
                </label>
              </td>
            </tr>
            <tr>
              <td>Low</td>
              <td>
                <label>
                  {amortizeTransactionFormatted(transaction, 'low_30')}
                  <IncludeCheckbox id={transaction._id + '.low_30'} calculator={calculator} />
                </label>
              </td>
            </tr>
          </tbody>
        </table>,
      }} />

    </div>
  );
}
