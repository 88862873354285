import React from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { useFormContext, get } from 'react-hook-form';
import cx from 'classnames';

import styles from './Field.module.scss';

type Props = {
  name: string;
  children?: React.ReactNode;
  label?: React.ReactNode;
  secondaryLabel?: React.ReactNode;
  subLabel?: React.ReactNode;
  required?: boolean;
  placeholder?: string;
  resetButton?: boolean;
  disabled?: boolean;
  className?: string;
  prepend?: string;
  controlProps?: {
    className?: string;
    type?: string;
    inputMode?: string;
    as?: React.ElementType;
    rows?: string;
    maxLength?: number;
    defaultValue?: unknown;
  };
}

export default function Field ({ name, children, label, subLabel, secondaryLabel, required, placeholder, resetButton, disabled, className, prepend, controlProps }: Props) {
  const { Group, Label, Text } = Form;
  const Control: any = Form.Control; // Bug in react-bootstrap. Control is missing React.HTMLAttributes props

  const { register, resetField, formState: { errors, dirtyFields } } = useFormContext();
  const error = get(errors, name);
  const dirtyField = dirtyFields[name];

  return <Group controlId={name} className={className}>
    {
      label && <Label className='w-100'>
        {label}{required && <span className="text-danger p-1">*</span>}
        {resetButton && dirtyField && <Button variant='info' className='ml-2 py-0 align-top' size='sm' onClick={() => resetField(name)}>reset</Button>}
      </Label>
    }

    { subLabel && <Text className={cx("text-muted", styles.indentLabel)}>{subLabel}</Text> }

    <InputGroup className={ prepend ? styles.inputGroup : ''}>
      { prepend && <InputGroup.Prepend><InputGroup.Text className={`${styles.prepend} bg-white ${ error ? styles.error : '' }`}>{ prepend }</InputGroup.Text></InputGroup.Prepend> }
      <Control
        required={required}
        isInvalid={!!error}
        placeholder={placeholder}
        disabled={disabled}
        {...controlProps}
        {...register(name)}
      >{children}</Control>
    </InputGroup>

    { secondaryLabel && <Text className="text-muted">{secondaryLabel}</Text> }

    { error && error.message && <Control.Feedback className='d-block' type="invalid">{error.message}</Control.Feedback> }
  </Group>;
}
