import React from 'react';
import SystemLayout from '../../Layouts/SystemLayout';
import Partners from '../../../components/System/Partners/Partners';

export default function PartnersPage () {
  return (
    <SystemLayout selectedTab='partners'>
      <Partners/>
    </SystemLayout>
  );
}
