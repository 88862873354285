import React from 'react';
import { Badge } from 'react-bootstrap';

export default function LoanStatusBadge (props: { status: string, className?: string }) {
  let pillVariant: React.ComponentProps<typeof Badge>['variant'];
  switch (props.status) {
    case 'OPEN':
      pillVariant = 'info';
      break;
    case 'PENDING':
      pillVariant = 'warning';
      break;
    case 'CLOSED':
      pillVariant = 'secondary';
      break;
    default:
      pillVariant = 'primary';
  }
  return (
    <Badge pill variant={ pillVariant } className={ props.className }>
      { props.status }
    </Badge>
  );
}
