import React from 'react';
import CachedIcon from '@material-ui/icons/Cached';

import './InlineLoadingIndicator.css';

export default function InlineLoadingIndicator (props: { children?: React.ReactNode }) {
  return (
    <span className='InlineLoadingIndicator'>
      <CachedIcon /> { props.children }
    </span>
  );
}
