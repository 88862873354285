import React from 'react';
import cx from 'classnames';
import { pickBy } from 'lodash';
import ExplainerPopover from './ExplainerPopover';
import styles from './LabelValueGrouping.module.scss';

function formatValue(value: Array<string> | string | boolean) {
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  if (value === true) {
    return 'True';
  }
  if (value === false) {
    return 'False';
  }
  return value;
}

type Props = {
  data: Record<string, any>;
  removeBlanks?: boolean;
  className?: string;
  condensed?: boolean;
  condensedLeft?: boolean;
  tooltip?: false | string;
  highlight?: boolean;
};

export default function LabelValueGrouping(props: Props) {
  let data = props.data;
  if (props.removeBlanks) {
    data = pickBy(data, (value) => value)
  }

  const showTooltipKeys = ['Direct Owner', 'POA', 'Owned via Entity']

  return (
    <dl
      className={cx(
        'mb-0',
        props.className,
        (props.condensed || props.condensedLeft) && styles.condensed,
        props.condensedLeft && styles.condensedLeft
      )}
    >
      {Object.entries(data).map(([key, value]) =>
        value === undefined ? null : (
          <React.Fragment key={key}>
            <dt className={`${styles.label} pr-4`}>{key}</dt>
            {props.tooltip && showTooltipKeys.includes(key) ?
              <ExplainerPopover explanation={ props.tooltip }>
                <dd className={ styles.value }><span className={props.highlight ? styles.highlight : ''} >{formatValue(value)}</span></dd>
              </ExplainerPopover>

              : <dd className={ styles.value }><span className={props.highlight ? styles.highlight : ''} >{formatValue(value)}</span></dd>
            }
          </React.Fragment>
        )
      )}
    </dl>
  );
}
