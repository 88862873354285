import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { capitalize } from 'lodash';
import LabelValueGrouping from '../../LabelValueGrouping';
import formatDollars from '../../../utils/formatDollars';
import { Income } from '../../../api/queries/loan-applications/useIncomes';

export default function IncomeInfo ({ className, income }: { className?: string, income: Income }) {

  return (
    <Card className={className}>
      <Card.Header>
        Income
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <LabelValueGrouping
              data={{
                Source: capitalize(income.source),
                Type: income.type ? capitalize(income.type) : '-',
                'Occupation': income.occupation || undefined,
                'Employer': income.employer || undefined,
                'Description': income.description || undefined,
                'Start Date': income.start_date || undefined,
                'Annual Income': formatDollars(income.annualAmountDollars) || '–',
                'Monthly Income': formatDollars(income.monthlyAmountDollars) || '-',
              }}
              condensed={ true }
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
