import React, { useEffect, useState } from 'react';
import useExpandableState from '../../utils/useExpandableState';
import GenericModal from './GenericModal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Form from '../Form/Form';
import SubmitError from '../Form/SubmitError';
import { Col, Container, FormText, Row } from 'react-bootstrap';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { isTodayOrFutureDate } from '../../utils/datetime';
import Field from '../Form/Field';
import { formatCents } from '../../utils';
import useAdminAPICall from '../../utils/useAdminAPICall';
import { Loan } from '../../api/queries/types';
import InlineLoadingIndicator from '../InlineLoadingIndicator';
import useMutateInvoiceTotals from '../../api/mutations/useMutateInvoiceTotals';
import { formatDateCommon } from '../../utils/formatDate';

type FieldValues = {
  payoffDate: string;
};

type Props = {
  expandable: ReturnType<typeof useExpandableState>;
  loan: Loan;
  onSubmit: () => void;
};

function downloadFile (filename: string, dataBlob: Blob) {
  const el = document.createElement('a');
  el.href = window.URL.createObjectURL(dataBlob);
  el.download = filename;
  el.target = '_blank';
  el.click();
}

function GenerateInvoiceModalContent (props: Props) {
  const { expandable, loan } = props;
  const [totalsLoading, setTotalsLoading] = useState(false);
  const {
    data: invoiceTotals,
    mutate: mutateInvoiceTotals,
    isLoading: loadPending,
  } = useMutateInvoiceTotals(loan._id);

  const downloadInvoiceReq = useAdminAPICall({
    endpoint: `/notebook/loans/${ loan._id }/invoices`,
    method: 'POST',
    onSuccess: (data) => {
      downloadFile(`${ loan.partner.brand_name } Payoff Statement - Loan ID ${ loan.slug }.pdf`, data);
    },
  });

  const schema = yup.object().shape({
    payoffDate: yup.string().test('payoffDate', 'Please enter a valid payoff date.', value => !value || isTodayOrFutureDate(value)),
  });

  const form = useForm<FieldValues>({
    defaultValues: {
      payoffDate: '',
    },
    resolver: yupResolver(schema),
  });

  const watchPayoffDate = form.watch('payoffDate');
  useEffect(() => {
    setTotalsLoading(true);
    mutateInvoiceTotals({ payoffDate: watchPayoffDate })
    setTimeout(() => { setTotalsLoading(false); }, 1000);
  }, [watchPayoffDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (form: FieldValues) => {
    await downloadInvoiceReq.callAPI({
      responseType: 'blob',
      data: {
        type: 'invoice',
        payoffDate: form.payoffDate,
      },
    });
    expandable.hideExpand();
  };

  const loading = totalsLoading || loadPending || !invoiceTotals;

  return (
    <Form { ...form } onSubmit={ handleSubmit }>
      <Container className='p-3'>
        <Row>
          <Col>
            <Field name='payoffDate' label='Payoff Date' controlProps={{ type: 'date' }} className='m-0'/>
            <FormText>
              Set an alternate payoff date to use for future interest
              calculations instead of the maturity date.
            </FormText>
          </Col>
        </Row>
      </Container>
      <Container className='border-top border-bottom p-3 bg-light'>
        { loading ? <InlineLoadingIndicator /> :
          <>
            <Row>
              <Col>Due Date</Col>
              <Col className='text-right'>{ formatDateCommon(invoiceTotals!.dueDate) }</Col>
            </Row>
            <Row>
              <Col>Principal</Col>
              <Col className='text-right'>{ formatCents(invoiceTotals!.creditUsageCents, '—') }</Col>
            </Row>
            <Row>
              <Col>Interest</Col>
              <Col className='text-right'>{ formatCents(invoiceTotals!.accruedInterestCents + invoiceTotals!.totalExpectedFutureInterestCents, '—') }</Col>
            </Row>
            <Row>
              <Col>Fees</Col>
              <Col className='text-right'>{ formatCents(invoiceTotals!.feesCents, '—') }</Col>
            </Row>
            <Row className='font-weight-bold'>
              <Col>Total Payoff</Col>
              <Col className='text-right'>{ formatCents(invoiceTotals!.payoffAmountCents, '—') }</Col>
            </Row>
          </>
        }
        <Row>
          <Col>
            <SubmitError/>
          </Col>
        </Row>
      </Container>
      <Container className='d-block p-3'>
        <ButtonWithSpinner block variant='primary' type='submit' disabled={ loading } loading={ downloadInvoiceReq.loadPending }>
          Generate Payoff Statement
        </ButtonWithSpinner>
      </Container>
    </Form>
  )
}

export default function GenerateInvoiceModal (props: Props) {
  const { expandable } = props;
  return (
    <GenericModal expandable={ expandable } title='Generate Payoff Statement' bodyClass='p-0'>
      { expandable.isExpanded && <GenerateInvoiceModalContent {...props} /> }
    </GenericModal>
  );
}
