import React from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../Form/Form';
import Field from '../Form/Field';
import SubmitError from '../Form/SubmitError';
import InlineLoadingIndicator from '../InlineLoadingIndicator';
import { postAPIEndpoint } from '../../utils/useAdminAPIData';
import useUser from '../../api/queries/users/useUser';
import useExpandableState from '../../utils/useExpandableState';
import ButtonWithSpinner from '../ButtonWithSpinner';

type FieldValues = {
  notes: string;
};

type Props = {
  modal: ReturnType<typeof useExpandableState>;
  loan: { _id: string, userId: string };
  onChange: () => void;
  isFirstNotice: boolean;
};

export default function SendPhysicalInvoiceModal ({ modal, loan, onChange, isFirstNotice }: Props) {
  const userReq = useUser(loan.userId);
  const user = userReq.data || {} as any;


  const handleSubmit = async (form: FieldValues) => {
    await postAPIEndpoint(`/notebook/loans/${ loan._id }/servicing/send-repayment-reminder-letter`, form);
    onChange();
    modal.hideExpand();
  }

  const schema = yup.object().shape({
    notes: yup.string(),
  });

  const methods = useForm<FieldValues>({
    defaultValues: { notes: '' },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting } } = methods;

  return (
    <Modal show={ modal.isExpanded } onHide={ modal.hideExpand }>
      <Modal.Header closeButton>
        <Modal.Title>Confirm home address for { user.firstName } { user.lastName }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <strong style={{ textTransform: 'uppercase', fontSize: '.85rem' }}>Mail to:</strong><br/>
        <div>
          {
            userReq.data ? (
              <>
                { user.firstName } { user.lastName }<br/>
                { user.address_line1 }<br/>
                { user.address_line2 }{ user.address_line2 ? <br/> : null }
                { user.city }, { user.state } { user.zip }
              </>
            ) : <InlineLoadingIndicator/>
          }
        </div>
        <hr />
        <Form {...methods} onSubmit={handleSubmit}>
          <Field name='notes' label='Notes' secondaryLabel='Any notes or comments about this mailing.' controlProps={{ as: 'textarea', rows: '4' }}/>
          {
            isFirstNotice ? (
              <Alert variant='warning'>
                This letter will serve as First Notice and set First Notice Date to today.
              </Alert>
            ) : null
          }

          <SubmitError/>

          <div className="d-flex justify-content-end">
            <Button
              variant   = 'secondary'
              className = 'mr-2'
              onClick   = { modal.hideExpand }
              disabled  = { isSubmitting }
            >
              Cancel
            </Button>
            <ButtonWithSpinner
              type='submit'
              variant   = 'primary'
              loading   = { isSubmitting }
            >
              Send the mail { isSubmitting ? <InlineLoadingIndicator/> : null }
            </ButtonWithSpinner>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
