import useQuery from '../../shared/useQuery';
import { ENTITY_TYPE, ESTATE_ROLE, TRUST_ROLE, OWNER_RELATIONSHIP } from '../../shared/types/LoanApplication';
import { KycResult } from './useKycResults';
import { PROGRAMS } from '../types';
import { PROPERTY_DEBT_HELOC_CALCULATOR_KEYS } from '../../../utils/constants';

type StatedDebt = {
  _id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  loan_application_id: string;
  type: string;
  remaining_balance_cents: number;
  amount_per_month_cents?: number;
  in_borrowers_name?: boolean;
  debtor_full_name?: string;
  debtor_relationship?: string;
  total_credit_cents?: number;
  count?: number;
  notes?: string;
  primary?: boolean;
};

type Denial = {
  code: string;
  message: string;
  manualCode?: boolean;
  failed: boolean;
  computedFacts?: { [key: string]: any };
  underwriting_rules_version?: string;
};

type Partner = {
  _id: string;
  theNumberOrg: string;
}

type Agent = {
  displayName: string;
  email: string;
  geo: string;
  externalId: string;
  imageUrl: string;
  agentMasterDataID: string;
  phone: string;
};

export type UnderwritingResult = {
  [k: string]: Denial;
};

export enum UNDERWRITING_VERSIONS {
  V1 = 'v1',
  V2 = 'v2',
  V4 = 'v4',
}

export type ReportedDebt = {
  _id: string;
  loan_application_id: string;
  account_type: string;
  status: string;
  borrower_indicated_active: boolean,
  high_credit_cents?: number,
  credit_limit_cents?: number,
  current_balance_cents?: number,
  monthly_payment_cents?: number,
  date_opened?: string,
  date_effective?: string,
  lender?: string,
  portfolio_type?: string,
  payment_frequency?: string,
  payment_schedule_month_count?: string,
  ecoa_designator?: string,
  is_heloc: boolean;
};

export type LegacyLoanApplication = {
  _id: string;
  address_line1: string;
  address_line2: string;
  addressHash: string;
  agent?: Agent;
  agentNotificationAffirmation: boolean;
  allDebtsDisclosedAffirmation: boolean;
  annualIncome: number;
  approvalNotes: string;
  approvedByAdminId: string;
  availableEquityCents: number;
  avmDeviation: string;
  avmMaximum: string;
  avmMinimum: string;
  businessName: string,
  businessRole: string,
  canActOnBehalfOfPropertyEntity: string;
  city: string;
  closed_at: string;
  closed_by_admin_id: string;
  completedCAFinEduProgram: boolean;
  counterOfferAvailable: boolean;
  createdByAdminId: string;
  createdOn: string;
  currentView: string;
  denialCode: string;
  denialDate: string;
  denialMessage: string;
  denialNotes: string;
  deniedByAdminId: string;
  dob: string;
  email: string;
  entityType: ENTITY_TYPE;
  estateRole: ESTATE_ROLE;
  ficoScore: string;
  ficoScoreGivenAt: string;
  firstName: string;
  iNeedHelpWithStatedDebt: boolean;
  ineligibleResults: Array<{ code: string, message: string }>;
  isActive: boolean;
  isPOA: boolean;
  isPropertyOwnershipAutomaticallyVerified: boolean;
  isUserPaused: boolean;
  kycFailureCodes: Array<string>;
  lastName: string;
  listing_address_line1: string;
  listing_address_line2: string;
  listing_city: string;
  listing_state: string;
  listing_zip: string;
  listingMsa: string;
  listingOwners: Array<{ firstName: string, lastName: string }>;
  listingOwnershipCode: string;
  listingOwnershipStatus: string;
  listingPrice: number;
  loanLimit: number;
  loanOwnershipType: string;
  loanPercentage: string;
  mailingAddressId: string;
  matchedUsers: Array<{
    _id: string;
    fullName: string;
  }>;
  matchedPoaUsers: Array<{
    _id: string;
    fullName: string;
  }>;
  maximumAmount: number;
  maximumReason: string;
  minimumAmount: number;
  notes: string;
  overrideAdminId: string;
  ownedByBorrower: boolean;
  ownedByEntity: boolean;
  ownerFullName: string;
  ownerRelationship: OWNER_RELATIONSHIP;
  pendingDate: string;
  phone: string;
  planSelectedAt: Date;
  poaFirstName: string;
  poaLastName: string;
  powerOfAttorney: boolean;
  previousLoanId: string;
  previousOpenApplication: boolean;
  principal: number;
  program: PROGRAMS;
  propertyDebt: string;
  purchaseMethod: string;
  reasonForNoMortgageOnListing: string;
  reportedDTI: string;
  reportedFICOFactors: Array<string>;
  reportedIncome: number;
  reportedMonthlyPayment: number;
  reportedSSNLastFour: string;
  sessionId: string;
  ssnConfirmationNeeded: boolean;
  ssnLastFour: string;
  state: string;
  status: string;
  submissionError: string;
  submissionErrorReasons: Array<string>;
  submittedAt: string;
  trustName: string;
  trustRole: Array<TRUST_ROLE>;
  underwritingOverrides: Array<string>;
  underwritingResult: UnderwritingResult;
  underwritingVersion: UNDERWRITING_VERSIONS;
  verifiedAvailableEquityCents: number;
  verifiedListingPrice: number;
  verifiedPropertyDebtCents: number;
  userId: string;
  zestimateFound: boolean;
  zip: string;

  loan: { _id: string } | null;
  lastKyc: KycResult;

  // Lens specific fields
  reportedDebts?: Array<ReportedDebt>;
  statedDebts?: Array<StatedDebt>;
  calculatedPropertyDebt?: {
    [PROPERTY_DEBT_HELOC_CALCULATOR_KEYS.CREDIT_LIMIT]: number;
    [PROPERTY_DEBT_HELOC_CALCULATOR_KEYS.CURRENT_BALANCE]: number;
  };

  // Better program specific
  isSourceBetter: boolean;
  propertyOwnershipType: string;
  propertyType: string;
  statedMortgageMonthlyPayment: number;
  propertyOwnedForMoreThan90Days: boolean;
  mortgageCloseDate: string;
  statedMaxExpectedMortgageMonthlyPayment: number;
  isGettingNewMortgage: boolean;

  partner: Partner;
};

export default function useLoanApplications (userId?: string) {
  return useQuery<Array<LegacyLoanApplication>>(userId ? `/notebook/users/${userId}/loan-applications` : null);
}
