import React from 'react';
import { Badge } from 'react-bootstrap';

export default function PausedBadge () {
  return (
    <Badge pill variant="danger">
      PAUSED
    </Badge>
  );
}
