import useMutation from '../../shared/useMutation';

type Payload = {
  slug?: string;
  landingPageEnabled?: boolean;
};

export default function useMutateAgentLandingPage (agentId: string) {
  return useMutation<Payload, any>('PUT', `/notebook/agents/${ agentId }/landing-page`);
}
