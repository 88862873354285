import React from 'react';
import { formatCents } from '../utils';

type Props = {
  amount: number;
  className?: string;
};

export default function TransactionAmount (props: Props) {
  return (
    <span className={ props.className }>
      { formatCents(props.amount) }
    </span>
  );
}
