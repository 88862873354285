import React from 'react';
import { Badge } from 'react-bootstrap';

import MaskedAccountNumber from './MaskedAccountNumber';

type Props = {
  reportedSSNLastFour: string;
  ssnLastFour: string;
};

export default function SSNLastFour ({ reportedSSNLastFour, ssnLastFour }: Props) {
  return (
    <>
      <MaskedAccountNumber number={ ssnLastFour } />
      { 
        ssnLastFour !== reportedSSNLastFour ? 
          <Badge pill title='Reported SSN differs' variant='danger'>{reportedSSNLastFour}</Badge> : 
          null 
      }
    </>
  )
}
