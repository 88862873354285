import React from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import CardCheckbox from '../../Form/CardCheckbox';
import SubmitError from '../../Form/SubmitError';
import Button from 'react-bootstrap/Button';
import { Alert, FormGroup, FormLabel, FormText } from 'react-bootstrap';
import { postAPIEndpoint } from '../../../utils/useAdminAPIData';
import { getDateInTimezone } from '../../../utils/datetime';
import { USER_NOTIFICATION_TYPES } from '../../../utils/constants';
import { isScheduled, isSent } from '../../../utils/notifications';
import { Reminder } from '../../../api/queries/loans/useReminders';
import ButtonWithSpinner from '../../ButtonWithSpinner';

const MAX_REPAYMENT_REMINDERS = 4;

function buildPausedLabel ({ allNotificationsPaused, paymentRemindersPaused }: { allNotificationsPaused: boolean, paymentRemindersPaused: boolean }) {
  if (allNotificationsPaused) {
    return 'all electronic notifications';
  } else if (paymentRemindersPaused) {
    return 'electronic payment reminders';
  }
}

type SendFirstNoticeModalProps = {
  loan: { _id: string };
  date: moment.MomentInput;
  includeLetterMessaging: unknown;
  allNotificationsPaused: boolean;
  paymentRemindersPaused: boolean;
  pausedReason: string;
  show: boolean;
  onHide: () => void;
  onFormWillSubmit: () => void;
  onFormDidSubmit: () => void;
};

type FirstNoticeFieldValues = {
  notes: string;
  notifyBorrower: boolean;
};

export default function SendFirstNoticeModal (props: SendFirstNoticeModalProps) {
  const {
    loan,
    date,
    includeLetterMessaging,
    allNotificationsPaused,
    paymentRemindersPaused,
    pausedReason,
    show,
    onHide,
    onFormWillSubmit,
    onFormDidSubmit
  } = props;
  const isPaused = allNotificationsPaused || paymentRemindersPaused;

  const sendFirstNotice = async (form: FirstNoticeFieldValues) => {
    onHide();
    onFormWillSubmit();

    await postAPIEndpoint(`/notebook/loans/${ loan._id }/servicing/send-repayment-reminder`, {
      notes: form.notes,
      notify_borrower: form.notifyBorrower,
    });
    onFormDidSubmit();
  };

  const schema = yup.object({
    notes: yup.string(),
    notifyBorrower: yup.boolean().required(),
  });

  const methods = useForm<FirstNoticeFieldValues>({
    defaultValues: { notes: '', notifyBorrower: !isPaused },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting }, setValue } = methods;


  React.useEffect(() => {
    setValue('notifyBorrower', !isPaused);
  }, [isPaused]); // eslint-disable-line react-hooks/exhaustive-deps

  return <Modal show={ show } onHide={ onHide } size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Send First Notice Manually</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <p>
        You must send first notice manually if repayment is discussed with the borrower by any medium prior to the
        scheduled first notice on <b>{ getDateInTimezone(date).format('ll') }</b>.
        {
          includeLetterMessaging ?
            " Sending manually will trigger an email to send today, and a physical letter to be sent to the borrower's home address within 5 calendar days."
            : null
        }
      </p>

      <Alert variant="warning">
        {
          isPaused ?
            <>
              This borrower has { buildPausedLabel(props) } disabled (Reason: <b>{ pausedReason }</b>) and will not be
              sent an email first notice. They will still receive the physical letter by mail.
            </>
            : <>
              If you select not to send the first notice email you <b>must</b> have sent the borrower an appropriate
              first notice email that included proper disclosures. When in doubt, send the email.
            </>
        }
      </Alert>

      <Form {...methods} onSubmit={sendFirstNotice}>
        <FormGroup>
          <FormLabel>Send email notification</FormLabel>
          <CardCheckbox name='notifyBorrower' label='Send First Notice Email' />

          {
            includeLetterMessaging ?
              <FormText className="text-muted">
                Physical letter will be sent within 5 days of first notice, whether or not the email is sent.
              </FormText> : null
          }
        </FormGroup>

        <Field name='notes' label='Notes'  placeholder='Enter notes'/>

        <div className="text-right mt-4">
          <Button
            variant   = "secondary"
            className = "mr-2"
            onClick   = { onHide }
          >
            Cancel
          </Button>
          <ButtonWithSpinner variant="primary" type="submit" loading={ isSubmitting }>
            Send
          </ButtonWithSpinner>
        </div>

        <SubmitError/>
      </Form>
    </Modal.Body>
  </Modal>
}

type SendRepaymentReminderModalProps = {
  loan: { _id: string };
  show: boolean;
  onHide: () => void;
  onFormWillSubmit: () => void;
  onFormDidSubmit: () => void;
  reminders: Array<Reminder>;
};

type RepaymentReminderFieldValues = {
  notes: string;
};

export function SendRepaymentReminderModal (props: SendRepaymentReminderModalProps) {
  const {
    loan,
    show,
    onHide,
    onFormWillSubmit,
    onFormDidSubmit,
    reminders,
  } = props;

  const sendFirstNotice = async (form: RepaymentReminderFieldValues) => {
    onHide();
    onFormWillSubmit();

    await postAPIEndpoint(`/notebook/loans/${ loan._id }/servicing/send-repayment-reminder`, {
      notes: form.notes,
    });
    onFormDidSubmit();
  };

  const schema = yup.object({ notes: yup.string().required('Please enter notes') });
  const methods = useForm<FirstNoticeFieldValues>({
    defaultValues: { notes: '' },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting } } = methods;

  const _reminders = reminders ? reminders : [];

  const repaymentReminders = _reminders.filter(r => r.type === USER_NOTIFICATION_TYPES.REPAYMENT_REMINDER);
  repaymentReminders.sort((a,b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));
  const nextReminder = repaymentReminders.find(isScheduled);
  const sentRepaymentReminders = _reminders.filter(isSent);

  return <Modal show={ show } onHide={ onHide } size='lg'>
    <Modal.Header closeButton>
      <Modal.Title>Send Additional Repayment Reminder Manually</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <p>
        The borrower has already received first notice{
          nextReminder ? <> and is scheduled to receive another electronic reminder on <strong>{ getDateInTimezone(nextReminder.createdAt).format('ll') }</strong></> : null
        }. You can immediately send another repayment reminder manually if necessary.
      </p>

      {
        sentRepaymentReminders.length >= MAX_REPAYMENT_REMINDERS ? (
          <Alert variant='warning'>
            This repayment reminder will exceed the normal limit
            of {MAX_REPAYMENT_REMINDERS} reminders. Only send this reminder if
            there is a specific reason to (eg a previous send attempt actually
            failed, or borrower request).
          </Alert>
        ) : null
      }

      <Form {...methods} onSubmit={sendFirstNotice}>
        <Field name='notes' label='Notes'  placeholder='Enter notes about why the reminder needs to be sent'/>

        <div className='d-flex justify-content-end mt-4'>
          <Button
            variant   = 'secondary'
            className = 'mr-2'
            onClick   = { onHide }
          >
            Cancel
          </Button>
          <ButtonWithSpinner variant='primary' type='submit' loading={ isSubmitting }>
            Send Reminder
          </ButtonWithSpinner>
        </div>

        <SubmitError/>
      </Form>
    </Modal.Body>
  </Modal>
}

