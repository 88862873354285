import React from 'react';
import GenericModal from './GenericModal';
import useAdminAPICall from "../../utils/useAdminAPICall";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../Form/Form';
import SubmitError from '../Form/SubmitError';
import useExpandableState from '../../utils/useExpandableState';
import ButtonWithSpinner from '../ButtonWithSpinner';

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
  agentsCount?: number;
  endpoint: string;
  platformName: string;
};

export default function PushAgentsModal (props: Props) {
  const { expand, onSubmit, agentsCount, endpoint, platformName } = props;

  const { callAPI } = useAdminAPICall({
    endpoint,
    method: 'POST',
  });

  const schema = yup.object().shape({});

  const methods = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });
  const { formState: { isSubmitting }, setError } = methods;

  async function handleSubmit() {
    try {
      await callAPI();
      onSubmit();
      expand.hideExpand();
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 500) {
        setError('submitError' as any, { message: e.response.data.message });
      } else {
        throw e;
      }
    }
  }

  return (
    <GenericModal expandable={expand} size='sm' title={'Please confirm'}>
      <Form {...methods} onSubmit={handleSubmit}>
        <p>This will push {agentsCount} agents to {platformName}. This may take several minutes.</p>
        <p>Would you like to continue?</p>
        <SubmitError/>
        <div className="d-flex mt-4 w-100">
          <Button variant="secondary" onClick={expand.hideExpand} className="mr-2 w-50">
            No
          </Button>
          <div className='w-50'>
            <ButtonWithSpinner variant="primary" type="submit" block loading={isSubmitting}>
              Yes
            </ButtonWithSpinner>
          </div>
        </div>
      </Form>
    </GenericModal>
  );
}
