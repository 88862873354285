import React from 'react';
import PropertyTransactionSummary from './Summary/PropertyTransactionSummary';
import PropertyLienTransactionSummary from './Summary/PropertyLienTransactionSummary';
import ReportedDebtSummary from './Summary/ReportedDebtSummary';
import StatedDebtDetails from './Summary/StatedDebtDetails';
import { TheNumberCR, TheNumberPR, FirstAmerican } from './SourceTag';
import useDebtCalculation from './DebtCalculation/useDebtCalculation';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { LegacyLoanApplication, ReportedDebt } from '../../api/queries/users/useLoanApplications';

type Transaction = {
  liens: Array<unknown>;
  debt: number;
};

type Props = {
  calculator: ReturnType<typeof useDebtCalculation>;
  lienReq: any;
  loanApplication: LegacyLoanApplication;
  reportedDebts: Array<ReportedDebt>;
  transactions: Array<Transaction>;
};

export default function Summaries (props: Props) {
  const {
    calculator,
    lienReq,
    loanApplication,
    reportedDebts,
    transactions,
  } = props;

  return (
    <div className='d-flex'>
      <div className='flex-grow-1 pr-3'>
      <h4>Stated on application</h4>
        <StatedDebtDetails loanApplication={ loanApplication } calculator={ calculator } />
      </div>
      <div className='flex-grow-1 pr-3'>
        <h4>Credit report <TheNumberCR small /></h4>
        <ReportedDebtSummary reportedDebts={reportedDebts} />
      </div>
      <div className='flex-grow-1 pr-3'>
        <h4>Latest property transaction <TheNumberPR small /></h4>
        <PropertyTransactionSummary transactions={transactions} />
      </div>
      <div className='flex-grow-1 pr-3'>
        <h4>Lien report <FirstAmerican small /></h4>
        {
          lienReq.loadUnstarted ? (
            <ButtonWithSpinner
              onClick   = { () => lienReq.callAPI() }
              loading   = { lienReq.loadPending }
              variant   = 'outline-dark'
              className = 'mb-2'
            >
              Request Lien Report
            </ButtonWithSpinner>
          ) : (
            <PropertyLienTransactionSummary lienReq={lienReq} />
          )
        }
      </div>
    </div>
  );
}
