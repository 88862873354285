import React from "react";
import { Card } from "react-bootstrap";
import LabelValueGrouping from "../../LabelValueGrouping";
import formatDollars from "../../../utils/formatDollars";
import AddressDisplay from "../../AddressDisplay";
import { formatDateCommon } from "../../../utils/formatDate";
import { titleCase } from "../../../utils/string";
import { LOAN_APPLICATION_SOURCES } from "../../../utils/constants";
import { ExcludedDebt } from './HomeCardApplicationTab';

function formatIntent (intent?: string) {
  if (!intent) { return ''; }

  return titleCase(intent.replaceAll('-', ' '));
}

export default function ExcludedDebtsInfo({ className, debt }: { className: string, debt: ExcludedDebt }) {
  const { typeOfDebt, name, unpaidBalance, monthlyPayment, dateOpened, monthsLeft, intent, propertyAddress } = debt;

  return (
    <Card className={className}>
      <Card.Header>Excluded Debt</Card.Header>
      <Card.Body>
        <LabelValueGrouping
          data={{
            Source: titleCase(LOAN_APPLICATION_SOURCES.BETTER),
            Type: typeOfDebt,
            Name: name,
            "Unpaid Balance": formatDollars(unpaidBalance),
            "Monthly Payment": formatDollars(monthlyPayment),
            "Date Opened": formatDateCommon(dateOpened),
            "Months Left": monthsLeft,
            Intent: formatIntent(intent),
            Address: propertyAddress && (
              <AddressDisplay
                line1={propertyAddress.address1}
                line2={propertyAddress.address2 ? propertyAddress.address2 : ''}
                city={propertyAddress.city}
                state={propertyAddress.state}
                zip={propertyAddress.zip}
              />
            ),
          }}
          condensed={true}
          removeBlanks={true}
        />
      </Card.Body>
    </Card>
  );
}
