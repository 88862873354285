import moment from 'moment-timezone';
import { getDateInTimezone, DATEONLY_FORMAT } from './datetime';

export default function formatDate (date_or_str: moment.MomentInput, fallback='') {
  if (!date_or_str) {
    return fallback;
  }
  return getDateInTimezone(date_or_str).format('MMM D, YYYY');
}

export function formatDateOnly (date_or_str: moment.MomentInput, fallback='') {
  if (!date_or_str) {
    return fallback;
  }
  return getDateInTimezone(date_or_str).format(DATEONLY_FORMAT);
}

export function formatDateCommon (date_or_str: moment.MomentInput, fallback='') {
  if (!date_or_str) {
    return fallback;
  }
  return getDateInTimezone(date_or_str).format('M/D/Y');
}

export function formatDateOnlyUTC (date_or_str: moment.MomentInput, fallback='') {
  if (!date_or_str) {
    return fallback;
  }
  return getDateInTimezone(date_or_str, 'UTC').format(DATEONLY_FORMAT);
}

export function formatDateWithTime (date_or_str: moment.MomentInput) {
  if (!date_or_str) {
    return '';
  }
  return getDateInTimezone(date_or_str).format('M/D/Y [at] h:mm a');
}

export function formatRelativeTime (date_or_str: moment.MomentInput, fallback='') {
  if (!date_or_str) {
    return fallback;
  }
  return moment(date_or_str).fromNow();
}

export function formatAbbreviatedDateWithTime (date_or_str: moment.MomentInput) {
  if (!date_or_str) {
    return '';
  }
  return getDateInTimezone(date_or_str).format('MMM D, YYYY [at] h:mm a');
}
