import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, Table } from 'react-bootstrap';

type Agreement = {
  _id: string;
  modifies_agreement_id: string;
  features: { TERM: { months: number } };
  url: string;
  urlForDownload: string;
};

type Contract = {
  agreementHistory: Array<Agreement>;
};

type Props = {
  contract: Contract | null;
  show: boolean;
  onHide: () => void;
};

export default function LendingAgreementModal (props: Props) {
  const { contract, show, onHide } = props;

  if (!contract) { return null; }

  return (
    <Modal show={show} onHide={onHide} size="lg">
      {
        show ? (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                Loan Agreements
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table>
                <thead>
                  <tr>
                    <th>Document</th>
                    <th colSpan={2}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    contract.agreementHistory.map((agreement) => (
                      <tr key={ agreement._id }>
                        <td>Loan Agreement{ agreement.modifies_agreement_id ? ` Modification (${ agreement.features.TERM.months } Months)` : null }</td>
                        <td>
                          <a target='_blank' rel="noopener noreferrer" href={ agreement.url }>View</a>
                        </td>
                        <td>
                          <a target='_blank' rel="noopener noreferrer" href={ agreement.urlForDownload }>Download</a>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
              <div className="text-right mt-4">
                <Button variant="primary" onClick={ onHide } className="mr-2">
                  Close
                </Button>
              </div>
            </Modal.Body>
          </>
        ) : null
      }
    </Modal>
  );
}
