import React, { useState } from 'react';
import { useExpandableState } from '../../../../utils';
import useDisbursementRequest from '../../../../api/queries/disbursement-requests/useDisbursementRequest';
import { CheckDetailContent } from './CheckDetailContent';
import UpdateCheckActivityModal from './UpdateCheckActivityModal';
import { ModifyDisbursementModal } from '../../../Modals/DisbursementModals';
import { ServicingActivity } from '../../../../api/queries/loans/useActivity';
import { TimelineListLoader } from '../TimelineTab';
import VerifyInvoiceModal from '../../../Modals/VerifyInvoiceModal';
import { getDisbursementDetail } from '../../../../utils/transactionUtils';
import StaticAddress from '../../../StaticAddress';
import { LedgerImpactsLoader } from '../LedgerImpacts';

export enum CHECK_ACTIVITY_ACTIONS {
  CLEARED = 'cleared',
  STOPPED = 'stopped',
  CANCELED = 'canceled',
}

type Props = {
  activity: ServicingActivity;
  onChange: () => void;
  loanId: string;
};

export const CheckDetail: React.FC<Props> = ({ activity, loanId, onChange }) => {
  const [actionType, setActionType] = useState<CHECK_ACTIVITY_ACTIONS>();
  const editModal = useExpandableState();
  const actionModal = useExpandableState();
  const verifyInvoiceModal = useExpandableState();
  const txnRequest = useDisbursementRequest(activity.originating_request_id, {
    shouldRetryOnError: false,
  });

  const handleAction = async (type: CHECK_ACTIVITY_ACTIONS) => {
    setActionType(type);
    actionModal.showExpand();
  };

  const handleSubmitted = () => {
    txnRequest.mutate();
    onChange();
  };

  if (!txnRequest.data) {
    return null;
  }

  const { modalData } = getDisbursementDetail(txnRequest.data.disbursementRequest);
  modalData['Vendor Address'] = txnRequest.data.disbursementRequest._vendor ? ( // DRs sometimes don't have singular vendor data (eg MULTI)
    <StaticAddress address={txnRequest.data.disbursementRequest._vendor} />
  ) : '—';

  return (
    <>
      <CheckDetailContent
        data={txnRequest.data}
        onAction={handleAction}
        onEditDisbursement={editModal.showExpand}
        onVerifyInvoice={verifyInvoiceModal.showExpand}
      />
      <TimelineListLoader
        scopeId={ loanId }
        entityId={ activity.base_id }
      />
      <LedgerImpactsLoader
        sourceId={ activity.base_id }
      />
      {txnRequest.data.disbursementRequest._checkActivity && (
        <UpdateCheckActivityModal
          type={actionType}
          modal={actionModal}
          checkActivityId={txnRequest.data.disbursementRequest._checkActivity._id}
          onSubmitted={handleSubmitted}
        />
      )}
      {txnRequest.data.disbursementRequest && (
        <>
          <ModifyDisbursementModal
            loan={txnRequest.data.loan}
            disbursement={txnRequest.data.disbursementRequest}
            expand={editModal}
            onSubmit={() => {
              txnRequest.mutate();
              onChange();
            }}
          />
          <VerifyInvoiceModal
            key={txnRequest.data.latest._id}
            disbursementRequest={txnRequest.data.disbursementRequest}
            infoData={modalData}
            expandable={verifyInvoiceModal}
            onSubmit={() => {
              verifyInvoiceModal.hideExpand();
              txnRequest.mutate();
              onChange();
            }}
          />
        </>
      )}
    </>
  );
};
