import React from 'react';
import OutboundButton from './OutboundButton';
import CONFIG from '../config';

type Props = {
  block: boolean;
  className: string;
  user: { _id: string; };
};

export default function ClarityLink (props: Props) {
  const { user, className } = props;

  const href = `https://clarity.microsoft.com/projects/view/${CONFIG.CLARITY_PROJECT_ID}/impressions?Variables=notable-user-id%3A${user._id}&date=Last 30 days`;

  return (
    <OutboundButton
      href={ href }
      block={ props.block }
      className={ className }
      size="sm"
    >
      Clarity
    </OutboundButton>
  );
}
