import React, { useState } from 'react';
import { FormGroup, FormLabel, FormControl, ListGroup, ListGroupItem, CloseButton, InputGroup, Button } from 'react-bootstrap';
import { useFormContext, useController } from 'react-hook-form';
import InlineError from '../InlineError';
import { validateEmail } from '../../utils/form-utilities';

type Props = {
  name: string;
  label: string;
  placeholder?: string;
  validationType?: 'email';
};

export default function ListGroupAddDelete ({ name, label, placeholder, validationType }: Props) {
  const { control } = useFormContext();
  const { field: { value, onChange } } = useController({ name, control });
  const [newAddition, setNewAddition] = useState('');
  const [submitError, setSubmitError] = useState<string | null>(null);
  const values = value as Array<string>;

  const add = (addValueRaw: string) => {
    const addValue = addValueRaw.trim();
    if (validationType === 'email' && !validateEmail(addValue)) {
      setSubmitError('Invalid email address');
      return;
    }
    onChange([...value, addValue]);
    setNewAddition('');
    setSubmitError(null);
  };
  const remove = (removeValue: string) => {
    onChange(value.filter((old: string) => old !== removeValue));
  };

  return (
    <FormGroup>
      <FormLabel>{ label }</FormLabel>
      <ListGroup>
        {
          values.map(value => {
            return <ListGroupItem key={ value }>
              { value }
              <CloseButton onClick={ () => remove(value) } />
            </ListGroupItem>
          })
        }
        <ListGroupItem>
          <InputGroup>
            <FormControl
              placeholder={ placeholder }
              value={ newAddition }
              onChange={ (e) => setNewAddition(e.currentTarget.value ?? '') }
            />
            <Button className='ml-3' onClick={ () => add(newAddition) }>Add</Button>
          </InputGroup>
        </ListGroupItem>
      </ListGroup>
      { submitError && <InlineError>{ submitError }</InlineError> }
    </FormGroup>
  );
}
