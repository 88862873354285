import React from 'react';
import useState from '../../../api/queries/states/useState';
import { useParams } from 'react-router-dom';
import StateShow from '../../../components/System/States/StateShow';
import SystemLayout from '../../Layouts/SystemLayout';

export default function StateShowPage () {
  const { stateId } = useParams<{ stateId: string }>();

  const statesRequest = useState(stateId);
  if (!statesRequest.data) {
    return null;
  }

  return (
    <SystemLayout selectedTab='states' heading={statesRequest.data.name}>
      <StateShow state={statesRequest.data}/>
    </SystemLayout>
  );
}
