import React from 'react';
import GenericModal from './GenericModal';
import useAdminAPICall from "../../utils/useAdminAPICall";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Form from '../Form/Form';
import SubmitError from '../Form/SubmitError';
import useExpandableState from '../../utils/useExpandableState';
import ButtonWithSpinner from '../ButtonWithSpinner';
import Field from '../Form/Field';
import * as yup from "yup";
import { Document } from '../../api/queries/documents/useDocuments';
import { yupResolver } from '@hookform/resolvers/yup';

type FieldValues = {
  documentName: string;
};

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
  loanApplicationId: string;
  document: Document;
};

export default function RenameDocumentModal (props: Props) {
  const { expand, document, onSubmit, loanApplicationId } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loan-applications/${loanApplicationId}/documents/${document._id}`,
    method: 'PUT',
  });

  async function handleSubmit(data: FieldValues) {
    await callAPI({
      data: {
        documentName: data.documentName,
      },
    });
    onSubmit();
    expand.hideExpand();
  }

  const schema = yup.object().shape({
    documentName: yup.string().required('Please enter a name'),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      documentName: document.name,
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting }, reset } = methods;

  const onClose = () => {
    reset();
    expand.hideExpand();
  }

  return (
    <GenericModal expandable={expand} title={'Rename Document'} onClose={onClose}>
      <Form {...methods} onSubmit={handleSubmit}>
        <div className='border-bottom pb-2'>
          <Field
            label='Document Name'
            name='documentName'
          />
          <SubmitError/>
        </div>
        <div className="d-flex justify-content-end mt-4">
          <Button variant="secondary" onClick={expand.hideExpand} className="mr-2">
            Cancel
          </Button>
          <ButtonWithSpinner variant="primary" type="submit" loading={isSubmitting}>
            Confirm
          </ButtonWithSpinner>
        </div>
      </Form>
    </GenericModal>
  );
}
