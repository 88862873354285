import React from 'react';
import Decimal from 'decimal.js';
import * as _ from 'lodash';
import { numberFormat } from 'humanize';
import { cleanDollarAmount } from './form-utilities';

type Options = {
  cents?: boolean;
  precision?: number;
};

export function formatDollarsWithColor (dollars: Decimal.Value, options: Options = {}) {
  if (dollars == null) {
    return '';
  }
  const dollarsDecimal = options.cents ? new Decimal(dollars).dividedBy(100) : new Decimal(dollars);
  let formatted = <><span className='small'>$</span><span style={{ marginRight: '0.1em' }}>{ numberFormat(dollarsDecimal.toNumber(), 2) }</span></>;

  const variant = dollarsDecimal.gt(0) ? 'text-success' : 'text-danger';
  formatted = <span className={ variant }>{ formatted }</span>;
  return formatted;
}

export function formatDollarsAccountingNotation (dollars: Decimal | string) {
  let value = '';

  if (typeof dollars === 'string') {
    value = '—';
  } else {
    const dollarsDecimal = new Decimal(dollars);
    value = numberFormat(dollarsDecimal.abs().toNumber(), 2);
    
    if (dollarsDecimal.isNegative()) {
      value = `(${ value })`;
    }
  }
  
  return <span className='d-flex justify-content-between'><span>$</span><span>{ value }</span></span>;
}

export function formatDollarsForInput (dollars: string) {
  dollars = cleanDollarAmount(dollars); // Be forgiving about input
  if (!dollars) {
    return '';
  }
  return parseFloat(dollars).toFixed(2);
}

export default function formatDollars (dollars?: Decimal.Value, options: Options = {}) {
  dollars = cleanDollarAmount(dollars);
  if (!dollars) {
    return '';
  }
  const dollarsDecimal = options.cents ? new Decimal(dollars).dividedBy(100) : new Decimal(dollars);
  const precision = _.isNil(options.precision) ? 2 : options.precision;
  if (dollarsDecimal.isNegative()) {
    return `-$${ numberFormat(dollarsDecimal.times(-1).toNumber(), precision) }`;
  } else {
    return `$${ numberFormat(dollarsDecimal.toNumber(), precision) }`;
  }
}
