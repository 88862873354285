import React from 'react';
import LoanPartnerBadge from './Badges/LoanPartnerBadge';
import LoanStatusBadge from './Badges/LoanStatusBadge';
import { formatDollars } from '../utils';
import LoanSlug from './LoanSlug';
import { LoanSummary } from '../api/queries/users/useLoansSummaries';
import { Card } from 'react-bootstrap';
import cx from 'classnames';
import { LoanBadges } from './LoanOverview/LoanApplicationOverviewHeader';
import { useNavigate } from 'react-router-dom';
import styles from './LoanSummaryCard.module.scss';
import POABadge from './Badges/POABadge';

type Props = {
  loan: LoanSummary;
}

export default function LoanSummaryCard (props: Props) {
  const { loan } = props;
  const navigate = useNavigate();

  return (
    <Card>
      <Card.Body className='cursor-pointer' onClick={() => navigate(`/loan-applications/${loan.loanApplicationId}/loan`)}>
        <div>
          <Card.Title as="span" className="h5">
            { loan.user.name } · <LoanSlug>{ loan.slug }</LoanSlug> · { formatDollars(loan.principle) }
          </Card.Title>
        </div>
        <div className={cx('mt-1', styles.badges)}>
          <LoanPartnerBadge partnerId={ loan.partnerId } source={loan.loanApplicationSource} />
          <LoanStatusBadge status={ loan.status } />
          <LoanBadges loan={ loan } />
          { loan.isPOA && <POABadge/> }
        </div>
      </Card.Body>
    </Card>
  );
}
