import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext, useController } from "react-hook-form";
import { cleanSSN } from '../../utils/form-utilities';

type Props = { name: string };

export default function SsnField ({ name }: Props) {
  const { Group, Label, Control } = Form;

  const { control } = useFormContext();
  const { field, fieldState: { error } } = useController({ name, control });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(cleanSSN(e.target.value));
  };

  return <Group controlId={name}>
    <Label>Social Security Number</Label>

    <Control
      type='text'
      placeholder='000-00-0000'
      isInvalid={!!error}
      {...field}
      onChange={handleChange as any} // React-bootstrap bug defining event type
    />

    { error && error.message && <Control.Feedback type="invalid">{error.message}</Control.Feedback> }
  </Group>;
}
