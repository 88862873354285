import React from 'react';

import Dropdown from 'react-bootstrap/Dropdown';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PauseUserModal from './Modals/PauseUserModal';
import { useExpandableState } from '../utils';
import UnpauseUserModal from './Modals/UnpauseUserModal';

type Props = {
  className?: string;
  user: { _id: string, is_paused: boolean };
}

export default function PauseUserDropdown (props: Props) {
  const { className, user } = props;

  const pauseUserModalState = useExpandableState();
  const unpauseUserModalState = useExpandableState();

  return (
    <>
      <Dropdown alignRight={true}>
        <Dropdown.Toggle
          className={className}
          id="dropdown-basic"
          bsPrefix=" "
          variant="outline-dark"
          size="sm"
        >
          <MoreHorizIcon/>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          { user.is_paused ?
            <Dropdown.Item onClick={() => unpauseUserModalState.showExpand()} className='small-dropdown-item'>
              Unpause user
            </Dropdown.Item> :
            <Dropdown.Item onClick={() => pauseUserModalState.showExpand()} className='small-dropdown-item'>
              Pause user
            </Dropdown.Item>
          }
        </Dropdown.Menu>
      </Dropdown>
      <PauseUserModal
        expand={pauseUserModalState}
        userId={user._id}
        onSubmit={() => window.location.reload()}
      />
      <UnpauseUserModal
        expand={unpauseUserModalState}
        userId={user._id}
        onSubmit={() => window.location.reload()}
      />
    </>
  );
}
