import useQuery from '../../shared/useQuery';

type PropertyAddress = {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
};

type PropertyLiability = {
  dateOpened: string;
  grossRentalIncomeVerifiedAmount?: number;
  monthlyPayment: number;
  name: string;
  netRentalIncomeAmount?: number;
  occupancyType?: string;
  propertyAddress: PropertyAddress;
  typeOfDebt: string;
  willBeSoldBeforeClosing?: boolean;
};

type Liability = {
  typeOfDebt: string;
  monthlyPayment: number;
}

type ExcludedDebt = {
  name: string;
  typeOfDebt: string;
  unpaidBalance: number;
  monthlyPayment: number;
  dateOpened: string;
  monthsLeft: number;
  intent: string;
  propertyAddress: PropertyAddress;
}

type MortgagePayment = {
  total: number;
  principalAndInterest: number;
  tax: number;
  insurance: number;
  hazardInsurance: number;
  hoa: number;
}

export type Response = {
  closingDate: string;
  fundingDate: string;
  monthlyIncome: number;
  excludedDebtTotalMonthlyAmount: number;
  excludedDebts: Array<ExcludedDebt>;
  estimatedClosingDate: string;
  propertyAddress: PropertyAddress;
  mortgageSize: number;
  term: number;
  appraisedValue: number;
  avm: number;
  mortgagePayment: MortgagePayment;
  liabilities: Array<Liability>;
  liabilitiesTotalMonthlyAmount: number;
  loanPurpose: string;
  propertyLiabilities: Array<PropertyLiability>;
};

export default function useBetterUnderwritingData (loanApplicationId: string) {
  return useQuery<Response>(`/notebook/loan-applications/${loanApplicationId}/better-underwriting-data`);
}
