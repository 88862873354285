import React from 'react';
import useAdmin from '../api/queries/admins/useAdmin';

type Props = {
  admin_id?: string;
  addBy?: boolean;
};

export default function AdminUserBadge (props: Props) {
  const req = useAdmin(props.admin_id);

  if (req.error) {
    return (
      <code>Error loading admin name</code>
    );
  }
  if (!props.admin_id) {
    return null;
  }
  return (
    <span>
      { props.addBy && 'by' } { req.data ? req.data.email.split('@')[0] : 'Loading…' }
    </span>
  );
}

