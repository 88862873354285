import React, { useEffect } from 'react';
import TabLayout from './TabLayout';

interface Props {
  selectedTab: string,
  heading?: string, 
  children: React.ReactNode
};

export default function SystemLayout ({ selectedTab, heading, children }: Props) {
  useEffect(() => {
    document.title = `System | Notebook`
  }, []);

  return (
    <div>
      <TabLayout
        id='system-tabs'
        selectedTab={selectedTab}
        heading={heading}
        tabs={{
          partners: 'Partners',
          states: 'States',
          investors: 'Investors',
        }}
        navigateTo = { (selected: string) => `/system/${selected}` }
      >
        { children }
      </TabLayout>
    </div>
  );
}
