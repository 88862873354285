import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar as BootstrapNavbar, Nav } from 'react-bootstrap';
import { useSWRConfig } from 'swr';
import CONFIG from '../config';
import useAdminAPICall from '../utils/useAdminAPICall';

export default function Navbar () {
  const { callAPI } = useAdminAPICall({ endpoint: '/notebook/logout', method: 'POST' });
  const { mutate } = useSWRConfig();

  let border = 'border-success';
  if (CONFIG.ENVIRONMENT === 'prod') {
    border = 'border-danger';
  } else if (CONFIG.ENVIRONMENT === 'local') {
    border = 'border-primary';
  }

  const title = CONFIG.ENVIRONMENT === 'prod' ? 'Notebook' : `Notebook ${CONFIG.ENVIRONMENT}`;

  const onLogout = async () => {
    await callAPI();
    mutate('/notebook/admins/self');
  };

  return <BootstrapNavbar className={`mb-4 border ${border} rounded mt-3`} bg='light' expand='lg'>
    <BootstrapNavbar.Brand className='text-capitalize' as={Link} to='/'>{title}</BootstrapNavbar.Brand>
    <BootstrapNavbar.Toggle aria-controls='navbar-nav' />
    <BootstrapNavbar.Collapse id='navbar-nav'>
      <Nav className='mr-auto'>
        <Nav.Link as={Link} to='/'>Home</Nav.Link>
        <Nav.Link as={Link} to='/disbursements'>Disbursements</Nav.Link>
        <Nav.Link as={Link} to='/system/partners'>System</Nav.Link>
        <Nav.Link onClick={onLogout}>Logout</Nav.Link>
      </Nav>
    </BootstrapNavbar.Collapse>
  </BootstrapNavbar>;
}
