import React from 'react';
import { Card } from 'react-bootstrap';
import { Address } from '../../api/queries/users/useAddresses';
import StaticAddress from '../StaticAddress';

type ShippingAddressCardProps = {
  address?: Address;
  className?: string;
};

export default function ShippingAddressCard ({ address, className }: ShippingAddressCardProps) {
  if (!address) { return null; }

  return (<>
    <Card className={className}>
      <Card.Header>
        Shipping Address
      </Card.Header>
      <Card.Body>
        <StaticAddress address={address}/>
      </Card.Body>
    </Card>
  </>);
}
