import useQuery from '../../shared/useQuery';

type Response = {
  active_invoice_due_date: string;
  in_past: boolean;
} | null;

export default function useActiveInvoiceDueDate (loanId: string) {
  return useQuery<Response>(`/notebook/loans/${ loanId }/invoices/due-date`);
}
