import React from "react";
import FactsTable from "./FactsTable";
import { FACT_CATEGORIES } from "./constants";
import { Fact, Flag } from './index';
import { useAPIData } from '../../../utils/index';
import { LegacyLoanApplication } from '../../../api/queries/users/useLoanApplications';
import FlagsTable from './FlagsTable';

function byCategory (facts: Array<Fact>, category: string) {
  return facts.filter(fact => fact.category === category);
}

export default function UnderwritingFacts({ loanApplication, allowFactsEdit }: { loanApplication: LegacyLoanApplication, allowFactsEdit: boolean }) {
  const facts = useAPIData<Array<Fact>>(`/notebook/loan-applications/${loanApplication._id}/underwriting-facts`);
  const flags = useAPIData<Array<Flag>>(`/notebook/loan-applications/${loanApplication._id}/underwriting-flags`);

  const identityFacts = byCategory(facts.data || [], FACT_CATEGORIES.IDENTITY);
  const programFacts = byCategory(facts.data || [], FACT_CATEGORIES.PROGRAM);

  return (
    <div className="mb-5">
      <div className="border-bottom">
        <h4>Underwriting: Facts</h4>
      </div>
      <div className="mt-4">
        <FactsTable
          title="Identity"
          facts={identityFacts}
          allowFactsEdit={allowFactsEdit}
        />
        <FactsTable
          title="Program"
          facts={programFacts}
          allowFactsEdit={allowFactsEdit}
        />
        <FlagsTable
          title="Suspicious Activity"
          flags={flags.data || []}
          allowFactsEdit={allowFactsEdit}
        />
      </div>
    </div>
  );
}
