import useQuery from '../../shared/useQuery';

export type PaymentDetail = {
  _id: string;
  type: 'ach' | 'cash_back';
  slug: string;
  amount: number;
  status: string;
  effective_date: string;
  created_at: string;
  is_retry: boolean;
  last_four: string;
  submitted_by: string;
  autopay_generated: boolean;
  return_reason: string;
  withdrawal_date: string;
  internal_account_last_four: string;
}

export default function usePaymentDetail (loanId: string, paymentId: string) {
  return useQuery<PaymentDetail>(`/notebook/loans/${loanId}/payment/activities/${paymentId}`);
}
