import { SWRConfiguration } from 'swr';
import { DisbursementData as Response } from '../../../utils/transactionUtils';
import useQuery from '../../shared/useQuery';

/**
 * @param disbursementId disbursement request id
 * @param swrConfig
 */
export default function useDisbursementRequest (disbursementId: string, swrConfig: SWRConfiguration<Response, any>={}) {
  return useQuery<Response>(`/notebook/disbursement-requests/${ disbursementId }`, swrConfig);
}
