import React from 'react';
import Field from './Field';
import { Col, Form } from 'react-bootstrap';

import { ALL_US_STATES } from '../../utils/constants';
import styles from './Field.module.scss';

type Props = {
  label?: string;
  addressLine1FieldName: string;
  addressLine2FieldName: string;
  cityFieldName: string;
  stateFieldName: string;
  zipFieldName: string;
};

export default function AddressFields (props: Props) {
  const {
    label = 'Address',
    addressLine1FieldName,
    addressLine2FieldName,
    cityFieldName,
    stateFieldName,
    zipFieldName,
  } = props;

  const { Group, Label } = Form;

  return (
    <Group controlId={addressLine1FieldName}>
      <Label>{label}</Label>
      <Field name={addressLine1FieldName} placeholder='Address Line 1'/>
      <div className={styles.addressIndent}>
        <Label>Apt / Unit</Label>
        <Field name={addressLine2FieldName} placeholder='Address Line 2'/>
        <Label>City</Label>
        <Field name={cityFieldName} placeholder='City'/>
        <Form.Row>
          <Col>
            <Label>State</Label>
            <Field name={stateFieldName} placeholder='State' controlProps={{ as: 'select' }}>
              <option value="">State</option>
              { ALL_US_STATES.map(({ abbr, name }) => <option value={abbr} key={abbr}>{name}</option>) }
            </Field>
          </Col>
          <Col>
            <Label>Zip</Label>
            <Field name={zipFieldName} placeholder='Zip'/>
          </Col>
        </Form.Row>
      </div>
    </Group>
  );
}
