import React, { useMemo } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import cx from 'classnames';
import { formatCents, formatDate } from '../../../../utils';
import { getDateInTimezone } from '../../../../utils/datetime';
import styles from './PaymentSchedule.module.scss';
import { Payment, Response, Obligation } from './';
import Drawer from '../../../Drawer';
import Decimal from 'decimal.js';
import { MinimizedEntityId } from '../../../MinimizedEntityId';
import CONFIG from '../../../../config';
import moment from 'moment';
import { PaymentDetails, PaymentType } from '../../../../api/queries/obligations/usePaymentDetails';
import { titleCase } from '../../../../utils/string';

type PaymentScheduleTablePropTypes = Response & { isDraw?: boolean, onRowClicked: (obligation: ObligationWithStatus) => void, selectedObligation: ObligationWithStatus | undefined };
type ObligationWithStatus = Obligation & { status?: string; statusText?: string };

function ObligationRow ({ amortizedPaymentAmount, obligation, isSelected, onObligationClicked }: { amortizedPaymentAmount: number, obligation: ObligationWithStatus, isSelected: boolean | undefined, onObligationClicked: () => void }) {
  return <>
    <tr className={`cursor-pointer ${isSelected ? "bg-blue" : "hover:shaded-grey"}`} onClick={onObligationClicked}>
      <td>{formatDate(obligation.dueDate)}</td>
      <td className={cx('amount-column', `color-${obligation.status}`)}>
        {formatCents(obligation.amount)}
        {!obligation.obligationId && !amortizedPaymentAmount && '*'}
      </td>
      <td className={cx('paid-column', `color-${obligation.status}`)}>
        {obligation.paidAmount ? formatCents(obligation.paidAmount) : ''}
      </td>
      <td className={cx('status-column', `color-${obligation.status}`)}>
        {obligation.statusText}
      </td>
    </tr>
  </>
}

function DetailRow ({ label, value }: { label: string, value: string | null | undefined }) {
  return <Row className={cx(styles.table, 'border-bottom py-2')}>
    <Col className='pl-0'>{label}</Col>
    <Col className={`text-right pr-0`} md='auto'>{value || '-'}</Col>
  </Row>
}

function DetailRowId ({ label, value }: { label: string, value: string | null | undefined }) {
  return <Row className={cx(styles.table, 'border-bottom py-2')}>
    <Col className='pl-0'>{label}</Col>
    <Col className={`text-right pr-0`} md='auto'>
      <MinimizedEntityId id={value} />
    </Col>
  </Row>
}

function DetailRowStatus ({ label, value, color }: { label: string, value: string | null | undefined, color: string }) {
  return <Row className={cx(styles.table, 'border-bottom py-2')}>
    <Col className='pl-0'>{label}</Col>
    <Col className={`text-right status-column ${color} pr-0`} md='auto'>{value || '-'}</Col>
  </Row>
}

export function ObligationDetail ({ obligation, paymentDetails, onClose }: { obligation: ObligationWithStatus, paymentDetails: PaymentDetails, onClose: () => void }) {
  const monthlyStatement = obligation.monthlyStatement;
  return <Drawer title='Details' onClose={onClose} closeDelay={500}>
    <div className="px-3">
      {
        monthlyStatement && <Row className='mb-4'>
          <Col className='pl-0 pr-0'>
            <Button
              as="a"
              href={ `${CONFIG.API_BASE_URL}/notebook/obligations/${ obligation.obligationId }/statement.pdf` }
              target='_blank'
              rel='noopener noreferrer'
              variant='outline-dark'
              style={ { width: '100%' } }
            >
              View Statement
            </Button>
          </Col>
        </Row>
      }

      <Row className='border-bottom py-2'>
        <Col className='pl-0'><b>Obligation Details</b></Col>
      </Row>

      <DetailRowId label={'Obligation ID'} value={obligation.obligationId}/>
      <DetailRowId label={'Statement ID'} value={monthlyStatement ? monthlyStatement._id : null}/>
      <DetailRow label={'Statement Period'} value={monthlyStatement ? `${formatDate(monthlyStatement.periodStart)} - ${formatDate(monthlyStatement.periodEnd)}` : null}/>
      <DetailRow label={'Due Date'} value={formatDate(obligation.dueDate)}/>
      <DetailRowStatus label={'Obligation Status'} value={obligation.statusText} color={`color-${obligation.status}`}/>
      <DetailRow label={'Fees'} value={formatCents(obligation.feeCents)}/>
      <DetailRow label={'Interest'} value={formatCents(obligation.interestCents)}/>
      <DetailRow label={'Principal'} value={formatCents(obligation.principalCents)}/>
      <DetailRow label={'Total Amount Due'} value={formatCents(obligation.amount)}/>
      <DetailRow label={'Remaining Amount Due'} value={formatCents(new Decimal(obligation.amount).minus(obligation.paidAmount || 0).toNumber())}/>

      { paymentDetails.length ?
        <Row className='border-bottom py-2 mt-4'>
          <Col className='pl-0'><b>Payment Details</b></Col>
        </Row> : null
      }

      {paymentDetails.map(details => {
        const isCashBack = details.payment_type === PaymentType.CASH_BACK;

        return (<div className='mb-5'>
          <DetailRowId label={'Payment ID'} value={details.payment_id} />
          <DetailRow label={'Effective Date'} value={moment(details.effective_date, 'YYYY-MM-DD').format('MMM DD, YYYY')} />
          <DetailRow label={'Submitted Date'} value={moment(details.submitted_date).format('MMM DD, YYYY')} />
          <DetailRow label={'Accounting Date'} value={!isCashBack ? moment(details.bank_post_date, 'YYYY-MM-DD').format('MMM DD, YYYY') : '--'} />
          <DetailRow label={'Autopay'} value={details.autopay_generated ? 'Yes' : 'No'} />
          <DetailRow label={'Retry (due to NSF)'} value={details.is_retry ? 'Yes' : 'No'} />
          <DetailRow label={'Bank Account'} value={isCashBack ? 'Cash Back' : details.last_four ? `Ending **${details.last_four}` : 'Unknown'} />
          <DetailRow label={'Status'} value={titleCase(details.status)} />
          <DetailRow label={'Towards Fees'} value={formatCents(details.amount_towards_fees)} />
          <DetailRow label={'Towards Interest'} value={formatCents(details.amount_towards_interest)} />
          <DetailRow label={'Towards Principal'} value={formatCents(details.amount_towards_principal)} />
          <DetailRow label={'Towards This Obligation'} value={formatCents(details.amount_towards_this_obligation)} />
          <DetailRow label={'Total Payment Amount'} value={formatCents(details.amount)} />
        </div>)
      })}
    </div>
  </Drawer>;
}

export default function PaymentScheduleTable({
  amortizedPaymentAmount,
  paymentPlan,
  scheduledPayments = [],
  onRowClicked,
  selectedObligation,
}: PaymentScheduleTablePropTypes) {
  const obligations: Array<ObligationWithStatus> = useMemo(() => {
    let scheduledAmount = scheduledPayments.reduce(
      (sum: number, payment: Payment) => sum + payment.amount,
      0
    );

    // determine status
    let firstFuturePlanSet = false;
    return paymentPlan.map((x) => {
      let status = '';
      let statusText = '';

      if (x.bulletRepayment) {
        return { ...x, status: 'future' };
      }

      const unpaid = x.amount - (x.paidAmount || 0);

      if (x.is_paid) {
        const daysLate = Math.ceil(
          getDateInTimezone(x.paidOn).diff(
            getDateInTimezone(x.dueDate),
            'days',
            true
          )
        );
        if (daysLate >= 2) {
          statusText = `Paid (${daysLate} days late)`;
        } else if (daysLate === 1) {
          statusText = `Paid (1 day late)`;
        } else {
          statusText = 'Paid';
        }
      } else {
        if (x.obligationId) {
          if (unpaid <= scheduledAmount) {
            status = 'scheduled';
            statusText = 'Payment Scheduled';
          } else if (scheduledAmount) {
            status = 'scheduled';
            statusText = 'Partial Payment Scheduled';
          } else if (x.paidAmount) {
            statusText = 'Partially Paid';
          } else {
            const daysDiff = Math.ceil(getDateInTimezone(new Date(x.dueDate)).diff(
              getDateInTimezone(),
              'days',
              true
            ));
            if (daysDiff < 0) {
              status = 'past-due';
              statusText = `${-daysDiff} ${daysDiff === -1 ? 'day' : 'days' } past due`;
            } else {
              status = 'due';
              if (daysDiff === 0) {
                statusText = 'Due today';
              } else {
                statusText = `Due in ${daysDiff} day`;
                if (daysDiff > 1) {
                  statusText += 's';
                }
              }
            }
          }
          if (scheduledAmount) {
            scheduledAmount -= Math.min(unpaid, scheduledAmount);
          }
        } else {
          if (!firstFuturePlanSet) {
            firstFuturePlanSet = true;
            const daysDiff = Math.ceil(getDateInTimezone(new Date(x.statementDate!)).diff(
              getDateInTimezone(),
              'days',
              true
            ));
            status = 'statement';
            if (daysDiff === 0) {
              statusText = 'Statement ready today';
            } else {
              statusText = `Statement ready in ${daysDiff} day`;
              if (daysDiff > 1) {
                statusText += 's';
              }
            }
          } else {
            status = 'future';
          }
        }
      }

      return { ...x, status, statusText };
    });
  }, [paymentPlan, scheduledPayments]);

  return (
    <>
      <Table size="sm" responsive={true} className={cx(styles.table, 'custom-table')}>
        <thead>
        <tr>
          <th style={{ width: '20%' }}>Date</th>
          <th style={{ width: '20%' }}>Amount</th>
          <th style={{ width: '20%' }}>Paid</th>
          <th style={{ width: '40%' }}>Status</th>
        </tr>
        </thead>
        <tbody>
        { obligations.map((obligation, index) => <ObligationRow
          amortizedPaymentAmount={amortizedPaymentAmount}
          obligation={obligation}
          key={`payment-${index}`}
          onObligationClicked={() => onRowClicked(obligation)}
          isSelected={(selectedObligation ? obligation.dueDate === selectedObligation.dueDate : false)}/>
        ) }
        </tbody>
      </Table>
    </>
  );
}
