import React, { useContext } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

import AdminContext, { ROLES } from '../../../adminContext';
import RepullCreditReportModal from '../../Modals/RepullCreditReportModal';
import TheNumberLink from '../../TheNumberLink';
import useExpandableState from '../../../utils/useExpandableState';
import { LegacyLoanApplication } from '../../../api/queries/users/useLoanApplications';

type Props = {
  loanApplication: LegacyLoanApplication;
};

export default function ConciergeActionButtons ({ loanApplication }: Props) {
  const adminContext = useContext(AdminContext);
  const repullCreditReportModalState = useExpandableState();

  const isSuperAdmin = adminContext.adminRole === ROLES.SUPERADMIN

  return (
    <>
      {/* Buttons */}
      {isSuperAdmin && <>
        <ButtonGroup vertical className='d-block mb-3'>
          <Button block={ true } onClick={ repullCreditReportModalState.showExpand }>Re-pull Credit Report</Button>
        </ButtonGroup>
      </>}

      <ButtonGroup vertical className="d-block mb-3">
        <TheNumberLink loanApplication={loanApplication} />
      </ButtonGroup>

      {/* Modals */}
      {isSuperAdmin && <>
        <RepullCreditReportModal
          show={repullCreditReportModalState.isExpanded}
          onHide={repullCreditReportModalState.hideExpand}
          onSubmit={() => {
            window.location.reload();
          }}
          loanApplication={loanApplication}
        />
      </>}
    </>
  )
}
