import React from 'react';

type Props = {
  maybe?: boolean;
};

export default class Highlight extends React.Component<Props> {
  render () {
    const { maybe = true } = this.props;

    if (maybe) {
      return <span style={{ backgroundColor: 'yellow' }}>{this.props.children}</span>;
    } else {
      return this.props.children;
    }
  }
}
