import React from "react";
import VerificationBadge from "../VerificationBadge";
import { Col, Row } from "react-bootstrap";
import { VERIFICATION_RESULTS } from '../constants';
import { Fact } from '../index';
import InlineButton from '../../../InlineButton';
import { useExpandableState } from '../../../../utils/index';
import EditFactVerificationModal from '../../../Modals/EditFactVerificationModal';

function ManualVerificationFact ({ fact, showEditButton }: { fact: Fact, showEditButton: boolean }) {
  const editFactModal = useExpandableState();

  if (fact.value === 'OFF') { return null; }
  return (
    <Row className="border-top py-3" data-qa='fact-row'>
      <Col sm={4} className="font-weight-bold pl-0">{fact.human_name}</Col>
      <Col sm={4}>{fact.value}</Col>
      <Col sm={3}>
        <VerificationBadge
          verificationResult={fact.verified ? VERIFICATION_RESULTS.VERIFIED : VERIFICATION_RESULTS.UNVERIFIED}
          unverifiedReasons={fact.unverified_reasons}
        />
      </Col>
      <Col sm={1} className="pr-0">
        { showEditButton &&
        <InlineButton onClick={editFactModal.showExpand} className='float-right'>
          Edit
        </InlineButton>
        }
      </Col>
      <EditFactVerificationModal
        expand={editFactModal}
        fact={fact}
        onSubmit={() => window.location.reload()}
        submitUrl='/forced-manual-review'
      />
    </Row>
  );
}

export default ManualVerificationFact;
