import React from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs';
import FormSteps from '../components/EditIdentity/FormSteps';
import styles from './EditIdentityFlow.module.scss';
import useAddresses, { Address } from '../api/queries/users/useAddresses';
import useUser, { User } from '../api/queries/users/useUser';

type EditIdentityFlowProps = {
  addresses: Array<Address>;
  user: User;
};

export function EditIdentityFlow (props: EditIdentityFlowProps) {
  const { addresses, user } = props;

  return (
    <div className={styles.scope}>
      <Breadcrumbs className='text-primary'>
        <Breadcrumbs.Link to={`/users/${user._id}`}>Back to <strong>{ user.firstName } { user.lastName }</strong></Breadcrumbs.Link>
      </Breadcrumbs>

      <h1>Edit Identity Info</h1>

      <FormSteps addresses={ addresses } user={ user } />
    </div>
  );
}

export default function ConnectedEditIdentityFlow () {
  const { userId } = useParams<{ userId: string }>();

  const { data: user } = useUser(userId);
  const addressesReq = useAddresses(user ? user._id : undefined);

  // Do not render form until data is loaded, for pre-fill functionality
  if (!user || (!addressesReq.data && !addressesReq.error)) {
    return null;
  }

  return <EditIdentityFlow
    addresses={ addressesReq.data! }
    user={ user }
  />;
}
