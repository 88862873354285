import React from 'react';
import ExplainerPopover from '../ExplainerPopover';

enum SOURCES {
  FIRST_AMERICAN = '1am',
  THENUMBER_PR = 'tnpr',
  THENUMBER_CR = 'tncr',
};

type Props = {
  source: SOURCES;
  small?: boolean;
};

export default function SourceTag (props: Props) {
  const { source, small } = props;
  let label;
  let explanation;
  switch (source) {
    case SOURCES.FIRST_AMERICAN:
      label = small ? '1AM' : '1AM Lien Report';
      explanation = 'Data sourced from First American Lien Report';
      break;
    case SOURCES.THENUMBER_CR:
      explanation = 'Data sourced from credit report via TheNumber';
      label = small ? 'TN' : 'TN Credit Report';
      break;
    case SOURCES.THENUMBER_PR:
      explanation = 'Data sourced from public property records via TheNumber';
      label = small ? 'TN' : 'TN Property Record';
      break;
    default:
  }
  return (
    <ExplainerPopover explanation={ explanation }>
      <span className='text-uppercase text-muted small'>
        { label }
      </span>
    </ExplainerPopover>
  );
}

export const TheNumberCR = function (props: { small?: boolean }) {
  return (
    <SourceTag source={ SOURCES.THENUMBER_CR } {...props} />
  );
}

export const TheNumberPR = function (props: { small?: boolean }) {
  return (
    <SourceTag source={ SOURCES.THENUMBER_PR } {...props} />
  );
}

export const FirstAmerican = function (props: { small?: boolean }) {
  return (
    <SourceTag source={ SOURCES.FIRST_AMERICAN } {...props} />
  );
}
