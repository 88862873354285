import { SWRConfiguration } from 'swr';
import useQuery from '../../shared/useQuery';

type Response = {
  name: string;
  routing_numbers: Array<string>;
};

export default function useBankInstitutionDetails (purchaseMethodId: string, swrConfig: SWRConfiguration<Response, any>={}) {
  return useQuery<Response>(`/notebook/purchase-methods/${purchaseMethodId}/institution-details`, swrConfig);
}
