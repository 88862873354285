import useQuery from "../../shared/useQuery";

export type Portfolio = {
  key: string;
  name: string;
}

export function usePortfolios (investorKey: string) {
  return useQuery<Array<Portfolio>>(`/notebook/investors/${ investorKey }/portfolios`);
}
