import { SWRConfiguration } from 'swr';
import useQuery from '../../shared/useQuery';

type Response = {
  _id: string;
  date: string;
  status: string;
  amount: number;
  cash_back_amount: number;
  card: {
    last_four: string;
    type: string;
    status: string;
  };
  merchant: {
    mid: string;
    mcc: string;
    name: string;
    street_address: string;
    city: string;
    state: string;
    postal_code: string;
    country_code: string;
    known_merchant_key: string;
  };
  swipe_details: {
    auth_status: string;
    auth_decline_reason_type: string;
    method: string;
    channel: string;
    is_force_capture: boolean;
    network_reference_id: string;
  };
  decline_details: {
    avs: string;
    memo: string;
  } | null
  marqeta_token: string;
  events: Array<{
    identifier: string;
    response_memo: string;
    token: string;
    type: string;
    state: string;
    amount: number;
    issuer_received_time: string;
  }>
}

/**
 *
 * @param activityId a CardActivity _id
 * @param swrConfig
 */
export default function useCardActivity (activityId: string, swrConfig: SWRConfiguration<Response, any>={}) {
  return useQuery<Response>(`/notebook/card-activities/${activityId}`, swrConfig);
}
