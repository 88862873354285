import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export default function SectionHeader ({ children, className }: Props) {
  return (
    <h6 className={`font-weight-bold text-muted text-uppercase ${className}`}>
      <small>{children}</small>
    </h6>
  )
}
