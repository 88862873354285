import React from 'react';
import * as  _ from 'lodash';
import './AgentItem.css';

type Props = {
  active?: boolean;
  imagePath: string;
  displayName: string;
};

function AgentImage (props: Props) {
  if (props.imagePath) {
    return <img
      className = '_AgentImage'
      src       = { props.imagePath }
      alt       = 'Agent'
    />;
  } else {
    const nameInitials = props.displayName.split(' ').slice(0, 2).map(v => v[0]);
    return (
      <div className = '_AgentImage'>
        <span>{nameInitials}</span>
      </div>
    );
  }
}

function AgentItem (props: Props) {
  // Allow for an empty geo when returning to the ListingInfo view
  const geo = _.get(props, 'agentProfile.profilePages.0.geography', '');
  return (
    <span className='AgentItem' style={{ opacity: props.active ? 0.5 : 1 }}>
      <AgentImage imagePath={props.imagePath} displayName={props.displayName} />
      <div>
        <span className='_AgentName'>
          { props.displayName }
        </span>
        <span className='_AgentGeo text-uppercase'>
          { geo.replace(/_/g,' ') }
        </span>
      </div>
    </span>
  );
}

export default AgentItem;
