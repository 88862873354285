import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext, useFieldArray, get } from "react-hook-form";
import S3FileUploadField from '../../Form/S3FileUploadField';

export default function DocumentsField () {
  const { formState: { errors, isValidating }, control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: 'documents' });
  const error = get(errors, 'documents');

  return (
    <>
      <Form.Label>
        Documents
      </Form.Label>
      { fields.map((item, index) =>
        <S3FileUploadField
          key={item.id}
          name={`documents.${index}`}
          bucket='user-docs'
          s3_path='verification-documents'
          onMultipleUpload={(s3Filename, originalFilename) => append({ s3Filename, originalFilename })}
          onUploadFinish={() => append({ s3Filename: '', originalFilename: '' })}
          remove={() => remove(index)}
        />)
      }

      { !isValidating && error && error.message && <div className="text-danger ml-1 mt-1 small">{error.message}</div> }
    </>
  );
}
