import GenericModal from './GenericModal';
import useExpandableState from '../../utils/useExpandableState';
import { Button } from 'react-bootstrap'
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Form from '../Form/Form';
import Field from '../Form/Field';
import SubmitError from '../Form/SubmitError';
import useAdminAPICall from '../../utils/useAdminAPICall'
import ButtonWithSpinner from '../ButtonWithSpinner';
import React from 'react';

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  loan: { _id: string },
  onSubmit: () => void;
};

export default function MarkLoanChargedOffModal (props: Props) {
  const { expand, loan, onSubmit } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loans/${loan._id}/charge-off`,
    method: 'PUT',
  })

  const handleSubmit = async (form: FieldValues) => {
    await callAPI({ data: form });
    onSubmit();
  }

  const schema = yup.object().shape({
    date: yup.string().required('Please select the date the charge-off should be effective.'),
    notes: yup.string(),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      date: '',
      notes: '',
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting } } = methods;
  return (
    <GenericModal expandable={ expand } title='Charge-off Loan' size='lg'>
      <Form { ...methods } onSubmit={ handleSubmit }>
        <Field name='date' label='Charge-off Date' controlProps={{ type: 'date' }}/>
        <Field name='notes' label='Internal Notes' controlProps={{ as: 'textarea', rows: '4' }}/>

        <SubmitError/>

        <div className='d-flex justify-content-end mt-4'>
          <Button variant='secondary' onClick={ expand.hideExpand } className='mr-2'>
            Cancel
          </Button>
          <ButtonWithSpinner variant='danger' type='submit' loading={ isSubmitting }>
            Confirm
          </ButtonWithSpinner>
        </div>
      </Form>
    </GenericModal>
  );
}
