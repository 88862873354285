import useQuery from '../../shared/useQuery';

export type Partner = {
  _id: string;
  name: string;
}

export type StateAprCap = {
  _id: string;
  minCreditLimitCents: number;
  maxCreditLimitCents: number;
  aprBpsCap: number;
  active: boolean;
  createdAt: string;
}

export type StateCreditLimit = {
  _id: string;
  minCreditLimitCents: number;
  active: boolean;
  createdAt: string;
}

export type State = {
  _id: string;
  code: string;
  name: string;
  partners: Array<Partner>;
  stateAprCaps: Array<StateAprCap>;
};

type Response = State;

export default function useState (stateId?: string) {
  return useQuery<Response>(stateId ? `/notebook/states/${stateId}` : null);
}
