import React, { useState } from 'react';
import { Badge, Button, Table } from 'react-bootstrap';

import EntityID from '../../EntityID';
import OutboundLink from '../../OutboundLink';
import PlaidLogo from '../../PlaidLogo';
import UnmaskedAccountNumber from '../../UnmaskedAccountNumber';
import AchModal from '../../Modals/AchModal';
import AchHistoryModal from '../../Modals/AchHistoryModal';
import ButtonWithSpinner from '../../ButtonWithSpinner';
import { PURCHASE_METHODS, PURCHASE_METHOD_LABELS, PURCHASE_METHOD_STATUS } from '../../../utils/constants';
import { useExpandableState } from '../../../utils';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import CONFIG from '../../../config';
import { Loan } from '../../../api/queries/types';
import MaskedAccountNumber from '../../MaskedAccountNumber';
import AccountTerminatationModal from '../../Modals/AccountTerminationModal';
import BankInstitutionDetailsModal from '../../Modals/BankInstitutionDetailsModal';

export type BankAccount = {
  _id: string;
  accountNumber: string;
  accountType: string;
  approvedAt: string;
  lastFour: string;
  plaidItemId: string;
  routingNumber: string;
  status: string;
  type: string;
  voidedCheckPhoto: string;
};

type BankAccountRowProps = {
  bankAccount: BankAccount;
  onChange: () => void;
  handleEdit: (bankAccount: BankAccount) => void;
  handleHistory: (bankAccount: BankAccount) => void;
  handleTerminate: (bankAccount: BankAccount) => void;
  handleShowInstitution: (bankAccount: BankAccount) => void;
  loan : { _id: string };
};

type Props = {
  bankAccounts: Array<BankAccount>;
  loan: Loan;
  onChange: () => void;
};

const outputInfoRow = (caption: string, value: React.ReactNode) => <div className='text-nowrap'>
  <strong className='-caption'>{caption}</strong>
  {' '}{value}
</div>;

function BankAccountRow ({ bankAccount, onChange, handleEdit, handleHistory, handleTerminate, handleShowInstitution, loan }: BankAccountRowProps) {
  const approveAchDisbursementReq = useAdminAPICall({
    endpoint: `/notebook/purchase-methods/${bankAccount._id}/approve`,
    method: 'POST',
    onSuccess: () => onChange()
  });
  const isTokenized = bankAccount.accountNumber && bankAccount.lastFour !== bankAccount.accountNumber.slice(-4);

  const voidedCheckPhotoUrl = bankAccount.voidedCheckPhoto
    ? `${ CONFIG.API_BASE_URL }/notebook/s3/user-docs/img/${ encodeURIComponent(bankAccount.voidedCheckPhoto) }`
    : null;

  return <tr>
    <td>
      {PURCHASE_METHOD_LABELS[bankAccount.type]}
      {
        bankAccount.type === PURCHASE_METHODS.DISBURSEMENT_TO_BORROWER && (
          bankAccount.plaidItemId ? ' (Plaid Link)' : ' (Manual)'
        )
      }
      <br/>
      <EntityID id={bankAccount._id} type='purchaseMethod'/>
    </td>
    <td className='PurchaseMethodInfo'>
      {
        bankAccount.type === PURCHASE_METHODS.DISBURSEMENT_TO_BORROWER && <>
          {
            <div>
              {
                bankAccount.status === PURCHASE_METHOD_STATUS.TERMINATED ?
                <Badge pill variant='dark'>Terminated</Badge> :
                (bankAccount.approvedAt || bankAccount.plaidItemId) ?
                <Badge pill variant='success'>Verified</Badge> :
                <Badge pill variant='warning'>Needs review</Badge>
              }
              {
                bankAccount.plaidItemId ? (
                  <PlaidLogo />
                ) : null
              }
            </div>
          }
          {isTokenized ? (
            <>
              {outputInfoRow('Account Number', <MaskedAccountNumber number={ bankAccount.lastFour } /> )}
              {outputInfoRow('Routing Token', <UnmaskedAccountNumber number={ bankAccount.routingNumber } /> )}
              {outputInfoRow('Account Token', <UnmaskedAccountNumber number={ bankAccount.accountNumber } /> )}
            </>
          ) : (
            <>
              {outputInfoRow('Account Number', <UnmaskedAccountNumber number={ bankAccount.accountNumber } /> )}
              {outputInfoRow('Routing Number', <UnmaskedAccountNumber number={ bankAccount.routingNumber } /> )}
            </>
          )}
          {outputInfoRow('Account type', bankAccount.accountType)}
        </>
      }
      {
        voidedCheckPhotoUrl &&
          outputInfoRow('Voided check', <OutboundLink href={voidedCheckPhotoUrl}>view</OutboundLink>)
      }
    </td>
    <td>
      {
        bankAccount.plaidItemId && (
          <Button variant="link" size="sm" onClick={ () => handleShowInstitution(bankAccount) }>
            View Bank Details
          </Button>
        )
      }
      {
        (
          bankAccount.type === PURCHASE_METHODS.DISBURSEMENT_TO_BORROWER &&
          !bankAccount.plaidItemId &&
          !bankAccount.approvedAt
        ) && (
          <ButtonWithSpinner
            loading={approveAchDisbursementReq.loadPending}
            variant='primary'
            onClick={() => approveAchDisbursementReq.callAPI()}
          >
            Mark Verified
          </ButtonWithSpinner>
        )
      }
      {
        (
          bankAccount.status !== PURCHASE_METHOD_STATUS.TERMINATED
        ) &&
        (
          <div>
            <Button variant="link" size="sm"
              onClick={() => handleTerminate(bankAccount)}>Terminate
            </Button>
          </div>
        )
      }
    </td>
  </tr>;
}

export default function BankAccounts (props: Props) {
  const [targetAccount, setTargetAccount] = useState<BankAccount | null>(null);
  const { bankAccounts, loan, onChange } = props;
  const achModal = useExpandableState();
  const achHistoryModal = useExpandableState();
  const bankAccountTerminationModal = useExpandableState();
  const bankInstitutionDetailsModal = useExpandableState();

  const handleEdit = (data: BankAccount) => {
    setTargetAccount(data);
    achModal.showExpand();
  }

  const handleHistory = (data: BankAccount) => {
    setTargetAccount(data);
    achHistoryModal.showExpand();
  }

  const handleTerminate = (data: BankAccount) => {
    setTargetAccount(data)
    bankAccountTerminationModal.showExpand();
  }

  const handleShowInstitution = (data: BankAccount) => {
    setTargetAccount(data)
    bankInstitutionDetailsModal.showExpand();
  }

  return (
    <div>
      <Table size='sm' responsive={true}>
        <thead>
          <tr>
            <th>Type</th>
            <th>Info</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            bankAccounts.map(bankAccount => (<BankAccountRow
              key={bankAccount._id}
              loan={loan}
              bankAccount={bankAccount}
              onChange={onChange}
              handleEdit={handleEdit}
              handleHistory={handleHistory}
              handleTerminate={handleTerminate}
              handleShowInstitution={handleShowInstitution}
              />)
            )
          }
        </tbody>
      </Table>
      {achModal.isExpanded && (
        <AchModal modal={achModal} loan={loan} bankAccount={targetAccount!} onChange={onChange} />
      )}
      {achHistoryModal.isExpanded && (
        <AchHistoryModal expand={achHistoryModal} loan={loan} bankAccount={targetAccount!} />
      )}
      {bankAccountTerminationModal.isExpanded && (
        <AccountTerminatationModal modal={bankAccountTerminationModal} loan={loan} bankAccount={targetAccount!} onChange={onChange} />
      )}
      {bankInstitutionDetailsModal.isExpanded && (
        <BankInstitutionDetailsModal modal={bankInstitutionDetailsModal} bankAccount={targetAccount!} />
      )}
    </div>
  );
}
