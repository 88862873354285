import * as React from 'react';
import ApplicationOverview from './ApplicationOverview'
import IdentityInfo from './IdentityInfo';
import HousingInfo from './HousingInfo';
import IncomeInfo from './IncomeInfo';
import CreditReportDebt from './CreditReportDebt';
import BetterMortgageInfo from './BetterMortgageInfo';
import ExcludedDebtsInfo from './ExcludedDebtsInfo';
import OtherLiabilitiesInfo from './OtherLiabilitiesInfo';
import PropertyLiabilitiesInfo from './PropertyLiabilitiesInfo';
import StatedDebtInfo from './StatedDebtInfo';
import useBetterUnderwritingData, { Response } from '../../../api/queries/loan-applications/useBetterUnderwritingData';
import useIncomes, { Income } from '../../../api/queries/loan-applications/useIncomes';
import { isNil } from 'lodash';
import { LegacyLoanApplication } from '../../../api/queries/users/useLoanApplications';
import { LOAN_APPLICATION_PROPERTY_OWNERSHIP_TYPE } from '../../../utils/constants';
import HomeCardListingInfo from './HomeCardListingInfo';
import CreditReport from './CreditReport';
import AgentInfo from './AgentInfo';
import PropertyDebt from './PropertyDebt';
import PropertyOwnership from './PropertyOwnership';

export type Liability = {
  typeOfDebt: string;
  monthlyPayment: number;
}

export type ExcludedDebt = {
  name?: string;
  dataSource?: string;
  dateOpened?: string;
  description?: string;
  intent?: string;
  monthlyPayment: number;
  monthsLeft?: number;
  propertyAddress?: PropertyAddress;
  pullType?: string;
  typeOfDebt: string;
  unpaidBalance?: number;
}

export type PropertyLiability = {
  typeOfDebt: string;
  dateOpened: string;
  monthlyPayment: number;
  grossRentalIncomeVerifiedAmount?: number;
  name?: string;
  netRentalIncomeAmount?: number;
  occupancyType?: string;
  propertyAddress?: PropertyAddress;
  willBeSoldBeforeClosing?: boolean;
}

type PropertyAddress = {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
}

export type BetterUnderwritingData = Response;

export default function HomeCardApplicationTab ({ loanApplication }: { loanApplication: LegacyLoanApplication }) {
  const { data: betterUnderwritingData } = useBetterUnderwritingData(loanApplication._id);
  const { data: incomes } = useIncomes(loanApplication._id);
  const { isSourceBetter, propertyOwnershipType, propertyType, reportedMonthlyPayment, statedMortgageMonthlyPayment, propertyOwnedForMoreThan90Days, mortgageCloseDate, statedMaxExpectedMortgageMonthlyPayment, isGettingNewMortgage } = loanApplication;
  const statedMortgagePayment = statedMortgageMonthlyPayment ?? statedMaxExpectedMortgageMonthlyPayment;
  const allLiabilities = betterUnderwritingData && betterUnderwritingData.liabilities;
  const excludedDebts = betterUnderwritingData && betterUnderwritingData.excludedDebts;
  const propertyLiabilities = betterUnderwritingData && betterUnderwritingData.propertyLiabilities;

  return (
    <>
      <ApplicationOverview loanApplication={loanApplication} />
      <IdentityInfo className="mt-4" loanApplication={loanApplication} />

      <CreditReport className='mt-4' loanApplication={loanApplication} />

      { propertyOwnershipType &&
        <HousingInfo
          className="mt-4"
          propertyOwnershipType={propertyOwnershipType}
          propertyType={propertyType}
          propertyOwnedForMoreThan90Days={propertyOwnedForMoreThan90Days}
          statedMortgageMonthlyPayment={statedMortgagePayment}
          mortgageCloseDate={mortgageCloseDate}
          isGettingNewMortgage={isGettingNewMortgage}
        />
      }

      {
        propertyOwnershipType === LOAN_APPLICATION_PROPERTY_OWNERSHIP_TYPE.SELLING &&
        <HomeCardListingInfo className="mt-4" loanApplication={loanApplication} />
      }

      { loanApplication.agent && // TODO: placeholder until we decide how to model agents
        <AgentInfo className="mt-4" loanApplication={loanApplication} />
      }

      { incomes &&
        incomes.map((income: Income) => {
          return <IncomeInfo key={income.id} className="mt-4" income={income} />
        })
      }

      {
        propertyOwnershipType === LOAN_APPLICATION_PROPERTY_OWNERSHIP_TYPE.SELLING &&
          <>
            <PropertyDebt className="mt-4" loanApplication={loanApplication} />
            <PropertyOwnership className="mt-4" loanApplication={loanApplication} />
          </>
      }

      { propertyOwnershipType !== LOAN_APPLICATION_PROPERTY_OWNERSHIP_TYPE.SELLING && !isNil(reportedMonthlyPayment) &&
        <CreditReportDebt className="mt-4" reportedMonthlyPayment={reportedMonthlyPayment} />
      }

      { !isNil(statedMortgageMonthlyPayment) &&
        <StatedDebtInfo className="mt-4" statedMortgageMonthlyPayment={statedMortgageMonthlyPayment} propertyOwnershipType={propertyOwnershipType} />
      }

      { isSourceBetter &&
        <>
          { betterUnderwritingData &&
            <BetterMortgageInfo className="mt-4" betterUnderwritingData={betterUnderwritingData} />
          }

          { allLiabilities &&
            allLiabilities.map((liability: Liability) => {
              return <OtherLiabilitiesInfo key={`${liability.typeOfDebt}-${liability.monthlyPayment}`} className="mt-4" liability={liability} />
            })
          }

          { propertyLiabilities &&
            propertyLiabilities.map((debt: PropertyLiability) => {
              return <PropertyLiabilitiesInfo key={`${debt.typeOfDebt}-${debt.monthlyPayment}`} className="mt-4" debt={debt} />
            })
          }

          { excludedDebts &&
            excludedDebts.map((debt: ExcludedDebt) => {
              return <ExcludedDebtsInfo key={`${debt.typeOfDebt}-${debt.monthlyPayment}`} className="mt-4" debt={debt} />
            })
          }
        </>
      }
    </>
  );
}
