import React from 'react';
import RadioGroupField from './RadioGroupField';

type Props = {
  name: string;
  label: string;
  options?: Array<{ label: string, value: string }>;
}

export default function BooleanRadioField (props: Props) {
  const { name, label, options } = props;

  return <RadioGroupField
    name={name}
    label={label}
    options={options || [
      { label: 'ON', value: 'true' },
      { label: 'OFF', value: 'false'},
    ]}
  />;
}
