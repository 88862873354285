import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Form from '../Form/Form';
import Field from '../Form/Field';
import PhoneField from '../Form/PhoneField';
import SubmitError from '../Form/SubmitError';
import cleanPhoneNumber from '../../utils/formatPhone';
import useExpandableState from '../../utils/useExpandableState';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { LegacyLoanApplication } from '../../api/queries/users/useLoanApplications';
import SsnField from '../Form/SsnField';
import { validateDOB, validateSSN } from '../../utils/form-utilities';
import ApiError from '../Form/ApiError';

export type FormKeys = {
  firstName?: string;
  lastName?: string;
  ssn?: string;
  dob?: string;
  email?: string;
  phone?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
  poaFirstName?: string;
  poaLastName?: string;
};

type Props = {
  expandable: ReturnType<typeof useExpandableState>;
  onSubmit: (form: FormKeys) => void;
  onClose: () => void;
  loanApplication: LegacyLoanApplication;
  isPending: boolean;
  error?: string;
};

export default function ResolvePartialMatchModal (props: Props) {
  const { expandable, onSubmit, onClose, loanApplication, isPending, error } = props;

  const schema = yup.object({
    firstName: yup.string().optional(),
    lastName: yup.string().optional(),
    ssn: yup.string().test('ssn', 'Please enter a valid SSN', v => !v || validateSSN(v)).optional(),
    dob: yup.string().test('dob', 'Please enter a valid DOB', v => !!v && validateDOB(v)).optional(),
    email: yup.string().email('Please enter a valid email').optional(),
    addressLine1: yup.string().optional(),
    addressLine2: yup.string().optional(),
    city: yup.string().optional(),
    state: yup.string().optional(),
    zip: yup.string().optional(),
    poaFirstName: yup.string().optional(),
    poaLastName: yup.string().optional(),
    shouldNotifyBorrower: yup.boolean(),
  });

  const form = useForm<FormKeys>({
    defaultValues: {
      firstName: loanApplication.firstName,
      lastName: loanApplication.lastName,
      ssn: '',
      dob: loanApplication.dob,
      email: loanApplication.email,
      phone: cleanPhoneNumber(loanApplication.phone),
      addressLine1: loanApplication.address_line1,
      addressLine2: loanApplication.address_line2,
      city: loanApplication.city,
      state: loanApplication.state,
      zip: loanApplication.zip,
      poaFirstName: loanApplication.poaFirstName,
      poaLastName: loanApplication.poaLastName,
    },
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  const handleClose = () => {
    form.reset();
    onClose();
  }

  const { formState: { isSubmitting, isDirty, defaultValues } } = form;

  const isBusy = isPending || isSubmitting;
  const disabled = !isDirty || isBusy;

  const handleSubmit = () => {
    const dirtyFields: FormKeys = {};
    const formValues = form.getValues();

    // only submit fields that have been updated
    for (const [key, value] of Object.entries(formValues)) {
      const defaultValue = (defaultValues as FormKeys)[key as keyof FormKeys];
      if (defaultValue !== value) {
        dirtyFields[key as keyof FormKeys] = value;
      }
    }

    onSubmit(dirtyFields);
  }

  return (
    <Modal show={ expandable.isExpanded } onHide={ handleClose }>
      <Form { ...form } onSubmit={ handleSubmit }>
        <Modal.Header>
          <Modal.Title>Resolve partial match error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Field name='firstName' label='Borrower’s First Name'/>
          <Field name='lastName' label='Borrower’s Last Name'/>
          <SsnField name='ssn'/>
          <Field name='dob' label='DOB'/>
          <Field name='email' label='Email'/>
          <PhoneField name='phone' label='Phone Number'/>
          <Field name='addressLine1' label='Address Line 1'/>
          <Field name='addressLine2' label='Address Line 2'/>
          <Field name='city' label='City'/>
          <Field name='state' label='State'/>
          <Field name='zip' label='Zip'/>
          { loanApplication.isPOA &&
            <>
              <Field name='poaFirstName' label='Applicant’s First Name'/>
              <Field name='poaLastName' label='Applicant’s Last Name'/>
            </>
          }
          <SubmitError/>
        </Modal.Body>
        <Modal.Footer>
        { error ? <ApiError error={ error } /> : null }
          <div className='d-flex flex-row justify-content-end'>
            <Button variant='secondary' onClick={ handleClose } className='mr-2'>
              Cancel
            </Button>
            <ButtonWithSpinner variant='primary' type='submit' loading={ isBusy } disabled={ disabled }>
              Resolve
            </ButtonWithSpinner>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
