const ONE_DAY = 1000 * 60 * 60 * 24;

export default function groupByDateRanges (data: Array<any> = [], sortBy = 'updated_date') {

  const todayStartLocal = new Date();
  todayStartLocal.setHours(0);
  todayStartLocal.setMinutes(0);
  todayStartLocal.setSeconds(0);
  todayStartLocal.setMilliseconds(0);

  const yesterdayStartLocal = new Date(todayStartLocal.getTime() - ONE_DAY);

  const todayItems: Array<any> = [];
  const yesterdayItems: Array<any> = [];
  const olderItems: Array<any> = [];

  data.forEach(function (item) {
    item._updated_date = new Date(item[sortBy]);
    if (item._updated_date >= todayStartLocal) {
      todayItems.push(item);
    } else if (item._updated_date >= yesterdayStartLocal) {
      yesterdayItems.push(item);
    } else {
      olderItems.push(item);
    }
  });

  todayItems.sort((a,b) => b._updated_date - a._updated_date);
  yesterdayItems.sort((a,b) => b._updated_date - a._updated_date);
  olderItems.sort((a,b) => b._updated_date - a._updated_date);

  return {
    today: todayItems,
    yesterday: yesterdayItems,
    older: olderItems,
  };
}
