import React from 'react';
import { Partner } from '../../../api/queries/partners/usePartner';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import Form from '../../Form/Form';
import SubmitError from '../../Form/SubmitError';
import { Button } from 'react-bootstrap';
import ButtonWithSpinner from '../../ButtonWithSpinner';
import BooleanRadioField from '../../Form/BooleanRadioField';
import ListGroupAddDelete from '../../Form/ListGroupAddDelete';

type PartnerReportingFields = {
  weeklyOriginationReportEnabled: string;
  weeklyOriginationReportRecipients: Array<string>;
};

type Props = {
  partner: Partner;
  onSubmit: () => void;
  onHide: () => void;
};

export default function EditPartnerReportingSettings (props: Props) {
  const { partner, onSubmit, onHide } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/partners/${partner._id}`,
    method: 'PUT',
  });

  async function handleSubmit(data: PartnerReportingFields) {
    await callAPI({
      data: {
        weeklyOriginationReportEnabled: data.weeklyOriginationReportEnabled === 'true',
        weeklyOriginationReportRecipients: data.weeklyOriginationReportRecipients,
      },
    });
    onSubmit();
    onHide();
  }

  const schema = yup.object();

  const methods = useForm<PartnerReportingFields>({
    defaultValues: {
      weeklyOriginationReportEnabled: partner.weeklyOriginationReportEnabled.toString(),
      weeklyOriginationReportRecipients: partner.weeklyOriginationReportRecipients,
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty } } = methods;

  return <div>
    <Form {...methods} onSubmit={handleSubmit}>
      <BooleanRadioField
        label='Weekly Origination Report'
        name='weeklyOriginationReportEnabled'
      />
      <ListGroupAddDelete
        label='Weekly Origination Report Recipients'
        name='weeklyOriginationReportRecipients'
        placeholder='Add new recipient...'
        validationType='email'
      />

      <SubmitError/>

      <div className='d-flex justify-content-end mt-4'>
        <Button variant='secondary' onClick={onHide} className='mr-2'>
          Cancel
        </Button>
        <ButtonWithSpinner variant='primary' type='submit' disabled={!isDirty} loading={isSubmitting}>
          Confirm
        </ButtonWithSpinner>
      </div>
    </Form>
  </div>;
}
