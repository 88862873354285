import React from 'react';

import { Dropdown } from "react-bootstrap";
import { ThreeDots } from 'react-bootstrap-icons';
import styles from './DropdownToggle.module.scss';
import cx from 'classnames';

type Props = {
  id: string;
  disabled?: boolean;
}

export default function DropdownToggle (props: Props) {
  return (
    <Dropdown.Toggle id={props.id} variant="light" className={styles.dropdownToggler} disabled={props.disabled}>
      <ThreeDots className={cx(styles.icon, "p-1 border rounded cursor-pointer")}></ThreeDots>
    </Dropdown.Toggle>
  );
}
