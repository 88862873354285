import React from 'react';
import { Button } from 'react-bootstrap';
import cx from 'classnames';

import styles from './ActivityDetail.module.scss';
import { formatDate } from '../../../../utils';
import MaskedAccountNumber from '../../../MaskedAccountNumber';
import { titleCase } from '../../../../utils/string';
import { RefundDetail } from '../../../../api/queries/loans/useRefundDetail';

type Props = {
  refund: RefundDetail;
  onCancel: () => void;
  onApprove: () => void;
  onEdit: () => void;
  updating: boolean;
};

export const RefundDetailContent: React.FC<Props> = ({
  refund,
  onCancel,
  onApprove,
  onEdit,
  updating,
}) => {
  const isActionable = refund.status === 'open';

  return (
    <div>
      {isActionable && (
        <>
          <Button
            block
            className={ cx('mt-2', styles.actionButton) }
            variant='primary'
            onClick={ onApprove }
            disabled= { updating }
          >
            Approve Refund
          </Button>
          <Button
            block
            className={ cx('mt-2', styles.actionButton) }
            variant='outline-primary'
            onClick={ onEdit }
            disabled= { updating }
          >
            Edit Refund
          </Button>
          <Button
            block
            className={ cx('mt-2', styles.actionButton, styles.cancelButton) }
            variant='danger'
            onClick={ onCancel }
            disabled= { updating }
          >
            Cancel Refund
          </Button>
        </>
      )}

      {refund.type === 'ach' && (
        <>
          <div className={ cx(styles.line, 'mt-4') }>
            <span className={ styles.title }>Bank Account Details</span>
          </div>
          <div className={ styles.line }>
            <span>Borrower Account</span>
            <span>
              { titleCase(refund.destinationAccount.accountType) } <MaskedAccountNumber number={ refund.destinationAccount.lastFour } />
            </span>
          </div>
          <div className={styles.line}>
            <span>Notable Account</span>
            { refund.internal_account_last_four ? <MaskedAccountNumber number={ refund.internal_account_last_four }/> : <span>—</span> }
          </div>
        </>
      )}

      <div className={ cx(styles.line, 'mt-4') }>
        <span className={ styles.title }>Refund Details</span>
      </div>
      <div className={ styles.line }>
        <span>Status</span>
        <span>{ titleCase(refund.status) }</span>
      </div>
      <div className={ styles.line }>
        <span>Submitted by</span>
        <span>{ refund.submitted_by }</span>
      </div>
      <div className={ styles.line }>
        <span>Submitted on</span>
        <span>{ formatDate(refund.created_at) }</span>
      </div>
      <div className={ styles.line }>
        <span>Approved by</span>
        <span>{ refund.approved_by ?? '—' }</span>
      </div>
      <div className={ styles.line }>
        <span>Deposit Date</span>
        <span>{ formatDate(refund.deposit_date, '—') }</span>
      </div>
      <div className={cx(styles.line, 'mt-4')}>
        <span className={styles.title}>Notes</span>
      </div>
      <div className="mt-1">
        <span className={cx(styles.notes, !refund.notes && styles.notesEmpty)}>
          {refund.notes || '—'}
        </span>
      </div>
    </div>
  );
};
