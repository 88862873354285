import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { useFormContext, useController } from "react-hook-form";
import CloseIcon from '@material-ui/icons/Close';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { ACCEPT_VALUES, FILE_TYPES, UploadedFileRepresentation } from './S3FileUploadField';
import cx from 'classnames';
import styles from './FileUploadField.module.scss';

type Props = {
  label?: string;
  required?: boolean;
  name: string;
  type: FILE_TYPES;
  placeholder: string;
};

export default function FileUploadField ({ label, required, name, type, placeholder }: Props) {
  const { control, resetField, setError } = useFormContext();
  const { field, fieldState: { error } } = useController({ name, control });

  const inputEl = React.useRef<HTMLInputElement | null>(null);
  const [isReading, setIsReading] = React.useState(false);
  const [fileName, setFileName] = React.useState('');

  const handleClickUpload = () => inputEl.current && inputEl.current.click();

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      setIsReading(true);

      try {
        // Read the file and convert it to a base64-encoded string.
        const bytes = new Uint8Array(await file!.arrayBuffer());
        const binary = [];
        for (const byte of bytes as any) { // Use loop instead of spread trick to allow for larger files, see: https://stackoverflow.com/questions/9267899/arraybuffer-to-base64-encoded-string
          binary.push(String.fromCharCode(byte));
        }

        setFileName(file.name);
        field.onChange(window.btoa(binary.join('')));
      } catch (error) {
        setError(name, { type: 'upload', message: 'Failed to upload file' });
      }

      setIsReading(false);
    }
  }

  return <Form.Group>
    {label && <Form.Label>{label}{required && <span className="text-danger p-1">*</span>}</Form.Label>}

    <div className='d-flex align-items-center mt-3'>
      { field.value ? <>
          <UploadedFileRepresentation type={type} />
          <span className={cx(styles.filename, 'font-size-2 ml-2')}>{fileName}</span>
          <Button variant='light' className='ml-auto' onClick={() => resetField(name)}><CloseIcon /></Button>
        </> : <>
          <ButtonWithSpinner variant='light' loading={isReading} onClick={handleClickUpload}>{placeholder}</ButtonWithSpinner>
          <input
            type='file'
            accept={ACCEPT_VALUES[type]}
            className='d-none form-control'
            {...field}
            ref={e => {
              field.ref(e);
              inputEl.current = e;
            }}
            onChange={handleInputChange}
          />
        </>
      }
    </div>

    { error && error.message && <Form.Control.Feedback type="invalid" className='d-block'>{error.message}</Form.Control.Feedback> }
  </Form.Group>
}
