import useQuery from '../../shared/useQuery';

export type TimelineEvent = {
  _id: string;
  publishedAt: string;
  message: Array<{
    text: string;
    link?: string;
  }>;
  notes: Array<{
    body: string;
    adminName: string;
  }>;
}

/**
 * Load the TimelineEvents for a given scope, optionally filtered by a specific
 * entity_id (for use when showing the events on that object's detail view).
 *
 * @param scope_id - a loan_application_id, loan_id, or user_id
 * @param entity_id - an optional id for an entity in the timeline message to filter on
 * @returns an array of serialized TimelineEvents for the given scope/entity.
 */
export default function useTimeline (scope_id: string, entity_id?: string) {
  let endpoint = `/notebook/timeline-events/scope=${scope_id}`;
  if (entity_id) {
    endpoint += `?entity_id=${ entity_id }`;
  }
  return useQuery<Array<TimelineEvent>>(endpoint);
}

/**
 * Load all of the timeline events across the three possible scopes for the given
 * loan_application_id (the associated user_id, loan_id if any). Optionally filtered
 * by entity_id.
 *
 * @param loan_application_id
 * @param entity_id
 * @returns an array of serialized TimelineEvents.
 */
export function useCombinedTimeline (loan_application_id: string, entity_id?: string) {
  let endpoint = `/notebook/timeline-events/combined=${loan_application_id}`;
  if (entity_id) {
    endpoint += `?entity_id=${ entity_id }`;
  }
  return useQuery<Array<TimelineEvent>>(endpoint);
}
