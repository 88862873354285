import React from "react";
import { Button } from "react-bootstrap";
import useExpandableState from '../../utils/useExpandableState';
import GenericModal from './GenericModal';
import useBankInstitutionDetails from "../../api/queries/disbursement-requests/useBankInstitutionDetails";
import LabelValueGrouping from "../LabelValueGrouping";
import UnmaskedAccountNumber from "../UnmaskedAccountNumber";

type Props = {
  modal: ReturnType<typeof useExpandableState>;
  bankAccount: { _id: string, lastFour: string };
}

export default function BankInstitutionDetailsModal(props: Props){
  const { modal, bankAccount } = props;

  const req = useBankInstitutionDetails(bankAccount._id);

  const footerChildren = <>
    <Button variant='secondary' onClick={modal.hideExpand} >
      Close
    </Button>
  </>;
  return (
    <GenericModal expandable={modal} title={ `Institution Details for **${ bankAccount.lastFour } `} size="sm" footerChildren={footerChildren}>
      { req.error && (<p className="text-danger">
        There was a problem loading the details. Please try again.
      </p>) }
      {
        req.isValidating ? 'Loading…' : (
          req.data ? (
            <LabelValueGrouping data={{
              'Name': req.data.name,
              'Routing Numbers': <ul className="pl-0">{
                req.data.routing_numbers.map((n) => {
                  return <div key={ n } ><UnmaskedAccountNumber number={n} /></div>
                })
              }</ul>
            }}/>
          ) : (
            <div>No details available.</div>
          )
        )
      }
    </GenericModal>
  );

}
