import React from 'react';
import { Button, Row, Col, Container, ButtonGroup } from 'react-bootstrap';
import { MainPanel, PanelWithActions, SecondaryPanel } from '../../ExpandableCard';
import CreateTransactionModal from '../../Modals/CreateTransactionModal';
import InlineError from '../../InlineError';
import { useExpandableState } from '../../../utils';
import { LOAN_STATES } from '../../../utils/constants';
import useActivity from '../../../api/queries/loans/useActivity';
import ActivityTable from './ActivityTable';
import CreateExternalActivityModal from '../../Modals/CreateExternalActivityModal';
import SchedulePaymentModal from '../../Modals/SchedulePaymentModal';
import { isInDrawPeriod, PurchaseMethod } from '../../../utils/transactionUtils';
import ButtonWithSpinner from '../../ButtonWithSpinner';
import { CreateDisbursementModal } from '../../Modals/DisbursementModals';
import usePauseInfo from '../../../api/queries/users/usePauseInfo';
import { CreateRefundRequestModal } from '../../Modals/RefundRequestModal/CreateRefundRequestModal';
import CreateWriteOffModal from '../../Modals/CreateWriteOffModal';

import { useIsSuperAdmin } from '../../../api/queries/admins/useAdmin';

export type Loan = {
  _id: string;
  status: string;
  userId: string;
  slug: string;
};

export default function TransactionHistory ({ loan, purchaseMethodsReq }: { loan: Loan, purchaseMethodsReq: any }) {
  const canRecordTransactions =
    loan.status !== LOAN_STATES.APPROVED ||
    loan.status !== LOAN_STATES.EXPIRED ||
    loan.status !== LOAN_STATES.ACCEPTED;
  const createTxnModal = useExpandableState();
  const schedulePaymentModal = useExpandableState();
  const createBorrowerRefundModal = useExpandableState();
  const createDisbursementModal = useExpandableState();
  const createWriteOffModal = useExpandableState();
  const recordExternalActivityModal = useExpandableState();
  const activityReq = useActivity(loan._id);
  const pausedInfo = usePauseInfo(loan.userId);
  const is_superadmin = useIsSuperAdmin();
  const purchaseMethods: Array<PurchaseMethod> = purchaseMethodsReq.loadSuccess ? purchaseMethodsReq.data.slice().filter((pm: PurchaseMethod) => pm.isActive && pm.isAch) : [];

  const reloadData = () => {
    activityReq.mutate();
  }

  let activityContent;
  if (!activityReq.data) {
    activityContent = <div>Loading&hellip;</div>;
  } else if (activityReq.error) {
    activityContent = <InlineError>Error loading transaction details.</InlineError>;
  } else {
    activityContent = (
      <ActivityTable
        loan={loan}
        activity={activityReq.data || []}
        onChange={reloadData}
      />
    );
  }

  return (
    <PanelWithActions>
      <MainPanel>
        <Container className="pl-0">
          <Row className="mb-5">
            <Col>
              {activityContent}
            </Col>
          </Row>
        </Container>
      </MainPanel>
      <SecondaryPanel>
        {canRecordTransactions && (
          <>
            <ButtonGroup vertical className='d-block mb-3'>
              <ButtonWithSpinner
                variant         = "success"
                loading         = {false}
                block           = {true}
                disabled        = {purchaseMethods.length === 0}
                tooltip         = { purchaseMethods.length === 0 ? 'Borrower has no verified bank accounts' : '' }
                onClick         = {schedulePaymentModal.showExpand}
              >
                Schedule Payment
              </ButtonWithSpinner>
              <SchedulePaymentModal
                modal={schedulePaymentModal}
                loan={loan}
                purchaseMethods={purchaseMethods}
                onSubmit={reloadData}
              />

            {isInDrawPeriod(loan) && (
              <>
                <ButtonWithSpinner
                  variant         = "primary"
                  loading         = {false}
                  block           = {true}
                  disabled        = {pausedInfo.data?.active}
                  tooltip         = {pausedInfo.data?.active ? 'This action is not available because this user is currently paused.' : ''}
                  onClick         = {createDisbursementModal.showExpand}
                >
                  Send Disbursement
                </ButtonWithSpinner>
                {createDisbursementModal.isExpanded && (
                  <CreateDisbursementModal
                    modal={createDisbursementModal}
                    loan={loan}
                    onSubmit={reloadData}
                  />
                )}
              </>
            )}
            </ButtonGroup>

            { is_superadmin &&
              <ButtonGroup vertical className='d-block mb-3'>
                <Button variant="outline-primary" block={true} onClick={recordExternalActivityModal.showExpand}>
                  Record External Activity
                </Button>
                <CreateExternalActivityModal
                  modal={recordExternalActivityModal}
                  loan={loan}
                  onChange={reloadData}
                />
                <ButtonWithSpinner
                  variant         = "outline-primary"
                  loading         = {false}
                  block           = {true}
                  disabled        = {purchaseMethods.length === 0}
                  tooltip         = { purchaseMethods.length === 0 ? 'Borrower has no verified bank accounts' : '' }
                  onClick         = {createBorrowerRefundModal.showExpand}
                >
                  Send Refund
                </ButtonWithSpinner>
                <CreateRefundRequestModal
                  modal={createBorrowerRefundModal}
                  loan={loan}
                  onSubmit={reloadData}
                />
                <Button variant="outline-secondary" block={true} onClick={createWriteOffModal.showExpand}>
                  Record Write-Off
                </Button>
                <CreateWriteOffModal
                  expandable={createWriteOffModal}
                  loan={loan}
                  onSubmit={reloadData}
                />
                <Button variant="outline-secondary" block={true} onClick={createTxnModal.showExpand}>
                  Add Ledger Transaction
                </Button>
                <CreateTransactionModal
                  modal={createTxnModal}
                  loan={loan}
                  onChange={reloadData}
                />
              </ButtonGroup>
            }
          </>
        )}
      </SecondaryPanel>
    </PanelWithActions>
  );
}
