import React from 'react';
import GenericModal from '../GenericModal';
import ModifyLoanForm from './ModifyLoanForm';
import useExpandableState from '../../../utils/useExpandableState';

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  loan: { _id: string, originationDate: string, termDescription: string },
  onSubmit: () => void;
};

export default function ModifyLoanModal(props: Props) {
  const { expand, loan, onSubmit } = props;
  return (
    <GenericModal expandable={expand} title="Modify Loan" size="lg">
      <ModifyLoanForm loan={loan} onHide={expand.hideExpand} onSubmit={onSubmit} />
    </GenericModal>
  );
}
