import React, { useContext } from 'react';

import { Alert } from "react-bootstrap";
import AdminContext, { ROLES } from '../../adminContext';
import usePauseInfo from '../../api/queries/users/usePauseInfo';
import { useExpandableState } from '../../utils';
import UnpauseUserModal from '../Modals/UnpauseUserModal';

type Props = {
  user: { _id: string };
};

export default function PausedUserAlert (props: Props) {
  const { user } = props;
  const adminContext = useContext(AdminContext);
  const pausedInfo = usePauseInfo(user._id);
  const unpauseUserModalState = useExpandableState();

  if (!pausedInfo.data?.active) {
    return null;
  }

  return (
    <>
      <Alert variant='danger' className='mb-0 d-flex'>
        <div>
          { pausedInfo.data.adminName } paused this user due to { pausedInfo.data.reason } <br/> "{ pausedInfo.data.notes }"
        </div>

        { adminContext.adminRole === ROLES.SUPERADMIN &&
          <div className='ml-auto'>
            <u className='cursor-pointer' onClick={() => unpauseUserModalState.showExpand()}>Unpause</u>
          </div>
        }
      </Alert>
      <UnpauseUserModal
        expand={unpauseUserModalState}
        userId={user._id}
        onSubmit={() => window.location.reload()}
      />
    </>
  );
}
