import useQuery from '../../shared/useQuery';

enum CONFIRMATION_ACTOR {
  BORROWER = 'borrower',
  NOTABLE = 'notable',
}

enum ACCELERATION_EVENT_TYPE {
  SALE = 'sale',
  ELA_CANCEL_CUSTOMER = 'ela_cancel_customer',
  ELA_CANCEL_COMPASS = 'ela_cancel_compass',
  HOUSE_NOT_PUT_ON_THE_MARKET = 'house_not_put_on_the_market', // Legacy type
  REPAY_LOAN_EARLY = 'repay_loan_early', // Legacy type
}

enum CLAIMED_REPAYMENT_METHODS {
  ACH   = 'ACH',
  CHECK = 'CHECK',
  NONE  = 'NONE',
  WIRE  = 'WIRE',
}

enum COLLECTIONS_STATUSES {
  NOT_STARTED     = 'NOT_STARTED',
  PERFORMING      = 'PERFORMING',
  NON_PERFORMING  = 'NON_PERFORMING',
  THIRD_PARTY     = 'THIRD_PARTY',
  LEGAL           = 'LEGAL',
  PAYMENT_PLAN    = 'PAYMENT_PLAN',
}

enum REPAYMENT_STATUSES {
  NOT_DUE       = 'NOT_DUE',
  GRACE_PERIOD  = 'GRACE_PERIOD',
  DELINQUENT    = 'DELINQUENT',
  DEFAULTED     = 'DEFAULTED',
}

enum SPECIAL_PROVISIONS {
  DO_NOT_CALL             = 'DO_NOT_CALL',
  LAWYER_REPRESENTATIVE   = 'LAWYER_REPRESENTATIVE',
  BANKRUPTCY              = 'BANKRUPTCY',
  BORROWER_DECEASED       = 'BORROWER_DECEASED',
}

type Response = {
  acceleration_confirmed_on: string;
  acceleration_confirmed_by: CONFIRMATION_ACTOR;
  acceleration_event_type: ACCELERATION_EVENT_TYPE;
  accumulated_late_fees: null;
  aging_bucket: Array<number>;
  amount_owed_cents: number;
  amount_repaid_cents: number;
  claimed_repayment_method: CLAIMED_REPAYMENT_METHODS;
  claimed_repayment_method_confirmed_by: CONFIRMATION_ACTOR;
  collections_status: COLLECTIONS_STATUSES;
  days_past_due_since_maturity: number;
  days_since_invoice: number;
  due_date: string;
  first_notice_date: string;
  governing_region: string;
  initial_invoice_date: string;
  notifications_manually_paused: boolean;
  notifications_paused: boolean;
  notifications_paused_by_override: boolean;
  regional_collections_rules: null;
  regional_late_fee_rules: null;
  repayment_letter_manually_paused: boolean;
  repayment_reminders_manually_paused: boolean;
  repayment_reminders_disablement: {
    disabled: boolean;
    by_override: boolean;
  };
  repayment_status: REPAYMENT_STATUSES;
  special_provisions: SPECIAL_PROVISIONS;
};

export default function useServicing (loanId?: string) {
  return useQuery<Response>(loanId ? `/notebook/loans/${loanId}/servicing` : null);
}
