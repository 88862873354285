import React from 'react';
import { Container } from 'react-bootstrap';
import CardList from '../layouts/CardList';
import InlineError from '../components/InlineError';
import InlineLoadingIndicator from '../components/InlineLoadingIndicator';
import LoanApplicationSummaryCard from '../components/LoanApplicationSummaryCard';
import LoanSummaryCard from '../components/LoanSummaryCard';
import useLoansSummaries, { LoanSummary } from '../api/queries/users/useLoansSummaries';
import useLoanApplications, { LegacyLoanApplication } from '../api/queries/users/useLoanApplications';
import * as _ from 'lodash';

type Props = {
  loanApplicationsReq: ReturnType<typeof useLoanApplications>;
  loansReq: ReturnType<typeof useLoansSummaries>;
};

enum ItemType {
  Loan = 'Loan',
  LoanApplication = 'LoanApplication',
}

type Item = {
  type: ItemType;
  object: LoanSummary | LegacyLoanApplication;
  date: Date;
}

function loanToItem (loan: LoanSummary) {
  return {
    type: ItemType.Loan,
    object: loan,
    date: new Date(loan.createdAt),
  };
}

function loanApplicationToItem (loanApplication: LegacyLoanApplication) {
  return {
    type: ItemType.LoanApplication,
    object: loanApplication,
    date: new Date(loanApplication.createdOn),
  };
}

function getActiveItems (loans: Array<LoanSummary>, loanApplications: Array<LegacyLoanApplication>): Array<Item> {
  const activeLoans = loans.filter(loan => loan.status !== 'EXPIRED' && loan.status !== 'REPAID');
  const activeLoanApplications = loanApplications.filter(loanApplication => loanApplication.status !== 'CLOSED');

  return [
    ...activeLoans.map(loan => loanToItem(loan)),
    ...activeLoanApplications.map(loanApplication => loanApplicationToItem(loanApplication)),
  ];
}

function getInactiveItems (loans: Array<LoanSummary>, loanApplications: Array<LegacyLoanApplication>): Array<Item> {
  const inactiveLoans = loans.filter(loan => loan.status === 'EXPIRED' || loan.status === 'REPAID');
  const closedLoanApplications = loanApplications.filter(loanApplication => loanApplication.status === 'CLOSED');

  return [
    ...inactiveLoans.map(loan => loanToItem(loan)),
    ...closedLoanApplications.map(loanApplication => loanApplicationToItem(loanApplication)),
  ];
}

export default function ApplicationAndLoanSummaryCards (props: Props) {
  const { loanApplicationsReq, loansReq } = props;

  if (loanApplicationsReq.error || loansReq.error) {
    return <InlineError>Error loading loans for user</InlineError>;
  }
  if (!loanApplicationsReq.data || !loansReq.data) {
    return <InlineLoadingIndicator>Loading…</InlineLoadingIndicator>;
  }

  const activeItems = _.orderBy(getActiveItems(loansReq.data, loanApplicationsReq.data), ['date'], ['desc']);
  const inactiveItems = _.orderBy(getInactiveItems(loansReq.data, loanApplicationsReq.data), ['date'], ['desc']);

  return (
    <Container as='section' className='px-0'>
      {
        activeItems.length || inactiveItems.length > 0 ? (
          <CardList>
            {
              activeItems.map(item => (
                item.type === ItemType.Loan ?
                  <LoanSummaryCard key={item.object._id} loan={item.object as LoanSummary} />
                  :
                  <LoanApplicationSummaryCard key={item.object._id} loanApplication={item.object as LegacyLoanApplication} />
              ))
            }
            {
              inactiveItems.map(item => (
                item.type === ItemType.Loan ?
                  <LoanSummaryCard key={item.object._id} loan={item.object as LoanSummary} />
                  :
                  <LoanApplicationSummaryCard key={item.object._id} loanApplication={item.object as LegacyLoanApplication} />
              ))
            }
          </CardList>
        ) : (
          <div>No loans</div>
        )
      }
    </Container>
  );
}
