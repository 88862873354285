import React, { ReactElement } from 'react';
import cx from 'classnames';
import Modal from 'react-bootstrap/Modal';
import useExpandableState from '../../utils/useExpandableState';

import styles from './GenericModal.module.scss';

type Props = {
  expandable: ReturnType<typeof useExpandableState>;
  title: string;
  size?: React.ComponentProps<typeof Modal>['size'];
  children: React.ReactNode;
  fullWidth?: boolean;
  className?: string;
  bodyClass?: string;
  showFooter?: boolean;
  footerChildren?: ReactElement;
  onClose?: () => void;
};

function GenericModal(props: Props) {
  const { expandable, title, size, children, fullWidth, className, bodyClass, footerChildren, onClose } = props;
  return (
    <Modal
      show={expandable.isExpanded}
      onHide={onClose || expandable.hideExpand}
      size={size}
      className={cx(className, fullWidth && styles.fullWidth)}
    >
      {expandable.isExpanded && (
        <>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body className={bodyClass}>{children}</Modal.Body>
          {
            footerChildren &&
            <Modal.Footer className="p-2">
              {footerChildren}
            </Modal.Footer>
          }
        </>
      )}
    </Modal>
  );
}

export default GenericModal;
