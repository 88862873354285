import React from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../Form/Form';
import Field from '../Form/Field';
import PhoneField from '../Form/PhoneField';
import SubmitError from '../Form/SubmitError';
import useAdminAPICall from '../../utils/useAdminAPICall';
import useExpandableState from '../../utils/useExpandableState';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { TitleDetails } from '../../api/queries/loans/useTitle';
import GenericModal from './GenericModal';
import formatPhone from '../../utils/formatPhone';

type FieldValues = {
  companyName: string;
  agentName: string;
  agentEmail: string;
  agentPhone: string;
};

type Props = {
  expandable: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
  loanId: string;
  titleDetails?: TitleDetails;
};

export default function EditTitleDetailsModal (props: Props) {
  const { loanId, titleDetails, expandable, onSubmit } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loans/${loanId}/title`,
    method: 'PUT',
  });

  async function handleSubmit(data: FieldValues) {
    await callAPI({ data: {
      companyName: data.companyName ? data.companyName : null,
      agentName: data.agentName ? data.agentName : null,
      agentEmail: data.agentEmail,
      agentPhone: data.agentPhone ? data.agentPhone.replace(/\D/g, '') : null,
    } });
    onSubmit();
    expandable.hideExpand();
  }

  const schema = yup.object({
    companyName: yup.string(),
    agentName: yup.string(),
    agentEmail: yup.string().email('Please provide a valid email address'),
    agentPhone: yup.string().test('is-phone', 'Please provide a valid phone number', value => !value || value.replace(/\D/g, '').length === 10),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      companyName: titleDetails?.companyName ?? '',
      agentName: titleDetails?.agentName ?? '',
      agentEmail: titleDetails?.agentEmail ?? '',
      agentPhone: formatPhone(titleDetails?.agentPhone),
    },
    resolver: yupResolver(schema),
  });

  const { formState: { isSubmitting } } = methods;

  return (
    <GenericModal expandable={expandable} title={'Add title details'} size="lg">
      <Form {...methods} onSubmit={handleSubmit}>
        <div className='border-bottom pb-2'>
          <Field label='Title Company Name' name='companyName' placeholder='Enter title company name'/>
          <Field label='Title Agent Name' name='agentName' placeholder='Enter title agent name'/>
          <Field label='Title Agent Email' name='agentEmail' placeholder='Enter title agent email'/>
          <PhoneField label='Title Agent Phone Number' name='agentPhone' placeholder='Enter title agent phone number'/>

          <SubmitError/>
        </div>
        <div className="d-flex justify-content-end mt-4">
          <Button variant="secondary" onClick={expandable.hideExpand} className="mr-2">
            Cancel
          </Button>
          <ButtonWithSpinner variant="primary" type="submit" loading={isSubmitting}>
            Confirm
          </ButtonWithSpinner>
        </div>
      </Form>
    </GenericModal>
  );
}
