import React from 'react';
import { Button } from 'react-bootstrap';

export default function OutboundButton ({ children, ...props }: React.ComponentPropsWithoutRef<typeof Button>) {
  return (
    <Button
      {
        ...{
          target: "_blank",
          rel: "noopener noreferrer",
        }
      }
      variant="outline-dark"
      { ...props }
    >
      { children }
    </Button>
  );
}
