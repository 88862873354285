import useQuery from "../../shared/useQuery";

interface PagedData<ItemT> {
  items: Array<ItemT>;
  totalItems: number;
  page: number;
  maxPages: number;
};

export type InvestorRemittanceSummary = {
  _id: string;
  created_at: string;
  updated_at: string;

  portfolio_key: string;
  remittance_date: string;
  loan_id: string;
  loan_slug: string;

  report_id: string;

  investor_collected_interest_delta: number;
  investor_collected_principal_delta: number;
  collected_interest_delta: number;
  collected_principal_delta: number;
  collected_late_fees_delta: number;
  collected_nsf_fees_delta: number;
  collected_origination_fees_delta: number;
  notable_servicing_fee_delta: number;
  tape_net_transfer: number;
}

interface PendingRemitResponse extends PagedData<InvestorRemittanceSummary> {
  earliestDate: string | null;
  latestDate: string | null;
  columnTotals: {
    investor_collected_principal_delta: number;
    investor_collected_interest_delta: number;
    collected_origination_fees_delta: number;
    notable_servicing_fee_delta: number;
    tape_net_transfer: number;
  };
}

export function usePendingInvestorRemittances (investorKey: string, params: { report_date?: string, page?: number } = {}) {
  return useQuery<PendingRemitResponse>(`/notebook/investors/${ investorKey }/remittances/pending?report_date=${ params.report_date || '' }&page=${ params.page || '' }`);
}
