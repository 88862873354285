import useQuery from '../../shared/useQuery';
import { PROGRAMS } from '../types';

export type PartnerSummary = {
  _id: string;
  active: boolean;
  slug: string;
  program: { key: PROGRAMS, name: string };
  shortName: string;
  loanApplicationsCount: number;
  loansCount: number;
  waitListEnabled: boolean;
  cardStatus: string;
  agentCount: number;
  agentPricingPaymentType: string;
};

type Response = PartnerSummary;

export function usePartners () {
  return useQuery<Array<Response>>('/notebook/partners');
}
