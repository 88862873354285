import useQuery from '../../shared/useQuery';

export type Address = {
  _id: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  zip: string;
};

type Response = Array<Address>;

export default function useAddresses (userId?: string) {
  return useQuery<Response>(userId ? `/notebook/users/${userId}/addresses` : null);
}
