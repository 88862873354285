import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import InlineButton from '../InlineButton';
import { User } from '../../api/queries/users/useUser';
import EditContactInfoModal from '../Modals/EditContactInfoModal';
import useExpandableState from '../../utils/useExpandableState';
import { Link } from 'react-router-dom';
import humanizeString from 'humanize-string';
import styles from './ProfileCards.module.scss';
import { LegacyLoanApplication } from '../../api/queries/users/useLoanApplications';
import formatPhone from '../../utils/formatPhone';

type ContactInfoCardProps = {
  user?: User;
  loanApplication?: LegacyLoanApplication;
  className?: string;
};

export default function ContactInfoCard ({ user, loanApplication, className }: ContactInfoCardProps) {
  const editUserModal = useExpandableState();

  if (!user || user.is_temporary || !loanApplication) { return null; }

  return (<>
    <Card className={className}>
      <Card.Header>
        Contact Info
        {
          !user.deletedAt ?
            <InlineButton onClick={ editUserModal.showExpand } className='float-right'>
              Edit
            </InlineButton>
            :
            null
        }
      </Card.Header>
      <Card.Body className='p-0'>
        <ListGroup variant='flush'  className='rounded'>
          <ListGroup.Item>
            <dt>Name</dt>
            <dd className='d-flex'>
              <div>
                <Link className={`w-100 ${styles.link}`} to={`/users/${user._id}`}>{user.firstName} {user.lastName}</Link>
                { user.deletedAt && <span className='text-muted'> (archived)</span> }
              </div>
              { loanApplication.isPOA && <div className='text-right w-100'><span className='text-info'>POA</span></div> }
            </dd>
          </ListGroup.Item>
          { loanApplication.isPOA &&
            <ListGroup.Item>
              <dt>Relationship to Borrower</dt>
              <dd>{humanizeString(loanApplication.ownerRelationship)}</dd>
            </ListGroup.Item>
          }
          <ListGroup.Item>
            <dt>Mobile</dt>
            <dd>{formatPhone(user.phone)}</dd>
          </ListGroup.Item>
          <ListGroup.Item>
            <dt>Email</dt>
            <dd>{user.email}</dd>
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </Card>
    <EditContactInfoModal
      expandable={ editUserModal }
      user={ user }
    />
  </>);
}
