import React from 'react';
import InlineWarning from './InlineWarning';
import AdminContext, { ROLES } from '../adminContext';

export default function SuperadminContextRequired (props: { children: React.ReactNode }) {
  const adminContext = React.useContext(AdminContext);
  if (adminContext.adminRole === ROLES.SUPERADMIN) {
    return <>{props.children}</>;
  }
  return <InlineWarning>Superadmin role is required.</InlineWarning>
}
