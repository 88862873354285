import React from 'react';
import { Badge } from 'react-bootstrap';

export default function POABadge () {
  return (
    <Badge pill variant='info'>
      POA
    </Badge>
  );
}
