import React from 'react';
import GenericModal from '../GenericModal';
import DisbursementDetailForm from './DisbursementDetailForm';
import useExpandableState from '../../../utils/useExpandableState';
import { DisbursementRequest, Loan } from '../../../utils/transactionUtils';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import { DISBURSEMENT_TYPE, VENDOR_TYPE } from '../../../api/queries/loans/useDisbursementLimits';

type Props = {
  loan: Loan;
  disbursement: DisbursementRequest;
  expand: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
};

export function ModifyDisbursementModal(props: Props) {
  const { loan, disbursement, expand, onSubmit } = props;

  const { callAPI, loadPending } = useAdminAPICall({
    endpoint: `/notebook/disbursement-requests/${disbursement._id}/update`,
    method: 'PUT',
  });

  const handleSubmit = async (data: any) => {
    await callAPI({ data });

    onSubmit();
    expand.hideExpand();
  };

  return (
    <GenericModal expandable={expand} title="Disbursement Details" size="lg">
      <DisbursementDetailForm
        loanId={loan._id}
        availableFunds={loan.availableFunds}
        quickDepositAvailableFunds={loan.quickDepositAvailableFunds}
        disbursement={disbursement}
        onSubmit={handleSubmit}
        onCancel={expand.hideExpand}
        vendorType={disbursement.vendor_type as VENDOR_TYPE}
        payType={disbursement.type as DISBURSEMENT_TYPE}
        isQuickDeposit={disbursement.quick_deposit}
        loading={loadPending}
      />
    </GenericModal>
  );
}
