import { Method } from 'axios';
import React from 'react';
import { useForm } from "react-hook-form";
import { Button, Modal } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import useAdminAPICall from '../../utils/useAdminAPICall';
import useExpandableState from '../../utils/useExpandableState';
import Form from '../Form/Form';
import Field from '../Form/Field';
import SubmitError from '../Form/SubmitError';
import S3FileUploadField, { FILE_TYPES } from '../Form/S3FileUploadField';
import ButtonWithSpinner from '../ButtonWithSpinner';

type Props = {
  modal: ReturnType<typeof useExpandableState>;
  bankAccount?: {
    _id: string;
    routingNumber: string;
    accountNumber: string;
    accountType: string;
    voidedCheckPhoto: string;
  };
  loan: { _id: string };
  onChange: () => void;
}

type FieldValues = {
  routingNumber: string;
  accountNumber: string;
  repeatAccountNumber: string;
  accountType: string;
  voidedCheckPhoto: {
    s3Filename: string | null,
    originalFilename: string | null,
  };
}

export default function AchModal ({ modal, bankAccount, loan, onChange }: Props) {
  const id  = bankAccount ? bankAccount._id : null;
  const apiData = id
    ? { endpoint: `/notebook/loans/${loan._id}/ach/${id}`, method: 'PUT' as Method }
    : { endpoint: `/notebook/loans/${loan._id}/ach`, method: 'POST' as Method };
  const achApi = useAdminAPICall(apiData);

  const schema = yup.object().shape({
    routingNumber: yup.string().matches(/^\d{9}$/, 'Please enter a valid routing number (9 digits)'),
    accountNumber: yup.string().required('Please enter a valid account number'),
    repeatAccountNumber: yup.string().equals([yup.ref('accountNumber')], "Doesn't match account number"),
    accountType: yup.string().oneOf(['checking', 'savings'], { message: 'Please select an account type' }),
    voidedCheckPhoto: yup.object({
      s3Filename: yup.string().nullable(),
      originalFilename: yup.string().nullable(),
    }),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      routingNumber: bankAccount ? bankAccount.routingNumber : '',
      accountNumber: bankAccount ? bankAccount.accountNumber : '',
      repeatAccountNumber: bankAccount ? bankAccount.accountNumber : '',
      accountType: bankAccount ? bankAccount.accountType : 'checking',
      voidedCheckPhoto: {
        s3Filename: bankAccount ? bankAccount.voidedCheckPhoto : null,
        originalFilename: null,
      },
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting }, reset } = methods;

  const onSubmit = async ({ routingNumber, accountNumber, accountType, voidedCheckPhoto }: FieldValues) => {
    await achApi.callAPI({ data: { routingNumber, accountNumber, accountType, voidedCheckPhoto: ( voidedCheckPhoto && voidedCheckPhoto.s3Filename) || null } });
    onHide();
    onChange && onChange();
  };

  const onHide = () => {
    modal.hideExpand();
    reset();
  };

  return (
    <Modal show={modal.isExpanded} onHide={onHide}>
      <Form {...methods} onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{bankAccount ? 'Edit' : 'Add'} Bank Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Field label='Routing Number' name='routingNumber' placeholder='Add Routing Number' controlProps={{ inputMode: 'numeric' }} required/>
          <Field label='Account Number' name='accountNumber' placeholder='Add Account Number' required/>
          <Field label='Repeat Account Number' name='repeatAccountNumber' placeholder='Repeat Account Number' />

          <Field controlProps={{ className: 'FieldSelect', as: 'select' }} label='Account Type' name='accountType' required>
            <option value="checking">Checking</option>
            <option value="savings">Savings</option>
          </Field>

          <S3FileUploadField
            name="voidedCheckPhoto"
            label="Voided Check (optional)"
            bucket='user-docs'
            s3_path='concierge/bank-account-voided-checks'
            type={ FILE_TYPES.IMAGE }
            subtitle='If a check is not included with this entry then there must be a verbal or written record of verification of the bank account.'
          />

          <SubmitError/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onHide} disabled={isSubmitting}>
            Cancel
          </Button>
          <ButtonWithSpinner type='submit' variant='primary' loading={isSubmitting}>
            {bankAccount ? 'Save' : 'Add Bank Account'}
          </ButtonWithSpinner>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
