import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SystemLayout from '../../Layouts/SystemLayout';
import { usePartner } from '../../../api/queries/partners/usePartner';
import ShowPartnerLayout from '../../Layouts/ShowPartnerLayout';
import { Alert, Button, Card, Col, Form, FormControl, InputGroup, Row, Spinner, Table } from 'react-bootstrap';
import { Partner } from '../../../api/queries/partners/usePartner';
import { useExpandableState } from '../../../utils';
import AdminContext from '../../../adminContext';
import useAgents, { Agent } from '../../../api/queries/agents/useAgents';
import InlineButton from '../../../components/InlineButton';
import querystring from 'qs';
import UploadAgentFileModal from '../../../components/Modals/UploadAgentFileModal';
import Pagination from '../../../components/Pagination';
import RePullAgentsFromRemote from '../../../components/Modals/RePullAgentsFromRemote';
import { useForm } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import styles from './PartnerShowAgentsPage.module.scss';
import PushAgentsToKustomerModal from '../../../components/Modals/PushAgentsToKustomerModal';
import useUnsyncedAgents from '../../../api/queries/agents/useUnsyncedAgents';
import PushAgentsToHubspotModal from '../../../components/Modals/PushAgentsToHubspotModal';
import useMutateAgentLandingPage from '../../../api/mutations/agents/useMutateAgentLandingPage';
import AgentSlugModal from '../../../components/Modals/AgentSlugModal';
import useMutateEnableAllLandingPages from '../../../api/mutations/partners/useMutateEnableAllLandingPages';
import ErroredAgentsModal from '../../../components/Modals/ErroredAgentsModal';
import EnableAllLandingPagesModal from '../../../components/Modals/EnableAllLandingPagesModal';
import useLatestKustomerJob, { DelayedJob } from '../../../api/queries/partners/delayed-jobs/useLatestKustomerJob';
import useLatestHubspotJob from '../../../api/queries/partners/delayed-jobs/useLatestHubspotJob';
import { SWRResponse } from 'swr';
import useLatestCsvImportJob from '../../../api/queries/partners/delayed-jobs/useLatestCsvImportJob';
import useLatestPullAgentsFromRemoteJob
  from '../../../api/queries/partners/delayed-jobs/useLatestPullAgentsFromRemoteJob';
import useMutateAgentHubspotSync from '../../../api/mutations/agents/useMutateAgentHubspotSync';

export default function PartnerShowAgentsPage () {
  const { partnerId } = useParams<{ partnerId: string }>();

  const [syncAgentHubspotError, setSyncAgentHubspotError] = useState<string | null>(null);

  const { data: partner } = usePartner(partnerId);
  if (!partner) {
    return null;
  }

  return (
    <SystemLayout selectedTab='partners' heading={partner.short_name}>
      <ShowPartnerLayout partner={partner} selectedTab='agents'>
        <section className='mb-4 mt-4'>
          <Row>
            <Col>
              <Agents partner={partner} syncAgentHubspotError={syncAgentHubspotError} setSyncAgentHubspotError={setSyncAgentHubspotError}/>
            </Col>
          </Row>
        </section>
      </ShowPartnerLayout>
    </SystemLayout>
  );
}

function installPollingFor (job?: DelayedJob, request?: SWRResponse) {
  if (job?.status === 'processing' && request) {
    // Poll every X seconds while we wait for the job to complete
    const interval = setInterval(() => request.mutate(), 10_000);
    return () => clearInterval(interval);
  }
}

function DelayedJobError ({ delayedJob }: { delayedJob?: DelayedJob }) {
  if (delayedJob?.status !== 'failed') {
    return null;
  }

  const toHumanName = {
    'SyncPartnerAgentsKustomerJob': 'Push to Kustomer',
    'SyncPartnerAgentsHubspotJob': 'Push to Hubspot',
    'ProcessAgentCsvJob': 'Import Agents',
    'UpsertAgentsFromRemoteJob': 'Import Agents',
  } as any;

  return <>
    <Alert variant="danger">
      { `Latest ${toHumanName[delayedJob.handler] || delayedJob.handler} job failed with error "${delayedJob.lastError}"` }
    </Alert>
  </>;
}

function Agents ({ partner, syncAgentHubspotError, setSyncAgentHubspotError }: { partner: Partner, syncAgentHubspotError: string | null, setSyncAgentHubspotError: (error: string | null) => void }) {
  const uploadAgentFile = useExpandableState();
  const rePullAgents = useExpandableState();
  const pushAgentsToKustomer = useExpandableState();
  const pushAgentsToHubspot = useExpandableState();
  const adminContext = useContext(AdminContext);
  const location = useLocation();
  const parsedParams = querystring.parse(location.search, { ignoreQueryPrefix: true });
  const page = parsedParams.page ? parseInt(parsedParams.page as string) : 1;
  const searchText = parsedParams.q ? parsedParams.q as string : '';
  const agentsRequest = useAgents(partner._id, page, searchText);
  const unsyncedAgentsRequest = useUnsyncedAgents(partner._id);
  const latestKustomerJobRequest = useLatestKustomerJob(partner._id);
  const latestHubspotJobRequest = useLatestHubspotJob(partner._id);
  const latestCsvImportJobRequest = useLatestCsvImportJob(partner._id);
  const latestPullAgentsFromRemoteJobRequest = useLatestPullAgentsFromRemoteJob(partner._id);

  const latestKustomerJob = useMemo(() => (latestKustomerJobRequest?.data), [latestKustomerJobRequest]);
  const latestHubspotJob = useMemo(() => (latestHubspotJobRequest?.data), [latestHubspotJobRequest]);
  const latestCsvImportJob = useMemo(() => (latestCsvImportJobRequest?.data), [latestCsvImportJobRequest]);
  const latestPullAgentsFromRemoteJob = useMemo(() => (latestPullAgentsFromRemoteJobRequest?.data), [latestPullAgentsFromRemoteJobRequest]);

  const navigate = useNavigate();
  const { register, setValue, getValues } = useForm<{ searchText: string }>();

  useEffect(() => {
    setValue('searchText', searchText);
  }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => installPollingFor(latestKustomerJob, latestKustomerJobRequest), [latestKustomerJob, latestKustomerJobRequest]);
  useEffect(() => installPollingFor(latestHubspotJob, latestHubspotJobRequest), [latestHubspotJob, latestHubspotJobRequest]);
  useEffect(() => installPollingFor(latestCsvImportJob, latestCsvImportJobRequest), [latestCsvImportJob, latestCsvImportJobRequest]);
  useEffect(() => installPollingFor(latestPullAgentsFromRemoteJob, latestPullAgentsFromRemoteJobRequest), [latestPullAgentsFromRemoteJob, latestPullAgentsFromRemoteJobRequest]);

  if (!agentsRequest.data) {
    return null;
  }
  if (!partner.agentSupport) {
    return <>Partner does not support agents.</>;
  }

  const { agents, pages: maxPages } = agentsRequest.data;
  const allowPushingToKustomer = unsyncedAgentsRequest.data &&
    unsyncedAgentsRequest.data.count > 0 &&
    latestKustomerJob?.status !== 'processing';

  const allowPushingToHubspot = partner.hubspotAllowSync &&
    latestHubspotJob?.status !== 'processing';

  const allowCsvImport = latestCsvImportJob?.status !== 'processing';
  const allowPullAgentsFromRemote = latestPullAgentsFromRemoteJob?.status !== 'processing';

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    navigate(`?q=${getValues('searchText')}&page=1`);
  }

  const clearSearch = () => {
    setValue('searchText', '');
    navigate('');
  }

  return <>
    <DelayedJobError delayedJob={latestKustomerJob}/>
    <DelayedJobError delayedJob={latestHubspotJob}/>
    <DelayedJobError delayedJob={latestCsvImportJob}/>
    <DelayedJobError delayedJob={latestPullAgentsFromRemoteJob}/>

    <Card>
      <Card.Header>
        Agents
        {
          adminContext.canEditSystemSettings &&
          (<div className='float-right'>
            <InlineButton disabled={!allowPushingToHubspot} onClick={pushAgentsToHubspot.showExpand} className='mr-3'>
              Push to Hubspot { latestHubspotJob?.status === 'processing' && <Spinner animation="border" role="status" size='sm'/> }
            </InlineButton>
            <InlineButton disabled={!allowPushingToKustomer} onClick={pushAgentsToKustomer.showExpand} className='mr-3'>
              Push to Kustomer { latestKustomerJob?.status === 'processing' && <Spinner animation="border" role="status" size='sm'/> }
            </InlineButton>
            {
              partner.hasRemoteAgentList ?
                (
                  <InlineButton disabled={!allowPullAgentsFromRemote} onClick={rePullAgents.showExpand}>
                    Re-pull Agents from Remote { latestPullAgentsFromRemoteJob?.status === 'processing' && <Spinner animation="border" role="status" size='sm'/> }
                  </InlineButton>
                )
                :
                (
                  <InlineButton disabled={!allowCsvImport} onClick={uploadAgentFile.showExpand}>
                    Upload Agent File { latestCsvImportJob?.status === 'processing' && <Spinner animation="border" role="status" size='sm'/> }
                  </InlineButton>
                )
            }
          </div>)
        }
      </Card.Header>

      <Card.Body>
        <div className='mb-4'>
          <Form onSubmit={handleSubmit}>
            <InputGroup>
              <FormControl
                placeholder='Search by name, email, phone'
                {...register('searchText')}
              />
              <InputGroup.Append>
                <Button variant="secondary" onClick={clearSearch}><CloseIcon /></Button>
              </InputGroup.Append>
            </InputGroup>
          </Form>
        </div>
        <div>
          <AgentsTable
            agents={ agents }
            partner={ partner }
            onChange={() => { agentsRequest.mutate(); setSyncAgentHubspotError(null); }}
            setSyncAgentHubspotError={setSyncAgentHubspotError}
            syncAgentHubspotError={syncAgentHubspotError}
          />
        </div>
        <div className='d-flex justify-content-center mt-3'>
          <Pagination page={page} maxPages={maxPages} onPageChange={(newPage) => navigate(`?q=${searchText}&page=${newPage}`)}/>
        </div>
      </Card.Body>

      <UploadAgentFileModal
        expand={uploadAgentFile}
        partner={partner}
        onSubmit={
          async () => {
            await agentsRequest.mutate();
            await latestCsvImportJobRequest.mutate();
          }
        }
      />
      <RePullAgentsFromRemote
        expand={rePullAgents}
        partner={partner}
        onSubmit={
          async () => {
            await agentsRequest.mutate();
            await latestPullAgentsFromRemoteJobRequest.mutate();
          }
        }
      />
      <PushAgentsToKustomerModal
        expand={pushAgentsToKustomer}
        partner={partner}
        onSubmit={
          async () => {
            await agentsRequest.mutate();
            await latestKustomerJobRequest.mutate();
          }
        }
        agentsCount={unsyncedAgentsRequest.data?.count}
      />
      <PushAgentsToHubspotModal
        expand={pushAgentsToHubspot}
        partner={partner}
        onSubmit={
          async () => {
            await agentsRequest.mutate();
            await latestHubspotJobRequest.mutate();
          }
        }
      />
    </Card>
  </>;
}

function AgentsTable ({ agents, partner, onChange, syncAgentHubspotError, setSyncAgentHubspotError }: { agents: Array<Agent>, partner: Partner, onChange: () => void, syncAgentHubspotError: string | null, setSyncAgentHubspotError: (error: string | null) => void }) {
  const { mutate, data } = useMutateEnableAllLandingPages(partner._id);
  const erroredAgentsModal = useExpandableState();
  const enableAllLandingPagesModal = useExpandableState();

  const handleEnableAllLandingPages = async () => {
    const { data } = await mutate();
    onChange();
    if (data?.erroredAgents.length) {
      erroredAgentsModal.showExpand();
    }
  }

  return <>
    {syncAgentHubspotError && <Alert variant="danger">{ syncAgentHubspotError }</Alert>}
    <Table className={styles.agentsTable}>
      <thead>
      <tr>
        <th>Name</th>
        <th>Primary Phone</th>
        <th>Secondary Phone</th>
        <th>Email</th>
        <th>External Id</th>
        <th>Sync to Hubspot</th>
        <th>Active</th>
        { partner.agentLandingPageEnabled &&
          <th>
            <div className='text-nowrap'>
              Landing Page
              <InlineButton className='font-weight-normal ml-2' onClick={enableAllLandingPagesModal.showExpand}>
                Enable all
              </InlineButton>
            </div>
          </th>
        }
      </tr>
      </thead>

      <tbody>
      {
        agents.map(agent => (
          <tr key={agent._id} className={styles.agentRow}>
            <td>{agent.firstName} {agent.lastName}</td>
            <td>{agent.primaryPhone}</td>
            <td>{agent.secondaryPhone}</td>
            <td>{agent.email}</td>
            <td>{agent.externalId}</td>
            <td>
              <AgentHubspotSyncSwitch
                agent={ agent }
                onChange={onChange}
                onError={setSyncAgentHubspotError}
              />
            </td>
            <td>{agent.active ? 'Yes' : 'No'}</td>
            { partner.agentLandingPageEnabled &&
              <td>
                <AgentLandingPageCell
                  agent={ agent }
                  partner={ partner }
                  onChange={onChange}
                />
              </td>
            }
          </tr>
        ))
      }
      </tbody>
    </Table>

    <ErroredAgentsModal
      expand={erroredAgentsModal}
      erroredAgents={data?.erroredAgents}
    />

    <EnableAllLandingPagesModal
      expand={enableAllLandingPagesModal}
      onConfirm={handleEnableAllLandingPages}
    />
  </>
}

function AgentLandingPageCell ({ agent, partner, onChange }: { agent: Agent, partner: Partner, onChange: () => void}) {
  const agentSlugModal = useExpandableState();

  return (
    <>
      <div className='d-flex'>
        <AgentLandingPageSwitch
          agent={ agent }
          onChange={ onChange }
          onEnable={ agentSlugModal.showExpand }
        />
        {agent.landingPageEnabled && <AgentLandingPageSlug agent={ agent } onClick={agentSlugModal.showExpand} /> }
      </div>

      <AgentSlugModal
        agent={ agent }
        partner={ partner }
        expand={ agentSlugModal }
        onChange={ onChange }
      />
    </>
  );
}

function AgentLandingPageSwitch ({ agent, onChange, onEnable }: { agent: Agent, onChange: () => void, onEnable: () => void }) {
  const { mutate: mutateLandingPage } = useMutateAgentLandingPage(agent._id);

  const handlePageEnabledChange = async () => {
    if (agent.landingPageEnabled) {
      await mutateLandingPage({ landingPageEnabled: false });
    } else {
      onEnable();
    }
    onChange();
  }

  return (
    <Form.Check
      id={ `${ agent._id }_landing_page` }
      type='switch'
      className={styles.agentSwitch}
      checked={ agent.landingPageEnabled }
      onChange={handlePageEnabledChange}
      label=''
    />
  );
}

function AgentLandingPageSlug ({ agent, onClick }: { agent: Agent, onClick?: () => void}) {
  return (
    <span className={styles.agentSlug} onClick={onClick}>
      { agent.slug ? `/${agent.slug}` : '--' }
    </span>
  );
}

function AgentHubspotSyncSwitch ({ agent, onChange, onError }: { agent: Agent, onChange: () => void, onError: (error: string | null) => void }) {
  const { mutate: mutateHubspotSync } = useMutateAgentHubspotSync(agent._id);

  const handleHubspotSyncChange = async () => {
    try {
      await mutateHubspotSync({ hubspotAllowSync: !agent.hubspotAllowSync});
      onChange();
    } catch (error: any) {
      onError(`Syncing ${agent.firstName} ${agent.lastName} to Hubspot failed. ${error?.response?.data?.message}`);
    }
  }

  return (
    <Form.Check
      id={ `${ agent._id }_hubspot_sync` }
      type='switch'
      className={styles.agentSwitch}
      checked={ agent.hubspotAllowSync }
      onChange={handleHubspotSyncChange}
      label=''
    />
  );
}
