import * as _ from 'lodash';
import React from 'react';

import { Badge } from 'react-bootstrap';
import { useExpandableState } from '../utils';
import LabelValueGrouping from './LabelValueGrouping';
import ExplainerPopover from './ExplainerPopover';
import { LegacyLoanApplication } from '../api/queries/users/useLoanApplications';

const PEOPLE_FRIENDLY_NAMES = {
  APPLICANT_NEEDS_HELP        : ['Applicant needs help', 'Fails when the borrower indicates they need help completing their application.'],
  APPLICANT_IS_NOT_A_TRUSTEE  : ['Applicant is not a trustee', 'Fails when the listed property is owned by a trust and the applicant is not a trustee.'],
  AVM_MAX_USED                : ['Below minimum because AVM used', 'Fails when the system calculates available equity below the program minimum allowed principal because it used the AVM instead of applicant-provided listing price.'],
  AVM_NOT_FOUND               : ['Insufficient equity with larger haircut', 'Fails when the Applicant has sufficient equity using the listing price they provide and the default haircut, but the system calculated insufficient equity after using a larger haircut because the AVM could not be found.'],
  AVM_NOT_FOUND_COUNTEROFFER  : ['Insufficient equity with larger haircut, Counter Offer possible', 'Fails when the Applicant has a counter offer possible using the listing price they provide and the default haircut, but the system calculated insufficient equity after using a larger haircut because the AVM could not be found.'],
  AVM_USED_COUNTEROFFER       : ['Counter Offer possible when AVM used', 'Fails when the applicant requests a larger principal than they have equity for, and the AVM was used instead of applicant-provided listing price.'],
  COUNTER_OFFER               : ['Counter Offer possible', 'Fails when the applicant requests a larger principal than they have equity for, but the system calculates they have enough equity for a smaller principal.'],
  CREDIT_REPORT_NOT_FOUND     : ['Credit Report not found', 'Fails when the system was unable to find a credit report for the Applicant (using TheNumber).'],
  CREDIT_REPORT_FRAUD_FLAGS   : ['Fraud Alert Flags found', 'Fails when Credit Report includes fraud alerts associated with the applicant'],
  DUPLICATE_APPLICATION       : ['Duplicate listing', 'Fails when the application is for a listing already associated with a loan or application from another user.'],
  GLOBAL_CREDIT_LIMIT         : ['Global credit limit', 'Fails when loan would cause global outstanding credit to exceed program limit.'],
  IDENTITY_VERIFICATION       : ['Identity verification', 'Fails when the KYC service (Marqeta) was unable to confirm the Applicant’s identity.'],
  INSUFFICIENT_DATA_FOR_FICO  : ['Insufficient data for FICO', 'Fails when the credit report returns a FICO score of “+”, indicating insufficient credit history to calculate a FICO. May also indicate a deceased person.'],
  INSUFFICIENT_EQUITY         : ['Insufficient equity', 'Fails when the Applicant does not have enough equity in the property to satisfy the program rules for the desired principal.'],
  INVALID_CONTACT             : ['Invalid contact information', 'Fails when the Applicant does not have a phone number.'],
  INVALID_TERMS               : ['Invalid Loan terms', 'Fails if application terms exceed Notable or program constraints (likely a system error or potentially malicious action).'],
  LOAN_APPLICATION_FROM_AGENT : ['Loan Application from Compass Agent', 'Fails when Applicant is a Compass Agent.'],
  LOW_FICO                    : ['Low FICO score', 'Fails when Applicant has a FICO score below the program-specified threshold.'],
  MANUAL_REVIEW_FICO_BAND     : ['FICO Band: Manual Review', 'Applicant\'s FICO Score falls within range requiring manual review.'],
  MAX                         : ['Principal too high', 'Fails when requested principal is above the maximum principal allowed by the program.'],
  MIN                         : ['Principal too low', 'Fails when requested principal is below the minimum principal allowed by the program.'],
  MISSING_ADDRESS_HASH        : ['Missing address hash', 'Fails when the system is unable to validate the listing address when checking for duplicate applications.'],
  MSA_MISSING                 : ['Missing MSA', "Fails when we can't determine the MSA for the listing address zip code."],
  ONE_ACTIVE_ONLY             : ['One active Loan only', 'Fails when Applicant already has an active loan.'],
  OUTSTANDING_BALANCE         : ['User inactive', 'Fails if Applicant’s user account is suspended (paymentsDisabled=true).'],
  OUT_OF_BOUNDS_REGION        : ['Out-of-bounds region', 'Fails if Applicant address is from a region not enabled for the program.'],
  OUTSIDE_OF_US               : ['Outside of the US', 'Fails if Applicant address is outside the US.'],
  POWER_OF_ATTORNEY_REVIEW    : ['Power of Attorney review required', 'Fails if Applicant claims Power of Attorney for a non-spousal relation.'],
  PROPERTY_OWNED_BY_TRUST     : ['Property is not owned by a Trust', 'Fails if the borrower indicated that the property is owned by a Trust.'],
  RECENT_BANKRUPTCY           : ['Recent bankruptcy', 'Fails if Applicant has a bankruptcy still pending or resolved too recently as defined by the program (reported by TheNumber).'],
}

type Details = {
  manualCode: boolean;
  failed: boolean;
  computedFacts: Record<string, any>;
};

type FactorItem = {
  factor: string;
  details: Details;
};

function UnderwritingStatusBadge (props: Pick<Details, 'manualCode' | 'failed'>) {
  if(props.manualCode) {
    return (
      <ExplainerPopover explanation={'Cannot be determined as pass or fail by the system.'}>
        <Badge variant={ 'secondary'}>
            unknown
        </Badge>
      </ExplainerPopover>
    );
  }
  return (
    <Badge variant={ props.failed ? 'danger' : 'success' }>
      {
        props.failed ? 'failed' : 'passed'
      }
    </Badge>
  )
}

function UnderwritingFactorItem ({ factor, details }: FactorItem) {
  const expand = useExpandableState(details.failed);
  const friendlyName = _.get(PEOPLE_FRIENDLY_NAMES, [factor,0]);
  let factorName;
  if (friendlyName) {
    const explanation = _.get(PEOPLE_FRIENDLY_NAMES, [factor,1]);
    factorName = (
      <ExplainerPopover
        id={ `${factor}_explanation` }
        explanation={ explanation }
      >
        { friendlyName }
        <span style={{ opacity: '0.25', fontSize: '0.75em' }}> ({ factor })</span>
      </ExplainerPopover>
    );
  } else {
    factorName = factor;
  }
  return (
    <div className='d-block hover:shaded py-1 px-2' key={ factor } onClick={expand.toggleExpand}>
      <div className='d-flex'>
        <div className='flex-grow-1'>
          { factorName }
        </div>
        <div>
          <UnderwritingStatusBadge failed={details.failed} manualCode={details.manualCode} />
        </div>
      </div>
      <div className='pl-3'>
        {
          expand.isExpanded ? (
            <FactorDetails factor={ factor } details={ details } />
          ) : null
        }
      </div>
    </div>
  );
}

// Copy certain facts from the application into the details for the factor,
// for easier reading.
function copyFactsFromApplication (factor: string, details: Details, loanApplication: LegacyLoanApplication) {
  const _details = {...details};

  const propertiesToCopy = [];

  switch (factor) {
    case 'IDENTITY_VERIFICATION':
      propertiesToCopy.push('kycFailureCodes');
      break;
    case 'LOW_FICO':
      propertiesToCopy.push('ficoScore');
      break;
    default:
  }
  _details.computedFacts = Object.assign(
    {},
    _details.computedFacts,
    _.pick(loanApplication, propertiesToCopy),
  );
  return _details;
}

export default function UnderwritingFactors (props: { loanApplication: LegacyLoanApplication }) {

  const { underwritingResult } = props.loanApplication;

  // Display the factors sorted alphabetically for quick scanning
  let factors: any;
  if (underwritingResult && _.isObject(underwritingResult)) {
    factors = _.sortBy(Object.entries(underwritingResult), (r) => {
      const factor = r[0];
      if (PEOPLE_FRIENDLY_NAMES[factor as keyof typeof PEOPLE_FRIENDLY_NAMES]) {
        return PEOPLE_FRIENDLY_NAMES[factor as keyof typeof PEOPLE_FRIENDLY_NAMES][0];
      }
      return factor;
    });
  } else {
    // Gracefully handle legacy data that does not store the factors.
    factors = [];
  }
  return (
    <div>
      {
        factors.map(([factor, details]: any) => (
          factor !== 'STATED_PROPERTY_DEBT_DOES_NOT_MATCH_REPORTED' &&
          <UnderwritingFactorItem
            key     = { factor }
            factor  = { factor }
            details = { copyFactsFromApplication(factor, details, props.loanApplication) }
          />
        ))
      }
    </div>
  );
}

function FactorDetails ({ factor, details }: { factor: string, details: { computedFacts: any, failed: boolean } }) {
  const { computedFacts = {}, failed } = details;
  switch (factor) {
    case 'APPLICANT_NEEDS_HELP':
      return <FactorApplicantNeedsHelp computedFacts={ computedFacts } />;
    case 'MISSING_ADDRESS_HASH':
      return failed ? <FactorMissingAddressHash/> : null;
    default:
      return computedFacts ? (
        <LabelValueGrouping data={ computedFacts } condensed={ true }/>
      ) : null;
  }
}

function FactorApplicantNeedsHelp ({ computedFacts = {} }: { computedFacts?: { iNeedHelpWithStatedDebt?: boolean } }) {
  const { iNeedHelpWithStatedDebt } = computedFacts;
  return (
    <LabelValueGrouping data={{
      'Financial Info': iNeedHelpWithStatedDebt ? 'Yes' : 'No',
    }} condensed={ true }/>
  );
}

function FactorMissingAddressHash () {
  return (
    <LabelValueGrouping data={{
      'Missing addressHash': 'Unable to check for duplicate loan applications for listing address',
    }} condensed={ true }/>
  );
}
