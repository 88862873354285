import React, { useEffect, useState } from 'react';
import PaymentScheduleTable, { ObligationDetail } from './PaymentScheduleTable';
import styles from './PaymentSchedule.module.scss';
import usePaymentDetails from '../../../../api/queries/obligations/usePaymentDetails';
import usePaymentPlan from '../../../../api/queries/loans/usePaymentPlan';
import { Loan } from '../../../../api/queries/types';
import InlineButton from '../../../InlineButton';
import { useExpandableState } from '../../../../utils';
import AddPaymentPlanModal from '../../../Modals/AddPaymentPlanModal';
import CONFIG from '../../../../config';

export type Obligation = {
  amount: number;
  bulletRepayment: boolean;
  paidAmount?: number;
  drawPeriod: boolean;
  dueDate: string;
  paidOn: string;
  interestCents: number;
  obligationId: string | null;
  period: number;
  periodStartDate: string | null;
  periodEndDate: string | null;
  statementDate: string | null;
  principalCents: number;
  remainingBalanceCents: number | null;
  is_paid: boolean;
  feeCents: number;
  monthlyStatement?: {
    _id: string,
    periodStart: string,
    periodEnd: string,
  }
};

export type Payment = {
  _id: string;
  autopayGenerated: boolean;
  amount: number;
  status: string;
  effectiveDate: string;
};

export type Response = {
  amortizedPaymentAmount: number;
  paymentPlan: Array<Obligation>;
  scheduledPayments: Array<Payment>;
};

type ObligationWithStatus = Obligation & { status?: string; statusText?: string };

export default function PaymentSchedule({ loan }: { loan: Loan }) {
  const { data } = usePaymentPlan(loan._id);
  const { amortizedPaymentAmount = 0, paymentPlan = [], scheduledPayments = [] } = data || {};
  const paymentScheduleDrawPeriod = paymentPlan.filter((p) => p.drawPeriod);
  const paymentScheduleRepaymentPeriod = paymentPlan.filter((p) => !p.drawPeriod);
  const [selectedObligation, setSelectedObligation] = useState<ObligationWithStatus>();
  let paymentDetails = usePaymentDetails(selectedObligation ? selectedObligation.obligationId : null);
  const addPaymentPlanModal = useExpandableState();

  function escFunction(event: KeyboardEvent){
    if (event.key === "Escape") {
      setSelectedObligation(undefined);
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return function cleanup() {
      document.removeEventListener("keydown", escFunction, false);
    };
  });

  return (
    <div>
      { loan.isInstallment &&
        <>
          <div className={styles.tableTitle}>Draw Period</div>
          <PaymentScheduleTable
            amortizedPaymentAmount={amortizedPaymentAmount}
            paymentPlan={paymentScheduleDrawPeriod}
            scheduledPayments={scheduledPayments}
            onRowClicked={(obligation) => {setSelectedObligation(obligation); paymentDetails.mutate();}}
            selectedObligation={selectedObligation}
          />
          {!amortizedPaymentAmount && <p className={styles.helpText}>* Interest amounts will vary as the balance changes.</p>}
          <div className={styles.tableTitle}>Repayment Period</div>
        </>
      }
      { CONFIG.PAYMENT_PLAN_ENABLED &&
        <div className={`${styles.tableTitle} d-flex`}>
          Payment
          <InlineButton className='ml-auto' onClick={addPaymentPlanModal.showExpand}>
            Add payment plan
          </InlineButton>
        </div>
      }
      <PaymentScheduleTable
        amortizedPaymentAmount={amortizedPaymentAmount}
        paymentPlan={paymentScheduleRepaymentPeriod}
        scheduledPayments={[]}
        onRowClicked={(obligation) => {setSelectedObligation(obligation); paymentDetails.mutate();}}
        selectedObligation={selectedObligation}
      />
      {!amortizedPaymentAmount && <p className={styles.helpText}>* Payment amounts will depend on the final balance.</p>}

      { selectedObligation && <ObligationDetail obligation={selectedObligation} paymentDetails={paymentDetails.data || []} onClose={() => setSelectedObligation(undefined)}/> }

      <AddPaymentPlanModal
        expand={addPaymentPlanModal}
        loan={loan}
        onSubmit={() => window.location.reload()}
      />
    </div>
  );
}
