import useQuery from '../../shared/useQuery';

export type FeeDetail = {
  _id: string;
  type: string;
  amount: number;
  effective_date: string;
  waived_at: Date | null;
  created_at: Date;
  incurredBy: {
    _id: string;
    type: string;
    label: string;
  } | null,
}

export default function useFeeDetail (loanId: string, feeId: string) {
  return useQuery<FeeDetail>(`/notebook/loans/${loanId}/fees/${feeId}`);
}
