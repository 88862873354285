const CONFIG = {
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  VENDOR_INVOICE_FILE_FORMATS: process.env.REACT_APP_VENDOR_INVOICE_FILE_FORMATS,
  CLARITY_PROJECT_ID: process.env.REACT_APP_CLARITY_PROJECT_ID,
  SENTILINK_DASHBOARD_URL: process.env.REACT_APP_SENTILINK_DASHBOARD_URL,
  PAYMENT_PLAN_ENABLED: process.env.REACT_APP_PAYMENT_PLAN_ENABLED === '1',
};

export default CONFIG;
