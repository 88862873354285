import React from 'react';

export default function OutboundLink (props: React.ComponentPropsWithoutRef<"a">) {
  return <a
    target="_blank"
    rel="noopener noreferrer"
    {...props}
  >
    { props.children }
  </a>;
}
