import React from 'react';
import useFeeDetail, { FeeDetail } from '../../../../api/queries/loans/useFeeDetail';
import { formatCents, formatDate } from '../../../../utils';
import { ServicingActivity } from '../../../../api/queries/loans/useActivity';
import { TimelineListLoader } from '../TimelineTab';
import styles from './ActivityDetail.module.scss';
import cx from 'classnames';
import { MinimizedEntityId } from '../../../MinimizedEntityId';
import { startCase } from 'lodash';
import { LedgerImpactsLoader } from '../LedgerImpacts';

type Props = {
  activity: ServicingActivity;
  onChange: () => void;
  loanId: string;
};



const FeeActivityDetailContent: React.FC<{
  data: FeeDetail;
}> = ({ data }) => {
  const feeActivity = data;

  if (!feeActivity) {
    return null;
  }

  const amount = feeActivity.amount;

  return (
    <div>
      <div className={styles.line}>
        <span className={cx(styles.title, 'mt-4')}>Fee Details</span>
      </div>
      <div className={styles.line}>
        <span>ID</span>
        <span>
          <MinimizedEntityId id={data._id} />
        </span>
      </div>
      <div className={styles.line}>
        <span>Effective Date</span>
        <span>{formatDate(data.effective_date, '—')}</span>
      </div>
      <div className={styles.line}>
        <span>Type</span>
        <span>{startCase(feeActivity.type)}</span>
      </div>
      <div className={styles.line}>
        <span>Amount</span>
        <span className={cx(amount < 0 && styles.creditAmount)}>{formatCents(amount)}</span>
      </div>
      <div className={styles.line}>
        <span>Incurred By</span>
        <span>{feeActivity.incurredBy?.label}</span>
      </div>
      <div className={styles.line}>
        <span>Waived At</span>
        <span>{formatDate(data.waived_at, '—')}</span>
      </div>
    </div>
  );
};


export const FeeActivityDetail: React.FC<Props> = ({ activity, loanId, onChange }) => {

  const txnRequest = useFeeDetail(loanId, activity.base_id);

  if (!txnRequest.data) {
    return null;
  }

  return (
    <>
      <FeeActivityDetailContent data={txnRequest.data} />
      <TimelineListLoader
        scopeId={ loanId }
        entityId={ activity.base_id }
      />
      <LedgerImpactsLoader
        sourceId={ activity.base_id }
      />
    </>
  );
};
