import React from 'react';
import { Flag } from '../../index';
import OutboundLink from '../../../../OutboundLink';
import CONFIG from '../../../../../config';

export type IdTheftScoreFlagData = {
  score: number;
}

export default function IdTheftScoreFlagDetails ({ flag }: { flag: Flag<IdTheftScoreFlagData> }) {
  return (
    <div>
      <OutboundLink href={`${CONFIG.SENTILINK_DASHBOARD_URL}/${flag.loan_application_id}`} className='text-secondary'>
        <u>ID Theft Score (Sentilink): {flag.data.score ?? 'n/a'}</u>
      </OutboundLink>
    </div>
  );
}
