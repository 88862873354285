import useQuery from '../../shared/useQuery';

export type MarqetaUser = {
  externalId: string;
  programSlug: string;
}

export type User = {
  _id: string;
  address_line1: string;
  address_line2: string;
  city: string;
  createdOn: string;
  dob: string;
  email: string;
  fico: string;
  firstName: string;
  is_temporary: boolean;
  is_paused: boolean;
  kustomerId: string;
  kyc: {
    status: string;
  };
  lastName: string;
  phone: string;
  previousAddressId: string;
  saltedSsn: string;
  ssnLastFour: string;
  state: string;
  zip: string;
  deletedAt: string;
  marqetaUsers: Array<MarqetaUser>;
};

export default function useUser (userId?: string) {
  return useQuery<User>(userId ? `/notebook/users/${userId}` : null);
}
