import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext, useFieldArray, get } from 'react-hook-form';
import cx from 'classnames';

import styles from './Field.module.scss';
import S3FileUploadField from './S3FileUploadField';
import CONFIG from '../../config';

const acceptFiles = CONFIG.VENDOR_INVOICE_FILE_FORMATS;

type Props = {
  name: string;
  label?: string;
  subLabel?: React.ReactNode;
};

const MultiS3FileUploadField: React.FC<Props> = ({ name, label, subLabel }: Props) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name });
  const error = get(errors, name);

  const { Group, Label, Text } = Form;

  return (
    <Group controlId={name}>
      {label && <Label>{label}</Label>}
      {subLabel && <Text className={cx('text-muted', styles.indentLabel)}>{subLabel}</Text>}

      {fields.map((item, index) => (
        <S3FileUploadField
          key={item.id}
          name={`${name}.${index}`}
          bucket="user-docs"
          accept={acceptFiles}
          s3_path="concierge/vendor/invoices"
          onMultipleUpload={(s3?: string, name?: string) => {
            append({ s3, name });
          }}
          onUploadFinish={() => append({ s3: '', name: '' })}
          remove={() => remove(index)}
          s3FieldKey="s3"
          filenameFieldKey="name"
        />
      ))}

      {error && error.message && <div className="text-danger ml-1 mt-1 small">{error.message}</div>}
    </Group>
  );
};

export default MultiS3FileUploadField;
