import { Portfolio } from './partners/usePortfolios';
import { CollectionStatuses } from '../../utils/constants';
import { LegacyLoanApplication } from './users/useLoanApplications';

export enum PROGRAMS {
  CONCIERGE = 'compass',
  HOME_CARD = 'better_home',
}

enum REPAYMENT_STATUSES {
  NOT_DUE       = 'NOT_DUE',
  GRACE_PERIOD  = 'GRACE_PERIOD',
  DELINQUENT    = 'DELINQUENT',
  DEFAULTED     = 'DEFAULTED',
};

interface LoanTerm {
  months: number;
};

type RepaymentStatusThresholds = Array<{
  days: number, // Whole days past the due date
  status: REPAYMENT_STATUSES,
}>;

interface LendingAgreementFeatures {
  CARD_ALLOWED?: boolean;
  ACH_ALLOWED?: boolean;
  TERM: LoanTerm;
  REPAYMENT_STATUS_THRESHOLDS: RepaymentStatusThresholds,
  NSF_FEE_AMOUNT_CENTS?: number;
  ORIGINATION_FEE_CENTS?: number;
};

export enum LENDING_AGREEMENT_TERM_MODIFICATION_REASONS {
  COVID = 'COVID',
  NATURAL_DISASTER = 'NATURAL_DISASTER',
  MEDICAL_HARDSHIP = 'MEDICAL_HARDSHIP',
  OTHER = 'OTHER',
}

type Contract = {
  _id: string;
  acceptedAt: string;
  createdAt: string;
  features: LendingAgreementFeatures;
  htmlContent: string;
  loanId: string;
  modification_reason: LENDING_AGREEMENT_TERM_MODIFICATION_REASONS;
  modifies_agreement_id: string;
  notes: string;
  url: string;
  urlForDownload: string;
};

type PortfolioTransfer = {
  _id: string;
  executed: boolean;
  from_portfolio: Portfolio;
  to_portfolio: Portfolio;
  transfer_date: string;
}

export type Loan = {
  _id: string;
  aprBps: number;
  authorizedAt: string;
  collectionsStatus: CollectionStatuses;
  chargeOffInfo: ChargeOffInfo | null;
  daysPastDue: number;
  drawPeriodEndDate: string;
  drawPeriods: number;
  eligiblePortfolios: Array<Portfolio>;
  originationDate: string;
  portfolio: Portfolio | null;
  pendingPortfolioTransfer: PortfolioTransfer | null;
  program: PROGRAMS;
  purchaseMethod: string;
  partnerId: string;
  partner: {
    _id: string;
    brand_name: string;
  };
  principal: number;
  repaidDate: string;
  slug: string;
  status: string;
  userId: string;
  withdrawalReason: string;
  contract: Contract;
  hasModifications: boolean;
  maturityDate: string;
  termDate: string;
  originalTermDate: string;
  proratedOriginationFeeCents: number;
  termDescription: string;
  isInstallment: boolean;
  isSelling: boolean;
  isUserPaused: boolean;
  annualInterestRateBps: number;
  autopayAccountId?: string;
  loanApplication: LegacyLoanApplication;
  loanApplicationSource: string;
  chargedOffDate: string;
  specialProvisions: string;
};

export type ChargeOffInfo = {
  effectiveDate: string;
  chargedOffBy?: string;
  notes?: string;
}
