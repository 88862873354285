import React from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useStates from '../../../api/queries/states/useStates';
import { State } from '../../../api/queries/states/useState';

function StatesTable ({ states }: { states: Array<State> }) {
  const navigate = useNavigate();

  return <Table>
    <thead>
    <tr>
      <th>Name</th>
    </tr>
    </thead>

    <tbody>
    {
      states.map(state => (
        <tr key={state._id} className='cursor-pointer' onClick={ () => navigate(`/system/states/${state._id}`) }>
          <td>{state.name}</td>
        </tr>
      ))
    }
    </tbody>
  </Table>;
}

export default function States () {
  const statesRequest = useStates();
  if (!statesRequest.data) {
    return null;
  }

  return (
    <section>
      <StatesTable states={statesRequest.data}/>
    </section>
  );
}

