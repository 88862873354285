import React from 'react';
import { Alert } from 'react-bootstrap';
import * as Sentry from '@sentry/react';

type Props = {
  errorText?: string;
  children: React.ReactNode;
};

export default function ErrorBoundary(props: Props) {
  const fallback = () => (
    <Alert variant="warning">{ props.errorText || 'Something went wrong.' }</Alert>
  );

  return (
    <Sentry.ErrorBoundary fallback={fallback}>
      {props.children}
    </Sentry.ErrorBoundary>
  );
}
