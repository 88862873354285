import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { titleCase } from '../../../../utils/string';
import useAdminAPICall from '../../../../utils/useAdminAPICall';
import { useExpandableStateType } from '../../../../utils/useExpandableState';
import { EXTERNAL_ACTIVITY_ACTIONS } from './ExternalActivityDetail';
import Form from '../../../Form/Form';
import Field from '../../../Form/Field';
import SubmitError from '../../../Form/SubmitError';
import ButtonWithSpinner from '../../../ButtonWithSpinner';

type FieldValues = { notes: string };

type Props = {
  modal: useExpandableStateType;
  type?: EXTERNAL_ACTIVITY_ACTIONS;
  activityId: string;
  onSubmitted: () => void;
};

const UpdateExternalActivityModal: React.FC<Props> = ({ modal, type, activityId, onSubmitted }) => {
  const schema = yup.object().shape({ notes: yup.string() });

  const methods = useForm<FieldValues>({ defaultValues: { notes: '' }, resolver: yupResolver(schema) });
  const { formState: { isSubmitting }, reset } = methods;

  const { callAPI, loadPending } = useAdminAPICall({ endpoint: `/notebook/external-activities/${activityId}/action`, method: 'POST' });

  const handleSubmit = async (values: FieldValues) => {
    const data = {
      notes: values.notes,
      type,
    };
    await callAPI({ data });
    onSubmitted();
    modal.hideExpand();
  };

  function handleCancel() {
    reset();
    modal.hideExpand();
  }

  return (
    <Modal show={modal.isExpanded} onHide={handleCancel}>
      <Form {...methods} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Mark as {titleCase(type)}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Field name='notes' label='Notes' controlProps={{ as: 'textarea' }}/>

          <SubmitError className='mt-3'/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel} disabled={loadPending || isSubmitting}>Cancel</Button>
          <ButtonWithSpinner type='submit' variant="primary" loading={loadPending || isSubmitting}>Confirm</ButtonWithSpinner>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default UpdateExternalActivityModal;
