import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import ConciergeActionButtons from './ConciergeActionButtons'
import ApplicationOverview from './ApplicationOverview'
import CreditReport from './CreditReport'
import IdentityInfo from './IdentityInfo';
import ListingInfo from './ListingInfo';
import SplitView from '../../../layouts/SplitView';
import UnderwritingRules from './UnderwritingRules';
import UnderwritingSummary from './UnderwritingSummary';
import { LegacyLoanApplication } from '../../../api/queries/users/useLoanApplications';
import styles from './LoanApplicationDetails.module.scss'

type Props = {
  loanApplication: LegacyLoanApplication;
};

export default function ConciergeApplicationTab ({ loanApplication }: Props) {
  return (
    <SplitView>
      <SplitView.PrimarySection>
        <Row>
          <Col lg={4} className={styles.firstCol}>
            <ApplicationOverview loanApplication={loanApplication} className="h-100" />
          </Col>
          <Col>
            <IdentityInfo loanApplication={loanApplication} />
          </Col>
        </Row>

        {loanApplication.listing_address_line1 && (
          <Row className="mt-4">
            <Col>
              <ListingInfo loanApplication={loanApplication} />
            </Col>
          </Row>
        )}

        <Row className="mt-4">
          <Col lg={4} className={styles.firstCol}>
            <CreditReport loanApplication={loanApplication} className="h-100" />
          </Col>

          <Col>
            <UnderwritingSummary loanApplication={loanApplication} />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <UnderwritingRules loanApplication={loanApplication} />
          </Col>
        </Row>
      </SplitView.PrimarySection>

      <SplitView.SecondarySection className="ml-4">
        <ConciergeActionButtons loanApplication={loanApplication} />
      </SplitView.SecondarySection>
    </SplitView>
  );
}
