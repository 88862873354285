import useQuery from '../../shared/useQuery';

export type TitleDetails = {
  _id: string,
  companyName: string;
  agentName: string;
  agentEmail: string;
  agentPhone: string;
};

export default function useTitle (loanId?: string) {
  return useQuery<TitleDetails>(loanId ? `/notebook/loans/${loanId}/title` : null);
}
