import useQuery from '../../shared/useQuery';

type ListingPrices = {
  avm: number;
  stated: number;
}

export default function usePropertyListingPrice (loanApplicationId: string) {
  return useQuery<ListingPrices>(`/notebook/loan-applications/${ loanApplicationId }/property-listing-price`);
}
