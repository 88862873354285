import React from "react";
import * as _ from 'lodash';

import { formatDollars } from "../utils";
import {
  REASON_NO_MORTGAGE_LABELS,
  STATED_DEBT_TYPE,
  STATED_DEBT_TYPE_LABELS,
  DEBTOR_RELATIONSHIP_LABELS,
} from "../utils/constants";
import ExplainerPopover from './ExplainerPopover';
import './DebtList.css';
import LabelValueGrouping from './LabelValueGrouping';
import Preamble from './Preamble';
import useReportedDebts, { ReportedDebt } from '../api/queries/loan-applications/useReportedDebts';
import useStatedDebts, { StatedDebt } from '../api/queries/loan-applications/useStatedDebts';

export default function DebtList ({ loanApplicationId, ...props }: { loanApplicationId: string } & React.ComponentPropsWithoutRef<'div'>) {
  const rdReq = useReportedDebts(loanApplicationId);
  const sdReq = useStatedDebts(loanApplicationId);
  return <DebtListWithData reportedDebts={ rdReq.data } statedDebts={ sdReq.data } {...props}/>;
}

export function FormattedDebtList ({ loanApplication }: { loanApplication: { _id: string, reasonForNoMortgageOnListing?: string } }) {
  const { reasonForNoMortgageOnListing } = loanApplication;
  return (
    <Preamble>
      {
        reasonForNoMortgageOnListing ? (
          <LabelValueGrouping condensed data={{
            'First Mortgage': (<span className='NoMortgage'>None ({REASON_NO_MORTGAGE_LABELS[reasonForNoMortgageOnListing as keyof typeof REASON_NO_MORTGAGE_LABELS]})</span>),
          }}/>
        ) : null
      }
      <DebtList loanApplicationId={ loanApplication._id }  />
    </Preamble>
  )
}

type DebtListWithDataProps = {
  reportedDebts: ReturnType<typeof useReportedDebts>['data'];
  statedDebts: ReturnType<typeof useStatedDebts>['data'];
} & React.ComponentPropsWithoutRef<'div'>;

export function DebtListWithData ({ reportedDebts, statedDebts, ...props }: DebtListWithDataProps) {
  const datas = React.useMemo(() => {
    // Group by type, so groups with multiple records can be pluralized (eg: HELOC 1, HELOC 2)
    const groupedReportedDebts = _.groupBy(reportedDebts, 'account_type');
    const groupedStatedDebts = _.groupBy(statedDebts, 'type');
    const _datas: any = [];

    for (const debtType in groupedReportedDebts) {
      const debtsOfType = groupedReportedDebts[debtType];
      debtsOfType.filter(n => n.borrower_indicated_active).forEach((record, index) => {
        _datas.push([
          record._id,
          getLabelValueDatasForReportedDebt(record, index, debtsOfType.length > 1),
        ]);
      });
    }

    for (const debtType in groupedStatedDebts) {
      const debtsOfType = groupedStatedDebts[debtType];
      debtsOfType.forEach((record, index) => {
        _datas.push([
          record._id,
          getLabelValueDatasForStatedDebt(record, index, debtsOfType.length > 1),
        ]);
      });
    }
    return _datas;
  }, [statedDebts, reportedDebts]);

  if (!datas.length) {
    return null;
  }

  return (
    <div {...props}>
      {
        datas.map(([ key, data ]: any) => (
          <LabelValueGrouping condensed className='DebtFragment' data={data} key={key} />
        ))
      }
    </div>
  );
}

function getLabelValueDatasForReportedDebt (record: ReportedDebt, index: number, showCounter: boolean) {
  // @todo - reuse humanizeAccountType from #2620 after it is merged
  const labelPrefix = `${ record.account_type } ${ showCounter ? index + 1 : '' }`.trim();
  const datas = {
    [`${ labelPrefix } - Balance`]: <ReportedDebtBalance reportedDebt={record}/>,
  };
  if (record.is_heloc) {
    datas[`${ labelPrefix } - Credit Limit`] = <span>{ formatDollars(record.credit_limit_cents! / 100) }</span>;
  }
  return datas;
}


function getLabelValueDatasForStatedDebt (record: StatedDebt, index: number, showCounter: boolean) {
  const labelPrefix = `${ STATED_DEBT_TYPE_LABELS[record.type] } ${ showCounter ? index + 1 : '' }`.trim();
  const showSuffix = record.type !== STATED_DEBT_TYPE.OTHER;
  const datas = {
    [`${ labelPrefix }${ showSuffix ? ' - Balance' : '' }`]: <StatedDebtBalance statedDebt={record}/>,
  };
  if (record.type === STATED_DEBT_TYPE.HELOC || record.type === STATED_DEBT_TYPE.REVERSE_MORTGAGE) {
    datas[`${ labelPrefix } - Credit Limit`] = <span>{ formatDollars(record.total_credit_cents! / 100) }</span>;
  }
  if (record.in_borrowers_name === false) {
    datas[`${ labelPrefix } - Owner`] = <StatedDebtOwner statedDebt={record}/>;
  }
  return datas;
}

function ReportedDebtBalance ({ reportedDebt }: { reportedDebt: ReportedDebt }) {
  return <DebtBalance remaining_balance_cents={reportedDebt.current_balance_cents!} amount_per_month_cents={reportedDebt.monthly_payment_cents!} />;
}

function StatedDebtBalance ({ statedDebt }: { statedDebt: StatedDebt }) {
  let caption = `(${ formatDollars(statedDebt.amount_per_month_cents! / 100) } / month)`;
  let Wrapper: any = React.Fragment;
  if (statedDebt.type === STATED_DEBT_TYPE.OTHER) {
    caption = `(${statedDebt.count} lines)`;
    Wrapper = (props: any) => <ExplainerPopover explanation={ statedDebt.notes } {...props} />;
  }
  if (statedDebt.type === STATED_DEBT_TYPE.REVERSE_MORTGAGE) {
    caption = '';
  }
  return <Wrapper>
    <span>{ formatDollars(statedDebt.remaining_balance_cents / 100) }</span>{` `}
    <span className='-caption'>{ caption }</span>
  </Wrapper>;
}

function DebtBalance ({ remaining_balance_cents, ...props }: { remaining_balance_cents: number, amount_per_month_cents: number, count?: number, notes?: string }) {
  let caption = `${ formatDollars(props.amount_per_month_cents / 100) } / month`;
  let Wrapper: any = React.Fragment;
  if (props.count) {
    caption = `${props.count} lines`;
    Wrapper = (passthru: any) => <ExplainerPopover explanation={ props.notes || '' } {...passthru} />;
  }
  return <Wrapper>
    <span>{ formatDollars(remaining_balance_cents / 100) }</span>{` `}
    <span className='-caption'>({ caption })</span>
  </Wrapper>;
}

function StatedDebtOwner ({ statedDebt }: { statedDebt: StatedDebt }) {
  return <>
    <span>{ statedDebt.debtor_full_name }</span>{` `}
    <span className='-caption'>({ DEBTOR_RELATIONSHIP_LABELS[statedDebt.debtor_relationship!] })</span>
  </>;
}
