import React from 'react';
import { Partner } from '../../../api/queries/partners/usePartner';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import SubmitError from '../../Form/SubmitError';
import { Button } from 'react-bootstrap';
import ButtonWithSpinner from '../../ButtonWithSpinner';
import MultiSelectField from '../../Form/MultiSelectField';
import BooleanRadioField from '../../Form/BooleanRadioField';

type PartnerDisbursementFields = {
  supportedPaymentTypes: Array<string>;
  supportedVendorTypes: Array<string>
  maxQuickDepositCents: string;
  checkMailingVendor: string;
  expressChecksEnabled: string;
};

type Props = {
  partner: Partner;
  onSubmit: () => void;
  onHide: () => void;
}

export default function EditDisbursementFields (props: Props) {
  const { partner, onSubmit, onHide } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/partners/${partner._id}`,
    method: 'PUT',
  });

  async function handleSubmit(data: PartnerDisbursementFields) {
    await callAPI({
      data: {
        supportedPaymentTypes: data.supportedPaymentTypes,
        supportedVendorTypes: data.supportedVendorTypes,
        maxQuickDepositCents: Number(data.maxQuickDepositCents),
        checkMailingVendor: data.checkMailingVendor,
        expressChecksEnabled: data.expressChecksEnabled,
      },
    });
    onSubmit();
    onHide();
  }

  const schema = yup.object({
    supportedPaymentTypes: yup.array(yup.string()),
    supportedVendorTypes: yup.array(yup.string()),
    maxQuickDepositCents: yup.number().required('Please enter a number'),
    checkMailingVendor: yup.string().required('Please select a vendor'),
    expressChecksEnabled: yup.boolean().required('Please select an option'),
  });

  const methods = useForm<PartnerDisbursementFields>({
    defaultValues: {
      supportedPaymentTypes: partner.supportedPayTypes,
      supportedVendorTypes: partner.supportedVendorTypes,
      maxQuickDepositCents: partner.maxQuickDepositCents.toString(),
      checkMailingVendor: partner.checkMailingVendor,
      expressChecksEnabled: partner.expressChecksEnabled.toString(),
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty } } = methods;

  return <div>
    <Form {...methods} onSubmit={handleSubmit}>
      <MultiSelectField
        name='supportedPaymentTypes'
        placeholder='Please select...'
        label='Supported Payment Types'
        options={[
          { value: 'ach', label: 'ACH' },
          { value: 'check', label: 'Check' },
        ]}
      />
      <MultiSelectField
        name='supportedVendorTypes'
        placeholder='Please select...'
        label='Supported Vendor Types'
        options={[
          { value: 'NONE', label: 'None' },
          { value: 'SINGLE', label: 'Single' },
          { value: 'MULTI', label: 'Multi' },
        ]}
      />
      <Field name='maxQuickDepositCents' label='Max Quick Deposit Cents'/>
      <Field label='Check Mailing Vendor' name='checkMailingVendor' controlProps={{ as: 'select' }}>
        <option value='lob'>Lob</option>
        <option value='postgrid'>Postgrid</option>
      </Field>
      <BooleanRadioField label='Express Checks Enabled' name='expressChecksEnabled'/>

      <SubmitError/>

      <div className="d-flex justify-content-end mt-4">
        <Button variant="secondary" onClick={onHide} className="mr-2">
          Cancel
        </Button>
        <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
          Confirm
        </ButtonWithSpinner>
      </div>
    </Form>
  </div>;
}
