import React, { useState } from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import copy from 'clipboard-copy';

import styles from './MinimizedEntityId.module.scss';

type Props = {
  id: string | null | undefined;
};

export const MinimizedEntityId: React.FC<Props> = ({ id }) => {
  const [animating, setAnimating] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const handleCopy = () => {
    copy(id!);
    setAnimating(true);

    if (timer) {
      clearTimeout(timer);
    }
    const timeout = setTimeout(() => setAnimating(false), 2000);
    setTimer(timeout);
  };

  if (!id) {
    return <span className={styles.value}>-</span>;
  }

  return (
    <div className={styles.root}>
      <span className={styles.value}>{id}</span>
      {animating ? <CheckCircleIcon /> : <FileCopyIcon onClick={handleCopy} />}
    </div>
  );
};
