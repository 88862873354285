import React from 'react';
import Button from 'react-bootstrap/Button';
import { FormGroup, FormLabel } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Modal from 'react-bootstrap/Modal';
import useAdminAPICall from '../../utils/useAdminAPICall';
import Form from '../Form/Form';
import Field from '../Form/Field';
import CardCheckbox from '../Form/CardCheckbox';
import SubmitError from '../Form/SubmitError';
import ButtonWithSpinner from '../ButtonWithSpinner';

type FieldValues = {
  notes: string;
  notifyBorrower: boolean;
  reason: string;
};

type CancelApplicationModalProps = {
  show: boolean;
  onHide: () => void;
  onSubmit: () => void;
  loanApplication: { _id: string };
};

export default function CancelApplicationModal(props: CancelApplicationModalProps) {
  const { loanApplication, show, onHide, onSubmit } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loan-applications/${loanApplication._id}/mark-as-canceled`,
    method: 'PUT',
  });

  const schema = yup.object().shape({
    notes: yup.string(),
    notifyBorrower: yup.boolean().required(),
    reason: yup.string().required('Please select a reason.'),
  });

  const methods = useForm<FieldValues>({
    defaultValues: { notes: '', notifyBorrower: true, reason: '' },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting } } = methods;

  const handleSubmit = async (form: FieldValues) => {
    await callAPI({ data: form });

    onSubmit();
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Cancel Application
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form {...methods} onSubmit={handleSubmit}>
          <Field name='reason' label='Reason' controlProps={{ as: 'select' }}>
            <option value="" disabled hidden>Select</option>
            <option value='Borrower request'>Borrower request</option>
            <option value='Fraud'>Fraud</option>
            <option value='Other'>Other</option>
          </Field>

          <Field name='notes' label='Internal Notes' controlProps={{ as: 'textarea', rows: '4' }}/>

          <FormGroup>
            <FormLabel>Notifications</FormLabel>
            <CardCheckbox name='notifyBorrower' label='Notify Borrower (by email)' />
          </FormGroup>

          <SubmitError/>

          <div className="d-flex justify-content-end mt-4">
            <Button variant="secondary" onClick={onHide} className="mr-2">
              Back
            </Button>
            <ButtonWithSpinner variant="primary" type="submit" loading={isSubmitting}>
              Cancel Application
            </ButtonWithSpinner>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
