import React from 'react';
import cx from 'classnames';

import styles from './ActivityDetail.module.scss';
import { formatCents, formatDate } from '../../../../utils';
import { titleCase } from '../../../../utils/string';
import { PaymentDetail } from '../../../../api/queries/loans/usePaymentDetail';
import ButtonWithSpinner from '../../../ButtonWithSpinner';
import MaskedAccountNumber from '../../../MaskedAccountNumber';

type Props = {
  data: PaymentDetail;
  onCancel?: () => void;
  loading?: boolean;
};

const TYPE_RAW_TO_NAME: {
  [type: string]: string;
} = {
  ach: 'ACH Transfer',
  cash_back: 'Cash Back',
};

export const PaymentDetailContent: React.FC<Props> = ({ data, loading = false, onCancel }) => {
  return (
    <div>
      {data.status === 'scheduled' && (
        <ButtonWithSpinner
          block
          className={cx('mt-2', styles.actionButton, styles.cancelButton)}
          variant="outline-danger"
          loading={loading}
          onClick={onCancel}
        >
          Cancel
        </ButtonWithSpinner>
      )}

      {data.type === 'ach' && (
        <>
          <div className={ cx(styles.line, 'mt-4') }>
            <span className={ styles.title }>Bank Account Details</span>
          </div>
          <div className={ styles.line }>
            <span>Borrower Account</span>
            <MaskedAccountNumber number={ data.last_four } />
          </div>
          <div className={styles.line}>
            <span>Notable Account</span>
            { data.internal_account_last_four ? <MaskedAccountNumber number={ data.internal_account_last_four }/> : <span>—</span> }
          </div>
        </>
      )}

      <div className={ cx(styles.line, 'mt-4') }>
        <span className={ styles.title }>Payment Details</span>
      </div>
      <div className={styles.line}>
        <span>Type</span>
        <span>{TYPE_RAW_TO_NAME[data.type]}</span>
      </div>
      <div className={styles.line}>
        <span>Amount</span>
        <span>{formatCents(data.amount)}</span>
      </div>
      <div className={ styles.line }>
        <span>Status</span>
        <span>{ titleCase(data.status) }</span>
      </div>
      <div className={styles.line}>
        <span>Autopay</span>
        <span>{data.autopay_generated ? 'Yes' : 'No'}</span>
      </div>
      <div className={styles.line}>
        <span>Retry (due to NSF)</span>
        <span>{data.is_retry ? 'Yes' : 'No'}</span>
      </div>
      <div className={ styles.line }>
        <span>Submitted by</span>
        <span>{ data.submitted_by }</span>
      </div>
      <div className={ styles.line }>
        <span>Submitted on</span>
        <span>{ formatDate(data.created_at) }</span>
      </div>
      <div className={styles.line}>
        <span>Effective Date</span>
        <span>{formatDate(data.effective_date, '—')}</span>
      </div>
      <div className={ styles.line }>
        <span>Withdrawal Date</span>
        <span>{ formatDate(data.withdrawal_date, '—') }</span>
      </div>
      {
        data.return_reason ? (
          <div className={cx(styles.line)}>
            <span>Return Reason</span>
            <span className='text-danger'>{data.return_reason.toUpperCase()}</span>
          </div>
        ) : null
      }
    </div>
  );
};
