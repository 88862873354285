import React from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { VERIFICATION_RESULTS } from "./constants";
import style from "./UnderwritingTab.module.scss";
import InfoOutlined from "@material-ui/icons/InfoOutlined";

export default function VerificationBadge({ verificationResult, unverifiedReasons }: { verificationResult: string, unverifiedReasons?: Array<string> }) {
  switch (verificationResult) {
    case VERIFICATION_RESULTS.VERIFIED:
      if (!unverifiedReasons || unverifiedReasons.length === 0) {
        return <Badge className={style.verifiedBadge} variant="success">VERIFIED</Badge>;
      } else {
        return (
          <UnverifiedReasonsOverlay
            title='Previous Unverified Reasons'
            unverifiedReasons={unverifiedReasons}
          >
            <Badge className={style.verifiedBadge} variant="success">VERIFIED <span className="pl-1"><InfoOutlined fontSize='inherit' className="align-top"/></span></Badge>
          </UnverifiedReasonsOverlay>
        );
      }
    case VERIFICATION_RESULTS.UNVERIFIED:
      if (!unverifiedReasons || unverifiedReasons.length === 0) {
        return <Badge variant="warning">UNVERIFIED</Badge>;
      } else {
        return (
          <UnverifiedReasonsOverlay
            title='Unverified Reasons'
            unverifiedReasons={unverifiedReasons}
          >
            <Badge variant="warning">UNVERIFIED <span className="pl-1"><InfoOutlined fontSize='inherit' className="align-top"/></span></Badge>
          </UnverifiedReasonsOverlay>
        );
      }
    case VERIFICATION_RESULTS.STATED:
      return (
        <Badge className={style.statedBadge} variant="success">
          STATED
        </Badge>
      );
    default:
      return <>{verificationResult}</>;
  }
}

function UnverifiedReasonsOverlay ({ unverifiedReasons, children, title }: { unverifiedReasons: Array<string>, children: React.ReactNode, title: string }) {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip id="unverified-reasons" className={style.reasonsTooltip}>
          <strong>{title}:</strong><br/>
          { unverifiedReasons.map((reason, i) => <span key={`${reason}-${i}`}>{reason}<br/></span>) }
        </Tooltip>
      }
    >
      { children }
    </OverlayTrigger>
  );
}
