import React from 'react';
import { Badge } from 'react-bootstrap';

import styles from './DisbursementTypeBadge.module.scss';

export default function DisbursementTypeBadge(props: { type: string }) {
  let label = 'ACH';
  if (props.type === 'check') {
    label = 'Check';
  }

  return (
    <Badge pill variant="dark" className={styles[props.type]}>
      {label}
    </Badge>
  );
}
