import useQuery from '../../shared/useQuery';

type AchHistory = {
  _id: string;
  date: string;
  account_number: string;
  routing_number: string;
  voided_check_photo: string;
};

export default function useAchHistory (loanId: string, bankAccountId: string) {
  return useQuery<Array<AchHistory>>(`/notebook/loans/${loanId}/ach/${bankAccountId}/history`);
}
