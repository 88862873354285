import CONFIG from '../config';
import { Document } from './transactionUtils';

export function formatFileName (value: string | null, len = 30) {
  const tmp = value ? value.split('/').slice(-1)[0] : '';
  if (tmp.length > len) {
    return `...${tmp.slice(-len)}`;
  }
  return tmp;
}

export const getDocumentUri = (doc: Document) =>
  `${CONFIG.API_BASE_URL}/notebook/s3/user-docs/img/${encodeURIComponent(doc.s3 || '')}`;
