import React, { useState } from 'react';
import cx from 'classnames';
import { Alert, Button } from 'react-bootstrap';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import DocViewerModal from '../DocViewerModal';
import LabelValueGrouping from '../../LabelValueGrouping';
import { formatFileName, getDocumentUri } from '../../../utils/formatData';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import OutboundLink from '../../OutboundLink';
import useExpandableState from '../../../utils/useExpandableState';
import { DisbursementRequest, Document } from '../../../utils/transactionUtils';
import ButtonWithSpinner from '../../ButtonWithSpinner';
import styles from './VerifyInvoiceModal.module.scss';
import usePauseInfo from '../../../api/queries/users/usePauseInfo';

type Props = {
  expandable: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
  disbursementRequest: DisbursementRequest
  infoData: Record<string, unknown>;
};

function VerifyInvoiceModal({ expandable, onSubmit, disbursementRequest, infoData }: Props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [document, setDocument] = useState<Document>(disbursementRequest.workStatements[0]);
  const pausedInfo = usePauseInfo(disbursementRequest.user_id);

  const sendAction = useAdminAPICall({
    endpoint: `/notebook/disbursement-requests/${disbursementRequest._id}/action`,
    method: 'POST',
  });

  const handleVerifyInvoice = async () => {
    setError(null);

    try {
      setLoading(true);
      await sendAction.callAPI({ data: { type: 'verify' } });
      onSubmit();
    } catch (err) {
      setError('There was an error verifying this invoice. Please try again or contact engineering if the error persists');
    } finally {
      setLoading(false);
    }
  };

  const handleRejectInvoice = async () => {
    setError(null);

    if (!window.confirm('Are you sure you want to deny this request?')) {
      return;
    }
    try {
      setLoading(true);
      await sendAction.callAPI({ data: { type: 'reject' } });

      onSubmit();
    } catch (err) {
      setError('There was an error canceling this request. Please try again or contact engineering if the error persists');
    } finally {
      setLoading(false);
    }
  };

  const infoList = {
    ...infoData,
    Documentation: (
      <div>
        {disbursementRequest.workStatements.map(doc => (
          <div
            key={doc._id}
            onClick={() => setDocument(doc)}
            className={cx(styles.documentLink, doc._id === document?._id && styles.documentLinkActive)}
          >
            <span className="mr-2">{formatFileName(doc.name)}</span>
            <OutboundLink href={getDocumentUri(doc)}>
              <DownloadIcon />
            </OutboundLink>
          </div>
        ))}
      </div>
    ),
  };

  if (!expandable.isExpanded) {
    return null;
  }

  return (
    <DocViewerModal
      expandable={expandable}
      title="Verify Invoice"
      document={document?.s3}
      infoContent={<>
        <LabelValueGrouping data={infoList} />
        { error && <Alert variant="danger">{error}</Alert> }
      </>}
      actionContent={
        disbursementRequest.status !== 'canceled' &&
        disbursementRequest.invoice_status === 'open' ? (
          <>
            <ButtonWithSpinner
              block
              className = "w-100"
              variant   = "primary"
              disabled  = {loading || pausedInfo.data?.active}
              loading   = {sendAction.loadPending}
              onClick   = {handleVerifyInvoice}
              tooltip   = {pausedInfo.data?.active ? 'This action is not available because this user is currently paused.' : ''}
            >
              Verify Invoice
            </ButtonWithSpinner>
            <Button className="w-100 mt-3" variant="danger" disabled={loading || sendAction.loadPending} onClick={handleRejectInvoice}>
              Cancel Request
            </Button>
          </>
        ) : null
      }
    />
  );
}

export default VerifyInvoiceModal;
