import React from 'react';
import { Flag } from '../../index';

export type CreditReportCollectionsFlagData = {
  count: number;
}

export default function CreditReportCollectionsFlagDetails ({ flag }: { flag: Flag<CreditReportCollectionsFlagData> }) {
  return (
    <div className='text-secondary'>
      {flag.data.count} Collection accounts
    </div>
  );
}
