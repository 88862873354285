import React from 'react';
import { Badge } from 'react-bootstrap';

export default function LoanSpecialProvisionsBadge ({ specialProvisions }: { specialProvisions?: string }) {
  if (!specialProvisions) { return null; }

  return (
    <Badge className="text-uppercase bg-dark" pill variant={ 'primary' }>
      { specialProvisions.replace(/_/g, ' ') }
    </Badge>
  );
}
