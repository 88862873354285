import React from 'react';
import { Alert } from 'react-bootstrap';

type Props = {
  className?: string;
  children: React.ReactNode;
};

export default function InlineError (props: Props) {
  return (
    <Alert as='span' variant='danger' className={ props.className }>
      { props.children }
    </Alert>
  );
}
