import React from 'react';
import { Document } from '../../../../utils/transactionUtils';
import { formatFileName, getDocumentUri } from '../../../../utils/formatData';
import OutboundLink from '../../../OutboundLink';

type Props = {
  documents: Document[];
};

export const VendorDocuments: React.FC<Props> = ({ documents }) => {
  return (
    <div>
      {documents.map((doc) => (
        <div key={doc._id} className="mb-1">
          <OutboundLink href={getDocumentUri(doc)}>{formatFileName(doc.name, 20)}</OutboundLink>
        </div>
      ))}
    </div>
  );
};
