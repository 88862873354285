import useQuery from '../../shared/useQuery';

enum STATED_DEBT_TYPE {
  FIRST_MORTGAGE = 'first_mortgage',
  HOME_EQUITY_LOAN = 'home_equity_loan',
  HELOC = 'heloc',
  OTHER = 'other',            /* Catch-all */
  UNSURE = 'unsure',
}

export enum DEBTOR_RELATIONSHIP {
  SPOUSE = 'spouse',
  FAMILY_MEMBER = 'family_member',
  BUSINESS_PARTNER = 'business_partner',
  OTHER = 'other',
}

export type StatedDebt = {
  _id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  loan_application_id: string;
  type: STATED_DEBT_TYPE;
  remaining_balance_cents: number;
  amount_per_month_cents?: number;
  in_borrowers_name?: boolean;
  debtor_full_name?: string;
  debtor_relationship?: DEBTOR_RELATIONSHIP;
  total_credit_cents?: number;
  count?: number;
  notes?: string;
  primary?: boolean;
};

export default function useStatedDebts (loanApplicationId: string) {
  return useQuery<Array<StatedDebt>>(`/notebook/loan-applications/${ loanApplicationId }/stated-debts`);
}
