import React from 'react';

import Dropdown from 'react-bootstrap/Dropdown';
import { MarqetaUser } from '../api/queries/users/useUser';
import OutboundButton from './OutboundButton';

type Props = {
  marqetaUsers: Array<MarqetaUser>;
  className?: string;
}

function MarqetaDropdown (props: Props) {
  const { marqetaUsers, className } = props;

  return (
    <Dropdown alignRight={true}>
      <Dropdown.Toggle id="marqeta-links-dropdown" variant="outline-dark" size="sm" className={className}>
        Marqeta
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {
          marqetaUsers.map(marqetaUser => (
            <Dropdown.Item
              key={marqetaUser.externalId}
              href={`https://app.marqeta.com/program/user?program=${marqetaUser.programSlug}&token=${marqetaUser.externalId}`}
              target='_blank'
              className='small-dropdown-item'
            >
              {marqetaUser.programSlug} - Marqeta
            </Dropdown.Item>
          ))
        }
      </Dropdown.Menu>
    </Dropdown>
  );
}

function MarqetaSingleButton ({ marqetaUser, className }: { marqetaUser: MarqetaUser, className?: string }) {
  return <OutboundButton
    href={`https://app.marqeta.com/program/user?program=${marqetaUser.programSlug}&token=${marqetaUser.externalId}`}
    block={ true }
    className={className}
    size="sm"
  >
    Marqeta
  </OutboundButton>;
}

export default function MarqetaLinks (props: Props) {
  const { marqetaUsers, className } = props;
  return (
    <>
      {
        marqetaUsers.length === 1 ?
          <MarqetaSingleButton marqetaUser={ marqetaUsers[0]} className={className}/>
          :
          <MarqetaDropdown marqetaUsers={ marqetaUsers } className={className}/>
      }
    </>
  )
}
