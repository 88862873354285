import React from 'react';
import { Partner } from '../../../api/queries/partners/usePartner';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import SubmitError from '../../Form/SubmitError';
import { Button } from 'react-bootstrap';
import ButtonWithSpinner from '../../ButtonWithSpinner';
import BooleanRadioField from '../../Form/BooleanRadioField';

type PartnerInfoFields = {
  hubspotAllowSync: string;
  hubspotCompanyDomain: string;
  hubspotId: string;
};

type Props = {
  partner: Partner;
  onSubmit: () => void;
  onHide: () => void;
}

export default function EditPartnerFavicons (props: Props) {
  const { partner, onSubmit, onHide } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/partners/${partner._id}`,
    method: 'PUT',
  });

  async function handleSubmit(data: PartnerInfoFields) {
    await callAPI({
      data: {
        hubspotAllowSync: data.hubspotAllowSync,
        hubspotCompanyDomain: data.hubspotCompanyDomain,
        hubspotId: data.hubspotId,
      },
    });
    onSubmit();
    onHide();
  }

  const schema = yup.object();

  const methods = useForm<PartnerInfoFields>({
    defaultValues: {
      hubspotAllowSync: partner.hubspotAllowSync.toString(),
      hubspotCompanyDomain: partner.hubspotCompanyDomain,
      hubspotId: partner.hubspotId,
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty } } = methods;

  return <div>
    <Form {...methods} onSubmit={handleSubmit}>
      <BooleanRadioField
          label='Allow syncing to Hubspot'
          name='hubspotAllowSync'
        />
      <Field name='hubspotCompanyDomain' label='Hubspot Company Domain'/>
      <Field
        name='hubspotId'
        label='Hubspot Company ID'
        subLabel='Leave blank if the company does not exist on Hubspot yet. 
        A new company will be created when syncing agents for the first time and the ID field will auto-fill.'
      />

      <SubmitError/>

      <div className="d-flex justify-content-end mt-4">
        <Button variant="secondary" onClick={onHide} className="mr-2">
          Cancel
        </Button>
        <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
          Confirm
        </ButtonWithSpinner>
      </div>
    </Form>
  </div>;
}
