import React from 'react';
import cx from 'classnames';
import { startCase } from 'lodash';

import styles from './ActivityDetail.module.scss';
import { MinimizedEntityId } from '../../../MinimizedEntityId';
import { formatCents, formatDate } from '../../../../utils';
import { ExternalActivity } from '../../../../utils/transactionUtils';
import { titleCase } from '../../../../utils/string';
import { Dropdown } from 'react-bootstrap';
import { EXTERNAL_ACTIVITY_ACTIONS } from './ExternalActivityDetail';

type Props = {
  data: ExternalActivity;
  onAction: (action: EXTERNAL_ACTIVITY_ACTIONS) => void;
};

export const EXTERNAL_ACTIVITY_METHODS: Record<string, string> = {
  check: 'Check',
  ach: 'ACH',
  wire: 'Wire',
  eft: 'EFT',
};

const EXTERNAL_ACTIVITY_BANKS: Record<string, string> = {
  jpm_chase: 'JPMorgan Chase',
  ach_com: 'ACH.com',
  regions_bank: 'Regions Bank',
};

export const ExternalActivityDetailContent: React.FC<Props> = ({ data, onAction }) => {
  if (!data) {
    return null;
  }

  const {
    _id,
    amount,
    method,
    type,
    voided_at,
    marked_returned_at,
    status,
    effective_date,
    accounting_date,
    posting_bank,
    internal_sender_account,
    internal_recipient_account,
    external_ref,
    impacts,
  } = data;

  const activityAmount = type === 'repayment' ? -amount : amount;

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle
          variant='outline-dark'
          id='dropdown-basic'
          className={ cx(styles.actionButton, styles.dropdownToggler) }
        >
          Edit
        </Dropdown.Toggle>
        <Dropdown.Menu className={ styles.dropdownMenu}>
          <Dropdown.Item
            onClick={ () => onAction(EXTERNAL_ACTIVITY_ACTIONS.VOIDED) }
            disabled={ !!(voided_at || marked_returned_at) }
          >
            Mark as Voided
          </Dropdown.Item>
          { type === 'repayment' &&
            <Dropdown.Item
              onClick={ () => onAction(EXTERNAL_ACTIVITY_ACTIONS.RETURNED) }
              disabled={ !!(voided_at || marked_returned_at) }
            >
              Mark as Returned
            </Dropdown.Item>
          }
        </Dropdown.Menu>
      </Dropdown>
      <div className={ styles.line }>
        <span className={ cx(styles.title, 'mt-4') }>Transaction Details</span>
      </div>
      <div className={ styles.line }>
        <span>ID</span>
        <span><MinimizedEntityId id={ _id } /></span>
      </div>
      <div className={ styles.line }>
        <span>Type</span>
        <span>{ startCase(type)}</span>
      </div>
      <div className={ styles.line }>
        <span>Amount</span>
        <span className={ cx(activityAmount < 0 && styles.creditAmount)}>{formatCents(activityAmount) }</span>
      </div>
      <div className={ styles.line }>
        <span>Status</span>
        <span>{ titleCase(status) }</span>
      </div>
      <div className={ styles.line }>
        <span>Method</span>
        <span>{ EXTERNAL_ACTIVITY_METHODS[method] }</span>
      </div>
      <div className={ styles.line }>
        <span>Bank</span>
        <span>{ EXTERNAL_ACTIVITY_BANKS[posting_bank!] }</span>
      </div>
      <div className={ styles.line }>
        <span>Bank Account</span>
        <span>{ startCase(internal_sender_account || internal_recipient_account) }</span>
      </div>
      <div className={ styles.line }>
        <span>Effective Date</span>
        <span>{ formatDate(effective_date, '—') }</span>
      </div>
      <div className={ styles.line }>
        <span>Accounting Date</span>
        <span>{ formatDate(accounting_date, '—') }</span>
      </div>
      { type === 'repayment' &&
        <div className={ styles.line }>
          <span>Marked Returned On</span>
          <span>{ formatDate(marked_returned_at, '—') }</span>
        </div>
      }
      <div className={ styles.line }>
        <span>External Ref</span>
        <span>{ external_ref ?? '—' }</span>
      </div>

      {
        impacts ? (
          <>
            <div className={ styles.line }>
              <span>Principal</span>
              <span>{ formatCents(impacts.accumulated_principal_repayments, '—') }</span>
            </div>
            <div className={ styles.line }>
              <span>Interest</span>
              <span>{ formatCents(impacts.accumulated_interest_repayments, '—') }</span>
            </div>
            <div className={ styles.line }>
              <span>Fees</span>
              <span>{ formatCents(impacts.accumulated_fee_repayments, '—') }</span>
            </div>
          </>
        ) : null
      }
    </div>
  );
};
