import { useState, useCallback } from 'react';
import axios, { Method } from 'axios';
import CONFIG from '../config';

export const axiosInstanceAdmin = axios.create({
  baseURL: CONFIG.API_BASE_URL,
  withCredentials: true,
  headers: {
      'Content-Type': 'application/json',
  },
});

type Options<TRawData, TParsedData> = {
  endpoint: string;
  method?: Method;
  onSuccess?: (data: TRawData | TParsedData) => void;
  parseResponse?: (data: TRawData) => TParsedData
};

type State<TData> = {
  data?: TData;
  loadUnstarted: boolean;
  loadError: boolean;
  loadPending: boolean;
  loadSuccess: boolean;
  loadComplete: boolean;
};

export default function useAdminAPICall<TRawData=any, TParsedData=TRawData> ({ endpoint, method, onSuccess, parseResponse }: Options<TRawData, TParsedData> ) {
  const [state, setState] = useState<State<TRawData | TParsedData>>({
    data          : undefined,
    loadUnstarted : true,
    loadError     : false,
    loadPending   : false,
    loadSuccess   : false,
    loadComplete  : false,
  });

  const callAPI = useCallback((config = {}) => {
    setState({ data: undefined, loadUnstarted: false, loadPending: true, loadError: false, loadSuccess: false, loadComplete: false });
    const req = axiosInstanceAdmin.request<TRawData>({
      method: method || 'GET',
      url: endpoint,
      ...config,
    });
    req.then((result) => {
      const data = parseResponse ? parseResponse(result.data) : result.data;
      setState({ data, loadUnstarted: false, loadPending: false, loadError: false, loadSuccess: true, loadComplete: true });
      if (onSuccess) {
        onSuccess(data);
      }
    }).catch((error) => {
      setState({ data: error.response ? error.response.data : undefined, loadUnstarted: false,  loadPending: false, loadError: error, loadSuccess: false, loadComplete: true });
    });
    return req;
  }, [endpoint, method, onSuccess, parseResponse]);

  return {
    ...state,
    callAPI,
  };
}
