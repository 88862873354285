import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';

import CONFIG from './config';
import './index.css';

if (CONFIG.SENTRY_DSN) {
  Sentry.init({
    dsn: CONFIG.SENTRY_DSN,
    integrations: [
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    environment: CONFIG.ENVIRONMENT,
  });
}

ReactDOM.render(<App/>, document.getElementById('root'));
