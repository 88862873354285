import React from 'react';
import { Badge } from 'react-bootstrap';
import humanizeString from 'humanize-string';
import { IncludeCheckbox } from '../DebtCalculation';
import LabelValueGrouping from '../../LabelValueGrouping';
import { TheNumberCR } from '../SourceTag';
import Highlight from '../../Highlight';
import { PROPERTY_DEBT_HELOC_CALCULATOR_KEYS } from '../../../utils/constants';
import formatDollars from '../../../utils/formatDollars';
import { TIMELINETYPE } from '../../../utils/constants';
import useDebtCalculation from '../DebtCalculation/useDebtCalculation';
import './ReportedDebts.css';

type ReportedDebt = {
  _id: string;
  account_type: string;
  borrower_indicated_active: boolean;
  credit_limit_cents: number;
  current_balance_cents: number;
  date_effective: string;
  date_opened: string;
  ecoa_designator: string;
  high_credit_cents: number;
  is_heloc: boolean;
  lender: string;
  monthly_payment_cents: number;
  payment_frequency: string;
  payment_schedule_month_count: number;
  portfolio_type: string;
  status: string;
};

function humanizeAccountType (reportedDebt: ReportedDebt) {
  if (reportedDebt.account_type === 'real_estate_junior_liens') {
    return 'Real Estate — Junior Liens';
  } else if (reportedDebt.account_type === 'fha_real_estate_mortgage') {
    return 'FHA Real Estate Mortgage';
  } else if (reportedDebt.account_type === 'v_a_real_estate_mortgage') {
    return 'V.A. Real Estate Mortgage';
  } else if (reportedDebt.account_type === 'fmha_real_estate_mortgage') {
    return 'FMHA Real Estate Mortgage';
  } else {
    return humanizeString(reportedDebt.account_type);
  }
}

type ReportedDebtsProps = {
  calculator: ReturnType<typeof useDebtCalculation>;
  records: { [key: string]: Array<{ item: ReportedDebt }> };
  showAll: { isExpanded: boolean };
  disabledEdit: boolean;
};

export default function ReportedDebts (props: ReportedDebtsProps): any {
  const { calculator, records, showAll, disabledEdit } = props;
  return (records[TIMELINETYPE.REPORTED_DEBT] || []).map(({ item }) => (
    <ReportedDebtItem
      key={item._id}
      reportedDebt={item}
      collapsed={!records.likelyRelevant && !showAll.isExpanded}
      calculator={calculator}
      disabledEdit={disabledEdit}
    />
  ));
}

type ReportedDebtProps = {
  calculator: ReturnType<typeof useDebtCalculation>;
  reportedDebt: ReportedDebt;
  collapsed: boolean;
  disabledEdit: boolean;
};

function ReportedDebtItem (props: ReportedDebtProps) {
  const { reportedDebt, calculator, disabledEdit } = props;

  // Previous saved manualDebtVerifications used reportedDebt._id (without sub_id) to indicate
  // selected reportedDebt should currentBalance to calculate sum.
  // To ensure IDs line up, continue to omit sub_id for currentBalance.
  const calculatorId = reportedDebt.is_heloc && calculator.helocCalculatorKey !== PROPERTY_DEBT_HELOC_CALCULATOR_KEYS.CURRENT_BALANCE
    ? `${reportedDebt._id}.${calculator.helocCalculatorKey}`
    : reportedDebt._id;

  if (props.collapsed) {
    // Show a subtle version of the reportedDebt for context.
    return (
      <div style={{ opacity: 0.25 }}>
        { humanizeAccountType(reportedDebt) } - { formatDollars(reportedDebt.current_balance_cents, { cents: true }) }
      </div>
    );
  }
  const reportedDebtIsSelected = calculator.selectedRecordIDs.includes(calculatorId);

  return (
    <div className={`p-2 ReportedDebt ${reportedDebtIsSelected ? 'ReportedDebt--selected' : ''} ${reportedDebt.borrower_indicated_active ? 'ReportedDebt--highlighted' : ''}`}>
      <div className='d-flex'>
        <div className='ReportedDebt-checkbox'>
          <IncludeCheckbox calculator={calculator} id={calculatorId} controlId={ `include-debt-${reportedDebt._id}` } disabled={ disabledEdit }/>
        </div>
        <label htmlFor={ `include-debt-${reportedDebt._id}` } className='flex-grow-1'>
          <div className='d-block'>
            <h4 className='d-flex'>
              <span className='flex-grow-1'>{ humanizeAccountType(reportedDebt) }</span>
              <TheNumberCR />
            </h4>
          </div>
          {
            reportedDebt.borrower_indicated_active ? (
              <div className='pb-3'>
                <Badge pill variant='info'>Identified by applicant</Badge>
              </div>
            ) : null
          }
          {
            reportedDebt.status === 'closed' ? (
              <div className='pb-3'>
                <Badge pill variant='danger'>Closed</Badge>
              </div>
            ) : null
          }
          <LabelValueGrouping data={{
            'Date opened': reportedDebt.date_opened,
            'Credit limit':  reportedDebt.credit_limit_cents != null ? <Highlight maybe={calculatorId === `${reportedDebt._id}.creditLimit`}><strong>{ formatDollars(reportedDebt.credit_limit_cents, { cents: true }) }</strong></Highlight> : 'n/a',
            'High credit': formatDollars(reportedDebt.high_credit_cents, { cents: true }),
            'Current balance': <Highlight maybe={calculatorId === reportedDebt._id}>{ formatDollars(reportedDebt.current_balance_cents, { cents: true }) }</Highlight>,
            'Date Effective': reportedDebt.date_effective,
            'Scheduled monthly payment': formatDollars(reportedDebt.monthly_payment_cents, { cents: true }),
            'Payment frequency': (`${reportedDebt.payment_frequency || ''} ${reportedDebt.payment_schedule_month_count || ''}`).trim(),
            'Lender': reportedDebt.lender,
            'Portfolio type': reportedDebt.portfolio_type,
            'ECOA Designator': reportedDebt.ecoa_designator,
          }} condensed />
        </label>
      </div>
    </div>
  )
}
