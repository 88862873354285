import plaidLogoURL from '../images/plaid-logo.svg';
import React from 'react';

export default function PlaidLogo () {
  return (
    <img
      src         = { plaidLogoURL }
      className   = 'ml-2'
      style       = {{ width: 50 }}
      alt         = 'Plaid Link'
    />
  );
}
