import React from 'react';
import { Button } from 'react-bootstrap';
import cx from 'classnames';

import styles from './ActivityDetail.module.scss';
import { formatDate } from '../../../../utils';
import { USE_OF_FUND_OPTIONS } from '../../../../utils/constants';
import {
  getPMNeedsReview,
  DisbursementData,
  getPMStatus,
  getPMVerificationMethod,
  getRequestedByUserInfo,
} from '../../../../utils/transactionUtils';
import MaskedAccountNumber from '../../../MaskedAccountNumber';
import { titleCase } from '../../../../utils/string';
import { VendorDocuments } from './VendorDocuments';

type Props = {
  data: DisbursementData;
  onEditDisbursement: () => void;
  onVerifyPurchaseMethod: () => void;
  onVerifyInvoice: () => void;
  onCancelRequest: () => void;
};

export const DisbursementDetailContent: React.FC<Props> = ({
  data,
  onEditDisbursement,
  onVerifyPurchaseMethod,
  onVerifyInvoice,
  onCancelRequest,
}) => {
  const { disbursementRequest: dr, purchaseMethod: pm, achActivity } = data;
  const { _vendor: vendor } = dr;

  const useOfFunds = dr.use_of_funds
    .map((x: string) => {
      const option = USE_OF_FUND_OPTIONS.find((u) => u.value === x);
      return option ? option.label : x;
    })
    .join(', ');

  const pmStatus = pm ? getPMStatus(pm) : null;
  const needsReview = getPMNeedsReview(pm);
  const isInvoiceOpen = dr.status !== 'canceled' && dr.invoice_status === 'open';
  const requestedByUserInfo = getRequestedByUserInfo(data.loan, data.requestedByUser);

  const isEditable = dr.status === 'open';
  
  let isCancellable = isEditable;
  if (achActivity) {
    isCancellable = !(achActivity.canceled_at || achActivity.submitted_at);
  }

  return (
    <div>
      {dr.type === 'ach' && pm && getPMVerificationMethod(pm) !== 'Plaid' && (
        <Button className={styles.actionButton} onClick={onVerifyPurchaseMethod}>
          {needsReview ? 'Review Bank Account' : 'View Check'}
        </Button>
      )}
      {dr && !dr.quick_deposit && (
        <Button
          className={cx('mt-2', styles.actionButton)}
          variant={isInvoiceOpen ? 'primary' : 'secondary'}
          onClick={onVerifyInvoice}
        >
          {isInvoiceOpen ? 'Review Invoice' : 'View Invoice'}
        </Button>
      )}

      <Button
        block
        className={cx('mt-2', styles.actionButton)}
        variant="outline-primary"
        onClick={onEditDisbursement}
        disabled={!isEditable}
      >
        Edit Request
      </Button>
      <Button
        block
        className={cx('mt-2', styles.actionButton, styles.cancelButton)}
        variant="danger"
        onClick={onCancelRequest}
        disabled={!isCancellable}
      >
        Cancel Request
      </Button>

      {dr.type === 'ach' && pm && (
        <>
          <div className={cx(styles.line, 'mt-4')}>
            <span className={styles.title}>Bank Account Details</span>
          </div>
          {pmStatus && (
            <div className={cx(styles.line, styles.pmStatus)}>
              <span>Status</span>
              <span className={`pm-status-${pmStatus.variant}`}>{pmStatus.text}</span>
            </div>
          )}
          <div className={styles.line}>
            <span>Verification Method</span>
            <span>{getPMVerificationMethod(pm)}</span>
          </div>
          <div className={styles.line}>
            <span>Borrower Account</span>
            <span>
              {titleCase(pm.accountType)} <MaskedAccountNumber number={pm.accountNumber} />
            </span>
          </div>
          <div className={styles.line}>
            <span>Notable Account</span>
            { achActivity?.internal_account_last_four ? <MaskedAccountNumber number={achActivity.internal_account_last_four}/> : <span>—</span> }
          </div>
        </>
      )}

      {dr.type === 'check' && (
        <>
          <div className={cx(styles.line, 'mt-4')}>
            <span className={styles.title}>Disbursement Info</span>
          </div>
          <div className={styles.line}>
            <span>Disbursement Method</span>
            <span>Physical Check</span>
          </div>
          <div className={styles.line}>
            <span>Check Number</span>
            <span>--</span>
          </div>
        </>
      )}

      <div className={cx(styles.line, 'mt-4')}>
        <span className={styles.title}>Disbursement Details</span>
      </div>
      {vendor && (
        <>
          <div className={styles.line}>
            <span>Vendor Name</span>
            <span>{vendor.name}</span>
          </div>
          <div className={styles.line}>
            <span>Vendor Phone</span>
            <span>{vendor.phone}</span>
          </div>
          <div className={styles.line}>
            <span>Vendor Email</span>
            <span>{vendor.email}</span>
          </div>
          <div className={styles.line}>
            <span>Vendor Address</span>
            <span>
              {`${vendor.address_line1} ${vendor.address_line2 || ''}`.trim()}
              <br />
              {vendor.city}, {vendor.state} {vendor.zip}
            </span>
          </div>
        </>
      )}
      <div className={styles.line}>
        <span>Use of Funds</span>
        <span>{useOfFunds}</span>
      </div>
      {!dr.quick_deposit && (
        <div className={styles.line}>
          <span>Documentation</span>
          <VendorDocuments documents={dr.workStatements} />
        </div>
      )}
      {requestedByUserInfo && (
        <div className={styles.line}>
          <span>Requested by</span>
          <span>{requestedByUserInfo}</span>
        </div>
      )}
      <div className={styles.line}>
        <span>Submitted on</span>
        <span>{formatDate(dr.created_at)}</span>
      </div>
      <div className={styles.line}>
        <span>Approved on</span>
        <span>
          {dr.invoice_status === 'verified' ? formatDate(dr.invoice_status_changed_at) : '—'}
        </span>
      </div>
      <div className={styles.line}>
        <span>Approved by</span>
        <span>{dr.invoice_status === 'verified' ? dr.adminName : '—'}</span>
      </div>
      <div className={styles.line}>
        <span>Deposit Date</span>
        <span>
          { formatDate(achActivity?.deposit_date, '—') }
        </span>
      </div>
    </div>
  );
};
