import useMutation from '../shared/useMutation';

export type InvoiceTotals = {
  dueDate: string;
  creditUsageCents: number;
  accruedInterestCents: number;
  totalExpectedFutureInterestCents: number;
  feesCents: number;
  payoffAmountCents: number;
}

type Payload = { payoffDate: string };

export default function useMutateInvoiceTotals (loanId: string) {
  return useMutation<Payload, InvoiceTotals>('GET', `/notebook/loans/${ loanId }/invoices/totals`);
}
