import React from 'react'
import { Button } from 'react-bootstrap'
import { FormGroup, FormLabel } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import CardCheckbox from '../../Form/CardCheckbox';
import SubmitError from '../../Form/SubmitError';
import useAdminAPICall from '../../../utils/useAdminAPICall'
import ButtonWithSpinner from '../../ButtonWithSpinner';

type FieldValues = {
  notes: string;
  withdrawalReason: string;
  notifyBorrower: boolean;
};

type Props = {
  onHide: () => void,
  onSubmit: () => void,
  loan: { _id: string },
};

function MarkLoanCanceledForm(props: Props) {
  const { loan, onHide, onSubmit } = props

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loans/${loan._id}/mark-as-canceled`,
    method: 'PUT',
  })

  async function handleSubmit(data: FieldValues) {
    await callAPI({ data });
    onSubmit();
    onHide();
  }

  const schema = yup.object().shape({
    notes: yup.string(),
    withdrawalReason: yup.string().required('Please select a reason for cancellation'),
    notifyBorrower: yup.boolean().required(),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      notes: '',
      withdrawalReason: '',
      notifyBorrower: true,
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting } } = methods;

  return (
    <>
      <Form {...methods} onSubmit={handleSubmit}>
        <Field name='withdrawalReason' required label='Cancellation reason' controlProps={{ as: 'select' }}>
          <option value=''>Please select a reason</option>
          <option value='ACCELERATION_EVENT'>Acceleration Event Pre-FUNDED</option>
          <option value='INELIGIBLE'>Borrower is Ineligible</option>
          <option value='USER_REAPPLYING'>Borrower is Reapplying</option>
          <option value='USER_REQUEST'>Borrower Requests Cancellation</option>
          <option value='OTHER'>Other (explain in notes)</option>
        </Field>

        <Field name='notes' label='Internal Notes' controlProps={{ as: 'textarea', rows: '4' }}/>

        <FormGroup>
          <FormLabel>Notifications</FormLabel>
          <CardCheckbox name='notifyBorrower' label='Notify Borrower (by email)' />
        </FormGroup>

        <SubmitError/>

        <div className="d-flex justify-content-end mt-4">
          <Button variant="secondary" onClick={onHide} className="mr-2">
            Back
          </Button>
          <ButtonWithSpinner variant="primary" type="submit" loading={isSubmitting}>
            Cancel Loan
          </ButtonWithSpinner>
        </div>
      </Form>
    </>
  )
}

export default MarkLoanCanceledForm
