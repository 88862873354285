import React from "react";
import { Badge } from "react-bootstrap";
import style from "../UnderwritingTab.module.scss";
import { UNDERWRITING_RUN_OUTCOMES } from "../constants";

function UnderwritingRunOutcomeBadge ({ outcome }: { outcome: UNDERWRITING_RUN_OUTCOMES }) {
  switch (outcome) {
    case UNDERWRITING_RUN_OUTCOMES.UNDECIDED:
      return (
        <Badge variant="dark">
          UNDECIDED
        </Badge>
      );
    case UNDERWRITING_RUN_OUTCOMES.INCOMPLETE:
      return (
        <Badge variant="dark">
          INCOMPLETE
        </Badge>
      );
    case UNDERWRITING_RUN_OUTCOMES.APPROVED:
      return (
        <Badge variant="success">
          APPROVED
        </Badge>
      );
    case UNDERWRITING_RUN_OUTCOMES.PREQUALIFIED:
      return (
        <Badge variant="success">
          PRE-QUALIFIED
        </Badge>
      );
    case UNDERWRITING_RUN_OUTCOMES.DENIED:
      return (
        <Badge variant="danger">
          DENY
        </Badge>
      );
    case UNDERWRITING_RUN_OUTCOMES.INELIGIBLE:
      return (
        <Badge variant="secondary">
          INELIGIBLE
        </Badge>
      );
    case UNDERWRITING_RUN_OUTCOMES.COUNTEROFFER:
      return (
        <Badge className={style.counterOfferBadge} variant="success">
          COUNTEROFFER
        </Badge>
      );
    default:
      return <Badge variant="dark">
        {outcome}
      </Badge>;
  }
}

export default UnderwritingRunOutcomeBadge;
