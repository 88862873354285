import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';

import { LegacyLoanApplication } from '../../../api/queries/users/useLoanApplications';
import OutboundLink from '../../OutboundLink';
import { useExpandableState } from '../../../utils';
import InlineButton from '../../InlineButton';
import ResolvePartialMatchModal, { FormKeys } from '../../Modals/ResolvePartialMatchModal';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import { useSWRConfig } from 'swr';
import ConfirmModal from '../../Modals/ConfirmModal/ConfirmModal';
import InlineLoadingIndicator from '../../InlineLoadingIndicator';

type Props = {
  loanApplication: LegacyLoanApplication;
}

export default function UserPartialMatchAlert ({ loanApplication }: Props) {
  const editApplicationIdentityModal = useExpandableState();
  const partialMatchResolvedModal = useExpandableState();

  const [stillPartialMatch, setStillPartialMatch] = useState(false);
  const [submissionError, setSubmissionError] = useState('');

  const resetResultState = () => {
    setStillPartialMatch(false);
  };

  const baseEndpoint = `/notebook/loan-applications/${ loanApplication._id }`;
  const { callAPI: updateAndReprocess, loadPending } = useAdminAPICall({
    endpoint: `${ baseEndpoint }/identity`,
    method: 'PUT',
  });
  const { callAPI: sendNotification } = useAdminAPICall({
    endpoint: `${ baseEndpoint }/continue-notification`,
    method: 'POST',
  });
  const { mutate } = useSWRConfig();

  const handleSubmit = (form: FormKeys = {}) => {
    resetResultState();

    updateAndReprocess({ data: form }).then(response => {
      mutate(`/notebook/loan-applications/${ loanApplication._id }`);
      const stillPartialMatch = response.data.matchedUserIds.length || response.data.matchedPoaUserIds.length;
      if (stillPartialMatch) {
        setStillPartialMatch(true);
        editApplicationIdentityModal.hideExpand();
      } else {
        mutate(baseEndpoint);
        editApplicationIdentityModal.hideExpand();
        partialMatchResolvedModal.showExpand();
      }
    }).catch(error => { setSubmissionError(error.response?.data ?? 'Unknown error occurred') });
  };

  const handleRetry = () => {
    handleSubmit();
  };

  const onClose = () => {
    editApplicationIdentityModal.hideExpand();
    resetResultState();
    mutate(baseEndpoint);
  };

  const handleSendNotification = () => {
    sendNotification();
    partialMatchResolvedModal.hideExpand();
  };


  const isBorrowerPartialMatch = loanApplication.matchedUsers.length > 0;
  const isPoaPartialMatch = loanApplication.matchedPoaUsers.length > 0;

  return (
    <>
      { loanApplication.currentView === 'user-partial-match' &&
        <Alert variant='warning' data-testid='user-partial-match-alert'>
          <div className='d-flex justify-content-between'>
            <strong>Info partially matches another user</strong>
            <Alert variant='danger' show={ stillPartialMatch } className='py-0 px-2 m-0'>
              Partial match still exists
            </Alert>
            <span>
              <span className='mr-4'>
                {
                  loadPending ?
                  <InlineLoadingIndicator /> :
                  <InlineButton className='text-secondary' onClick={ handleRetry }>
                    <u>Retry</u>
                  </InlineButton>
                }
              </span>
              <InlineButton className='text-secondary' onClick={ editApplicationIdentityModal.showExpand }>
                <u>Edit this application</u>
              </InlineButton>
            </span>
          </div>
          {
            isBorrowerPartialMatch &&
            <div className='mt-2'>
              Borrower info partially matches:
              <div>{ mapUsersToLinks(loanApplication.matchedUsers) }</div>
            </div>
          }
          {
            isPoaPartialMatch &&
            <div className='mt-2'>
              POA info partially matches:
              <div>{ mapUsersToLinks(loanApplication.matchedPoaUsers) }</div>
            </div>
          }
        </Alert>
      }

      <ResolvePartialMatchModal
        expandable={ editApplicationIdentityModal }
        loanApplication={ loanApplication }
        onSubmit={ handleSubmit }
        onClose={ onClose }
        isPending={ loadPending }
        error={ submissionError }
      />
      <ConfirmModal
        title='Partial match resolved!'
        expand={ partialMatchResolvedModal }
        onConfirm={ handleSendNotification }
        confirmButtonVariant='primary'
      >
        <p>
          Would you like to send an automatic email and text notification to the applicant letting them know
          they can continue their application?
        </p>
      </ConfirmModal>
    </>
  );
}

const mapUsersToLinks = (users: Array<{ _id: string, fullName: string }>) => {
  return users.map((user, i) => (
    <div key={ i }>
      <OutboundLink href={ `/users/${ user._id }` } className='text-secondary'>
        <u>{ user.fullName }</u>
      </OutboundLink>
    </div>
  ));
}
