import React from 'react';
import amortize from 'amortize';
import Decimal from 'decimal.js';
import { Button, Form } from 'react-bootstrap';
import _ from 'lodash';
import ClearIcon from '@material-ui/icons/Clear';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { DEBT_TYPES, ManualDebtModal } from './ManualDebts';
import RATES from './rates';
import { useExpandableState } from '../../utils';
import formatDollars, { formatDollarsWithColor } from '../../utils/formatDollars';
import useAdminAPICall from '../../utils/useAdminAPICall';
import { PROPERTY_DEBT_HELOC_CALCULATOR_KEYS, TIMELINETYPE } from '../../utils/constants';
import useDebtCalculation from './DebtCalculation/useDebtCalculation';
import { LegacyLoanApplication } from '../../api/queries/users/useLoanApplications';

const SUB_ID_TO_FIELD = {
  currentBalance: 'current_balance_cents',
  creditLimit: 'credit_limit_cents',
};

type Calculator = ReturnType<typeof useDebtCalculation>;

type Lien = {
  date: string;
  value: number;
};

type Transaction = {
  Amount: number;
  TxDate: string;
  mortgageAmount: number;
  mortgageOriginationDate: string;
  liens: Array<Lien>;
};

type RateClass = 'low_30' | 'high_30' | 'avg_30';

// https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
function usePrevious(value: string) {
  const ref = React.useRef<string>();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

type IncludeCheckboxProps = {
  calculator: Calculator;
  id: string;
  controlId?: string;
  disabled?: boolean;
};

export function IncludeCheckbox (props: IncludeCheckboxProps) {
  const { calculator, id, controlId, disabled } = props;
  const checked = calculator.selectedRecordIDs.includes(id);
  const prevId = usePrevious(id);
  const prevChecked = calculator.selectedRecordIDs.includes(prevId);
  // Using "layout" effect to avoid visual jank from this state update
  React.useLayoutEffect(() => {
    // Migrate checkbox state to new ID, if ID changes
    if (id !== prevId && prevChecked) {
      const toggleIds = [prevId];
      if (!checked) toggleIds.push(id);
      calculator.toggleCalculatorRecord(...toggleIds);
    }
  }, [calculator, id, checked, prevId, prevChecked]);

  return (
    <Form.Group
      controlId   = { controlId }
      className   = 'd-inline-block ml-2 mb-0'
      style       = {{ width: '1rem' }}
    >
      <Form.Control
        type      = 'checkbox'
        checked   = { checked }
        onChange  = { () => props.calculator.toggleCalculatorRecord(props.id) }
        style     = {{ height: '1rem' }}
        disabled  = { disabled }
      />
    </Form.Group>
  );
}

export function amortizeTransactionFormatted (transaction: Transaction, rate_class: RateClass, type = TIMELINETYPE.TRANSACTION) {
  const result = amortizeTransaction(transaction, rate_class, type);
  if (!result) {
    return (
      <>?</>
    );
  }
  return (
    <>
      {formatDollars(result.balance)}
      <br /><small>@ {formatDollars(result.payment)}/m</small>
    </>
  );
}

function amortizeTransaction (transaction: Transaction, rate_class: RateClass, type = TIMELINETYPE.TRANSACTION) {
  let liens: Array<Lien>;
  switch (type) {
    case TIMELINETYPE.LIENREPORT:
      liens = [{
        value: transaction.Amount,
        date: transaction.TxDate.split('T')[0],
      }];
      break;

    default:
      liens = transaction.liens;
  }
  const individualResults: any = liens.map((lien) => amortizeLien(lien, rate_class));

  if (individualResults.length === 0) {
    return null;
  }

  const inputs: any = [];
  const result = {
    balance: 0,
    payment: 0,
  };

  // Merge amortization results to get an estimated payment
  individualResults.forEach((r: any) => {
    inputs.push(r.inputs);
    result.balance += r.balance;
    result.payment += r.payment;
  });

  return {
    // Pass the computed inputs back for saving
    inputs,
    ...result,
  };
}

function amortizeLien (lien: Lien, rate_class: RateClass) {
  const [year, month] = lien.date.split('-').map(v => parseInt(v));
  const rate = _.get(RATES, [year, rate_class]);
  const now = new Date();
  const thisYear = now.getFullYear();
  const thisMonth = now.getMonth() + 1;
  let amortizeTerm = (thisYear - year) * 12;
  amortizeTerm += thisMonth - month;
  const amount = lien.value;
  const totalTerm = 360;
  if (!rate) {
    if (amortizeTerm < 12) {
      return amount;
    }
    return 0;
  }
  const result = amortize({
    amortizeTerm,
    amount,
    rate,
    totalTerm,
  });
  const inputs = {
    rate,
    rate_class,
    rate_year: year.toString(),
    amortizeTerm,
    totalTerm,
    amount,
  };
  return { inputs, ...result };
}

export function usePropertyDebtCents (loanApplication: LegacyLoanApplication, calculator: Calculator) {
  const getPropertyDebt = (loanApplication: LegacyLoanApplication, key: PROPERTY_DEBT_HELOC_CALCULATOR_KEYS) => {
    if (loanApplication && loanApplication.calculatedPropertyDebt) {
      return loanApplication.calculatedPropertyDebt[key];
    }
    if (loanApplication) {
      return new Decimal(loanApplication.propertyDebt).times(100).toNumber();
    }
  }

  const [debt, setDebt] = React.useState<number>();
  React.useEffect(() => {
    setDebt(getPropertyDebt(loanApplication, calculator.helocCalculatorKey));
  }, [loanApplication, calculator.helocCalculatorKey]);
  return debt;
}

type Props = {
  loanApplication: LegacyLoanApplication;
  calculator: Calculator;
  manualDebtVerification: unknown;
  disabledEdit: boolean;
};

export default function DebtCalculation (props: Props) {
  const { loanApplication, calculator, manualDebtVerification, disabledEdit } = props;
  const propertyDebtCents = usePropertyDebtCents(loanApplication, calculator);
  const [isSaved, setIsSaved] = React.useState(false);
  const [previouslySaved, setPreviouslySaved] = React.useState(Boolean(manualDebtVerification));
  const expand = useExpandableState(true);
  const manualModal = useExpandableState(false);

  const saveReq = useAdminAPICall({
    endpoint: `/notebook/loan-applications/${ loanApplication._id }/manual-debt-verifications`,
    method: 'POST'
  });
  const deleteReq = useAdminAPICall({
    endpoint: `/notebook/loan-applications/${ loanApplication._id }/manual-debt-verification`,
    method: 'DELETE'
  });

  const calculatorBoxStyles = {
    zIndex: 1020,
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'white',
    boxShadow: 'black 0px 0px 4px',
    padding: '0.5rem',
  };

  if (!expand.isExpanded) {
    return (
      <div style={calculatorBoxStyles as any} id='calculator_box' aria-expanded='false'>
        <Button onClick={ expand.showExpand } aria-controls='calculator_box'>
          <PlaylistAddIcon />
          Show Calculator
        </Button>
      </div>
    );
  }


  const selectedRecords: Array<any> = [];
  let crSum = 0;

  async function saveVerification () {
    await saveReq.callAPI({
      data: {
        total_amount_cents: Math.round(crSum * 100), // Floating point
        statedPropertyDebt: `${propertyDebtCents! / 100}`,
        selectedReportedDebts: selectedRecords.map(r => r.saveable),
        helocCalculatorKey: calculator.helocCalculatorKey,
      }
    });
    window.location.reload();
    setPreviouslySaved(true);
    setIsSaved(true);
    // Reset the save status so they can save again if changed
    setTimeout(function () {
      setIsSaved(false);
    }, 2000);
  }

  async function deleteVerification () {
    if (!window.confirm('Are you sure that you want to remove all of the saved debt?')) { return; }
    await deleteReq.callAPI();
    window.location.reload();
  }

  calculator.selectedRecordIDs.forEach((_id: string) => {
    const [id, sub_id] = _id.split('.');
    const record = (calculator.allRecordsById as any)[id];
    if (!record) {
      // The source record may not be loaded (eg a Lien Report), so render a synthetic placeholder
      // if the saved record can be found
      const savedRecord: any = _.get(manualDebtVerification, 'selectedReportedDebts', []).find((saved: any) => saved.key === _id);
      if (!savedRecord) {
        console.warn('Missing source debt record for _id', _id);
        return;
      }
      crSum += savedRecord.amount_cents / 100;
      selectedRecords.push({
        _id,
        record: undefined,
        label: `(previously saved ${savedRecord.source})`,
        amount: savedRecord.amount_cents / 100,
        saveable: savedRecord,
      });
    } else if (record.type === TIMELINETYPE.REPORTED_DEBT) {
      // @todo - refactor `calculateSelectedDebts` logic to ReportedDebts.js?
      // (ditto for propertyTransactions, lienReports, and manual records)
      const raw_amount = sub_id ? record.item[(SUB_ID_TO_FIELD as any)[sub_id]] : record.item.current_balance_cents;
      const amount = parseFloat(raw_amount) / 100;
      crSum += amount;
      selectedRecords.push({
        _id,
        record,
        label: 'cr: ' + record.item.date_opened + ' ' + record.item.account_type,
        amount,
        saveable: {
          key: _id,
          reportedDate: record.item.date,
          reportedType: record.item.account_type,
          source: 'credit_report',
          amount_cents: (new Decimal(amount)).times(100).toNumber(),
          raw_amount,
        },
      });
    } else if (record.type === TIMELINETYPE.TRANSACTION) {
      let amount;
      let amortizedTxn;
      let label = 'pr: ' + record.item.date + ' transaction';
      if (sub_id) {
        amortizedTxn = amortizeTransaction(record.item, sub_id as any);
        amount = amortizedTxn ? amortizedTxn.balance : 0;
        label += ` (est ${sub_id})`;
      } else {
        amount = parseFloat(record.item.debt);
      }
      crSum += amount;
      selectedRecords.push({
        _id,
        record,
        label,
        amount,
        saveable: {
          key: _id,
          reportedDate: record.item.date,
          reportedType: record.item.transactionType,
          source: 'property_record',
          source_id: record.item._id,
          amount_cents: (new Decimal(amount)).times(100).toNumber(),
          raw_amount: record.item.debt,
          amortization: amortizedTxn ? amortizedTxn.inputs : null,
        },
      });
    } else if (record.type === TIMELINETYPE.LIENREPORT) {
      let amount;
      let amortizedTxn;
      let label = `lr: ${record.item.TxDate} ${record.item.TypeDescription}`;
      if (sub_id) {
        amortizedTxn = amortizeTransaction(record.item, sub_id as any, TIMELINETYPE.LIENREPORT);
        amount = amortizedTxn ? amortizedTxn.balance : 0;
        label += ` (est ${sub_id})`;
      } else {
        amount = parseFloat(record.item.Amount);
      }
      crSum += amount;
      selectedRecords.push({
        _id,
        record,
        label,
        amount,
        saveable: {
          key: _id,
          reportedDate: record.item.TxDate,
          reportedType: record.item.TransactionType,
          source: 'lien_report',
          amount_cents: (new Decimal(amount)).times(100).toNumber(),
          raw_amount: record.item.Amount,
          amortization: amortizedTxn ? amortizedTxn.inputs : null,
        },
      });
    } else if (record.type === TIMELINETYPE.MANUAL) {
      let raw_amount;
      let label = `mr: ${record.item.date_opened || '—' } ${record.item.debt_type}`;
      if (record.item.debt_type === DEBT_TYPES.MORTGAGE) {
        raw_amount = record.item.remaining_balance;
      } else {
        raw_amount = record.item.credit_limit;
      }
      const amount = parseFloat(raw_amount);
      crSum += amount;
      selectedRecords.push({
        _id,
        record,
        label,
        amount,
        saveable: {
          key: _id,
          reportedDate: record.item.date_opened,
          reportedType: record.item.debt_type,
          source: 'manual_entry',
          amount_cents: (new Decimal(amount)).times(100).toNumber(),
          raw_amount,
          manual_entry: record.item,
        },
      });
    }
  });
  const pdFloat = propertyDebtCents! / 100;
  const crDelta = pdFloat - crSum;
  const crDeltaPct = (100 * crDelta / pdFloat);
  let crColor = 'orange';
  if (Math.abs(crDeltaPct) < 15) {
    crColor = 'blue';
  }
  const copyableLabel = selectedRecords.map(v => v.label + ' ' + v.amount.toFixed(2)).join('; ');
  return (
    <div style={calculatorBoxStyles as any} aria-expanded='true' id='calculator_box'>
      <Button variant='secondary' style={{ float: 'right' }} onClick={ expand.hideExpand } title='Hide Calculator' aria-controls='calculator_box'>
        <VisibilityOffIcon />
      </Button>
      <h3>
        { loanApplication.firstName } { loanApplication.lastName }
        :&nbsp;
        { formatDollarsWithColor(propertyDebtCents! / 100) }
      </h3>
      <Button onClick={ manualModal.toggleExpand } variant='link' disabled={disabledEdit}>
        Add Debt Record
      </Button>
      {
        previouslySaved && (
          <Button onClick={ deleteVerification } variant='link' disabled={disabledEdit}>
            Remove Saved Debt
          </Button>
        )
      }

      {
        selectedRecords.length === 0 ? null : (
          <>
            <div className='d-flex'>
              <table>
                <thead>
                  <tr>
                    <th>Selected CR</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    selectedRecords.map((v) => (
                      <tr key={v._id}>
                        <td style={{ textAlign: 'right' }}>+{formatDollars(v.amount)}</td>
                        <td style={{ textAlign: 'left' }}><small style={{ paddingLeft: '1rem', color: 'gray' }}>{v.label}</small>
                          <button
                            title    = 'Remove from calculation'
                            style    = {{ color: 'red', border: 0, padding: '0 0.25em', background: 'transparent' }}
                            onClick  = {() => calculator.toggleCalculatorRecord(v._id)}
                            disabled = {disabledEdit}
                          >
                            <ClearIcon style={{ fontSize: '1rem' }} />
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                  <tr>
                    <td data-qa='crSum'><strong>= {formatDollars(crSum)}</strong></td>
                    <td><small style={{ paddingLeft: '1rem', color: crColor }}>{formatDollars(crDelta)} ({crDeltaPct.toFixed(0)}%)</small></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />
            <div>
              <div>"Manual"</div>
              <input style={{ display: 'block', width: '100%' }} value={crSum.toFixed(2)} disabled />
              <div>"Notes"</div>
              <textarea style={{ display: 'block', width: '100%' }} value={copyableLabel} disabled />
            </div>
            <div className='pt-3'>
              <Button
                variant   = 'success'
                onClick   = { saveVerification }
                disabled  = { isSaved || disabledEdit}
                data-qa   = 'save_button'
              >
                { isSaved ? 'Saved!' : previouslySaved ? 'Save Again' : 'Save' }
              </Button>
            </div>
          </>
        )
      }
      {
        manualModal.isExpanded ? (
          <ManualDebtModal
            modal     = { manualModal }
            onSubmit  = { calculator.addManualDebtRecord }
          />
        ) : null
      }
    </div>
  );
}

