import React from 'react';
import GenericModal from '../GenericModal';
import ModifyEligiblePortfolioForm from './ModifyEligiblePortfolioForm';
import useExpandableState from '../../../utils/useExpandableState';
import { Loan } from '../../../api/queries/types';

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  loan: Loan,
  onSubmit: (data: unknown) => void;
};

export default function ModifyEligiblePortfolioModal(props: Props) {
  const { expand, loan, onSubmit } = props;
  return (
    <GenericModal expandable={expand} title="Modify Eligible Portfolios" size="lg">
      <ModifyEligiblePortfolioForm loan={loan} onHide={expand.hideExpand} onSubmit={onSubmit} />
    </GenericModal>
  );
}
