import useQuery from "../../shared/useQuery";
import { State } from "../partners/usePartner";

export type Portfolio = {
  key: string;
  name: string;
  states: Array<State>;
}

export function usePortfolio (portfolioKey: string) {
  return useQuery<Portfolio>(`/notebook/portfolios/${ portfolioKey }`);
}
