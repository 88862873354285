import useQuery from '../../shared/useQuery';

export type PaymentDetails = Array<{
  payment_id: string;
  payment_type: PaymentType;
  effective_date: string;
  submitted_date: string;
  bank_post_date: string;
  last_four: string;
  autopay_generated: boolean;
  is_retry: boolean;
  status: string;
  amount_towards_fees: number;
  amount_towards_interest: number;
  amount_towards_principal: number;
  amount_towards_this_obligation: number;
  amount: number;
}>;

export enum PaymentType {
  ACH = 'ach',
  CASH_BACK = 'cash_back',
}

export default function usePaymentDetails (obligationId?: string | null) {
  return useQuery<PaymentDetails>(obligationId ? `/notebook/obligations/${obligationId}/payment-details` : null);
}
