import React from 'react';
import { Alert } from 'react-bootstrap';

import AdminUserBadge from '../../AdminUserBadge';
import {
  UNDERWRITING_OUTCOMES,
  DENIAL_CODES_INDICATING_INELIGIBLE,
} from '../../../utils/constants';
import { getDateInTimezone } from '../../../utils/datetime';
import { UnderwritingDecision } from '../../../api/queries/underwriting-decisions/useLatest';

export default function IneligibleApplicationAlert ({ underwritingDecision }: { underwritingDecision?: UnderwritingDecision }) {
  const ineligibleLoanApp = underwritingDecision && underwritingDecision.outcome === UNDERWRITING_OUTCOMES.INELIGIBLE;

  return ineligibleLoanApp ? (
      <Alert variant='secondary' data-qa="ineligibility-alert">
        { getDateInTimezone(underwritingDecision.decision_date).format('ll') }
        <span> - Ineligible <AdminUserBadge admin_id={ underwritingDecision.admin_id } addBy={ true } /> </span>
        { underwritingDecision.notes && <span> : "{ underwritingDecision.notes }"</span> }
        {
          underwritingDecision.ineligibility_codes &&
            <ul>
              {underwritingDecision.ineligibility_codes.map(code => {
                const reason = DENIAL_CODES_INDICATING_INELIGIBLE[code as keyof typeof DENIAL_CODES_INDICATING_INELIGIBLE].reason;
                return (
                  <li key={`${underwritingDecision.admin_id}-${reason}`}>
                    { reason }
                  </li>
                );
              })}
            </ul>
        }
      </Alert>
    ) : null
}
