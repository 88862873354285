import React from 'react';
import { Loan } from '../api/queries/types';
import ErrorBoundary from './ErrorBoundary';
import { Button, Card, Col, Row } from 'react-bootstrap';
import useTitle from '../api/queries/loans/useTitle';
import formatPhone from '../utils/formatPhone';
import useExpandableState from '../utils/useExpandableState';
import EditTitleDetailsModal from './Modals/EditTitleDetailsModal';

export default function TitleDetailsWidget ({ loan }: { loan: Loan }) {
  const titleRequest = useTitle(loan._id);
  const noTitleInfo = titleRequest.error?.request?.status === 404;
  const expandable = useExpandableState();

  if (!loan) {
    return <>Loading...</>;
  }

  return (
    <ErrorBoundary errorText='Error loading the Title Details widget'>
      <Row className='mb-5'>
        <Col>
          <div className='d-flex justify-content-between mb-1'>
            <h4 className='mb-3'>Title  Details</h4>
            <div>
              <Button data-qa='add-title' variant='link' onClick={ () => expandable.showExpand() }>
                { noTitleInfo ? 'Add' : 'Update' }
              </Button>
            </div>
          </div>
          <Card className='p-3'>
            { noTitleInfo ? <span className='text-muted'>No title details.</span> :
              <div>
                <div><b>Title Company:</b> {titleRequest.data?.companyName}</div>
                <div><b>Title Agent:</b> {titleRequest.data?.agentName}</div>
                <div><b>Title Agent Email:</b> {titleRequest.data?.agentEmail}</div>
                <div><b>Title Agent Phone:</b> {formatPhone(titleRequest.data?.agentPhone)}</div>
              </div>
            }
          </Card>
        </Col>
        { expandable.isExpanded && <EditTitleDetailsModal
          expandable={expandable}
          onSubmit={() => titleRequest.mutate()}
          loanId={loan._id}
          titleDetails={titleRequest.data}
        /> }

      </Row>
    </ErrorBoundary>
  );
}
