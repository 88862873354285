import React from 'react'
import { Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../../Form/Form';
import MultiSelectField from '../../Form/MultiSelectField';
import SubmitError from '../../Form/SubmitError';

import useAdminAPICall from '../../../utils/useAdminAPICall'
import ButtonWithSpinner from '../../ButtonWithSpinner';
import { Loan } from '../../../api/queries/types';
import usePortfolios from '../../../api/queries/partners/usePortfolios';

type Props = {
  onHide: () => void,
  onSubmit: (data: unknown) => void,
  loan: Loan,
};

type FieldValues = {
  eligiblePortfolios: Array<string>;
};

function ModifyLoanForm(props: Props) {
  const { loan, onHide, onSubmit } = props
  const portfolios = usePortfolios(loan.partnerId);

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loans/${loan._id}/eligible-portfolio`,
    method: 'PUT',
  })

  const handleSubmit = async (form: FieldValues) => {
    await callAPI({ data: form });
    onSubmit(form);
    onHide();
  }

  const schema = yup.object({
    eligiblePortfolios: yup.array(yup.string()).required('Please select at least one eligible portfolio'),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      eligiblePortfolios: loan.eligiblePortfolios.map(portfolio => portfolio.key),
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty } } = methods;
  const portfolioOptions = portfolios.data?.map(portfolio => ({
    value: portfolio.key,
    label: portfolio.name,
    disabled: portfolio.isFallbackPortfolio,
  })) || [];

  return (
    <>
      <Form {...methods} onSubmit={handleSubmit}>
        <MultiSelectField required name='eligiblePortfolios' placeholder='Please select...' label='Eligibile Portfolios' options={portfolioOptions} />
        <SubmitError/>

        <div className="d-flex justify-content-end mt-4">
          <Button variant="secondary" onClick={onHide} className="mr-2">
            Cancel
          </Button>
          <ButtonWithSpinner variant="primary" type="submit" loading={isSubmitting} disabled={!isDirty}>
            Modify
          </ButtonWithSpinner>
        </div>
      </Form>
    </>
  )
}

export default ModifyLoanForm
