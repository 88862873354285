import React from 'react';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import { LegacyLoanApplication } from "../../../api/queries/users/useLoanApplications";
import { formatDollars } from '../../../utils';
import AddressDisplay from '../../AddressDisplay';
import LabelValueGrouping from '../../LabelValueGrouping';
import ExplainerPopover from '../../ExplainerPopover';

export default function HomeCardListingInfo ({ className, loanApplication }: { className: string, loanApplication: LegacyLoanApplication}) {
  const dataForValueGrouping: Record<string, React.ReactNode> = {
    'List Price': formatDollars(loanApplication.listingPrice),
    'AVM': loanApplication.zestimateFound ?
      <>
        { formatDollars(loanApplication.avmMinimum) }&thinsp;&ndash;&thinsp;{ formatDollars(loanApplication.avmMaximum) }
      </>
      :
      <ExplainerPopover explanation='Zestimate not found. Using synthetic AVM from borrower-provided listing price.'>
        <Badge variant='warning'>Not found</Badge>
      </ExplainerPopover>,
    'List Address': (
      <AddressDisplay
        line1={loanApplication.listing_address_line1}
        line2={loanApplication.listing_address_line2}
        city={loanApplication.listing_city}
        state={loanApplication.listing_state}
        zip={loanApplication.listing_zip}
      />
    ),
  };

  return (
    <Card className={className}>
      <Card.Header>
        Listing
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <LabelValueGrouping
              data = {dataForValueGrouping}
              condensed
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
