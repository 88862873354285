import React from 'react';
import { Badge } from 'react-bootstrap';

export default function LoanApplicationFromAgentBadge (props: { className?: string }) {
  const pillVariant = 'info';
  return (
    <Badge pill variant={ pillVariant } className={ props.className }>
      AGENT BORROWER
    </Badge>
  );
}
