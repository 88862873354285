import React from 'react';
import { Badge } from 'react-bootstrap';

export default function LoanPastDueDaysBadge ({ days }: { days: number }) {
  const variant = days < 30 ? 'info' : 'danger';
  return (
    <Badge className='text-uppercase' pill variant={ variant }>
      { days } { days > 1 ? 'days' : 'day' } past due
    </Badge>
  );
}
