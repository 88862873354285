import React from 'react';
import InlineLoadingIndicator from './InlineLoadingIndicator';
import InlineError from './InlineError';
import { Card, Container, Table } from 'react-bootstrap';
import useAccounting from '../api/queries/loans/useAccounting';
import cx from 'classnames';
import ExplainerPopover from './ExplainerPopover';
import { formatCentsAccountingNotation } from '../utils/formatCents';

export default function AccountingSummary (props: { loanId: string }) {
  const { data, error } = useAccounting(props.loanId);
  if (!data) {
    return <InlineLoadingIndicator />
  }
  if (error) {
    return <InlineError>Error loading accounting summary.</InlineError>
  }

  return (
    <Container className='pl-0 mb-5'>
      <h4 className='mb-3'>Accounting</h4>
      <Card className='p-3'>
        <Table className='mb-0' size='sm' responsive={ true } >
          <thead className='border-bottom-0'>
            <tr>
              <th style={{ width: '30%', borderTop: 0 }} className='border-bottom-0'>Account</th>
              <th className="text-right border-bottom-0" style={{ width: '20%', borderTop: 0 }}>Disbursed/Accrued</th>
              <th className="text-right border-bottom-0" style={{ width: '20%', borderTop: 0 }}>Repaid/Applied</th>
              <th className="text-right border-bottom-0" style={{ width: '20%', borderTop: 0 }}>Balance</th>
            </tr>
          </thead>
          <tbody>
            <AccountRow account='Principal (cleared)' values={ data.clearedPrincipal } />
            <AccountRow account='Principal (pending)' values={ data.pendingPrincipal } />
            <AccountRow account='Interest' values={ data.interest } />
            <AccountRow account='Fees' values={ data.fees } />
            <AccountRow account='Overpayment' values={ data.overpayments } />
            <AccountRow account='Total' values={ data.total } bold />
          </tbody>
          <br />
          <tbody>
            <AccountRow account='Cash Back' values={ data.cashBack } noTopBorder />
            <AccountRow
              account='Write-Offs / Adjustments'
              values={ data.adjustments }
              tooltipRepaidApplied='This value is calculated as writeoffs + debt forgiveness + collection fees.' />
            <AccountRow account='Cash Repayments' values={ data.cashRepayments } />
            <AccountRow account='Unclaimed Funds' values={ data.unclaimedFunds } />
          </tbody>
        </Table>
      </Card>
    </Container>
  );
}

interface TableRowProps {
  account: string;
  values: {
    disbursedAccrued?: number;
    repaidApplied?: number;
    balance?: number;
  },
  bold?: boolean;
  noTopBorder?: boolean;
  tooltipDisbursedAccrued?: string;
  tooltipRepaidApplied?: string;
  tooltipBalance?: string;
};

const AccountRow = ({
  account,
  values: {
    disbursedAccrued,
    repaidApplied,
    balance
  },
  bold,
  noTopBorder,
  tooltipDisbursedAccrued,
  tooltipRepaidApplied,
  tooltipBalance,
}: TableRowProps) => {
  const cellClass = cx({
    'font-weight-bold': bold,
    'border-top-0': noTopBorder,
  });

  const disbursedAccruedValue = disbursedAccrued == null ? '' : formatCentsAccountingNotation(disbursedAccrued);
  const repaidAppliedValue = repaidApplied == null ? '' : formatCentsAccountingNotation(repaidApplied);
  const balanceValue = balance == null ? '' : formatCentsAccountingNotation(balance);

  const disbursedAccruedColumn = tooltipDisbursedAccrued ? <ExplainerPopover explanation={ tooltipDisbursedAccrued }>{ disbursedAccruedValue }</ExplainerPopover> : disbursedAccruedValue;
  const repaidAppliedColumn = tooltipRepaidApplied ? <ExplainerPopover explanation={ tooltipRepaidApplied }>{ repaidAppliedValue }</ExplainerPopover> : repaidAppliedValue;
  const balanceColumn = tooltipBalance ? <ExplainerPopover explanation={ tooltipBalance }>{ balanceValue }</ExplainerPopover> : balanceValue;

  return (
    <tr>
      <td className={ cellClass }>{ account }</td>
      <td className={ cellClass }>{ disbursedAccruedColumn }</td>
      <td className={ cellClass }>{ repaidAppliedColumn }</td>
      <td className={ cellClass }>{ balanceColumn }</td>
    </tr>
  )
}
