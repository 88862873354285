import React from 'react';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import DocViewerModal from '../DocViewerModal';
import LabelValueGrouping from '../../LabelValueGrouping';
import OutboundLink from '../../OutboundLink';
import { PURCHASE_METHODS } from '../../../utils/constants';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import { formatFileName } from '../../../utils/formatData';
import CONFIG from '../../../config';
import useExpandableState from '../../../utils/useExpandableState';
import ButtonWithSpinner from '../../ButtonWithSpinner';

type Props = {
  expandable: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
  purchaseMethod: {
    _id: string;
    accountNumber: string;
    approvedAt: string | null;
    plaidItemId: string | null;
    routingNumber: string;
    type: string;
    voidedCheckPhoto: string | null;
  };
};

export default function VerifyBankModal({ expandable, onSubmit, purchaseMethod }: Props) {
  const documentUri = purchaseMethod.voidedCheckPhoto
    ? `${ CONFIG.API_BASE_URL }/notebook/s3/user-docs/img/${ encodeURIComponent(purchaseMethod.voidedCheckPhoto) }`
    : null;

  const approvePurchaseMethodReq = useAdminAPICall({
    endpoint: `/notebook/purchase-methods/${purchaseMethod._id}/approve`,
    method: 'POST',
  });

  const approvePurchaseMethod = () => {
    approvePurchaseMethodReq
      .callAPI()
      .then(() => onSubmit());
  };

  const infoList = {
    'Destination Account': purchaseMethod.accountNumber,
    'Routing Number': purchaseMethod.routingNumber,
    Filename: (
      <div className="d-flex align-items-center">
        {documentUri && (
          <>
            <span className="mr-2">{formatFileName(purchaseMethod.voidedCheckPhoto)}</span>
            <OutboundLink href={documentUri}>
              <DownloadIcon />
            </OutboundLink>
          </>
        )}
      </div>
    ),
  };

  if (!expandable.isExpanded) {
    return null;
  }

  return (
    <DocViewerModal
      expandable={expandable}
      title="Verify Bank Account"
      document={purchaseMethod.voidedCheckPhoto}
      infoContent={<LabelValueGrouping data={infoList} />}
      actionContent={
        purchaseMethod.type === PURCHASE_METHODS.DISBURSEMENT_TO_BORROWER &&
        !purchaseMethod.plaidItemId &&
        !purchaseMethod.approvedAt ? (
          <ButtonWithSpinner block className="w-100" variant="primary" disabled={approvePurchaseMethodReq.loadComplete} loading={approvePurchaseMethodReq.loadPending} onClick={approvePurchaseMethod}>
            Verify Bank Account
          </ButtonWithSpinner>
        ) : null
      }
    />
  );
}
