import React from "react";
import { Button } from "react-bootstrap";
import useAdminAPICall from "../../utils/useAdminAPICall";
import ButtonWithSpinner from "../ButtonWithSpinner";
import GenericModal from "./GenericModal";
import { Loan } from "../../api/queries/types";

type ModalProps = {
  modal: any,
  loan: Loan,
}

export function CancelPortfolioTransferModal (props: ModalProps) {
  const { modal, loan } = props;

  const { callAPI, loadPending } = useAdminAPICall({
    endpoint: `/notebook/loans/${loan._id}/portfolio-transfer`,
    method: 'DELETE',
  });

  const handleCancelPortfolioTransfer = async () => {
    await callAPI({
      data: {
        portfolioTransferId: loan.pendingPortfolioTransfer!._id
      },
    });
    modal.hideExpand();
    window.location.reload();
  };

  const footerChildren = <>
    <Button variant='secondary' onClick={() => modal.hideExpand()}>
      No
    </Button>
    <ButtonWithSpinner variant='primary' loading={loadPending} onClick={() => handleCancelPortfolioTransfer()}>
      Yes
    </ButtonWithSpinner>
  </>;
  return (
    <GenericModal expandable={modal} title='Cancel Transfer' size='sm' footerChildren={footerChildren} >
      <p>Cancelling this transfer will keep this loan in the Notable Portfolio.</p>
      <p className='mb-0' >Do you want to continue?</p>
    </GenericModal>
  );
};
