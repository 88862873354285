import React from 'react';
import { Flag } from '../../index';

export type ExcessiveNewTradelinesFlagData = {
  amount: number;
  threshold: number;
}

export default function ExcessiveNewTradelinesFlagDetails ({ flag }: { flag: Flag<ExcessiveNewTradelinesFlagData> }) {
  return (
    <div className='text-secondary'>
      {flag.data.amount} in last {flag.data.threshold} days
    </div>
  );
}
