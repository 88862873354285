import useQuery from '../../shared/useQuery';

type PropertyTransactionBayer = {
  name: string;
};

type Lien = {
  _id: string;
  value: number;
  date: string;
  lender: string;
  rate_type: string;
};

type PropertyTransaction = {
  _id: string;
  loan_application_id: string;
  date: string;
  buyer: Array<PropertyTransactionBayer>;
  seller: Array<PropertyTransactionBayer>;
  amount: number;
  debt: number;
  transactionType: string;
  liens: Array<Lien>;
};

export default function usePropertyTransactions (loanApplicationId: string) {
  return useQuery<Array<PropertyTransaction>>(`/notebook/loan-applications/${loanApplicationId}/property-transactions`);
}
