import React from "react";
import { Badge } from "react-bootstrap";

import { RULE_DECISION } from "./constants";

function RuleDecisionBadge({ ruleDecision }: { ruleDecision: string }) {
  switch (ruleDecision) {
    case RULE_DECISION.PASS:
      return <Badge variant="success">PASS</Badge>;
    case RULE_DECISION.FAIL:
      return <Badge variant="danger">FAIL</Badge>;
    case RULE_DECISION.UNDECIDED:
    default:
      return <Badge variant="secondary">UNDECIDED</Badge>;
  }
}

export default RuleDecisionBadge;
