import React from 'react';
import { useParams } from 'react-router-dom';
import SystemLayout from '../../Layouts/SystemLayout';
import ShowInvestorLayout from '../../Layouts/ShowInvestorLayout';
import { ReportDetail, useReport } from '../../../api/queries/investors/useReports';
import { Table } from 'react-bootstrap';
import { LabelValueGrouping } from '../../../components';
import CONFIG from '../../../config';
import { titleCase } from '../../../utils/string';
import InlineButton from '../../../components/InlineButton';
import InlineLoadingIndicator from '../../../components/InlineLoadingIndicator';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import { useSWRConfig } from 'swr';
import ExplainerPopover from '../../../components/ExplainerPopover';

function DeliveryStatus ({ delivery }: { delivery: ReportDetail['files'][0]['deliveries'][0] }) {
  if (delivery.delivered_at) {
    return <span title='Delivered'>✅</span>;
  }
  if (delivery.failed_at) {
    return <span title='Failed'>❌</span>;
  }
  return <span title='Pending'>🟡</span>;
}

function RetryDeliveryButton ({ report }: { report: ReportDetail }) {
  const { callAPI: retryDelivery, loadPending } = useAdminAPICall({
    endpoint: `/notebook/reports/${ report._id }/retry-delivery`,
    method: 'POST',
  });
  const { mutate } = useSWRConfig();

  const canRetryDelivery = !report.isDelivered;

  const handleRetry = () => {
    retryDelivery().then(() => {
      mutate(`/notebook/reports/${ report._id }`);
    });
  }

  return canRetryDelivery ? (
    loadPending ? (
      <InlineLoadingIndicator />
    ) : (
      <ExplainerPopover explanation='Will retry delivery only for files that have not been delivered.' >
        <InlineButton onClick={ handleRetry }>Retry Delivery</InlineButton>
      </ExplainerPopover>
    )
  ) : null;
}


export default function InvestorsShowReportDetailPage () {
  const params = useParams<{ investorKey: string, reportId: string }>();
  const investorKey = params.investorKey as string;
  const reportId = params.reportId as string;
  const report = useReport(reportId);
  if (!report.data) {
    return <>Loading…</>;
  }

  return (
    <SystemLayout selectedTab='investors' heading={titleCase(investorKey)}>
      <ShowInvestorLayout
        investorKey={ investorKey }
        selectedTab='reports'
        heading={ `${ report.data.type } ${ report.data.report_date }` }
      >
        <div>
          <LabelValueGrouping condensed data={{
            'Type': report.data.type,
            'Report Date': report.data.report_date,
            'Delivered': report.data.isDelivered ? 'Yes' : 'No',
          }} />
          <br />
          <h4>Files</h4>
          <Table>
            <thead>
              <tr>
                <th>Generated At</th>
                <th>Delivered At</th>
                <th>Delivery Type</th>
                <th>Filename</th>
              </tr>
            </thead>
            <tbody>
              {
                report.data.files.map((file) => (
                  <tr key={ file._id }>
                    <td>{ file.generated_at }</td>
                    <td>{ file.delivered_at || '—'}</td>
                    <td>{
                      file.deliveries.map(d => (
                        <div key={d._id}>{ d.delivery_type } <DeliveryStatus delivery={d} /></div>
                      ))}
                      <RetryDeliveryButton report={ report.data! } />
                    </td>
                    <td>
                      <a href={ `${ CONFIG.API_BASE_URL }/notebook/reports/report-files/${ file._id }/content` } download={ file.filename }>
                        { file.filename }
                      </a>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </div>
      </ShowInvestorLayout>
    </SystemLayout>
  );
}
