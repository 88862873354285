import useQuery from '../../../shared/useQuery';

export type DelayedJob = {
  _id: string;
  handler: string;
  status: string;
  lastError: string;
};

export default function useLatestKustomerJob (partnerId?: string) {
  return useQuery<DelayedJob>(partnerId ? `/notebook/partners/${partnerId}/delayed-jobs/push-to-kustomer/latest` : null);
}
