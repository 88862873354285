import React from 'react';
import LoanPartnerBadge from './Badges/LoanPartnerBadge';
import { formatDollars } from '../utils';
import { LOAN_APPLICATION_SOURCES, LOAN_APPLICATION_STATUSES, UNDERWRITING_OUTCOMES } from '../utils/constants';
import { Card } from 'react-bootstrap';
import cx from 'classnames';
import { LegacyLoanApplication } from '../api/queries/users/useLoanApplications';
import LoanApplicationStatusBadge from './Badges/LoanApplicationStatusBadge';
import useLatest from '../api/queries/underwriting-decisions/useLatest';
import LoanApplicationUnderwritingDecisionBadge from './Badges/LoanApplicationUnderwritingDecisionBadge';
import { get } from 'lodash';
import LoanApplicationFromAgentBadge from './Badges/LoanApplicationFromAgentBadge';
import PausedBadge from './Badges/PausedBadge';
import { useNavigate } from 'react-router-dom';
import styles from './LoanSummaryCard.module.scss';
import POABadge from './Badges/POABadge';

type Props = {
  loanApplication: LegacyLoanApplication;
}

export default function LoanApplicationSummaryCard (props: Props) {
  const { loanApplication } = props;
  const navigate = useNavigate();
  const { data: underwritingDecision } = useLatest(loanApplication._id, loanApplication.status === LOAN_APPLICATION_STATUSES.CLOSED);
  const ineligibleLoanApp = underwritingDecision && underwritingDecision.outcome === UNDERWRITING_OUTCOMES.INELIGIBLE;
  const agentLoanApp = get(loanApplication,'underwritingResult.LOAN_APPLICATION_FROM_AGENT.failed', false);

  return (
    <Card>
      <Card.Body className='cursor-pointer' onClick={() => navigate(`/loan-applications/${loanApplication._id}`)}>
        <div>
          <Card.Title as="span" className="h5">
            { loanApplication.firstName } { loanApplication.lastName } { loanApplication.principal ? `· ${formatDollars(loanApplication.principal)}` : '' }
          </Card.Title>
        </div>
        <div className={cx('mt-1', styles.badges)}>
          <LoanPartnerBadge partnerId={ loanApplication.partner._id } source={loanApplication.isSourceBetter ? LOAN_APPLICATION_SOURCES.BETTER : LOAN_APPLICATION_SOURCES.STATED} />
          <LoanApplicationStatusBadge status={ loanApplication.status } />
          {ineligibleLoanApp &&
            <LoanApplicationUnderwritingDecisionBadge outcome={underwritingDecision.outcome} />
          }
          {agentLoanApp &&
            <LoanApplicationFromAgentBadge />
          }
          { loanApplication.isUserPaused && <PausedBadge/> }
          { loanApplication.isPOA && <POABadge /> }
        </div>
      </Card.Body>
    </Card>
  );
}
