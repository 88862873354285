import React from 'react';
import { Partner } from '../../../api/queries/partners/usePartner';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import SubmitError from '../../Form/SubmitError';
import { Button } from 'react-bootstrap';
import ButtonWithSpinner from '../../ButtonWithSpinner';

type PartnerInfoFields = {
  emailHeaderLogoUrl: string;
  funnelApplyScreenLogoUrl: string;
  funnelIntroBackground1Url: string;
  funnelIntroBackground2Url: string;
  funnelIntroBackground3Url: string;
  funnelSidebarLogoUrl: string;
  portalNavbarLogoUrl: string;
  marqetaCardLogoUrl: string;
};

type Props = {
  partner: Partner;
  onSubmit: () => void;
  onHide: () => void;
}

export default function EditPartnerLogos (props: Props) {
  const { partner, onSubmit, onHide } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/partners/${partner._id}`,
    method: 'PUT',
  });

  async function handleSubmit(data: PartnerInfoFields) {
    await callAPI({
      data: {
        emailHeaderLogoUrl: data.emailHeaderLogoUrl,
        funnelApplyScreenLogoUrl: data.funnelApplyScreenLogoUrl,
        funnelIntroBackground1Url: data.funnelIntroBackground1Url,
        funnelIntroBackground2Url: data.funnelIntroBackground2Url,
        funnelIntroBackground3Url: data.funnelIntroBackground3Url,
        funnelSidebarLogoUrl: data.funnelSidebarLogoUrl,
        portalNavbarLogoUrl: data.portalNavbarLogoUrl,
        marqetaCardLogoUrl: data.marqetaCardLogoUrl,
      },
    });
    onSubmit();
    onHide();
  }

  const schema = yup.object();

  const methods = useForm<PartnerInfoFields>({
    defaultValues: {
      emailHeaderLogoUrl: partner.emailHeaderLogoUrl,
      funnelApplyScreenLogoUrl: partner.funnelApplyScreenLogoUrl,
      funnelIntroBackground1Url: partner.funnelIntroBackground1Url,
      funnelIntroBackground2Url: partner.funnelIntroBackground2Url,
      funnelIntroBackground3Url: partner.funnelIntroBackground3Url,
      funnelSidebarLogoUrl: partner.funnelSidebarLogoUrl,
      portalNavbarLogoUrl: partner.portalNavbarLogoUrl,
      marqetaCardLogoUrl: partner.marqetaCardLogoUrl,
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty } } = methods;

  return <div>
    <Form {...methods} onSubmit={handleSubmit}>
      <Field name='emailHeaderLogoUrl' label='Email Header Logo URL' required/>
      <Field name='funnelApplyScreenLogoUrl' label='Funnel Apply Screen Background'/>
      <Field name='funnelIntroBackground1Url' label='Funnel Intro Screen Background Step 1'/>
      <Field name='funnelIntroBackground2Url' label='Funnel Intro Screen Background Step 2'/>
      <Field name='funnelIntroBackground3Url' label='Funnel Intro Screen Background Step 3'/>
      <Field name='funnelSidebarLogoUrl' label='Funnel Sidebar Logo & Modal Logo' required/>
      <Field name='portalNavbarLogoUrl' label='Portal Navbar Logo' required/>
      <Field name='marqetaCardLogoUrl' label='Physical Card Image' required/>

      <SubmitError/>

      <div className="d-flex justify-content-end mt-4">
        <Button variant="secondary" onClick={onHide} className="mr-2">
          Cancel
        </Button>
        <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
          Confirm
        </ButtonWithSpinner>
      </div>
    </Form>
  </div>;
}
