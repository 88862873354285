import React from 'react';
import GenericModal from './GenericModal';
import useAdminAPICall from "../../utils/useAdminAPICall";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../Form/Form';
import SubmitError from '../Form/SubmitError';
import useExpandableState from '../../utils/useExpandableState';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { Partner } from '../../api/queries/partners/usePartner';

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
  partner: Partner;
};

export default function RePullAgentsFromRemote (props: Props) {
  const { expand, onSubmit, partner } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/partners/${partner._id}/agents/pull`,
    method: 'POST',
  });

  const schema = yup.object().shape({});

  const methods = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });
  const { formState: { isSubmitting }, setError } = methods;

  async function handleSubmit() {
    try {
      await callAPI();
      onSubmit();
      expand.hideExpand();
    } catch (e: any) {
      if (e.response.status === 422) {
        setError('submitError' as any, { message: e.response.data });
      } else {
        throw e;
      }
    }
  }

  return (
    <GenericModal expandable={expand} title={'Re-pull Agents from Remote'}>
      <Form {...methods} onSubmit={handleSubmit}>
        <SubmitError/>
        <div className="d-flex justify-content-end mt-4">
          <Button variant="secondary" onClick={expand.hideExpand} className="mr-2">
            Cancel
          </Button>
          <ButtonWithSpinner variant="primary" type="submit" loading={isSubmitting}>
            Confirm
          </ButtonWithSpinner>
        </div>
      </Form>
    </GenericModal>
  );
}
