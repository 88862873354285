import React, { useState } from 'react';
import { Table } from "react-bootstrap";
import cx from 'classnames';
import styles from './ActivityTable.module.scss';
import { ServicingActivity, ServicingActivityMoneyFlowDirection, ServicingActivityStatus, ServicingActivityType } from '../../../api/queries/loans/useActivity';
import { formatCents, formatDate } from '../../../utils';
import Drawer from '../../Drawer';
import { ActivityDrawerContent, DRAWER_TYPES } from './ActivityDrawers/ActivityDrawerContent';
import { Loan } from '../../../api/queries/types';

type Props = {
  activity: Array<ServicingActivity>;
  loan: Pick<Loan, '_id' | 'slug'>;
  onChange: () => void;
}



export default function ActivityTable ({
  activity,
  loan,
  onChange,
}: Props) {
  const [selected, setSelected] = useState<ServicingActivity | null>(null);

  if (activity.length === 0) {
    return <EmptyView />;
  }

  return (
    <>
      <Table size="sm" responsive={true} className={cx(styles.table, 'custom-table')}>
        <thead>
          <tr>
            <th className={ styles.dateColumn }>Date</th>
            <th className={ styles.typeColumn }>Type</th>
            <th className={ styles.labelColumn }></th>
            <th className={ styles.amountColumn }>Amount</th>
            <th className={ styles.statusColumn }>Status</th>
          </tr>
        </thead>
        <tbody>
          {
            activity.map(item => (
              <ActivityRow
                key         = { item.key }
                item        = { item }
                onSelect    = { () => setSelected(item) }
                isSelected  = { item.key === selected?.key }
              />
            ))
          }
        </tbody>
      </Table>
      {
        selected && DRAWER_TYPES[selected.base_type] ? (
          <Drawer
            title     = "Activity Details"
            onClose   = { () => setSelected(null) }
            className = {styles.underModal}
            size      = { DRAWER_TYPES[selected.base_type] }
            closeDelay = {500}
          >
            <ActivityDrawerContent
              loanId    = { loan._id }
              onChange  = { onChange }
              activity  = { selected }
            />
          </Drawer>
        ) : null
      }
    </>
  );
}


function ActivityRow (props: {
  item: ServicingActivity;
  onSelect: () => void;
  isSelected: boolean;
}) {
  const { item, onSelect, isSelected } = props;
  const amountModifiers = [];
  const statusModifiers = [];
  const rowModifiers = [];
  let amount = item.cents;
  if (item.direction === ServicingActivityMoneyFlowDirection.INBOUND) {
    amount = amount * -1;
    if (amount < 1 && item.status === ServicingActivityStatus.CLEARED && item.type === ServicingActivityType.REPAYMENT) {
      amountModifiers.push('text-success');
      statusModifiers.push('text-success');
    }
  }
  if (item.status === ServicingActivityStatus.VOIDED) {
    rowModifiers.push(styles.rowVoided);
  } else if (item.status === ServicingActivityStatus.RETURNED) {
    rowModifiers.push(styles.rowVoided);
    statusModifiers.push('text-danger');
  } else if (item.status === ServicingActivityStatus.NEEDS_REVIEW) {
    rowModifiers.push(styles.rowNeedsReview);
  } else if (item.status === ServicingActivityStatus.PROCESSING) {
    rowModifiers.push(styles.rowProcessing);
  } else if (item.status === ServicingActivityStatus.SCHEDULED) {
    rowModifiers.push(styles.rowScheduled);
  }

  return (
    <tr
      className         = { cx(styles.activityRow, 'hover:shaded-grey', ...rowModifiers) }
      onClick           = { onSelect }
      data-is_selected  = { isSelected }
    >
      <td className={ cx(styles.dateColumn) }>
        { formatDate(item.date) }
      </td>
      <td className={ cx(styles.typeColumn) }>
        { item.type }
      </td>
      <td className={ cx(styles.labelColumn) }>
        { item.label }
      </td>
      <td className={ cx(styles.amountColumn, ...amountModifiers) }>
        { formatCents(amount) }
      </td>
      <td className={ cx(styles.statusColumn, ...statusModifiers) }>
        { item.status }
      </td>
    </tr>
  )
}

function EmptyView () {
  return (
    <Table size="sm" responsive={true}>
      <tbody>
        <tr>
          <td>No activity</td>
        </tr>
      </tbody>
    </Table>
  );
}
