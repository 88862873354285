import useQuery from '../../shared/useQuery';

enum USER_NOTIFICATION_STATES {
  PENDING   = 'PENDING',
  SENT      = 'SENT',
  FAILED    = 'FAILED',
  SCHEDULED = 'SCHEDULED',
}

export type Reminder = {
  _id: string,
  createdAt: string;
  sendResult: unknown;
  type: string;
  state: USER_NOTIFICATION_STATES;
  channel: string;
  is_paused: string;
};

export default function useReminders (loanId?: string) {
  return useQuery<Array<Reminder>>(loanId ? `/notebook/loans/${loanId}/servicing/reminders` : null);
}
