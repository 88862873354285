import React from 'react';
import { Badge } from 'react-bootstrap';

export default function NeedsReviewBadge() {
  return (
    <Badge pill variant="warning">
      Needs Review
    </Badge>
  );
}
