import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useExpandableState } from '../../../utils';
import styles from './InvalidAddressModal.module.scss';

type Props = {
  modal: ReturnType<typeof useExpandableState>;
};

const InvalidAddressModal = ({ modal }: Props) => {
  return (
    <Modal
      show={modal.isExpanded}
      onHide={modal.hideExpand}
      size="sm"
      backdropClassName={styles.backdrop}
      className={styles.modal}
    >
      <Modal.Header closeButton>Invalid mailing address</Modal.Header>
      <Modal.Body>
        <p>
          We were unable to validate the vendor's mailing address. Please try entering the address
          again.
        </p>
      </Modal.Body>
      <Modal.Footer className="p-2">
        <Button block variant="primary" onClick={modal.hideExpand}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InvalidAddressModal;
