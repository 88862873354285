import React from 'react'
import GenericModal from '../GenericModal'
import MarkLoanCanceledForm from './MarkLoanCanceledForm'
import useExpandableState from '../../../utils/useExpandableState';

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  loan: { _id: string },
  onSubmit: () => void;
};

export default function MarkLoanCanceledModal(props: Props) {
  const { expand, loan, onSubmit } = props
  return (
    <GenericModal expandable={expand} title="Cancel Loan" size="lg">
      <MarkLoanCanceledForm loan={loan} onHide={expand.hideExpand} onSubmit={onSubmit} />
    </GenericModal>
  )
}
