import React from 'react';
import { Badge } from 'react-bootstrap';
import * as _ from 'lodash';

export function transactionStatusToVariant (status: string) {
  let variant: React.ComponentProps<typeof Badge>['variant'];
  switch (status) {
    case 'open':
      variant = 'info';
      break;
    case 'pending':
      variant = 'warning';
      break;
    case 'cleared':
    case 'fulfilled':
      variant = 'success';
      break;
    case 'voided':
    case 'canceled':
      variant = 'secondary';
      break;
    case 'hold':
      variant = 'danger';
      break;
    default:
      variant = 'primary';
  }
  return variant;
}

export default function TransactionStatusBadge (props: { status: string, className?: string }) {
  const pillVariant = transactionStatusToVariant(props.status)
  return (
    <Badge pill variant={ pillVariant } className={ props.className }>
      { _.capitalize(props.status) }
    </Badge>
  );
}
