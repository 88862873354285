import useQuery from '../../shared/useQuery';

type Response = {
  agents: Array<Agent>;
  pages: number;
}

export type Agent = {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  externalId: string;
  primaryPhone: string;
  secondaryPhone: string;
  imageUrl: string;
  active: boolean;
  slug: string;
  landingPageEnabled: boolean;
  hubspotAllowSync: boolean;
}

export default function useAgents (partnerId?: string, page?: number, q?: string) {
  return useQuery<Response>(partnerId ? `/notebook/partners/${partnerId}/agents/search?q=${q}&page=${page}` : null);
}
