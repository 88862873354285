import { Obligation, Payment } from '../../../components/BorrowerTabs/ServicingTab/PaymentSchedule';
import useQuery from '../../shared/useQuery';

type Response = {
  amortizedPaymentAmount: number,
  paymentPlan: Array<Obligation>,
  scheduledPayments: Array<Payment>,
};

export default function usePaymentPlan (loanId: string) {
  return useQuery<Response>(`/notebook/loans/${loanId}/payment/plan`);
}
