import React from 'react';
import { AGENT_STRATEGY, Partner } from '../../api/queries/partners/usePartner';
import TabLayout from './TabLayout';

interface ShowPartnerLayoutProps {
  partner: Partner,
  selectedTab: string,
  heading?: string,
  children: React.ReactNode
}

export default function ShowPartnerLayout ({ partner, selectedTab, heading, children } : ShowPartnerLayoutProps) {
  return <TabLayout
    id='partner-tabs'
    selectedTab={selectedTab}
    tabs={{
      info: 'Information',
      products: 'Products',
      agents: partner.agentSupport && partner.agentStrategy === AGENT_STRATEGY.LOCAL ? 'Agents' : null,
      history: 'History',
    }}
    heading={heading}
    navigateTo = { (selected: string) => `/system/partners/${partner._id}/${selected}` }
  >
    {children}
  </TabLayout>
};
