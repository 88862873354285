import { useState } from 'react';

export type useExpandableStateType = {
  hideExpand: () => void;
  showExpand: () => void;
  toggleExpand: () => void;
  isExpanded: boolean;
};

export default function useExpandableState (initiallyExpanded=false): useExpandableStateType {
  const [isExpanded, _setIsExpanded] = useState(initiallyExpanded);
  function showExpand () {
    _setIsExpanded(true);
  }
  function hideExpand () {
    _setIsExpanded(false);
  }
  function toggleExpand () {
    _setIsExpanded(!isExpanded);
  }
  return {
    hideExpand,
    isExpanded,
    showExpand,
    toggleExpand,
  };
}
