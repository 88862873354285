import React from 'react';
import { Alert } from 'react-bootstrap';
import LabelValueGrouping from '../../LabelValueGrouping';
import formatDollars from '../../../utils/formatDollars';

type Transaction = {
  liens: Array<unknown>;
  debt: number;
};

export default function PropertyTransactionSummary (props: { transactions: Array<Transaction> }) {
  const { transactions } = props;
  return transactions.length > 0 ? (
    <LabelValueGrouping data={{
      '# liens': transactions[0].liens.length,
      'Sum of debt': formatDollars(transactions[0].debt),
    }} />
  ) : (
    <Alert variant='warning'>
      No property records found.
    </Alert>
  );
}
