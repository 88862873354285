import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import AddressDisplay from '../../AddressDisplay'
import LabelValueGrouping from '../../LabelValueGrouping';
import SSNLastFour from '../../SSNLastFour';
import formatPhone from '../../../utils/formatPhone';
import { formatDisplayDobWithAge } from '../../../utils/formatDisplayDobWithAge';
import useAddresses from '../../../api/queries/users/useAddresses';
import useUser from '../../../api/queries/users/useUser';
import { LegacyLoanApplication } from '../../../api/queries/users/useLoanApplications';
import UserPartialMatchAlert from './UserPartialMatchAlert';

export default function IdentityInfo ({ loanApplication, className }: { loanApplication: LegacyLoanApplication, className?: string }) {
  const { data: user } = useUser(loanApplication.userId);
  const { data: addresses } = useAddresses(loanApplication.userId);
  if (!loanApplication) return null;

  const {
    dob,
    email,
    firstName,
    lastName,
    phone,
    reportedSSNLastFour,
    ssnLastFour,
  } = loanApplication;
  const previousAddress = addresses && user && addresses.find(a => a._id === user.previousAddressId);

  const dataForValueGrouping: Record<string, React.ReactNode> = {
    'First': firstName,
    'Last': lastName,
    'SSN': <SSNLastFour reportedSSNLastFour={reportedSSNLastFour} ssnLastFour={ssnLastFour} />,
    'DOB': dob ? formatDisplayDobWithAge(dob) : '–',
    'Email': email,
    'Phone': formatPhone(phone),
    'Home Address': <AddressDisplay
      line1={loanApplication.address_line1}
      line2={loanApplication.address_line2}
      city={loanApplication.city}
      state={loanApplication.state}
      zip={loanApplication.zip}
    />
  }

  if (previousAddress) {
    dataForValueGrouping["Previous Address"] = (
      <AddressDisplay
        line1={previousAddress.address_line1}
        line2={previousAddress.address_line2}
        city={previousAddress.city}
        state={previousAddress.state}
        zip={previousAddress.zip}
      />
    );
  }

  return (
    <Card className={className}>
      <Card.Header>
        Identity
      </Card.Header>
      <Card.Body>
        <UserPartialMatchAlert loanApplication={ loanApplication } />
        <Row>
          <Col>
            <LabelValueGrouping
              data = {dataForValueGrouping}
              condensed
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}
