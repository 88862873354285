import { titleCase } from './string';
import { ESTATE_ROLE, TRUST_ROLE } from '../api/shared/types/LoanApplication';

type LoanApplication = {
  estateRole: ESTATE_ROLE;
  trustRole: Array<TRUST_ROLE> ;
};

export function displayEntityRole (loanApplication: LoanApplication) {
  const hasEntityRole = loanApplication.estateRole || (loanApplication.trustRole && loanApplication.trustRole.length > 0)

  return hasEntityRole ? 
    titleCase(loanApplication.estateRole) || loanApplication.trustRole.map(t => titleCase(t)) : 
    undefined
}
