import useQuery from '../../shared/useQuery';

export type AccelerationEvent = {
  _id: string;
  date: string;
  notice_date: string;
  type: string;
  last_update_by_admin_id: string;
  last_update_by_user_id: string;
  notes: string;
  _previous?: AccelerationEvent;
  created_at: string;
  canceled: boolean;
  confirmed: boolean;
};

export default function useAccelerationEvent (loanId?: string) {
  return useQuery<Array<AccelerationEvent>>(loanId ? `/notebook/loans/${ loanId }/acceleration-events` : null);
}

export function useHasActiveSaleEvent (loanId?: string) {
  const req = useAccelerationEvent(loanId);
  const latest = req.data?.[0];
  if (!latest || latest.canceled) {
    return false;
  }
  return latest.type === 'sale';
}
