import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import LabelValueGrouping from '../../LabelValueGrouping';
import formatDollars from '../../../utils/formatDollars';
import { DEBTOR_RELATIONSHIP_LABELS, REASON_NO_MORTGAGE_LABELS, STATED_DEBT_TYPE, STATED_DEBT_TYPE_LABELS } from "../../../utils/constants";
import { LegacyLoanApplication } from '../../../api/queries/users/useLoanApplications';
import useReportedDebts, { ReportedDebt } from '../../../api/queries/loan-applications/useReportedDebts';
import useStatedDebts, { StatedDebt } from '../../../api/queries/loan-applications/useStatedDebts';
import { titleCase } from '../../../utils/string';

export default function PropertyDebt ({ className, loanApplication }: { className?: string, loanApplication: LegacyLoanApplication }) {
  return (
    <>
      {
        loanApplication.reasonForNoMortgageOnListing ?
          <NoPropertyDebt loanApplication={loanApplication} className={className} />
        :
          <DebtList loanApplication={loanApplication} className={className}/>
      }
    </>
  );
}

function NoPropertyDebt ({ loanApplication, className }: { loanApplication: LegacyLoanApplication, className?: string }) {
  return (
      <Card className={className}>
        <Card.Header>
          No Property Debt
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <LabelValueGrouping
                data={{
                  'Reason': REASON_NO_MORTGAGE_LABELS[loanApplication.reasonForNoMortgageOnListing as keyof typeof REASON_NO_MORTGAGE_LABELS],
                }}
                condensed={ true }
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
  );
};

function DebtList ({ loanApplication, className }: { loanApplication: LegacyLoanApplication, className?: string }) {
  const rdReq = useReportedDebts(loanApplication._id);
  const sdReq = useStatedDebts(loanApplication._id);

  return (
    <>
      {
        (rdReq.data ?? []).filter(debt => debt.borrower_indicated_active).map((debt, index) => {
          return (
            <ReportedDebtCard key={index} record={debt} className={className} />
          );
        })
      }
      {
        (sdReq.data ?? []).map((debt, index) => {
          return (
            <StatedDebtCard key={index} record={debt} loanApplication={loanApplication} className={className} />
          );
        })
      }

    </>
  );
};

function ReportedDebtCard ({ record, className }: { record: ReportedDebt, className?: string }) {
  const dataForValueGrouping: Record<string, string> = {
    'Type': record.account_type ? titleCase(record.account_type) : '-',
    'Monthly Payment': record.monthly_payment_cents ? formatDollars(record.monthly_payment_cents / 100) : '-',
    'Remaining Balance': record.current_balance_cents ? formatDollars(record.current_balance_cents / 100) : '-',
  };

  if (record.is_heloc) {
    dataForValueGrouping['Credit Limit'] = record.credit_limit_cents ? formatDollars(record.credit_limit_cents / 100) : '-';
  }

  dataForValueGrouping['Lender'] = record.lender ?? '-';
  dataForValueGrouping['Applicant Confirmed Active'] = record.borrower_indicated_active ? 'Yes' : 'No';

  return (
    <Card className={className}>
      <Card.Header>
        Reported Property Debt
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <LabelValueGrouping
              data={dataForValueGrouping}
              condensed={ true }
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

function StatedDebtCard ({ record, loanApplication, className }: { record: StatedDebt, loanApplication: LegacyLoanApplication, className?: string }) {
  const dataForValueGrouping: Record<string, string> = {
    'Type': STATED_DEBT_TYPE_LABELS[record.type],
    'Monthly Payment': record.amount_per_month_cents ? formatDollars(record.amount_per_month_cents / 100) : '-',
    'Current Balance': record.remaining_balance_cents ? formatDollars(record.remaining_balance_cents / 100) : '-',
  };

  if (record.type === STATED_DEBT_TYPE.HELOC) {
    dataForValueGrouping['Credit Limit'] = record.total_credit_cents ? formatDollars(record.total_credit_cents / 100) : '-';
  }

  if (record.type === STATED_DEBT_TYPE.REVERSE_MORTGAGE) {
    dataForValueGrouping['Principal Limit'] = record.total_credit_cents ? formatDollars(record.total_credit_cents / 100) : '-';
  }

  dataForValueGrouping['In Borrower\'s Name'] = record.in_borrowers_name ? 'Yes' : 'No';

  if (!record.in_borrowers_name) {
    dataForValueGrouping['Relationship to Debtor'] = record.debtor_relationship ? DEBTOR_RELATIONSHIP_LABELS[record.debtor_relationship] : '-';
    dataForValueGrouping['Debtor Name'] = record.debtor_full_name ?? '-';
  }

  return (
    <Card className={className}>
      <Card.Header>
        Stated Property Debt
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <LabelValueGrouping
              data={dataForValueGrouping}
              condensed={ true }
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
