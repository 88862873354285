import React from 'react';
import InlineButton from '../InlineButton';
import useExpandableState from '../../utils/useExpandableState';
import { User } from '../../api/queries/users/useUser';
import { Card, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { formatDisplayDobWithAge } from '../../utils/formatDisplayDobWithAge';
import StaticAddress from '../StaticAddress';
import useKycResults from '../../api/queries/users/useKycResults';
import KYCHistoryModal from '../Modals/KYCHistoryModal';
import styles from './ProfileCards.module.scss';
import { Address } from '../../api/queries/users/useAddresses';

type IdentityInfoCardProps = {
  className?: string;
  user?: User;
  currentAddress?: Address;
  previousAddress?: Address;
}

export default function IdentityCard ({ className, user, previousAddress, currentAddress }: IdentityInfoCardProps) {
  const kycResultsReq = useKycResults(user?._id);
  const kycHistoryModal = useExpandableState();

  if (!kycResultsReq.data || !user || !currentAddress) { return null; }
  const kycResults = kycResultsReq.data;
  const isPreviousAddress = kycResults.length && kycResults[0].isPreviousAddress;

  return (<>
    <Card data-qa='identity-info-card' className={className}>
      <Card.Header style={{ display: 'flex', alignItems: 'center' }}>
        Identity
        <KYCHistoryLink kycHistoryModal={ kycHistoryModal } kycResults={ kycResults } />
        {
          !user.deletedAt ? <EditIdentityLink user={ user } /> : null
        }
      </Card.Header>
      <Card.Body className="p-0">
        <ListGroup variant="flush" className='rounded'>
          <ListGroup.Item>
            <dt>Name</dt>
            <dd>
              <Link className={`w-100 ${styles.link}`} to={`/users/${user._id}`}>{user.firstName} {user.lastName}</Link>
              { user.deletedAt && <span className='text-muted'> (archived)</span> }
            </dd>
          </ListGroup.Item>
          <ListGroup.Item>
            <dt>DOB</dt>
            <dd>{formatDisplayDobWithAge(user.dob)}</dd>
          </ListGroup.Item>
          <ListGroup.Item>
            <dt>SSN</dt>
            <dd>*** - ** - <span className='text-danger'>{ user.ssnLastFour }</span></dd>
          </ListGroup.Item>
          <ListGroup.Item>
            <dt>{previousAddress ? `Current Address ${isPreviousAddress ? '' : '(KYC Verified)'}` : 'Home Address'}</dt>
            <dd><StaticAddress address={ currentAddress } /></dd>
          </ListGroup.Item>
          { previousAddress &&
            <ListGroup.Item>
              <dt>{`Previous Address ${isPreviousAddress ? '(KYC Verified)' : ''}`}</dt>
              <dd><StaticAddress address={ previousAddress } /></dd>
            </ListGroup.Item>
          }
        </ListGroup>
      </Card.Body>
    </Card>
    <KYCHistoryModal modalState={ kycHistoryModal } kycResults={ kycResults } />
  </>);
}

function KYCHistoryLink ({ kycHistoryModal, kycResults }: { kycHistoryModal: ReturnType<typeof useExpandableState>, kycResults: Array<unknown> }) {
  if (kycResults.length === 0) {
    return null;
  }
  return (
    <InlineButton className='ml-auto' onClick={ kycHistoryModal.showExpand }>
      KYC History ({ kycResults.length })
    </InlineButton>
  );
}

function EditIdentityLink ({ user }: { user: { _id: string, kyc: { status: string } } }) {
  const kycStatus = _.get(user, 'kyc.status');
  if (!kycStatus) return null;
  return (
    <Link to={`/users/${user._id}/edit-identity`} className='ml-4'>
      { kycStatus === 'success' ? 'Edit' : 'Re-verify KYC' }
    </Link>
  );
}
