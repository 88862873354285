import React from 'react';
import useAdminAPICall from "../../utils/useAdminAPICall";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../Form/Form';
import SubmitError from '../Form/SubmitError';
import useExpandableState from '../../utils/useExpandableState';
import { LegacyLoanApplication } from '../../api/queries/users/useLoanApplications';
import AddressFields from '../Form/AddressFields';
import ButtonWithSpinner from '../ButtonWithSpinner';

type FieldValues = {
  listingAddressLine1: string;
  listingAddressLine2: string;
  listingCity: string;
  listingState: string;
  listingZip: string;
};

type LoanApplication = Pick<LegacyLoanApplication,
  'listing_address_line1' |
  'listing_address_line2' |
  'listing_city' |
  'listing_state' |
  'listing_zip' |
  '_id'>

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
  loanApplication: LoanApplication;
};

export default function EditListingAddressModal (props: Props) {
  const { expand, onSubmit, loanApplication } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loan-applications/${loanApplication._id}/listing-address`,
    method: 'PUT',
  });

  async function handleSubmit(data: FieldValues) {
    await callAPI({
      data: {
        listingAddressLine1: data.listingAddressLine1,
        listingAddressLine2: data.listingAddressLine2,
        listingCity: data.listingCity,
        listingState: data.listingState,
        listingZip: data.listingZip,
      },
    });
    onSubmit();
    expand.hideExpand();
  }

  const schema = yup.object().shape({
    listingAddressLine1: yup.string().required('required'),
    listingAddressLine2: yup.string().nullable(),
    listingCity: yup.string().required('required'),
    listingState: yup.string().required('required'),
    listingZip: yup.string().required('required'),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      listingAddressLine1: loanApplication.listing_address_line1,
      listingAddressLine2: loanApplication.listing_address_line2,
      listingCity: loanApplication.listing_city,
      listingState: loanApplication.listing_state,
      listingZip: loanApplication.listing_zip,
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty }, reset } = methods;

  const onModalHide = () => {
    expand.hideExpand();
    reset();
  }

  return (
    <Modal show={expand.isExpanded} onHide={onModalHide}>
      <Modal.Header>
        <Modal.Title>
          Listing Address
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form {...methods} onSubmit={handleSubmit}>
          <AddressFields
            addressLine1FieldName='listingAddressLine1'
            addressLine2FieldName='listingAddressLine2'
            cityFieldName='listingCity'
            stateFieldName='listingState'
            zipFieldName='listingZip'
          />
          <SubmitError/>

          <div className="d-flex justify-content-end mt-4">
            <Button variant="secondary" onClick={onModalHide} className="mr-2">
              Cancel
            </Button>
            <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
              Confirm
            </ButtonWithSpinner>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
