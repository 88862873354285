import React from 'react';
import useExpandableState from '../../utils/useExpandableState';
import { Partner } from '../../api/queries/partners/usePartner';
import PushAgentsModal from './PushAgentsModal';

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
  partner: Partner;
  agentsCount?: number;
};

export default function PushAgentsToKustomer (props: Props) {
  const { expand, onSubmit, partner, agentsCount } = props;
  return <PushAgentsModal
    expand={expand}
    onSubmit={onSubmit}
    agentsCount={agentsCount}
    endpoint={`/notebook/partners/${partner._id}/agents/push-to-kustomer`}
    platformName='Kustomer'
  />
}
