import React from 'react';
import useAdminAPICall from "../../utils/useAdminAPICall";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../Form/Form';
import Field from '../Form/Field';
import SubmitError from '../Form/SubmitError';
import useExpandableState from '../../utils/useExpandableState';
import ButtonWithSpinner from '../ButtonWithSpinner';

type FieldValues = {
  notes: string
  reason: string
};

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
  userId: string;
};

export default function PauseUserModal (props: Props) {
  const { expand, onSubmit, userId } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/users/${userId}/pause`,
    method: 'POST',
  });

  async function handleSubmit({ notes, reason }: FieldValues) {
    await callAPI({ data: { notes, reason }});
    onSubmit();
    expand.hideExpand();
  }

  const schema = yup.object().shape({
    notes: yup.string().required('Please add a note'),
    reason: yup.string().required('Please select a reason'),
  });

  const methods = useForm<FieldValues>({
    defaultValues: { notes: '', reason: '' },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting }, reset } = methods;

  const onModalHide = () => {
    expand.hideExpand();
    reset();
  }

  return (
    <Modal show={expand.isExpanded} onHide={onModalHide}>
      <Modal.Header>
        <Modal.Title>
          Pause User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form {...methods} onSubmit={handleSubmit}>
          <Field name='reason' label='Reason' controlProps={{ as: 'select' }}>
            <option value="" disabled hidden>Select</option>
            <option value='suspected_fraud_borrower_reported'>Suspected fraud (borrower reported)</option>
            <option value='suspected_fraud_internally_discovered'>Suspected fraud (internally discovered)</option>
            <option value='confirmed_fraud'>Confirmed fraud</option>
            <option value='other'>Other</option>
          </Field>
          <Field name='notes' label='Internal Notes' placeholder='Add notes...' controlProps={{ as: 'textarea', rows: '4' }}/>

          <SubmitError/>

          <div className="d-flex justify-content-end mt-4">
            <Button variant="secondary" onClick={onModalHide} className="mr-2">
              Back
            </Button>
            <ButtonWithSpinner variant="danger" type="submit" loading={isSubmitting}>
              Pause User
            </ButtonWithSpinner>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
