import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useUser from '../../api/queries/users/useUser';
import { InlineError } from '../../components';
import ApplicationAndLoanSummaryCards from '../../components/ApplicationAndLoanSummaryCards';
import InlineLoadingIndicator from '../../components/InlineLoadingIndicator';
import InlineWarning from '../../components/InlineWarning';
import { useExpandableState } from '../../utils';
import ArchiveUserModal from '../../components/Modals/ArchiveUserModal';
import useLoanApplications from '../../api/queries/users/useLoanApplications';
import useLoansSummaries from '../../api/queries/users/useLoansSummaries';
import ButtonWithTooltip from '../../components/ButtonWithTooltip';
import styles from './UserShowPage.module.scss';

export default function UserShowPage () {
  const { userId } = useParams<{ userId: string }>();
  const userReq = useUser(userId);
  const archiveUserModal = useExpandableState();
  const loanApplicationsReq = useLoanApplications(userId);
  const loansReq = useLoansSummaries(userId);

  useEffect(() => {
    if (userReq.data && userReq.data.firstName) {
      document.title = `${userReq.data.firstName} ${userReq.data.lastName} | Notebook`
    }
  }, [userReq.data]);

  if (userReq.error) {
    if (userReq.error?.request?.status === 404) {
      return <InlineWarning>No such User. Check the ID in the URL.</InlineWarning>;
    }
    return <InlineError>
      { userReq.error.request ? `(${ userReq.error.request.status })` : null } Error loading user details.
    </InlineError>;
  }

  const user = userReq.data;
  if (!user) {
    return <InlineLoadingIndicator>Loading…</InlineLoadingIndicator>;
  }

  const activeLoanApplications = loanApplicationsReq.data?.filter(la => la.isActive) || [];
  const activeLoans = loansReq.data?.filter(l => l.isActive) || [];
  const canArchiveUser = !user.deletedAt && activeLoanApplications.length === 0 && activeLoans.length === 0;

  return (
    <div className='pt-2'>
      <div className='d-flex'>
        <h4 className='m-0'>{`${user.firstName} ${user.lastName}`} { user.deletedAt && <span className='text-muted'> (archived)</span> }</h4>
        { !user.deletedAt &&
          <ButtonWithTooltip
            variant='outline-dark'
            className={`${styles.buttonLink} ml-auto`}
            size='sm'
            onClick={archiveUserModal.showExpand}
            disabled={!canArchiveUser}
            tooltip={!canArchiveUser ? 'This user cannot be archived.' : undefined}
          >
            Archive user
          </ButtonWithTooltip>
        }
      </div>
      <hr/>
      <ApplicationAndLoanSummaryCards loanApplicationsReq={loanApplicationsReq} loansReq={loansReq} />

      <ArchiveUserModal user={user} expand={archiveUserModal} />
    </div>
  );
}
