import useQuery from '../../shared/useQuery';
import { InternalBankAccount } from '../partners/usePartner';

export type Investor = {
  key: string;
}

export type Portfolio = {
  key: string;
  description: string;
  name: string;
  drawPeriods: number;
  purchasePremiumBps: number;
  servicingFeeAprBps: number;
  transferReviewDays: number;
  fallbackPortfolio: string;
  isFallbackPortfolio: boolean;
  collectionAccount?: InternalBankAccount;
  investor: Investor;
}

type Response = Array<Portfolio>;

export default function usePortfolios (partnerId?: string) {
  return useQuery<Response>(partnerId ? `/notebook/partners/${partnerId}/portfolios` : null);
}
