import React from 'react';
import useAdminAPICall from "../../utils/useAdminAPICall";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Form from '../Form/Form';
import SubmitError from '../Form/SubmitError';
import useExpandableState from '../../utils/useExpandableState';
import ButtonWithSpinner from '../ButtonWithSpinner';
import CardCheckbox from '../Form/CardCheckbox';
import useStates from '../../api/queries/states/useStates';
import { State } from '../../api/queries/partners/usePartner';

type FieldValues = Record<string, boolean>;

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
  states: Array<State>;
  endpoint: string;
  hasPrimary?: boolean;
};

export default function EditActiveStatesModal (props: Props) {
  const { expand, onSubmit, states, endpoint, hasPrimary } = props;
  const { data: allStates } = useStates();

  const { callAPI } = useAdminAPICall({
    endpoint,
    method: 'PUT',
  });

  async function handleSubmit(data: FieldValues) {
    const stateIds = [];
    const primaryStatesIds = [];
    for (const stateCode in data) {
      if (data[stateCode]) {
        const stateId = allStates!.find(st => st.code === stateCode)?._id;
        if (stateId) {
          stateIds.push(stateId);
          if (data[`${stateCode}-primary`]) {
            primaryStatesIds.push(stateId);
          }
        }
      }
    }

    await callAPI({
      data: {
        activeStates: stateIds,
        primaryStates: primaryStatesIds,
      },
    });
    onSubmit();
    expand.hideExpand();
  }

  const currentStates: FieldValues = {};
  for (const state of states) {
    currentStates[state.code] = true;
    currentStates[`${state.code}-primary`] = state.isPrimary;
  }

  const methods = useForm<FieldValues>({
    defaultValues: currentStates,
  });
  const { formState: { isSubmitting, isDirty }, reset, watch, setValue } = methods;

  const onModalHide = () => {
    expand.hideExpand();
    reset();
  }

  return (
    <Modal show={expand.isExpanded} size='lg' onHide={onModalHide}>
      <Modal.Header>
        <Modal.Title>
          Edit Active States
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex mx-2'>
          <div><strong>Active</strong></div>
          { hasPrimary && <div className='ml-auto'><strong>Primary</strong></div> }
        </div>
        <hr className='mx-2'/>
        <Form {...methods} onSubmit={handleSubmit}>
          {allStates?.map(state => {
            return (
              <div key={state.code} className='d-flex'>
                <CardCheckbox name={state.code} label={`${state.name} (${state.code})`} defaultTheme={false} onChange={() => setValue(`${state.code}-primary`, false)}/>
                { hasPrimary && <CardCheckbox name={`${state.code}-primary`} label='' defaultTheme={false} className='ml-auto' disabled={!watch(state.code)} /> }
              </div>
            );
          })}

          <SubmitError/>

          <div className="d-flex justify-content-end mt-4">
            <Button variant="secondary" onClick={onModalHide} className="mr-2">
              Cancel
            </Button>
            <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
              Confirm
            </ButtonWithSpinner>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
