import { useState, useEffect } from 'react';
import axios, { RawAxiosRequestConfig } from 'axios';
import CONFIG from '../config';

export const axiosInstanceAdmin = axios.create({
  baseURL: CONFIG.API_BASE_URL,
  withCredentials: true,
  headers: {
      'Content-Type': 'application/json',
  },
});

export type Options = {
  abort?: boolean;
};

type State<TData> = {
  data?: TData;
  loadError: boolean;
  loadPending: boolean;
  loadSuccess: boolean;
};

type RequestApiEndpointConfig = {
  endpoint: string;
} & RawAxiosRequestConfig;


/**
 * @deprecated Use queries in /api/queries
 */
export default function useAdminAPIData<TRawData = any> (endpoint: string, { abort }: Options = {}) {
  const [state, setState] = useState<State<TRawData>>({
    data        : undefined,
    loadError   : false,
    loadPending : false,
    loadSuccess : false,
  });

  function _loadData () {
    if (abort) {
      setState({ data: undefined, loadPending: false, loadError: false, loadSuccess: true });
      return Promise.resolve();
    }
    setState(state => ({ ...state, loadPending: true, loadError: false, loadSuccess: false }));
    const req = axiosInstanceAdmin.request<TRawData>({ method: 'GET', url: endpoint });
    req.then((result) => {
      setState({ data: result.data, loadPending: false, loadError: false, loadSuccess: true });
    }).catch((error) => {
      setState({ data: undefined, loadPending: false, loadError: error, loadSuccess: false });
    });
    return req;
  }

  useEffect(() => {
    if (!abort && !state.loadSuccess && !state.loadError && !state.loadPending) {
      const req: any = _loadData(); // TODO: fix any
      return () => {
        req.request && req.request.abort();
      };
    }
  });

  return {
    ...state,
    loadData: _loadData,
  };
}

export async function requestAPIEndpoint ({ method, endpoint, ...config }: RequestApiEndpointConfig) {
  const res = await axiosInstanceAdmin.request({
    method: method || 'GET',
    url: endpoint,
    ...config,
  });
  return res.data;
}

export function getAPIEndpoint (endpoint: string, config: RawAxiosRequestConfig = {}) {
  return requestAPIEndpoint({ method: 'GET', endpoint, ...config });
}

export function postAPIEndpoint (endpoint: string, data: any, config: RawAxiosRequestConfig = {}) {
  return requestAPIEndpoint({ method: 'POST', endpoint, data, ...config });
}

export function putAPIEndpoint (endpoint: string, data: any, config: RawAxiosRequestConfig = {}) {
  return requestAPIEndpoint({ method: 'PUT', endpoint, data, ...config });
}

export async function getAdminEndpoint (endpoint: string) {
  const req = await axiosInstanceAdmin.get(endpoint);
  return req.data;
}
