export enum ENTITY_TYPE {
  LLC = 'llc',
  CORPORATION = 'corporation',
  TRUST = 'trust',
  ESTATE = 'estate',
  PARTNERSHIP = 'partnership',
}

export enum ESTATE_ROLE {
  EXECUTOR = 'executor',
  ADMINISTRATOR = 'administrator',
  BENEFICIARY = 'beneficiary',
  UNSURE = 'unsure',
}

export enum TRUST_ROLE {
  SETTLOR = 'settlor',
  TRUSTEE = 'trustee',
  BENEFICIARY = 'beneficiary',
  UNSURE = 'unsure',
}

export enum OWNER_RELATIONSHIP {
  SPOUSE = 'spouse',
  FAMILY_MEMBER = 'family_member',
  BUSINESS_PARTNER = 'business_partner',
  OTHER = 'other',
}
