import React, { useEffect } from 'react';
import * as _ from 'lodash';
import { groupByDateRanges } from '../utils';
import { LedgerTransactionCard } from '../components';
import { CardList } from '../layouts';
import { Tabs, Tab } from 'react-bootstrap';

import SuperadminAuthRequired from '../components/SuperadminAuthRequired';
import usePending, { PendingTransaction } from '../api/queries/disbursement-requests/usePending'

function LegacyDisbursements () {
  const txnRequest = usePending();
  if (!txnRequest.data) {
    return <p>Loading...</p>
  }

  const transactions = _.groupBy(txnRequest.data, 'bucket');

  return (
    <div className='HomePage__'>

      <div className='d-flex align-items-center mb-3'>
        <h1 className='mr-auto'>Recent bank transfers</h1>
      </div>

      <Tabs defaultActiveKey='open' id='ledger-entries'>
        <Tab eventKey='open' title={ `All Open (${ transactions.open ? transactions.open.length : 0 })` }>
          <HomePageTransactions transactions={transactions.open} reloadData={txnRequest.mutate} />
        </Tab>
        <Tab eventKey='pending' title={ `All Pending (${ transactions.pending ? transactions.pending.length : 0 })` }>
          <HomePageTransactions transactions={transactions.pending} reloadData={txnRequest.mutate} />
        </Tab>
        <Tab eventKey='cleared' title={ `Recently Cleared (${ transactions.cleared ? transactions.cleared.length : 0 })` }>
          <HomePageTransactions transactions={transactions.cleared} reloadData={txnRequest.mutate} />
        </Tab>
        <Tab eventKey='canceled' title={ `Recently Canceled (${ transactions.canceled ? transactions.canceled.length : 0 })` }>
          <HomePageTransactions transactions={transactions.canceled} reloadData={txnRequest.mutate} />
        </Tab>
      </Tabs>
    </div>
  );
}

type Props = {
  transactions: Array<PendingTransaction>;
  reloadData: () => void;
};

function HomePageTransactions (props: Props) {
  const { today, yesterday, older } = groupByDateRanges(props.transactions, 'created_date');
  return (
    <>
      <section className='mb-5 mt-5'>
        <h2>Today</h2>
        <CardList>
          {
            today.map((txn) => (
              <LedgerTransactionCard
                key         = { txn.disbursement_request_id }
                transaction = { txn }
                reloadData  = { props.reloadData }
              />
            ))
          }
        </CardList>
      </section>
      <section className='mb-5'>
        <h2>Yesterday</h2>
        <CardList>
          {
            yesterday.map((txn) => (
              <LedgerTransactionCard
                key         = { txn.disbursement_request_id }
                transaction = { txn }
                reloadData  = { props.reloadData }
              />
            ))
          }
        </CardList>
      </section>
      <section className='mb-5'>
        <h2>Older</h2>
        <CardList>
          {
            older.map((txn) => (
              <LedgerTransactionCard
                key         = { txn.disbursement_request_id }
                transaction = { txn }
                reloadData  = { props.reloadData }
              />
            ))
          }
        </CardList>
      </section>
    </>
  );
}

export default function HomePage () {
  useEffect(() => {
    document.title = `Disbursements | Notebook`
  }, []);

  return (
    <SuperadminAuthRequired>
      <LegacyDisbursements />
    </SuperadminAuthRequired>
  );
}
