import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import SubmitError from '../../Form/SubmitError';
import ButtonWithSpinner from '../../ButtonWithSpinner';
import { PurchaseMethod } from '../../../utils/transactionUtils';
import DollarField from '../../Form/DollarField';
import { getAPIEndpoint } from '../../../utils/useAdminAPIData';

export type RefundRequestFormFields = {
  amount: string;
  purchase_method_id: string;
  effective_date: string;
  type: string;
  notes: string;
};

type Props = {
  handleSubmit: (form: RefundRequestFormFields) => Promise<void>;
  handleCancel: () => void;
  defaultValues: RefundRequestFormFields;
  loanId: string;
};

function RefundRequestForm (props: Props) {
  const {
    handleSubmit,
    handleCancel,
    defaultValues,
    loanId,
  } = props;
  const [purchaseMethods, setPurchaseMethods] = useState<Array<PurchaseMethod>>([]);

  useEffect(() => {
    const getPMs = async () => {
      const pms = await getAPIEndpoint(`/notebook/loans/${loanId}/purchase-methods`, {
        params: { active: true },
      });
      setPurchaseMethods(pms);
    };

    getPMs();
  }, [loanId]);

  const schema = yup.object({
    amount: yup.number().typeError('Amount must be a number').required('Please enter a refund amount'),
    effective_date: yup.string().required('Please select an effective date'),
    purchase_method_id: yup.string().required('Please select a bank account'),
    type: yup.string().required('Please select a refund type.'),
    notes: yup.string(),
  });

  const form = useForm<RefundRequestFormFields>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const {
    formState: { isSubmitting, isValid },
    watch,
  } = form;
  const values = watch();

  const onCancel = () => {
    handleCancel();
    form.reset(defaultValues);
  }

  const buildOptions = useMemo(() => {
    const options = [
      <option key='default' value='' selected={ true } >
        Select a bank account
      </option>,
    ];
    for (const pm of purchaseMethods) {
      options.push(
        <option key={ pm._id } value={ pm._id } selected={values.purchase_method_id === pm._id}>
          { `${ pm.accountType } ending in ${ pm.lastFour }` }
        </option>
      );
    }
    return options;
  }, [purchaseMethods]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form { ...form } onSubmit={ handleSubmit }>
      <Field
        name='purchase_method_id'
        label='Destination Bank Account'
        controlProps={{ as: 'select' }}
        secondaryLabel='The account the refund will be deposited to.'
      >
        {buildOptions}
      </Field>

      <Field
        label='Effective Date'
        name='effective_date'
        controlProps={{ type: 'date' }}
      />

      <DollarField
        name='amount'
        label='Amount'
        footnote='Will need review from a second user if > $1,000.'
      />

      <Field name='notes' label='Internal Notes' controlProps={{ as: 'textarea' }}/>

      <SubmitError />

      <div className='d-flex justify-content-end mt-4'>
        <Button variant='secondary' onClick={ onCancel } className='mr-2'>
          Cancel
        </Button>
        <ButtonWithSpinner
          variant='primary'
          type='submit'
          loading={ isSubmitting && isValid }
        >
          Confirm
        </ButtonWithSpinner>
      </div>
    </Form>
  );
}

export default RefundRequestForm;
