import React from 'react';
import GenericModal from './GenericModal';
import useAdminAPICall from "../../utils/useAdminAPICall";
import { Button } from "react-bootstrap";
import ApiError from "../Form/ApiError";
import useExpandableState from '../../utils/useExpandableState';
import ButtonWithSpinner from '../ButtonWithSpinner';

const DECISIONS = {
  'pre-qualification': {
    title: 'Run Pre-qualification',
  },
  'strict-decision': {
    title: 'Run Final Decision',
  },
};

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
  loanApplication: { _id: string };
  type: keyof typeof DECISIONS;
};

export default function RunUnderwritingModal (props: Props) {
  const { expand, loanApplication, onSubmit, type } = props;
  return (
    <GenericModal expandable={expand} title={DECISIONS[type].title}>
      <RunUnderwritingForm loanApplication={loanApplication} onHide={expand.hideExpand} onSubmit={onSubmit} description={DECISIONS[type].title} type={type} />
    </GenericModal>
  );
}

type RunUnderwritingFormProps = {
  onHide: () => void;
  onSubmit: () => void;
  loanApplication: { _id: string };
  description: string
  type: keyof typeof DECISIONS;
};

function RunUnderwritingForm (props: RunUnderwritingFormProps) {
  const { loanApplication, onHide, onSubmit, description, type } = props

  const { callAPI, data, loadError, loadPending: submitting } = useAdminAPICall({
    endpoint: `/notebook/loan-applications/${loanApplication._id}/underwriting-decisions/${type}`,
    method: 'POST',
  })

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    await callAPI();
    onSubmit();
    onHide();
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <p>Please confirm you want to {description} for this application.</p>
        <div className="d-flex justify-content-end mt-4">
          <Button variant="secondary" onClick={onHide} className="mr-2">Cancel</Button>
          <ButtonWithSpinner variant="primary" type="submit" loading={submitting}>Confirm</ButtonWithSpinner>
        </div>
      </form>
      <ApiError className="mt-3" error={loadError ? data : null} />
    </>
  )
}
