import React from 'react';
import { Partner } from '../../../api/queries/partners/usePartner';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import SubmitError from '../../Form/SubmitError';
import { Button } from 'react-bootstrap';
import ButtonWithSpinner from '../../ButtonWithSpinner';
import BooleanRadioField from '../../Form/BooleanRadioField';

type PartnerFields = {
  marqetaProgramSlug: string;
  supportsPhysicalCard: string;
  marqetaPhysicalCardProductToken: string;
  supportsVirtualCard: string;
  marqetaVirtualCardProductToken: string;
  marqetaApplicationId: string;
  marqetaCarrierCardPersonalizationEnabled: string;
  marqetaCardLogoName: string;
};

type Props = {
  partner: Partner;
  onSubmit: () => void;
  onHide: () => void;
}

export default function EditPartnerMarqetaFields (props: Props) {
  const { partner, onSubmit, onHide } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/partners/${partner._id}`,
    method: 'PUT',
  });

  async function handleSubmit(data: PartnerFields) {
    await callAPI({
      data: {
        marqetaProgramSlug: data.marqetaProgramSlug,
        supportsPhysicalCard: data.supportsPhysicalCard === 'true',
        marqetaPhysicalCardProductToken: data.marqetaPhysicalCardProductToken,
        supportsVirtualCard: data.supportsVirtualCard === 'true',
        marqetaVirtualCardProductToken: data.marqetaVirtualCardProductToken,
        marqetaApplicationId: data.marqetaApplicationId,
        marqetaCarrierCardPersonalizationEnabled: data.marqetaCarrierCardPersonalizationEnabled === 'true',
        marqetaCardLogoName: data.marqetaCardLogoName,
      },
    });
    onSubmit();
    onHide();
  }

  const schema = yup.object();

  const methods = useForm<PartnerFields>({
    defaultValues: {
      marqetaProgramSlug: partner.marqetaProgramSlug,
      supportsPhysicalCard: partner.supportsPhysicalCard.toString(),
      marqetaPhysicalCardProductToken: partner.marqetaPhysicalCardProductToken,
      supportsVirtualCard: partner.supportsVirtualCard.toString(),
      marqetaVirtualCardProductToken: partner.marqetaVirtualCardProductToken,
      marqetaApplicationId: partner.marqetaApplicationId,
      marqetaCarrierCardPersonalizationEnabled: partner.marqetaCarrierCardPersonalizationEnabled.toString(),
      marqetaCardLogoName: partner.marqetaCardLogoName,
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty } } = methods;

  return <div>
    <Form {...methods} onSubmit={handleSubmit}>
      <Field label='Marqeta Program' name='marqetaProgramSlug' controlProps={{ as: 'select' }}>
        <option value='peco'>peco (Penguin Compass)</option>
        <option value='notho'>notho (Notable HomeCard)</option>
      </Field>
      <BooleanRadioField name='supportsPhysicalCard' label='Supports Physical Card'/>
      <Field name='marqetaPhysicalCardProductToken' label='Marqeta Physical Card Product Token'/>
      <BooleanRadioField name='supportsVirtualCard' label='Supports Virtual Card'/>
      <Field name='marqetaVirtualCardProductToken' label='Marqeta Virtual Card Product Token'/>
      <Field name='marqetaApplicationId' label='Marqeta Application ID'/>
      <BooleanRadioField name='marqetaCarrierCardPersonalizationEnabled' label='Marqeta Carrier Card Personalization Enabled'/>
      <Field name='marqetaCardLogoName' label='Marqeta Card Logo Name'/>

      <SubmitError/>

      <div className="d-flex justify-content-end mt-4">
        <Button variant="secondary" onClick={onHide} className="mr-2">
          Cancel
        </Button>
        <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
          Confirm
        </ButtonWithSpinner>
      </div>
    </Form>
  </div>;
}
