import React, { useState } from "react";
import { Button } from "react-bootstrap";
import useExpandableState from '../../utils/useExpandableState';
import { Loan } from '../../api/queries/types';
import useAdminAPICall from '../../utils/useAdminAPICall';
import GenericModal from './GenericModal';
import ButtonWithSpinner from "../ButtonWithSpinner";

type Props = {
	modal: ReturnType<typeof useExpandableState>;
	loan: Loan;
	bankAccount: { _id: string, lastFour: string };
	onChange: () => void;
}

export default function AccountTerminatationModal(props: Props){
	const { modal, loan, bankAccount, onChange } = props;
	const [errorMessage, setErrorMessage] = useState<string>();
	const { callAPI, loadPending, loadError } = useAdminAPICall({
		endpoint: `/notebook/loans/${loan._id}/purchase-methods/${bankAccount._id}`,
		method: 'DELETE'
	});

	const handleSubmit = async () => {
		callAPI().then(res => {
			modal.hideExpand();
			onChange();
		}).catch(error => {
			if(error.response.status === 403){
				setErrorMessage("This bank account is associated with open or pending transactions. Terminate it after the transactions have settled.");
			} else {
				setErrorMessage("An unexpected error had ocurred");
			}
		});
	};

	const footerChildren = <>
		<Button variant='secondary' onClick={modal.hideExpand} >
			Cancel
		</Button>
		<ButtonWithSpinner type='submit' variant='danger'
			loading={loadPending}
			disabled={loadError}
			onClick={handleSubmit}>
			Terminate
		</ButtonWithSpinner>
	</>;
	return (
		<GenericModal expandable={modal} title="Account Termination" size="sm" footerChildren={footerChildren}>
			{ loadError && (<p className="text-danger">{errorMessage}</p>) }
			<p>Do you want to terminate the account ending in {bankAccount.lastFour} ?</p>
		</GenericModal>
	);

}
