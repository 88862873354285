import React from 'react';
import { formatCents } from '../utils';
import { formatCentsWithColor } from '../utils/formatCents';
import ExplainerPopover from './ExplainerPopover';
import InlineLoadingIndicator from './InlineLoadingIndicator';
import InlineError from './InlineError';
import useLedgerSummary from '../api/queries/loans/useLedgerSummary';
import { Card, Col, Row } from 'react-bootstrap';

export default function FundsSummary (props: { loanId: string }) {
  const { data, error } = useLedgerSummary(props.loanId);
  if (!data) {
    return <InlineLoadingIndicator />
  }
  if (error) {
    return <InlineError>Error loading the ledger summary.</InlineError>
  }

  const isBorrowerOwedRefund = data.balanceCents < 0;
  const isBorrowerOverDisbursed = data.availableFundsCents < 0;

  const availableFundsDollars = isBorrowerOverDisbursed ? formatCentsWithColor(data.availableFundsCents) : formatCents(data.availableFundsCents);
  const totalRepaidDollars = formatCents(data.repaidCents);
  const balanceDollars = isBorrowerOwedRefund ? formatCentsWithColor(data.balanceCents) : formatCents(data.balanceCents);

  return (
    <Row className='pb-3'>
      <Col className='px-3'>
        <ExplainerPopover explanation='The amount of funds available for disbursement. Does not include unsettled returns from borrower/merchant/bank.'>
          <Card className='px-3 py-2'>
            <strong>Available</strong>
            <span>{ availableFundsDollars }</span>
          </Card>
       </ExplainerPopover>
      </Col>
      <Col className='px-0'>
        <ExplainerPopover explanation='The sum of repayment transactions, including unsettled refunds back to borrower but not including unsettled payments from the borrower.'>
          <Card className='px-3 py-2'>
            <strong>Repaid</strong>
            <span>{ totalRepaidDollars }</span>
          </Card>
        </ExplainerPopover>
      </Col>
      <Col className='px-3'>
        <ExplainerPopover explanation='The amount the borrower owes. This includes pending disbursement activity that the borrower may not have received yet.'>
          <Card className='px-3 py-2'>
            <strong>Outstanding</strong>
            <span>{ balanceDollars }</span>
          </Card>
        </ExplainerPopover>
      </Col>
    </Row>
  );
}
