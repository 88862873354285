import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import uniqueId from 'lodash/uniqueId';
import styles from './ButtonWithTooltip.module.scss';

type Props = {
  block?: string;
  tooltip?: string;
} & Omit<React.ComponentProps<typeof Button>, 'block'>;

const ButtonWithTooltip = ({ disabled, block, className, tooltip, ...rest}: Props) => {
  const cx = [className];
  if (block) {
    if (block === 'fluid') {
      cx.push('-block--fluid');
    } else {
      cx.push('-block--fixed');
    }
  }

  let content = (
    <div className={ cx.join(' ') }>
      <Button {...rest} disabled={disabled} className={`${disabled ? styles.disabledButtonTooltip : ''} ${className}`}/>
    </div>
  );

  if (tooltip) {
    console.log('tooltip')
    content = (
      <OverlayTrigger
        delay={ 500 /* ms */ }
        overlay={
          <Tooltip id={ uniqueId('buttonwithspinner-tooltip-') /* The Tooltip requires an ID, so make sure it gets a unique one. */ }>
            { tooltip }
          </Tooltip>
        }
        placement="top"
      >
        { content }
      </OverlayTrigger>
    );
  }
  return content;
};

export default ButtonWithTooltip;
