import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { isDateOnlyWithinReasonableBounds } from '../../../../utils/datetime';
import { titleCase } from '../../../../utils/string';
import useAdminAPICall from '../../../../utils/useAdminAPICall';
import { useExpandableStateType } from '../../../../utils/useExpandableState';
import { CHECK_ACTIVITY_ACTIONS } from './CheckDetail';
import Form from '../../../Form/Form';
import Field from '../../../Form/Field';
import SubmitError from '../../../Form/SubmitError';
import ButtonWithSpinner from '../../../ButtonWithSpinner';

type FieldValues = {
  post_date: string;
  notes: string;
};

type Props = {
  modal: useExpandableStateType;
  type?: CHECK_ACTIVITY_ACTIONS;
  checkActivityId: string;
  onSubmitted: () => void;
};

const UpdateCheckActivityModal: React.FC<Props> = ({
  modal,
  type,
  checkActivityId,
  onSubmitted,
}) => {
  const isCleared = type === CHECK_ACTIVITY_ACTIONS.CLEARED;

  const schema = yup.object().shape({
    post_date: yup.string().test('post_date', 'Please enter a valid date within 10 years.', value => (!isCleared && !value) || isDateOnlyWithinReasonableBounds(value)),
    notes: yup.string(),
  });

  const methods = useForm<FieldValues>({
    defaultValues: { post_date: '', notes: '' },
    resolver: yupResolver(schema)
  });
  const { formState: { isSubmitting }, reset } = methods;

  const { callAPI, loadPending } = useAdminAPICall({ endpoint: `/notebook/check-activities/${checkActivityId}/action`, method: 'POST' });

  const handleSubmit = async (values: FieldValues) => {
    const data = {
      notes: values.notes,
      post_date: isCleared ? values.post_date : undefined,
      type,
    };
    await callAPI({ data })

    onSubmitted();
    modal.hideExpand();
  };

  function handleCancel() {
    reset();
    modal.hideExpand();
  }

  return (
    <Modal show={modal.isExpanded} onHide={handleCancel}>
      <Form {...methods} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Mark as {titleCase(type)}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {type === CHECK_ACTIVITY_ACTIONS.CLEARED && (
            <Field name='post_date' label='Accounting Date' controlProps={{ type: 'date' }}/>
          )}

          <Field name='notes' label='Notes'/>

          <SubmitError className='mt-3'/>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel} disabled={loadPending || isSubmitting}>Cancel</Button>
          <ButtonWithSpinner type='submit' variant="primary" loading={loadPending || isSubmitting}>Confirm</ButtonWithSpinner>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default UpdateCheckActivityModal;
