import useQuery from '../../shared/useQuery';

type Response = {
  _id: string;
  created_at: string;
  admin_user_id: string;
  helocCalculatorKey: string;
  total_amount_cents: number;
  selectedReportedDebts: unknown;
};

export default function useManualDebtVerification (loanApplicationId: string) {
  return useQuery<Response>(`/notebook/loan-applications/${loanApplicationId}/manual-debt-verification`);
}
