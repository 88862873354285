import React from 'react';
import { Alert } from 'react-bootstrap';
import { REPORTED_DEBT_TYPES_OF_INTEREST } from '../../../utils/constants';
import LabelValueGrouping from '../../LabelValueGrouping';
import formatDollars from '../../../utils/formatDollars';
import { ReportedDebt } from '../../../api/queries/users/useLoanApplications';

export default function ReportedDebtSummary (props: { reportedDebts: Array<ReportedDebt> }) {
  const { reportedDebts } = props;

  let numMortgages = 0;
  let numHELOCs = 0;
  let sumCRDebt = 0;

  reportedDebts.forEach((reportedDebt) => {
    if (REPORTED_DEBT_TYPES_OF_INTEREST.has(reportedDebt.account_type) && reportedDebt.status === 'open') {
      if (reportedDebt.is_heloc) {
        numHELOCs += 1;
        sumCRDebt += reportedDebt.credit_limit_cents ?? NaN;
      } else {
        numMortgages += 1;
        sumCRDebt += reportedDebt.current_balance_cents ?? NaN;
      }
    }
  });

  return reportedDebts.length > 0 ? (
    <LabelValueGrouping data={{
      '# mortgages': numMortgages,
      '# HELOCs': numHELOCs,
      'Sum of debt': formatDollars(sumCRDebt, { cents: true }),
    }} />
  ) : (
    <Alert variant='warning'>
      No trade lines found.
    </Alert>
  );
}
