import React from 'react';
import { Card } from 'react-bootstrap';

import LabelValueGrouping from '../../LabelValueGrouping';
import formatDate from '../../../utils/formatDate';
import { LegacyLoanApplication } from '../../../api/queries/users/useLoanApplications';

type Props = {
  className?: string;
  loanApplication: LegacyLoanApplication;
};

export default function CreditReport ({ className, loanApplication }: Props) {
  return (
    <Card className={className}>
      <Card.Header>
        Credit Report
      </Card.Header>
      <Card.Body>
        <LabelValueGrouping
          data={{
            FICO: loanApplication.ficoScore || '–',
            'Pull Date': formatDate(loanApplication.ficoScoreGivenAt) || '–',
          }}
          condensed={ true }
        />
      </Card.Body>
    </Card>
  );
}
