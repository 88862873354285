import React from 'react';
import GenericModal from './GenericModal';
import useAdminAPICall from "../../utils/useAdminAPICall";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../Form/Form';
import SubmitError from '../Form/SubmitError';
import useExpandableState from '../../utils/useExpandableState';
import ButtonWithSpinner from '../ButtonWithSpinner';
import AgentFileField from '../BorrowerTabs/SystemTab/AgentFileField';
import { Partner } from '../../api/queries/partners/usePartner';

type DocumentType = {
  s3Filename: string | null,
  originalFilename: string | null,
};

type FieldValues = {
  document: DocumentType;
};

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
  partner: Partner;
};

export default function UploadAgentFileModal (props: Props) {
  const { expand, onSubmit, partner } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/partners/${partner._id}/agents`,
    method: 'POST',
  });

  const schema = yup.object().shape({
    document: yup.object({
      s3Filename: yup.string(),
      originalFilename: yup.string(),
    }).required('Please Upload a document'),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      document: { s3Filename: '', originalFilename: '' },
    },
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });
  const { formState: { isSubmitting }, setError } = methods;

  async function handleSubmit(data: FieldValues) {
    try {
      await callAPI({
        data: {
          document: data.document,
        },
      });
      onSubmit();
      expand.hideExpand();
    } catch (e: any) {
      if (e.response.status === 422) {
        setError('document', { message: e.response.data });
      } else {
        throw e;
      }
    }
  }

  return (
    <GenericModal expandable={expand} title={'Add a Document'} size="lg">
      <Form {...methods} onSubmit={handleSubmit}>
        <div className='border-bottom pb-2'>
          <AgentFileField partnerSlug={partner.slug}/>

          <SubmitError/>
        </div>
        <div className="d-flex justify-content-end mt-4">
          <Button variant="secondary" onClick={expand.hideExpand} className="mr-2">
            Cancel
          </Button>
          <ButtonWithSpinner variant="primary" type="submit" loading={isSubmitting}>
            Confirm
          </ButtonWithSpinner>
        </div>
      </Form>
    </GenericModal>
  );
}
