import * as React from 'react';

import ApprovedByAdminAlert from './ApprovedByAdminAlert'
import DeniedByAdminAlert from './DeniedByAdminAlert'
import IneligibleApplicationAlert from './IneligibleApplicationAlert';
import ManualDebtVerificationAlert from '../../Lens/ManualDebtVerificationAlert';
import ConciergeApplicationTab from './ConciergeApplicationTab';
import HomeCardApplicationTab from './HomeCardApplicationTab';
import { PROGRAMS } from '../../../api/queries/types';
import { LegacyLoanApplication } from '../../../api/queries/users/useLoanApplications';
import useLatest from '../../../api/queries/underwriting-decisions/useLatest';
import { LOAN_APPLICATION_STATUSES } from '../../../utils/constants';
import InlineLoadingIndicator from '../../InlineLoadingIndicator';

type Props = {
  loanApplication: LegacyLoanApplication;
}

export default function ApplicationTab ({ loanApplication }: Props) {
  const underwritingDecisionReq = useLatest(loanApplication._id, loanApplication.status === LOAN_APPLICATION_STATUSES.CLOSED);
  if (!underwritingDecisionReq) { return <InlineLoadingIndicator>Loading…</InlineLoadingIndicator>; }
  const underwritingDecision = underwritingDecisionReq.data;

  const getApplicationTabContents = () => {
    switch (loanApplication.program) {
      case PROGRAMS.HOME_CARD:
        return <HomeCardApplicationTab loanApplication={loanApplication} />
      default:
        return <ConciergeApplicationTab loanApplication={loanApplication} />
    }
  }

  return (
    <div className='mt-3 mb-5'>
      <IneligibleApplicationAlert underwritingDecision={underwritingDecision} />
      <DeniedByAdminAlert loanApplication={loanApplication} underwritingDecision={underwritingDecision} />
      <ApprovedByAdminAlert loanApplication={loanApplication} />
      <ManualDebtVerificationAlert loanApplication={loanApplication} />

      {getApplicationTabContents()}
    </div>
  )
}
