import useQuery from '../../shared/useQuery';

export type ProductSummary = {
  _id: string;
  name: string;
  partner: string;
};

type Response = Array<ProductSummary>;

export default function useProductSummaries () {
  return useQuery<Response>('/notebook/products/summaries');
}
