import useQuery from '../../shared/useQuery';

export type TrelloCard = {
  externalId: string;
  externalUrl: string;
  userNotificationType: string;
}

export type TrelloCards = Array<TrelloCard>;

export default function useTrelloCards (loanApplicationId?: string) {
  return useQuery<TrelloCards>(loanApplicationId ? `/notebook/loan-applications/${loanApplicationId}/trello-cards` : null);
}
