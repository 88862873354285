import useQuery from '../../shared/useQuery';

export type Document = {
  _id: string;
  name: string;
  createdAt: string;
  filename: string;
  type: string;
};

type Response = Array<Document>;

export default function useDocuments (loanApplicationId?: string) {
  return useQuery<Response>(loanApplicationId ? `/notebook/loan-applications/${loanApplicationId}/documents` : null);
}
