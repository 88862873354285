import React from "react";
import { Table, Badge } from "react-bootstrap";
import PrequalificationBadge from "./PrequalificationBadge";
import FinalDecisionBadge from "./FinalDecisionBadge";
import style from "../UnderwritingTab.module.scss";
import { Product } from '../index';

function ProductsTable({ products }: { products: Array<Product> }) {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th className={style.tableHead}>Product</th>
          <th className={style.tableHead}>Maximum Credit</th>
          <th className={style.tableHead}>Available Credit</th>
          <th className={style.tableHead}>Requested Credit</th>
          <th className={style.tableHead}>Selection</th>
          <th className={style.tableHead}>Pre-Qual</th>
          <th className={style.tableHead}>Final Decision</th>
        </tr>
      </thead>
      <tbody>
        {products && products.map((product) => (
          <tr key={product.name.replace(/ /g, '-')}>
            <td>{product.name}</td>
            <td>{product.maxCreditAmount}</td>
            <td>{product.availableCreditAmount}</td>
            <td>{product.requestedCreditAmount}</td>
            <td>
              {product.selected && (
                <Badge variant="success" className={style.selectedBadge}>
                  SELECTED
                </Badge>
              )}
            </td>
            <td>
              <PrequalificationBadge
                prequalificationResult={product.preQualDecision}
              />
            </td>
            <td>
              <FinalDecisionBadge finalDecision={product.strictDecision} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default ProductsTable;
