import React from 'react';
import GenericModal from './GenericModal';
import useAdminAPICall from "../../utils/useAdminAPICall";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Form from '../Form/Form';
import SubmitError from '../Form/SubmitError';
import useExpandableState from '../../utils/useExpandableState';
import ButtonWithSpinner from '../ButtonWithSpinner';

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
  loanApplicationId: string;
  documentId: string;
};

export default function DeleteDocumentModal (props: Props) {
  const { expand, documentId, onSubmit, loanApplicationId } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loan-applications/${loanApplicationId}/documents/${documentId}`,
    method: 'DELETE',
  });

  async function handleSubmit(data: {}) {
    await callAPI({
      data: {},
    });
    onSubmit();
    expand.hideExpand();
  }

  const methods = useForm<{}>({});
  const { formState: { isSubmitting }, reset } = methods;

  const onClose = () => {
    reset();
    expand.hideExpand();
  }

  return (
    <GenericModal expandable={expand} title={'Delete Document'} onClose={onClose}>
      <Form {...methods} onSubmit={handleSubmit}>
        <div className='border-bottom pb-2'>
          <p>Deleting this document will remove it completely from this loan profile.</p>
          <p>Would you like to continue?</p>
          <SubmitError/>
        </div>
        <div className="d-flex justify-content-end mt-4">
          <Button variant="secondary" onClick={expand.hideExpand} className="mr-2">
            No
          </Button>
          <ButtonWithSpinner variant="danger" type="submit" loading={isSubmitting}>
            Yes
          </ButtonWithSpinner>
        </div>
      </Form>
    </GenericModal>
  );
}
