import { Agent } from '../../queries/agents/useAgents';
import useMutation from '../../shared/useMutation';

type Payload = {
  erroredAgents: Array<Agent>;
}

export default function useMutateEnableAllLandingPages (partnerId: string) {
  return useMutation<void, Payload>('PUT', `/notebook/partners/${partnerId}/agents/enable-landing-pages`);
}
