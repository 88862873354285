import React from 'react';
import { Badge } from 'react-bootstrap';
import { LOAN_STATES } from '../../utils/constants';

export function getFundingPeriodPrettyString (status: string) {
  switch (status) {
    case LOAN_STATES.COMPLETED:
    case LOAN_STATES.PASTDUE:
      return 'Repayment';

    case LOAN_STATES.AUTHORIZED:
    case LOAN_STATES.FUNDED:
      return 'Draw';

    default: // do nothing
  }
}

export default function FundingPeriodBadge (props: { status: string }) {
  let status;
  switch (props.status) {
    case LOAN_STATES.COMPLETED:
    case LOAN_STATES.PASTDUE:
      status = 'REPAYMENT PERIOD';
      break;

    case LOAN_STATES.AUTHORIZED:
    case LOAN_STATES.FUNDED:
      status = 'DRAW PERIOD';
      break;

    default:
      return null;
  }
  return (
    <Badge pill variant='secondary'>
      { status }
    </Badge>
  );
}
