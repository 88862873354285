import cx from 'classnames';
import React from 'react';
import { Modal, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useTimeline, { TimelineEvent, useCombinedTimeline } from '../../../api/queries/loans/useTimeline';
import { useExpandableState } from '../../../utils';
import { formatRelativeTime, formatAbbreviatedDateWithTime } from '../../../utils/formatDate';
import InlineError from '../../InlineError';
import styles from './ActivityDrawers/ActivityDetail.module.scss';
import InlineLoadingIndicator from '../../InlineLoadingIndicator';

type Props = {
  loanApplicationId: string;
}

function FormattedTime (props: { time: string }) {
  if (!props.time) { return null; }
  return <>
    <OverlayTrigger
      placement='right'
      overlay={
        <Tooltip
          id='time-tooltip'
          className='m-2'
        >
            {formatAbbreviatedDateWithTime(props.time)}
        </Tooltip>
      }>
      <time dateTime={ props.time } className='text-secondary'> { formatRelativeTime(props.time) }</time>
    </OverlayTrigger>
  </>;
}

function TimelineMessagePart ({ part }: { part: TimelineEvent['message'][0]}) {
  if (part.link) {
    return <Link to={ part.link }>{ part.text }</Link>;
  }
  return <>{ part.text }</>;
}

function TimelineEventNote ({ event, note }: { event: TimelineEvent, note: TimelineEvent['notes'][0] }) {
  const expandable = useExpandableState();
  if (!note.body) { return null; }
  const parsedText = parseText(note.body, false);
  const truncateText = parsedText.length > 180;
  return <>
    <div className={`mt-2 d-flex ${truncateText ? 'cursor-pointer' : ''}`} onClick={() => truncateText && expandable.showExpand()}>
      <span className={`text-muted small border rounded p-3 pr-4 w-75 ${styles.noteBody}`}>
        "{ truncateText ?
          parsedText.slice(0, 180) + '...'
          :
          parseText(note.body)
        }"
      </span>
    </div>
    <TimelineEventNoteModal event={event} note={note} expandable={expandable} />
  </>;
}

function parseText (text: string, showLinks: boolean = true) {
  const URL_REGEX = /[(\s|*|[](http.*?)[\s)|*|\]]/;
  const arr = text.split(URL_REGEX);
  if (showLinks) {
    return arr.map((phrase, i) => /^http/.test(phrase) ? <><a href={phrase} key={i}>Link</a> </> : `${phrase}${i === arr.length - 1 ? '' : ' '}`);
  } else {
    return arr.map((phrase, i) => /^http/.test(phrase) ? 'Link ' : `${phrase}${i === arr.length - 1 ? '' : ' '}`).join('');
  }
}

function TimelineEventNoteModal ({ event, note, expandable }: {event: TimelineEvent, note: TimelineEvent['notes'][0], expandable: ReturnType<typeof useExpandableState> }) {
  return <Modal show={expandable.isExpanded} onHide={expandable.hideExpand} size='lg'>
    <Modal.Header closeButton>
      <div>
        <TimelineMessage event={event}/>
      </div>
    </Modal.Header>
    <Modal.Body className={styles.noteBody}>
      {parseText(note.body)}
    </Modal.Body>
  </Modal>;
}

function TimelineMessage ({ event }: { event: TimelineEvent }) {
  return <>
    {event.message.map((part, i) => {
      return <span key={i}>
        <TimelineMessagePart part={ part } key={i} />{ i === event.message.length - 1 ? '' : ' ' }
      </span>;
    })}
    <FormattedTime time={ event.publishedAt } />
  </>;
}

function TimelineEventItem ({ event }: { event: TimelineEvent }) {
  return <div className='mb-3'>
    <TimelineMessage event={event}/>
    {event.notes.map((note, i) => {
      return <TimelineEventNote event={event} note={note} key={i} />;
    })}
  </div>;
}

export function TimelineList (props: { events?: Array<TimelineEvent>, isLoading?: boolean, error?: any }) {
  if (props.isLoading || !props.events) {
    return <div>Loading... <InlineLoadingIndicator /></div>;
  }

  if (props.error) {
    return <InlineError>Error loading timeline events. Please try again.</InlineError>;
  }

  if (props.events.length === 0) {
    return <div>No timeline events yet.</div>;
  }

  return (
    <div>
      {props.events.map((event) => {
        return <TimelineEventItem event={event} key={event._id} />;
      })}
    </div>
  );
}

export function TimelineListLoader (props: { scopeId: string; entityId: string }) {
  const { data: events, error, isLoading } = useTimeline(props.scopeId, props.entityId);
  return (
    <>
      <div className={ cx(styles.line, 'mt-4 mb-3') }>
        <span className={ styles.title }>Timeline</span>
      </div>
      <TimelineList
        events={ events }
        isLoading={ isLoading }
        error={ error }
      />
    </>
  );
}

export default function TimelineTab (props: Props) {

  const { data: events, error, isLoading } = useCombinedTimeline(props.loanApplicationId);

  return <TimelineList
    events={ events }
    isLoading={ isLoading }
    error={ error }
  />

}
