import React from 'react';
import './Spinner.css';

type Props = {
  radius?: number;
  stroke?: number;
  gap?: number;
  margin?: number;
  slow?: boolean;
  reverse?: boolean;
};

export default function Spinner (props: Props) {
  const radius      = props.radius || 100;
  const strokeWidth = props.stroke || 25;
  const gapPercent  = props.gap || 15;
  const margin      = props.margin || 15;
  
  const G = (gapPercent / 100 * 360) / 180 * Math.PI;
  const W = radius * 2 + margin * 2;
  const H = W;
  
  const Ox = W / 2;
  const Oy = W / 2;
  const viewBox = `${ Ox - W / 2 } ${ Oy - H / 2 } ${ Ox + W / 2 } ${ Oy + H / 2 }`;
  
  const Ro = radius;
  const Ri = radius - strokeWidth;
  
  const Gcos = Math.cos(G / 2);
  const Gsin = Math.sin(G / 2);
  
  const Xo = Gcos * Ro;
  const Yo = Gsin * Ro;
  const Xi = Gcos * Ri;
  const Yi = Gsin * Ri;
  
  const Ax = Ox + Xo;
  const Ay = Oy - Yo;
  const Bx = Ox + Xo;
  const By = Oy + Yo;
  const Cx = Ox + Xi;
  const Cy = Oy + Yi;
  const Dx = Ox + Xi;
  const Dy = Oy - Yi;
  
  const pathDraw = `M ${ Ax } ${ Ay } A ${ Ro } ${ Ro } 0 1 0 ${ Bx } ${ By } L ${ Cx } ${ Cy } A ${ Ri } ${ Ri } 0 1 1 ${ Dx } ${ Dy } Z`;
  return (
    <svg
      className     = 'Spinner'
      viewBox       = { viewBox }
      data-slow     = { props.slow }
      data-reverse  = { props.reverse }
    >
      <path d={ pathDraw } />
    </svg>
  );
}
