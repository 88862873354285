import React from 'react';
import { Button, Modal, ButtonProps } from 'react-bootstrap';
import { useExpandableState } from '../../../utils';
import styles from './ConfirmModal.module.scss';
import Field from '../../Form/Field';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Form from '../../Form/Form';

type Props = {
  title: string;
  expand: ReturnType<typeof useExpandableState>;
  onConfirm: (form: FieldValues) => void;
  confirmButtonVariant?: ButtonProps['variant'];
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  notes?: boolean;
};

const ConfirmModal = ({
  title,
  children,
  expand,
  onConfirm,
  onCancel = () => expand.hideExpand(),
  confirmButtonVariant = 'primary',
  notes = false,
}: Props) => {

  const schema = yup.object().shape({ notes: notes ? yup.string().required('Please enter notes.') : yup.string() });
  const methods = useForm<FieldValues>({ defaultValues: { notes: undefined }, resolver: yupResolver(schema) });

  const handleSubmit = (form: FieldValues) => {
    onConfirm(form);
    expand.hideExpand();
  }

  return (
    <Modal show={ expand.isExpanded } onHide={ expand.hideExpand } size='sm'>
      <Form { ...methods } onSubmit={ handleSubmit }>
        <Modal.Header className={ styles.header } closeButton>
          { title }
        </Modal.Header>
        <Modal.Body>
          { notes && <Field name='notes' label='Internal Notes' controlProps={{ as: 'textarea', rows: '4' }}/> }
          { children }
        </Modal.Body>
        <Modal.Footer className='p-2'>
          <div className={ styles.footerActions }>
            <Button block variant='secondary' onClick={ onCancel }>
              No
            </Button>
            <Button block variant={ confirmButtonVariant } type='submit'>
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ConfirmModal;
