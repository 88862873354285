import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Decimal from 'decimal.js';
import LabelValueGrouping from '../../LabelValueGrouping';
import formatDollars from '../../../utils/formatDollars';
import { titleCase } from '../../../utils/string';
import { LOAN_APPLICATION_PROPERTY_OWNERSHIP_TYPE, LOAN_APPLICATION_SOURCES } from "../../../utils/constants";

type Props = {
  className?: string,
  statedMortgageMonthlyPayment: number,
  propertyOwnershipType?: string,
}

export default function StatedDebtInfo ({ className, statedMortgageMonthlyPayment, propertyOwnershipType }: Props) {

  return (
    <Card className={className}>
      <Card.Header>
        Debt
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <LabelValueGrouping
              data={{
                Source: titleCase(LOAN_APPLICATION_SOURCES.STATED),
                [`${propertyOwnershipType === LOAN_APPLICATION_PROPERTY_OWNERSHIP_TYPE.OWNED ? '' : 'Expected '}Monthly Total`]: formatDollars(new Decimal(statedMortgageMonthlyPayment).div(100).toNumber()),
              }}
              condensed={ true }
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
