import React from 'react';
import { Col, Form, FormGroup } from 'react-bootstrap';
import { useFormContext } from "react-hook-form";
import Field from '../Form/Field';
import SsnField from '../Form/SsnField';
import AddressFields from '../Form/AddressFields';

export default function UserInformation () {
  const { watch } = useFormContext();
  const isPreviousAddress = watch('isPreviousAddress');

  return (
    <>
      <h4 className='mt-4'>User Information</h4>
      <p className='text-muted small'>Update this person's information below.</p>

      <FormGroup className='mb-0'>
        <Form.Label>Full Name</Form.Label>

        <Form.Row>
          <Col sm={6}>
            <Field placeholder='First Name' name='firstName' />
          </Col>
          <Col sm={6}>
            <Field placeholder='Last Name' name='lastName' />
          </Col>
        </Form.Row>
      </FormGroup>

      <Field label='Date of Birth' name='dob' placeholder='MM / DD / YYYY' controlProps={{ type: 'date' }} />
      <Field label='Address' name='isPreviousAddress' controlProps={{ as: 'select' }}>
        <option value='home'>Home Address</option>
        <option value='previous'>Previous Address</option>
      </Field>

      {
        isPreviousAddress === 'home' &&
        <AddressFields
          addressLine1FieldName='address_line1'
          addressLine2FieldName='address_line2'
          cityFieldName='city'
          stateFieldName='state'
          zipFieldName='zip'
        />
      }

      {
        isPreviousAddress === 'previous' &&
        <AddressFields
          addressLine1FieldName='previous_address_line1'
          addressLine2FieldName='previous_address_line2'
          cityFieldName='previous_city'
          stateFieldName='previous_state'
          zipFieldName='previous_zip'
        />
      }

      <SsnField name='ssn'/>
    </>
  );
}
