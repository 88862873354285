import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import styles from './Drawer.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Col, Row } from 'react-bootstrap';

export type DrawerSize = 'sm' | 'md' | 'lg' | 'xl';

export default function Drawer ({
  children,
  className,
  onClose,
  size,
  title,
  closeDelay,
}: {
  children: any,
  className?: string,
  onClose: () => void,
  size?: DrawerSize,
  title: string,
  closeDelay?: number,
}) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!visible) { setVisible(true) }
  }, [visible]);

  async function onCloseButton() {
    setVisible(false);
    await new Promise(resolve => setTimeout(resolve, closeDelay || 0));
    onClose();
  }

  return (
    <div className={cx(
      'bg-white',
      'shadow',
      styles.drawer,
      styles[size || 'sm'],
      visible ? `${styles.visibleDrawer}` : '',
      className,
    )}>
      <div className='p-3'>
        <Row className='mb-4'>
          <Col><h4 className={cx(styles.title, 'mt-1')}>{title}</h4></Col>
          <Col className='text-right'>
            <Button variant='outline-secondary' onClick={onCloseButton} className={styles.closeButton}>
              <CloseIcon />
            </Button>
          </Col>
        </Row>
        {children}
      </div>
    </div>
  );
}
