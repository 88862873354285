import useQuery from '../../shared/useQuery';

type MembershipAgreement = {
  _id: string;
  loanId: string;
  url: string;
  urlForDownload: string;
  htmlContent: string;
  acceptedAt: string;
  createdAt: string;
};

export default function useMembershipAgreement (loanId?: string) {
  return useQuery<MembershipAgreement>(loanId ? `/notebook/loans/${loanId}/contract/membership_agreement` : null);
}
