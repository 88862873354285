import React from 'react';
import { useFormContext, useController } from 'react-hook-form';
import cx from 'classnames';
import CheckmarkIcon from '../CardCheckbox/CheckmarkIcon';
import '../CardCheckbox/CardCheckbox.css';

type Props = {
  name: string;
  label: string;
  className?: string;
  defaultTheme?: boolean;
  disabled?: boolean;
  onChange?: () => void;
};

export default function CardCheckbox ({ name, className, label, defaultTheme = true, disabled = false, onChange }: Props) {
  const { control } = useFormContext();
  const { field: { onChange: onCheckChanged, value: checked } } = useController({ name, control });

  const onClick = () => {
    if (!disabled) {
      onCheckChanged(!checked);
      onChange?.();
    }
  };

  return (
    <fieldset className={cx(className, 'p-2', 'cardCheckbox-container', defaultTheme ? 'default-theme' : '', { checked, disabled })} onClick={onClick}>
      <label htmlFor={label}>{label}</label>
      <div className='cardCheckbox-checkbox-wrapper'>
        <CheckmarkIcon role='presentation' name={label} />
      </div>
    </fieldset>
  );
}
