import useQuery from '../../shared/useQuery';

export type StatementAdjustmentResponse = {
  _id: string;
  status: string;
  statement_category: string;
  interest_cents: number;
  principal_cents: number;
  fee_cents: number;
  overpayment_cents: number;
  direction: string;
  writeoff_reason: string;
  debt_forgiveness: boolean;
  effective_date: string;
  accounting_date: string;
  voided_at: Date;
};

export default function useStatementAdjustmentDetail (adjustmentId: string) {
  return useQuery<StatementAdjustmentResponse>(`/notebook/statement-adjustments/${adjustmentId}`);
}
