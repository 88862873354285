export const FACT_CATEGORIES = {
  PROGRAM: 'program',
  IDENTITY: 'identity',
  FLAG: 'flag',
}

export const RULE_GROUPS = {
  CREDIT: 'credit',
  ELIGIBILITY: 'eligibility',
  OTHER_ELIGIBILITY: 'other_eligibility',
  VERIFICATION: 'verification',
  FLAG: 'flag',
  COUNTEROFFER: 'counteroffer',
}

export const UNDERWRITING_DECISION_TYPES = {
  PRE_QUALIFICATION: 'pre_qualification',
  STRICT_DECISION: 'strict_decision',
}

export const PRE_QUALIFICATION_RESULTS = {
  UNDECIDED: "UNDECIDED",
  PRE_QUALIFIED: "PREQUALIFIED",
  DENY: "DENIED",
  INELIGIBLE: "INELIGIBLE",
  COUNTEROFFER: "COUNTEROFFER",
};

export const FINAL_DECISION_RESULTS = {
  UNDECIDED: "UNDECIDED",
  APPROVED: "APPROVED",
  DENY: "DENIED",
  INELIGIBLE: "INELIGIBLE",
  COUNTEROFFER: "COUNTEROFFER",
};

export enum UNDERWRITING_RUN_OUTCOMES {
  APPROVED      = 'APPROVED',
  COUNTEROFFER  = 'COUNTEROFFER',
  DENIED        = 'DENIED',
  INELIGIBLE    = 'INELIGIBLE',
  UNDECIDED     = 'UNDECIDED',
  PREQUALIFIED  = 'PREQUALIFIED',
  INCOMPLETE    = 'INCOMPLETE',
}

export const RULE_TYPE = {
  VERIFICATION: "VERIFICATION",
  CREDIT: "CREDIT",
  ELIGIBILITY: "ELIGIBILITY",
  FLAG: "FLAG",
  COUNTEROFFER: "COUNTEROFFER",
};

export const VERIFICATION_RESULTS = {
  VERIFIED: "VERIFIED",
  UNVERIFIED: "UNVERIFIED",
  STATED: "STATED",
};

export const RULE_DECISION = {
  PASS: "pass",
  FAIL: "fail",
  UNDECIDED: "undecided",
};
