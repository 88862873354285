import React from 'react';
import { Card } from 'react-bootstrap';
import cx from 'classnames';
import { useExpandableState } from '../utils';
import RelativeTimestamp from './RelativeTimestamp';
import SmallMutedText from './SmallMutedText';

import styles from './ExpandableCard.module.scss';

// @todo - de-dupe this with layouts/SplitView

export function MainPanel(props: { children: React.ReactNode }) {
  return (
    <div className={styles.mainPanel}>
      { props.children }
    </div>
  );;
}

export function SecondaryPanel(props: { className?: string, children: React.ReactNode }) {
  return <div className={cx(styles.secondaryPanel, props.className)}>{props.children}</div>;
}

export function InsetContent(props: { children: React.ReactNode }) {
  return (
    <div className="d-flex border-left pl-3 mb-3">
      {React.Children.map(props.children, (child) => (
        <div className="flex-fill">{child}</div>
      ))}
    </div>
  );
}

export function PanelWithActions(props: { children: React.ReactNode }) {
  return <div className="d-flex">{props.children}</div>;
}

type Props = {
  initiallyExpanded?: boolean;
  expandableState?: ReturnType<typeof useExpandableState>;
  id?: string;
  subtitle: string | Date;
  faded?: boolean;
  title: React.ReactNode;
  badges?: React.ReactNode;
  detailsLink?: string | null;
  linkInNewTab?: boolean;
  renderBody?: () => React.ReactNode;
  'data-qa'?: string;
};

export default function ExpandableCard(props: Props) {
  const fallbackExpandableState = useExpandableState(props.initiallyExpanded || false);
  const expand = props.expandableState || fallbackExpandableState;
  const controlTargetId = `${props.id}_expandedcontent`;

  let subtitle;
  if (props.subtitle) {
    if (props.subtitle instanceof Date) {
      subtitle = <RelativeTimestamp date={props.subtitle} />;
    } else {
      subtitle = props.subtitle;
    }
  }

  return (
    <Card
      id={props.id}
      className={cx(props.faded && styles.faded)}
      data-expanded={expand.isExpanded}
      data-qa={props['data-qa']}
    >
      <Card.Body className={styles.cardBody}>
        <div className="d-flex">
          <div
            className={cx('flex-grow-1', styles.expandToggle)}
            onClick={expand.toggleExpand}
            role="button"
            aria-controls={controlTargetId}
          >
            <div>
              <Card.Title as="span" className="h5">
                {props.title}
              </Card.Title>
              {!!subtitle && (
                <Card.Subtitle as="span" className="ml-2">
                  <SmallMutedText>{subtitle}</SmallMutedText>
                </Card.Subtitle>
              )}
            </div>
            {!!props.badges && <div className={cx('mt-1', styles.badges)}>{props.badges}</div>}
          </div>
          {!!props.detailsLink && (
            <div className={styles.detailLink}>
              <a href={props.detailsLink} target={props.linkInNewTab ? '_blank' : undefined} rel='noreferrer'>View Details &raquo;</a>
            </div>
          )}
        </div>

        <div
          className={styles.expandableContent}
          id={controlTargetId}
          aria-expanded={expand.isExpanded}
        >
          {expand.isExpanded && props.renderBody ? props.renderBody() : null}
        </div>
      </Card.Body>
    </Card>
  );
}
