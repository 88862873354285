import React from 'react'
import useLedgerSummary from '../api/queries/loans/useLedgerSummary';
import { Form, FormGroup, FormLabel } from 'react-bootstrap';
import CardCheckbox from './Form/CardCheckbox';
import Decimal from 'decimal.js';

export default function IntendedFinalRepayment ({ loanId, field, repaymentAmount }: { loanId: string, field: string, repaymentAmount: string }) {
  const { data, error } = useLedgerSummary(loanId);

  if (error) { return <span>Error loading outstanding balance</span> };
  if (!(data?.balanceCents && repaymentAmount)) { return null; };

  return (
    data.balanceCents === new Decimal(repaymentAmount).times(100).toNumber() ?
    <FormGroup>
      <FormLabel>Final Repayment</FormLabel>
      <CardCheckbox
        name={ field }
        label='Update loan to "Repaid" after processing this payment.'
      />
      <Form.Text className='text-muted'>
        The entered payment amount matches the outstanding balance on the loan. Check this box to mark the loan repaid after this payment is processed.
      </Form.Text>
    </FormGroup>
    : null
  );
}
