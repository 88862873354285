import useQuery from '../../shared/useQuery';

export enum INCOME_SOURCES {
  BETTER = 'better',
  STATED = 'stated'
}

export type Income = {
  id: string;
  type: string;
  source: INCOME_SOURCES;
  annualAmountDollars: number;
  monthlyAmountDollars: number;
  employer: string;
  occupation: string;
  description: string;
  start_date: string; // DATEONLY
};

export default function useIncomes (loanApplicationId: string) {
  return useQuery<Array<Income>>(`/notebook/loan-applications/${loanApplicationId}/incomes`);
}
