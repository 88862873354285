import React, { useEffect } from 'react';
import { Partner } from '../../../api/queries/partners/usePartner';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import SubmitError from '../../Form/SubmitError';
import { Button } from 'react-bootstrap';
import ButtonWithSpinner from '../../ButtonWithSpinner';

type PartnerFields = {
  subdomain: string;
  helpUrl: string;
  learnMoreUrl: string;
};

type Props = {
  partner: Partner;
  onSubmit: () => void;
  onHide: () => void;
}

type ErrorMessage = {
  key: string;
  message: string;
};

export default function EditPartnerUrls (props: Props) {
  const { partner, onSubmit, onHide } = props;

  const { callAPI, data: apiResponse } = useAdminAPICall({
    endpoint: `/notebook/partners/${partner._id}`,
    method: 'PUT',
  });

  async function handleSubmit(data: PartnerFields) {
    await callAPI({
      data: {
        subdomain: data.subdomain,
        helpUrl: data.helpUrl,
        learnMoreUrl: data.learnMoreUrl,
      },
    });
    onSubmit();
    onHide();
  }

  const schema = yup.object();

  const form = useForm<PartnerFields>({
    defaultValues: {
      subdomain: partner.subdomain,
      helpUrl: partner.helpUrl,
      learnMoreUrl: partner.learnMoreUrl,
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty } } = form;

  useEffect(() => {
    if (apiResponse?.errors) {
      apiResponse.errors.forEach((error: ErrorMessage) => {
        form.setError(error.key as any, { message: error.message });
      })
    }
  }, [apiResponse?.errors, form]);

  return <div>
    <Form {...form} onSubmit={handleSubmit}>
      <Field name='subdomain' label='Subdomain' secondaryLabel={partner.url} required/>
      <Field name='helpUrl' label='Help URL' required/>
      <Field name='learnMoreUrl' label='Learn More URL'/>

      <SubmitError/>

      <div className="d-flex justify-content-end mt-4">
        <Button variant="secondary" onClick={onHide} className="mr-2">
          Cancel
        </Button>
        <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
          Confirm
        </ButtonWithSpinner>
      </div>
    </Form>
  </div>;
}
