import React from 'react';
import ProductShow from '../../../components/System/Products/ProductShow';
import useProduct, { Product } from '../../../api/queries/products/useProduct';
import { useParams } from 'react-router-dom';
import SystemLayout from '../../Layouts/SystemLayout';
import ShowPartnerLayout from '../../Layouts/ShowPartnerLayout';
import { usePartner } from '../../../api/queries/partners/usePartner';
import { titleCase } from '../../../utils/string';

function hasConfigs (product: Product) {
  return product.ficoConfigs && product.ficoConfigs.length > 0;
}

export default function ProductShowPage () {
  const { productId, portfolioId } = useParams<{ productId: string, portfolioId?: string }>();

  const productRequest = useProduct(productId);
  const partnerRequest = usePartner(productRequest.data?.partnerId);
  if (!productRequest.data || !partnerRequest.data) {
    return null;
  }

  const product = productRequest.data;
  const partner = partnerRequest.data;
  let currentPortfolio;
  if (portfolioId) {
    currentPortfolio = product.portfolios.find(portfolio => portfolio._id === portfolioId);
  } else {
    currentPortfolio = hasConfigs(product) ? undefined : product.portfolios[0];
  }
  return (
    <SystemLayout selectedTab='partners' heading={partner.short_name}>
      <ShowPartnerLayout partner={partner} selectedTab='products' heading={titleCase(product.name)}>
        <ProductShow product={product} currentPortfolio={currentPortfolio}/>
      </ShowPartnerLayout>
    </SystemLayout>
  );
}
