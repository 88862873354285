import React from "react";
import VerificationBadge from "../VerificationBadge";
import { Col, Row } from "react-bootstrap";
import { VERIFICATION_RESULTS } from '../constants';
import { Fact } from '../index';
import InlineButton from "../../../InlineButton";
import { useExpandableState } from "../../../../utils";
import EditFactVerificationModal from "../../../Modals/EditFactVerificationModal";

export type AgentAuthorizationFactData = {
  status: string;
};

function AgentAuthorizationFact ({ fact, showEditButton }: { fact: Fact<AgentAuthorizationFactData>, showEditButton?: boolean }) {
  const editFactModal = useExpandableState();

  return (
    <Row className="border-top py-3" data-qa='fact-row'>
      <Col sm={4} className="font-weight-bold pl-0">{fact.human_name}</Col>
      <Col sm={4}>{fact.value}</Col>
      <Col sm={4} className="pr-0">
        <VerificationBadge
          verificationResult={fact.verified ? VERIFICATION_RESULTS.VERIFIED : VERIFICATION_RESULTS.UNVERIFIED}
          unverifiedReasons={fact.unverified_reasons}
        />
        {
          showEditButton &&
          <InlineButton onClick={editFactModal.showExpand} className='float-right'>
            Edit
          </InlineButton>
        }
      </Col>
      <EditFactVerificationModal
        expand={editFactModal}
        fact={fact}
        onSubmit={() => window.location.reload()}
        submitUrl='/agent-authorization'
      />
    </Row>
  );
}

export default AgentAuthorizationFact;
