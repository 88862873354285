import React, { useState } from 'react';
import { Button, Collapse, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { PartnerSummary, usePartners } from '../../../api/queries/partners/usePartners';
import { CollapsableIcon } from '../../CollapsableIcon';
import useExpandableState from '../../../utils/useExpandableState';
import NewPartnerModal from '../../Modals/NewPartnerModal';

function PartnersTable ({ partners }: { partners: Array<PartnerSummary> }) {
  const navigate = useNavigate();

  return <Table>
    <thead>
    <tr>
      <th>Name</th>
      <th>Pricing Type</th>
      <th>Live</th>
      <th>Card Status</th>
      <th>Agents #</th>
      <th>Applications #</th>
      <th>Loans #</th>
    </tr>
    </thead>

    <tbody>
    {
      partners.map(partner => (
        <tr key={partner._id} className='cursor-pointer' onClick={ () => navigate(`/system/partners/${partner._id}/info`) }>
          <td>{partner.shortName}</td>
          <td>{partner.agentPricingPaymentType || '-'}</td>
          <td>{partner.waitListEnabled ? 'No' : 'Yes'}</td>
          <td>{partner.cardStatus}</td>
          <td>{partner.agentCount}</td>
          <td>{partner.loanApplicationsCount}</td>
          <td>{partner.loansCount}</td>
        </tr>
      ))
    }
    </tbody>
  </Table>;
}

export default function Partners () {
  const partnersRequest = usePartners();
  const [open, setOpen] = useState(false);
  const newPartnerModal = useExpandableState();

  if (!partnersRequest.data) {
    return null;
  }
  const partners = partnersRequest.data;

  return (
    <section className='mb-4 mt-2 d-flex flex-column'>
      <Button className='btn btn-primary ml-auto h-100 mt-n5 mb-2' onClick={ () => newPartnerModal.showExpand() }>Add partner</Button>
      <PartnersTable partners={partners.filter((partner) => partner.active)}/>

      <h5 onClick={() => setOpen(!open)} className='cursor-pointer'>
        Inactive Partners
        <CollapsableIcon open={open}/>
      </h5>
      <Collapse in={open}>
        <div>
          <PartnersTable partners={partners.filter((partner) => !partner.active)}/>
        </div>
      </Collapse>

      <NewPartnerModal
        expandable={newPartnerModal}
        partners={partners}
      />
    </section>
  );
}
