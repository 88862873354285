import { PortfolioUnderwritingResult, Rule } from '../index';
import { Col, Collapse, Row } from 'react-bootstrap';
import React from 'react';
import { RULE_GROUPS, RULE_TYPE } from '../constants';
import { CollapsableIcon } from '../../../CollapsableIcon';
import RulesTable from '../RulesTable';
import UnderwritingRunOutcomeBadge from './UnderwritingRunOutcomeBadge';

function byGroup (rules: Array<Rule>, group: string) {
  return rules.filter(rule => rule.notebookGroup === group);
}

function PortfolioUnderwritingResultRow ({ portfolioUnderwritingResult }: { portfolioUnderwritingResult: PortfolioUnderwritingResult }) {
  const [open, setOpen] = React.useState(false);

  const rules = portfolioUnderwritingResult.underwritingRules || [];

  const requiredRules = rules.filter(rule => rule.required); // Hide non-required rules from UI
  const verificationRules = byGroup(requiredRules, RULE_GROUPS.VERIFICATION);
  const flagRules = byGroup(requiredRules, RULE_GROUPS.FLAG);
  const creditRules = byGroup(requiredRules, RULE_GROUPS.CREDIT);
  const counterofferRules = byGroup(requiredRules, RULE_GROUPS.COUNTEROFFER);
  const eligibilityRules = byGroup(requiredRules, RULE_GROUPS.ELIGIBILITY);
  const otherEligibilityRules = byGroup(requiredRules, RULE_GROUPS.OTHER_ELIGIBILITY);

  return <>
    <Row
      onClick={() => setOpen(!open)}
      className="border-top py-3 cursor-pointer"
    >
      <Col sm={2} className="font-weight-bold pl-0">
        {portfolioUnderwritingResult.product.name}
      </Col>
      <Col sm={7}>
        {portfolioUnderwritingResult.portfolio.name}
      </Col>
      <Col sm={2} className='pr-0'>
        <UnderwritingRunOutcomeBadge outcome={portfolioUnderwritingResult.outcome} />
      </Col>
      <Col sm={1} className='pr-0'>
        <CollapsableIcon open={open}/>
      </Col>
    </Row>
    <Collapse in={open}>
      <div>
        <RulesTable
          title="SUSPICIOUS ACTIVITY"
          type={RULE_TYPE.FLAG}
          rules={flagRules}
        />
        <RulesTable
          title="VERIFICATION"
          description="Facts are derived from underwriting data. All verification rules must pass for a loan to be approved."
          type={RULE_TYPE.VERIFICATION}
          rules={verificationRules}
        />
        <RulesTable
          title="CREDIT"
          description="Facts are derived from underwriting data. All verification rules must pass for a loan to be approved."
          type={RULE_TYPE.CREDIT}
          rules={creditRules}
        />
        <RulesTable
          title="COUNTEROFFER"
          description="Counteroffer rules."
          type={RULE_TYPE.COUNTEROFFER}
          rules={counterofferRules}
        />
        <RulesTable
          title="ELIGIBILITY"
          description="Facts are derived from underwriting data. All verification rules must pass for a loan to be approved."
          type={RULE_TYPE.ELIGIBILITY}
          rules={eligibilityRules}
        />
        <RulesTable
          title="OTHER ELIGIBILITY"
          description="Other system checks that must pass for a borrower to be eligible."
          type={RULE_TYPE.ELIGIBILITY}
          rules={otherEligibilityRules}
        />
      </div>
    </Collapse>
  </>;
}

export default function PortfolioUnderwritingResults ({ portfolioUnderwritingResults }: { portfolioUnderwritingResults: Array<PortfolioUnderwritingResult> }) {
  return (
    <div className="portfolio-underwriting-results px-3">
      <Row className="pb-3">
        <Col sm={2} className="font-weight-bold pl-0">
          Product
        </Col>
        <Col sm={7} className="font-weight-bold">
          Portfolio
        </Col>
        <Col sm={3} className="font-weight-bold">
          UW Outcome
        </Col>
      </Row>
      {
        portfolioUnderwritingResults.map(portfolioUnderwritingResult => (
          <PortfolioUnderwritingResultRow
            key={portfolioUnderwritingResult._id}
            portfolioUnderwritingResult={portfolioUnderwritingResult}
          />
        ))
      }
    </div>
  )
}
