import React from 'react';
import { Button, Modal } from "react-bootstrap";
import useExpandableState from '../../utils/useExpandableState';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { Partner } from '../../api/queries/partners/usePartner';
import { useNavigate } from 'react-router-dom';
import useMutateDeletePartner from '../../api/mutations/partners/useMutateDeletePartner';

type Props = {
  expandable: ReturnType<typeof useExpandableState>;
  partner: Partner;
};

export default function DeletePartnerModal (props: Props) {
  const { expandable, partner } = props;
  const navigate = useNavigate();

  const {
    mutate: deletePartner,
    isLoading,
  } = useMutateDeletePartner(partner._id);

  async function handleSubmit() {
    await deletePartner();
    navigate('/system/partners');
  }

  const onModalHide = () => {
    expandable.hideExpand();
  }

  return (
    <Modal show={expandable.isExpanded} size='sm' onHide={onModalHide}>
      <Modal.Header>
        <Modal.Title>
          Please confirm
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          This will permanently remove this partner and all it's associated configurations.
        </p>
        <p>
          Are you sure you want to delete this partner?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex w-100'>
          <Button variant="secondary" onClick={onModalHide} className="mr-2 w-50">
            No
          </Button>
          <div className='w-50'>
            <ButtonWithSpinner variant="danger" type="submit" block onClick={handleSubmit} loading={isLoading}>
              Yes
            </ButtonWithSpinner>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
