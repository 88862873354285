import React from 'react';
import useAdminAPICall from "../../utils/useAdminAPICall";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../Form/Form';
import Field from '../Form/Field';
import SubmitError from '../Form/SubmitError';
import useExpandableState from '../../utils/useExpandableState';
import { Fact } from '../BorrowerTabs/UnderwritingTab';
import { MonthlyIncomeFactData } from '../BorrowerTabs/UnderwritingTab/facts/MonthlyIncomeFact';
import { MonthlyDebtFactData } from '../BorrowerTabs/UnderwritingTab/facts/MonthlyDebtFact';
import DollarField from '../Form/DollarField';
import { Decimal } from 'decimal.js';
import * as _ from 'lodash';
import ButtonWithSpinner from '../ButtonWithSpinner';

type FieldValues = {
  verified: string;
  valueInCents: string;
};

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
  fact: Fact<MonthlyIncomeFactData | MonthlyDebtFactData>;
  submitUrl: string;
  admitsZero: boolean;
  disabledValue?: boolean;
  valueFootnote?: string;
};

export default function EditAmountFactModal (props: Props) {
  const { expand, onSubmit, fact, submitUrl, admitsZero, disabledValue, valueFootnote } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loan-applications/${fact.loan_application_id}${submitUrl}`,
    method: 'PUT',
  });

  async function handleSubmit(data: FieldValues) {
    await callAPI({ data: {
      verified: data.verified === 'true',
      valueInCents: new Decimal(data.valueInCents).times(100).toNumber(),
    }});
    onSubmit();
    expand.hideExpand();
  }

  const schema = yup.object().shape({
    verified: yup.string(),
    valueInCents: yup.string().test(
      'valueInCents',
      `Must be a positive${!admitsZero ? ', non zero' : ''} dollar value.`,
      value => !!value && (admitsZero ? new Decimal(value).greaterThanOrEqualTo(0) : new Decimal(value).greaterThan(0)),
    ),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      verified: fact.verified ? 'true' : 'false',
      valueInCents: !_.isNil(fact.data.value_in_cents) ? new Decimal(fact.data.value_in_cents).dividedBy(100).toFixed(2) : '',
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty }, reset } = methods;

  const onModalHide = () => {
    expand.hideExpand();
    reset();
  }

  return (
    <Modal show={expand.isExpanded} onHide={onModalHide} data-qa='edit-fact-modal'>
      <Modal.Header>
        <Modal.Title>
          {fact.human_name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form {...methods} onSubmit={handleSubmit}>
          <Field name='verified' label='Verification Status' controlProps={{ as: 'select' }}>
            <option value='true'>Verified</option>
            <option value='false'>Unverified</option>
          </Field>

          <DollarField label='Value' name='valueInCents' disabled={disabledValue} footnote={valueFootnote} />

          <SubmitError/>

          <div className="d-flex justify-content-end mt-4">
            <Button variant="secondary" onClick={onModalHide} className="mr-2">
              Cancel
            </Button>
            <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
              Confirm
            </ButtonWithSpinner>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
