import React from 'react'
import { Button } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import SubmitError from '../../Form/SubmitError';
import useAdminAPICall from '../../../utils/useAdminAPICall'
import ButtonWithSpinner from '../../ButtonWithSpinner';

type FieldValues = {
  notes: string;
  completedReason: string;
};

type Props = {
  onHide: () => void,
  onSubmit: () => void,
  loan: { _id: string },
};

function MarkLoanCompletedForm(props: Props) {
  const { loan, onHide, onSubmit } = props

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loans/${loan._id}/mark-as-completed`,
    method: 'PUT',
  })

  async function handleSubmit(data: FieldValues) {
    await callAPI({ data });
    onSubmit();
    onHide();
  }

  const schema = yup.object().shape({
    notes: yup.string(),
    completedReason: yup.string().required('Please select a reason'),
  });

  const methods = useForm<FieldValues>({
    defaultValues: { notes: '', completedReason: '' },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting } } = methods;

  return (
    <>
      <Form {...methods} onSubmit={handleSubmit}>
        <Field name='completedReason' required label='Reason' controlProps={{ as: 'select' }}>
          <option value=''>Please select a reason</option>
          <option value='BORROWER_REQUEST'>Borrower Request</option>
          <option value='OTHER'>Other</option>
        </Field>

        <Field name='notes' label='Internal Notes' controlProps={{ as: 'textarea', rows: '4' }}/>

        <SubmitError/>

        <div className="d-flex justify-content-end mt-4">
          <Button variant="secondary" onClick={onHide} className="mr-2">
            Cancel
          </Button>
          <ButtonWithSpinner variant="primary" type="submit" loading={isSubmitting}>
            Complete Loan
          </ButtonWithSpinner>
        </div>
      </Form>
    </>
  )
}

export default MarkLoanCompletedForm
