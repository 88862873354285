import useQuery from '../../shared/useQuery';

export type LedgerImpactsData = {
  source_id: string;
  impacts: Array<{
    account: string;
    cents: number;
  }>;
  legacy_ledger_impact: number | null;
}

/**
 * Get the ledger impacts for a given source.
 *
 * @param source_id - the source of the ledger transactions to load
 * @returns LedgerImpactsData
 */
export default function useLedgerImpacts (source_id: string) {
  let endpoint = `/notebook/ledger/impacts/source=${source_id}`;
  return useQuery<LedgerImpactsData>(endpoint);
}
