import React from 'react';
import States from '../../../components/System/States/States';
import SystemLayout from '../../Layouts/SystemLayout';

export default function StatesPage () {
  return (
    <SystemLayout selectedTab='states'>
      <States/>
    </SystemLayout>
  );
}
