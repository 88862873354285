import React from 'react';
import { Alert } from 'react-bootstrap';

import AdminUserBadge from '../../AdminUserBadge';
import { getDateInTimezone } from '../../../utils/datetime';
import { LegacyLoanApplication } from '../../../api/queries/users/useLoanApplications';
import { UnderwritingDecision } from '../../../api/queries/underwriting-decisions/useLatest';

type Props = {
  loanApplication: LegacyLoanApplication;
  underwritingDecision?: UnderwritingDecision;
}

export default function DeniedByAdminAlert ({ loanApplication, underwritingDecision }: Props) {
  return loanApplication.deniedByAdminId ? (
      <Alert variant='warning'>
        { underwritingDecision ? getDateInTimezone(underwritingDecision.decision_date).format('ll') : '-' }
        <span> 
          { loanApplication.denialDate && '-'} Denied <AdminUserBadge admin_id={ loanApplication.deniedByAdminId } addBy={ true } /> 
        </span>
        { loanApplication.denialNotes && <span> : { loanApplication.denialNotes }</span> }
      </Alert>
    ) : null
}
