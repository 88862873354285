import useQuery from '../../shared/useQuery';

enum ACCOUNTING_ACTIVITY_TYPES {
  INTERNAL_COLLECTION_FEE = 'internal_collection_fee',
  RETROACTIVE_OVERPAYMENT_ALLOCATION = 'retroactive_overpayment_allocation',
}

export type AccountingActivityResponse = {
  _id: string;
  type: ACCOUNTING_ACTIVITY_TYPES;
  effective_date: string;
  amount: number;
  voided_at: Date;
};

export default function useAccountingActivityDetail (activityId: string) {
  return useQuery<AccountingActivityResponse>(`/notebook/accounting-activities/${ activityId }`);
}
