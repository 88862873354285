import React from 'react';
import LabelValueGrouping from './LabelValueGrouping';
import MaskedAccountNumber from './MaskedAccountNumber';

import { Badge, Button } from 'react-bootstrap';
import FeedbackIcon from '@material-ui/icons/Feedback';
import ExplainerPopover from './ExplainerPopover';
import VerifyBankModal from './Modals/VerifyBankModal';
import { useExpandableState } from '../utils';

import styles from './TransactionDisbursementDetails.module.scss';
import { getPMNeedsReview, getPMVerificationMethod, getPMStatus, PurchaseMethod } from '../utils/transactionUtils';

type Props = {
  purchaseMethod: PurchaseMethod;
  reloadData: () => void;
  ['data-qa']?: string;
};

function TransactionDisbursementAchDetails (props: Props) {
  const { purchaseMethod, reloadData } = props;

  const verifyModal = useExpandableState();
  const needsReview = getPMNeedsReview(purchaseMethod);
  const pmStatus = getPMStatus(purchaseMethod);

  const accountNumber = (
    <>
      <MaskedAccountNumber number={purchaseMethod.accountNumber} />
      {needsReview && (
        <span className="small text-danger ml-3">
          <ExplainerPopover explanation="Account details need review and confirmation.">
            <FeedbackIcon />
          </ExplainerPopover>
        </span>
      )}
    </>
  );

  const data = {
    Status: (
      <Badge pill variant={pmStatus.variant as 'danger' | 'warning' | 'success'}>
        {pmStatus.text}
      </Badge>
    ),
    'Verification Method': getPMVerificationMethod(purchaseMethod),
    'Destination Account': (
      <>
        {<span className={styles.value}>{purchaseMethod.accountType}</span>}
        {accountNumber}
      </>
    ),
  };

  return (
    <>
      <div className={styles.block} data-qa={props['data-qa']}>
        <div className="w-100">
          <h5 className={styles.title}>BANK ACCOUNT</h5>
          <LabelValueGrouping condensed data={data} />
        </div>
        {(purchaseMethod.plaidAccountId || !purchaseMethod.voidedCheckPhoto)
          ? <div className={styles.actionButton} />
          : (
            <Button className={styles.actionButton} variant={needsReview ? "primary" : "secondary"} onClick={() => verifyModal.showExpand()}>
              {needsReview ? 'Review Bank Account' : 'View Check'}
            </Button>
          )
        }
      </div>
      <VerifyBankModal
        purchaseMethod={purchaseMethod}
        expandable={verifyModal}
        onSubmit={() => {
          verifyModal.hideExpand();
          reloadData();
        }}
      />
    </>
  );
}

export default React.memo(TransactionDisbursementAchDetails);
