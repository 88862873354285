import React from 'react';
import { Badge } from 'react-bootstrap';

import { PURCHASE_METHOD_STATUS } from '../../utils/constants';

type Card = {
  status: string;
};

export default function CardStatusBadge ({ card }: { card: Card }) {
  const cardStatus = (card: Card) => {
    if (card.status === PURCHASE_METHOD_STATUS.CREATED) {
      return 'Unactivated';
    } else {
      return card.status && card.status.replace(/_/g, ' ');
    }
  }
  const cardBadgeVariant = (card: Card) => {
    if (card.status === PURCHASE_METHOD_STATUS.ACTIVE) {
      return 'success';
    } else if (card.status === PURCHASE_METHOD_STATUS.FAILED_TO_ACTIVATE || card.status === PURCHASE_METHOD_STATUS.TERMINATED) {
      return 'secondary';
    } else if (card.status === PURCHASE_METHOD_STATUS.SUSPENDED) {
      return 'danger';
    } else {
      return 'primary';
    }
  }

  return (
    <Badge variant={ cardBadgeVariant(card) } className="text-uppercase pr-2 pl-2">{ cardStatus(card) }</Badge>
  )
}
