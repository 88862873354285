import React from 'react';
import { ServicingActivity, ServicingActivityBaseType } from '../../../../api/queries/loans/useActivity';
import { DrawerSize } from '../../../Drawer';
import { CardActivityDetail } from './CardActivityDetail';
import { CheckDetail } from './CheckDetail';
import { DisbursementDetail } from './DisbursementDetail';
import { ExternalActivityDetail } from './ExternalActivityDetail';
import { PaymentDetail } from './PaymentDetail';
import { RefundDetail } from './RefundDetail';
import StatementAdjustmentDetail from './StatementAdjustmentDetail';
import { FeeActivityDetail } from './FeeActivityDetail';
import AccountingActivityDetail from './AccountingActivityDetail';

export const DRAWER_TYPES: {
  [key in ServicingActivityBaseType]?: DrawerSize;
} = {
  [ServicingActivityBaseType.CARD_ACTIVITY]: 'lg',
  [ServicingActivityBaseType.CHECK_ACTIVITY]: 'sm',
  [ServicingActivityBaseType.EXTERNAL_ACTIVITY]: 'sm',
  [ServicingActivityBaseType.PAYMENT]: 'sm',
  [ServicingActivityBaseType.DISBURSEMENT_REQUEST]: 'sm',
  [ServicingActivityBaseType.REFUND_REQUEST]: 'sm',
  [ServicingActivityBaseType.STATEMENT_ADJUSTMENT]: 'sm',
  [ServicingActivityBaseType.FEE_ACTIVITY]: 'sm',
  [ServicingActivityBaseType.ACCOUNTING_ACTIVITY]: 'sm',
};

export function ActivityDrawerContent (props: { loanId: string; activity: ServicingActivity, onChange: () => void }) {

  if (props.activity.base_type === 'CardActivity') {
    return (
      <CardActivityDetail
        loanId    = { props.loanId }
        activity  = { props.activity }
        onChange  = { props.onChange }
      />
    );
  }

  if (props.activity.base_type === 'CheckActivity') {
    return (
      <CheckDetail
        loanId    = { props.loanId }
        activity  = { props.activity }
        onChange  = { props.onChange }
      />
    );
  }

  if (props.activity.base_type === 'ExternalActivity') {
    return (
      <ExternalActivityDetail
        loanId    = { props.loanId }
        activity  = { props.activity }
        onChange  = { props.onChange }
      />
    );
  }

  if (props.activity.base_type === 'Payment') {
    return (
      <PaymentDetail
        loanId    = { props.loanId }
        activity  = { props.activity }
        onChange  = { props.onChange }
      />
    )
  }

  if (props.activity.base_type === 'DisbursementRequest') {
    return (
      <DisbursementDetail
        loanId    = { props.loanId }
        activity  = { props.activity }
        onChange  = { props.onChange }
        onInvoiceVerified  = { () => {} }
      />
    );
  }

  if (props.activity.base_type === 'StatementAdjustment') {
    return (
      <StatementAdjustmentDetail
        loanId    = { props.loanId }
        activity  = { props.activity }
        onChange  = { props.onChange }
      />
    );
  }

  if (props.activity.base_type === 'FeeActivity') {
    return (
      <FeeActivityDetail
        loanId    = { props.loanId }
        activity  = { props.activity }
        onChange  = { props.onChange }
      />
    );
  }

  if (props.activity.base_type === ServicingActivityBaseType.REFUND_REQUEST) {
    return (
      <RefundDetail
        loanId    = { props.loanId }
        activity  = { props.activity }
        onChange  = { props.onChange }
      />
    );
  }

  if (props.activity.base_type === ServicingActivityBaseType.ACCOUNTING_ACTIVITY) {
    return (
      <AccountingActivityDetail
        loanId    = { props.loanId }
        activity  = { props.activity }
        onChange  = { props.onChange }
      />
    );
  }

  return (
    <div>
      Unsupported activity type { props.activity.base_type } { props.activity.base_id }
    </div>
  );
}
