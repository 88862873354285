import React, { useState } from "react";

import useDocuments from '../../../api/queries/documents/useDocuments';
import { Card, Dropdown, Row, Table } from 'react-bootstrap';
import { useExpandableState } from "../../../utils/index";
import AddDocumentModal from "../../Modals/AddDocumentModal";
import moment from "moment";
import cx from 'classnames';
import { startCase } from 'lodash';
import { Grid, List, Plus } from 'react-bootstrap-icons';
import OutboundLink from '../../OutboundLink';
import DeleteDocumentModal from '../../Modals/DeleteDocumentModal';
import { Document } from '../../../api/queries/documents/useDocuments';
import RenameDocumentModal from "../../Modals/RenameDocumentModal";
import styles from './DocumentsTab.module.scss';
import DropdownToggle from "../../DropdownToggle";

type Props = {
  loanApplicationId: string;
}

type EditDocumentProps = {
  document: Document;
  onSubmit: () => void;
  loanApplicationId: string;
}

function EditDocument (props: EditDocumentProps) {
  const deleteDocumentModal = useExpandableState();
  const renameDocumentModal = useExpandableState();
  const { onSubmit, loanApplicationId, document } = props;

  return <>
    <Dropdown className="ml-auto" onClick={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}>
      <DropdownToggle id='edit-document' disabled={document.type === 'credit_report'} />
      <Dropdown.Menu>
        <Dropdown.Item onClick={renameDocumentModal.showExpand}>Rename</Dropdown.Item>
        <Dropdown.Item onClick={deleteDocumentModal.showExpand}>Delete</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

    <DeleteDocumentModal
      expand={deleteDocumentModal}
      onSubmit={onSubmit}
      loanApplicationId={loanApplicationId}
      documentId={document._id}
    />
    <RenameDocumentModal
      expand={renameDocumentModal}
      onSubmit={onSubmit}
      loanApplicationId={loanApplicationId}
      document={document}
    />
  </>
}

export default function DocumentsTab (props: Props) {
  const { loanApplicationId } = props;
  const [showList, setShowList] = useState(true);
  const documentsReq = useDocuments(loanApplicationId);
  const addDocumentModal = useExpandableState();
  if (!documentsReq.data) {
    return null;
  }

  return <>
    <div className={styles.tableTitle}>
      <div>
        Verification Documents
      </div>
      <div className="ml-auto">
        { showList ?
          <Grid className={cx(styles.icon, "p-1 border rounded cursor-pointer")} onClick={() => setShowList(false)}></Grid>
        :
          <List className={cx(styles.icon, "p-1 border rounded ml-1 cursor-pointer")} onClick={() => setShowList(true)}></List>
        }
        <Plus className={cx(styles.icon, "p-1 border rounded ml-1 cursor-pointer")} onClick={ addDocumentModal.showExpand }></Plus>
      </div>
    </div>
    { showList ?
      <Table responsive={true} className={'custom-table'}>
        <thead>
          <tr>
            <th style={{ width: '50%' }}>Name</th>
            <th style={{ width: '25%' }}>Category</th>
            <th style={{ width: '25%' }}>Added</th>
            <th/>
          </tr>
        </thead>
        <tbody>
          { documentsReq.data.map(document => (
            <OutboundLink href={ document.filename } className={cx(styles.tableRow, "hover:shaded-grey cursor-pointer")} key={document._id}>
              <td>
                {document.name}
              </td>
              <td>
                {startCase(document.type)}
              </td>
              <td>
                {moment(document.createdAt).format('lll')}
              </td>
              <td>
                <EditDocument
                  document={document}
                  onSubmit={documentsReq.mutate}
                  loanApplicationId={loanApplicationId}
                />
              </td>
            </OutboundLink>
          ))}
        </tbody>
      </Table>
    :
      <Row className="d-flex flex-wrap px-2">
        { documentsReq.data.map(document => (
          <OutboundLink href={ document.filename } className={cx(styles.thumbnail, "p-2 hover:shaded-grey cursor-pointer")} key={document._id}>
            <Card>
              <Card.Body className="p-1">
                <object
                  height={190}
                  width={190}
                  data={`${document.filename}`}
                  aria-label={document.name}
                />
                <div className={cx(styles.thumbnailDropdown)}>
                  <EditDocument
                    document={document}
                    onSubmit={documentsReq.mutate}
                    loanApplicationId={loanApplicationId}
                  />
                </div>
              </Card.Body>
              <Card.Footer className="bg-white">
                <span>{document.name}</span>
              </Card.Footer>
            </Card>
          </OutboundLink>
        ))}
      </Row>
    }

    <AddDocumentModal
      expand={ addDocumentModal }
      onSubmit={documentsReq.mutate}
      loanApplicationId={loanApplicationId}
    />
  </>
}
