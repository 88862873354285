import React from "react";
import style from "./UnderwritingTab.module.scss";
import { Card, Col, Row } from "react-bootstrap";
import { Flag } from './index';
import BaseFlag from './facts/BaseFlag';

function FlagRow ({ flag, showEditButton }: { flag: Flag, showEditButton?: boolean }) {
  return <BaseFlag flag={flag} showEditButton={!!showEditButton} />;
}

function FlagsTable ({ title, description, flags, allowFactsEdit }: { title: string, description?: string, flags: Array<Flag>, allowFactsEdit?: boolean }) {
  return (
    <Card className="mb-5">
      <Card.Header>
        {title}
      </Card.Header>
      <Card.Body>
        { description && <p className={style.description}>{description}</p> }
        <div className="pt-2 px-3">
          <Row className="pb-3">
            <Col sm={4} className="font-weight-bold pl-0">Fact</Col>
            <Col sm={4} className="font-weight-bold">Value</Col>
            <Col sm={4} className="font-weight-bold">Verification Status</Col>
          </Row>
          { flags.map(flag => <FlagRow key={flag.type} flag={flag} showEditButton={allowFactsEdit} />) }
        </div>
      </Card.Body>
    </Card>
  );
}

export default FlagsTable;
