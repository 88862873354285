import React, { useEffect, useState } from 'react';
import { useSWRConfig } from 'swr';
import CONFIG from '../config';
import useAdminAPICall from '../utils/useAdminAPICall';

export default function Login () {
  const { callAPI } = useAdminAPICall({ endpoint: '/notebook/login', method: 'POST' });
  const [token, setToken] = useState('');
  const { mutate } = useSWRConfig();

  useEffect(() => {
    (window as any).googleAuthCallback = async ({ credential }: { credential: string }) => {
      const response = await callAPI({ data: { token: credential } });
      mutate('/notebook/admins/self', response.data, false);
    };

    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      delete (window as any).googleAuthCallback;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const response = await callAPI({ data: { token } });
    mutate('/notebook/admins/self', response.data, false);
  };

  return <div className='m-4'>
    <h1>Log In to Notebook</h1>
    <div id="g_id_onload" data-client_id={CONFIG.GOOGLE_CLIENT_ID} data-callback="googleAuthCallback" data-auto_prompt="false"/>
    <div className="g_id_signin"/>

    <form onSubmit={onSubmit} style={{ display: 'none' }}>
      <input type='text' id='id_token' onChange={e => setToken(e.target.value)} value={token}/>
    </form>
  </div>;
}
