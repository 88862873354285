import useQuery from '../../shared/useQuery';

type Response = {
  availableFundsCents: number;
  balanceCents: number;
  principalCents: number;
  principalBalanceCents: number;
  utilizationRatio: number;
  repaidCents: number;
  settledDisbursedCents: number;
  unsettledDisbursedCents: number;
};

export default function useLedgerSummary (loanId: string) {
  return useQuery<Response>(`/notebook/loans/${loanId}/ledger/summary`);
}
