import React, { useEffect } from 'react';
import GenericModal from '../GenericModal';
import { useAPIData } from '../../../utils';
import useExpandableState from '../../../utils/useExpandableState';

import styles from './DocViewerModal.module.scss';

const SUPPORTED_FILE_TYPES = ['png', 'jpg', 'jpeg', 'pdf'];

type Props = {
  expandable: ReturnType<typeof useExpandableState>;
  title: string;
  document?: string | null;
  infoContent?: React.ReactNode;
  actionContent?: React.ReactNode;
};

export default function DocViewerModal(props: Props) {
  const { expandable, title, document, infoContent, actionContent } = props;

  const fileReq = useAPIData(`/notebook/s3/user-docs/pre-signed-url/${encodeURIComponent(document!)}`);

  useEffect(() => {
    fileReq.loadData();
  }, [document]); // eslint-disable-line react-hooks/exhaustive-deps

  let fileType = 'unknown';
  if (document) {
    const ext = document.split(/\.|_/).slice(-1)[0].toLowerCase();
    if (SUPPORTED_FILE_TYPES.includes(ext)) {
      fileType = ext;
    }
  }

  const renderDocument = () => {
    if (!document || fileType === 'unknown') {
      return <div className={styles.fallback}>Not supported</div>;
    }
    if (!fileReq.data) {
      return <div>Loading</div>;
    }
    if (fileType !== 'pdf') {
      return <img className={styles.image} src={fileReq.data} alt="Preview Content" />;
    }
    return (
      <iframe
        name="preview-iframe"
        id="preview-iframe"
        title="preview-iframe"
        frameBorder="0"
        scrolling="no"
        width="100%"
        height="100%"
        src={fileReq.data}
      >
        <p>iframes are not supported by your browser.</p>
      </iframe>
    );
  };

  return (
    <GenericModal expandable={expandable} title={title} fullWidth className={styles.root} size="lg">
      <div className={styles.content}>

        <div className={styles.previewContent}>{renderDocument()}</div>
        <div className={styles.sidebar}>
          {!!infoContent && <div className={styles.infoContent}>{infoContent}</div>}
          {!!actionContent && <div className={styles.actionContent}>{actionContent}</div>}
        </div>
      </div>
    </GenericModal>
  );
}
