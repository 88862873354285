import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../Form/Form';
import Field from '../Form/Field';
import SubmitError from '../Form/SubmitError';
import InlineLoadingIndicator from '../InlineLoadingIndicator';
import InlineWarning from '../InlineWarning';
import { postAPIEndpoint } from '../../utils/useAdminAPIData';
import { LegacyLoanApplication } from '../../api/queries/users/useLoanApplications';

type FieldValues = {
  notes: string;
};

type Props = {
  loanApplication: LegacyLoanApplication;
  show: boolean;
  onHide: () => void;
  onSubmit: () => void;
}

export default function RepullCreditReportModal(props: Props) {
  const { loanApplication, show, onHide, onSubmit } = props;

  const schema = yup.object().shape({
    notes: yup.string(),
  });

  const methods = useForm<FieldValues>({
    defaultValues: { notes: '' },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting } } = methods;

  const handleSubmit = async (data: FieldValues) => {
    await postAPIEndpoint(`/notebook/loan-applications/${loanApplication._id}/repull-credit-report`, data);
    onSubmit();
    onHide();
  };

  return <Modal show={show} onHide={onHide} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>
        Re-pull Credit Report
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <InlineWarning>
        <strong>Warning:</strong> only run this if you are sure you need to
        re-pull the credit report from the credit bureau, e.g. because some
        information has changed on the borrower's loan application.
      </InlineWarning>

      <Form {...methods} onSubmit={handleSubmit}>
        <Field name='notes' label='Internal Notes' controlProps={{ as: 'textarea', rows: '4' }}/>

        <SubmitError/>

        <div className="text-right mt-4">
          <Button type="button" variant="secondary" disabled={isSubmitting} onClick={onHide}>
            Cancel
          </Button>

          <Button type="submit" variant="primary" className='ml-2' disabled={isSubmitting}>
            {
              isSubmitting ? <InlineLoadingIndicator/> : null
            }
            Submit
          </Button>
        </div>
      </Form>
    </Modal.Body>
  </Modal>
}
