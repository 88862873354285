import React from 'react';
import _ from 'lodash';

type NameProps = {
  matchAgainst: {
    firstName: string;
    lastName: string;
  };
  name: string;
};

function Name (props: NameProps) {
  const style: React.CSSProperties = {};
  if (props.matchAgainst) {
    const fullName = (props.matchAgainst.firstName + props.matchAgainst.lastName).replace(/\s+/, '').toLowerCase();
    const lastName = props.matchAgainst.lastName.replace(/\s+/g, '').toLowerCase();
    const cleanedName = props.name.replace(/\s+/g, '').toLowerCase();
    if (cleanedName === fullName) {
      style.backgroundColor = 'lightgreen';
    } else if (cleanedName.indexOf(lastName) > -1) {
      style.backgroundColor = 'honeydew';
    } else if (cleanedName.indexOf('trust') > -1) {
      style.color = 'orange';
      style.fontWeight = 'bold';
    } else if (cleanedName.indexOf('llc') > -1) {
      style.color = 'red';
      style.fontWeight = 'bold';
    }
  }
  return (
    <div className='mb-1' style={style}>{props.name}</div>
  );
}

type Props = {
  loanApplication: {
    firstName: string;
    lastName: string;
  };
  names?: Array<string>;

};

export default function NameList (props: Props) {
  const names = (props.names || []).filter((name) => typeof name === 'string');
  return (
    <>
      {
        names.length > 0 ? names.map(name => (
          <Name key={name} name={name} matchAgainst={props.loanApplication && _.pick(props.loanApplication, ['firstName', 'lastName'])} />
        )) : <>&mdash;</>
      }
    </>
  );
}
