import React from 'react';
import SplitView from '../../../layouts/SplitView';
import { Container, Row, Col, ButtonGroup } from 'react-bootstrap';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import AdminContext from '../../../adminContext';
import { formatCents, formatDate, formatPositiveInteger, useExpandableState } from '../../../utils';
import { COLLECTIONS_STATUS_NAMES, LOAN_STATES, REPAYMENT_STATUS_NAMES, SPECIAL_PROVISIONS_NAMES } from '../../../utils/constants';
import useReminders from '../../../api/queries/loans/useReminders';
import useServicing from '../../../api/queries/loans/useServicing';
import InlineLoadingIndicator from '../../InlineLoadingIndicator';
import * as _ from 'lodash';
import InlineError from '../../InlineError';
import EditableServicingField from '../../Modals/Servicing/EditableServicingField';
import { Loan } from '../../../api/queries/types';
import Field from '../../Form/Field';
import ButtonWithSpinner from '../../ButtonWithSpinner';
import GenerateInvoiceModal from '../../Modals/GenerateInvoiceModal';
import { useHasActiveSaleEvent } from '../../../api/queries/loans/useAccelerationEvent';
import ExplainerPopover from '../../ExplainerPopover';

type Servicing = NonNullable<ReturnType<typeof useServicing>['data']>;

function formatAgingBucket (bucket: Array<number>) {
  if (!bucket) {
    return '—';
  }
  const [start, end] = bucket;
  if (end == null) {
    return `${ start - 1 }+ Days`;
  }
  return `${ start }–${ end } Days`;
}

function downloadFile (filename: string, dataBlob: Blob) {
  const el = document.createElement('a');
  el.href = window.URL.createObjectURL(dataBlob);
  el.download = filename;
  el.target = '_blank';
  el.click();
}
function ServicingActions ({ loan, servicing }: { loan: Loan, servicing: Servicing }) {

  const adminContext = React.useContext(AdminContext);
  const generateInvoiceModal = useExpandableState();

  const hasActiveSaleEvent = useHasActiveSaleEvent(loan._id);
  const downloadEscrowDemandReq = useAdminAPICall({
    endpoint: `/notebook/loans/${ loan._id }/invoices`,
    method: 'POST',
    onSuccess: (data) => {
      downloadFile(`${ loan.partner.brand_name } Escrow Demand - Loan ID ${ loan.slug }.pdf`, data);
    },
  });

  const canDownloadInvoice =
    loan.status === LOAN_STATES.FUNDED ||
    loan.status === LOAN_STATES.COMPLETED ||
    loan.status === LOAN_STATES.PASTDUE ||
    loan.status === LOAN_STATES.REPAID;
  return (
    <div>
      {
        canDownloadInvoice ? (
          <>
            <ButtonGroup vertical className='d-block mb-3'>
              <ButtonWithSpinner
                variant   = 'secondary'
                block     = { true }
                loading   = { false }
                disabled  = { downloadEscrowDemandReq.loadPending }
                onClick   = { () => generateInvoiceModal.showExpand() }
              >
                Generate Payoff Statement
              </ButtonWithSpinner>
              {loan.status !== LOAN_STATES.REPAID
                && !loan.isInstallment // TODO: convert this to a more appropriate buy-side indicator rather than installment
                && (
                <ExplainerPopover explanation={ !hasActiveSaleEvent ? 'Cannot generate Escrow Demands for loans without an active sale event.' : '' }>
                  <ButtonWithSpinner
                    variant   = 'secondary'
                    block     = { true }
                    loading   = { downloadEscrowDemandReq.loadPending }
                    disabled  = { generateInvoiceModal.isExpanded || !hasActiveSaleEvent }
                    onClick   = { () => {
                      downloadEscrowDemandReq.callAPI({
                        responseType: 'blob',
                        data: {
                          admin_user_id: adminContext.adminId,
                          type: 'escrow_demand',
                        },
                      });
                    }}
                  >
                    Generate Escrow Demand
                  </ButtonWithSpinner>
                </ExplainerPopover>
              )}
            </ButtonGroup>
            <GenerateInvoiceModal
              loan={ loan }
              expandable={ generateInvoiceModal }
              onSubmit={ () => { /* submission handled within the modal */ } }
            />
          </>
        ) : null
      }
    </div>
  );
}


function Label (props: { children: React.ReactNode }) {
  return (
    <dt>
      <span className='text-muted d-block font-size-1'>
        { props.children }
      </span>
    </dt>
  );
}

function Value (props: { className?: string, children: React.ReactNode }) {
  return (
    <dd className={ props.className }>{ props.children }</dd>
  );
}


export default function CollectionsContent ({ loan }: { loan: Loan }) {
  const { data, error, mutate: mutateServicing } = useServicing(loan._id);
  const { mutate: mutateReminders } = useReminders(loan._id);

  if (error) {
    return (
      <div><InlineError>Error loading servicing info.</InlineError></div>
    );
  }

  if (!data) {
    return (
      <div><InlineLoadingIndicator>Loading servicing info…</InlineLoadingIndicator></div>
    );
  }

  return (
    <SplitView>
      <SplitView.PrimarySection>
        <Container className='pl-0'>
          <Row>
            <Col>
              <dl className='bg-grey p-3 border-radius-3'>
                <Row className='mb-4'>
                  <Col>
                    <Label>Repayment Status</Label>
                    <Value className={ `font-weight-medium text-${ REPAYMENT_STATUS_NAMES[data.repayment_status][0] } font-size-5` }>
                      { REPAYMENT_STATUS_NAMES[data.repayment_status][1] }
                    </Value>
                  </Col>
                </Row>

                <Row className='mb-4'>
                  <Col>
                    <Label>Days Past Due (DPD) Since Maturity </Label>
                    <Value>{ formatPositiveInteger(data.days_past_due_since_maturity, '—') }</Value>
                  </Col>
                  <Col>
                    <Label>Due Date</Label>
                    <Value>
                      { formatDate(data.due_date, '—') }
                    </Value>
                  </Col>
                  <Col>
                    <Label>Acceleration Event</Label>
                    <Value>{ data.acceleration_event_type || 'None' }</Value>
                  </Col>
                </Row>

                <Row className='mb-4'>
                  <Col>
                    <Label>Aging Bucket</Label>
                    <Value>
                      { formatAgingBucket(data.aging_bucket) }
                    </Value>
                  </Col>
                  <Col>
                    <Label>Days Since Payoff Statement</Label>
                    <Value>{ formatPositiveInteger(data.days_since_invoice, '—') }</Value>
                  </Col>
                  <Col>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Label>Amount Owed</Label>
                    <Value>{ formatCents(data.amount_owed_cents, '—') } </Value>
                  </Col>
                  <Col>
                    <Label>Amount Repaid</Label>
                    <Value>{ formatCents(data.amount_repaid_cents, '—') }</Value>
                  </Col>
                  <Col>
                    <Label>Repaid Date</Label>
                      <Value>
                        { formatDate(loan.repaidDate, '—') }
                      </Value>
                  </Col>
                </Row>
              </dl>

            </Col>
            <Col>

              <dl className='bg-grey p-3 border-radius-3'>
                <Row className='mb-4'>
                  <Col>
                    <Label>Collections Status</Label>
                    <div className='d-flex'>
                      <div className='flex-grow-1'>
                        <Value className={ `font-weight-medium text-${ COLLECTIONS_STATUS_NAMES[data.collections_status][0] } font-size-5` }>
                          { COLLECTIONS_STATUS_NAMES[data.collections_status][1] }
                        </Value>
                      </div>
                      <EditableServicingField
                        title         = 'Collections Status'
                        name          = 'collections_status'
                        initial_value = { data.collections_status }
                        onChange      = { () => { mutateServicing(); mutateReminders() } }
                        loanId        = { loan._id }
                        renderControl = { () => (
                          <Field name='collections_status' label='Collections Status' resetButton controlProps={{ as: 'select' }}>
                            {
                              Object.entries(COLLECTIONS_STATUS_NAMES).map(([key, [color, name]]) => (
                                <option key={ key } value={ key }>{ name }</option>
                              ))
                            }
                          </Field>
                        )}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className='mb-4'>
                  <Col>
                    <Label>Special Provisions</Label>
                    <div className='d-flex'>
                      <div className='flex-grow-1'>
                        <Value className={ `font-weight-medium text-${ _.get(SPECIAL_PROVISIONS_NAMES, [data.special_provisions, 0], 'muted') } font-size-5`}>
                          { _.get(SPECIAL_PROVISIONS_NAMES, [data.special_provisions, 1], '—') }
                        </Value>
                      </div>
                      <EditableServicingField
                        title         = 'Special Provisions'
                        name          = 'special_provisions'
                        initial_value = { data.special_provisions }
                        onChange      = { () => { mutateServicing(); mutateReminders() } }
                        loanId        = { loan._id }
                        renderControl = { () => (
                          <Field name='special_provisions' label='Special Provisions' resetButton controlProps={{ as: 'select' }}>
                            <optgroup>
                              <option value=''>None</option>
                            </optgroup>
                            <optgroup>
                              {
                                Object.entries(SPECIAL_PROVISIONS_NAMES).map(([key, [color, name]]) => (
                                  <option key={ key } value={ key }>{ name }</option>
                                ))
                              }
                            </optgroup>
                          </Field>
                        )}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className='mb-4'>
                  <Col>
                    <Label>Governing Region</Label>
                    <Value>{ data.governing_region }</Value>
                  </Col>
                  <Col>
                    <Label>Applicable Late Fees</Label>
                    <Value>{ data.regional_late_fee_rules || 'TBD' }</Value>
                  </Col>
                  <Col>
                    <Label>Accum. Late Fees</Label>
                    <Value>{ formatPositiveInteger(data.accumulated_late_fees, '—') }</Value>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Label>State/Region Collections Rules</Label>
                    <p>{ data.regional_collections_rules || 'TBD' }</p>
                  </Col>
                </Row>
              </dl>

            </Col>
          </Row>
        </Container>
      </SplitView.PrimarySection>
      <SplitView.SecondarySection>
        <ServicingActions loan={ loan } servicing={ data } />
      </SplitView.SecondarySection>
    </SplitView>
  );
}
