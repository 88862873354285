import cx from 'classnames';
import React from 'react';
import { Table } from 'react-bootstrap';
import styles from './ActivityDrawers/ActivityDetail.module.scss';
import useLedgerImpacts, { LedgerImpactsData } from '../../../api/queries/loans/useLedgerImpacts';
import { formatCents } from '../../../utils';
import ErrorBoundary from '../../ErrorBoundary';

function IncDec (props: { amount: number }) {
  return <span className={ cx({ 'text-success': props.amount > 0, 'text-danger': props.amount < 0 }) }>
    { props.amount > 0 ? 'inc' : 'dec' }&nbsp;
  </span>;
}

function LedgerImpactsTable (props: { impacts: LedgerImpactsData['impacts'] }) {
  if (props.impacts.length === 0) {
    return <>—</>
  }
  return (
    <Table>
      <tbody>
        { props.impacts.map((impact, i) => (
          <tr key={ impact.account } className='text-monospace py-0'>
            <td className={ `pl-0 py-1 ${ i === 0 ? 'border-top-0' : '' }` }>
              { impact.account.replace(/_/g,' ') }
            </td>
            <td className={ `text-right nowrap pr-0 py-1 ${ i === 0 ? 'border-top-0' : '' }` }>
              <IncDec amount={ impact.cents } />
              { formatCents(Math.abs(impact.cents)) }
            </td>
          </tr>
        )) }
      </tbody>
    </Table>
  );
}

export default function LedgerImpacts (props: { sourceId: string }) {
  const request = useLedgerImpacts(props.sourceId);
  return (
    <>
      {
        request.data ? (
          <>
            <LedgerImpactsTable impacts={ request.data.impacts } />
            <p className='small text-muted text-right mt-0'>
              L1 Impact: { request.data.legacy_ledger_impact === null ? 'null' : formatCents(request.data.legacy_ledger_impact) }
            </p>
          </>
        ) : (
          <>Loading…</>
        )
      }
    </>
  );
}

export function LedgerImpactsLoader (props: { sourceId: string }) {
  return (
    <ErrorBoundary errorText='There was a problem loading the impacts. Please try again.'>
      <div className={ cx(styles.line, 'mt-4 mb-3') }>
        <span className={ styles.title }>Ledger Impacts</span>
      </div>
      <LedgerImpacts sourceId={ props.sourceId } />
    </ErrorBoundary>
  );
}
