import React from 'react';
import GenericModal from '../GenericModal';
import MarkLoanRepaidForm from './MarkLoanRepaidForm';
import useExpandableState from '../../../utils/useExpandableState';

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  loan: { _id: string },
  onSubmit: () => void;
};

export default function MarkLoanRepaidModal(props: Props) {
  const { expand, loan, onSubmit } = props;
  return (
    <GenericModal expandable={expand} title="Mark Loan Repaid" size="lg">
      <MarkLoanRepaidForm loan={loan} onHide={expand.hideExpand} onSubmit={onSubmit} />
    </GenericModal>
  );
}
