import React, { useEffect } from 'react';
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Form from '../Form/Form';
import SubmitError from '../Form/SubmitError';
import useExpandableState from '../../utils/useExpandableState';
import ButtonWithSpinner from '../ButtonWithSpinner';
import Field from '../Form/Field';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { PartnerSummary } from '../../api/queries/partners/usePartners';
import { useNavigate } from 'react-router-dom';
import useMutateCreatePartner from '../../api/mutations/partners/useMutateCreatePartner';

type FieldValues = {
  template: string;
  name: string;
  slug: string;
};

type Props = {
  expandable: ReturnType<typeof useExpandableState>;
  partners: Array<PartnerSummary>;
};

type ErrorMessage = {
  key: string;
  message: string;
};

export default function NewPartnerModal (props: Props) {
  const { expandable, partners } = props;
  const navigate = useNavigate();

  const {
    mutate: createPartner,
    error,
  } = useMutateCreatePartner();

  async function handleSubmit(data: FieldValues) {
    const response = await createPartner({
      template: data.template,
      name: data.name,
      slug: data.slug,
    });
    navigate(`/system/partners/${response.data._id}/info`);
  }

  const schema = yup.object({
    name: yup.string().required('Please provide a name'),
    slug: yup.string().required('Please provide a slug'),
  });

  const form = useForm<FieldValues>({
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty }, reset } = form;

  const onModalHide = () => {
    expandable.hideExpand();
    reset();
  }

  useEffect(() => {
    if (error) {
      (error as any).response?.data?.errors?.forEach((error: ErrorMessage) => {
        form.setError(error.key as any, { message: error.message });
      })
    }
  }, [error, form]);

  return (
    <Modal show={expandable.isExpanded} onHide={onModalHide}>
      <Modal.Header>
        <Modal.Title>
          New partner
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form {...form} onSubmit={handleSubmit}>
          <Field label='Template' name='template' controlProps={{ as: 'select' }}>
            {
              partners.map(partner => (
                <option key={partner._id} value={partner.slug}>{partner.shortName}</option>
              ))
            }
          </Field>
          <Field name='name' label='Partner Name' placeholder='Enter name' />
          <Field name='slug' label='Slug' placeholder='Enter slug' />

          <SubmitError/>

          <div className="d-flex justify-content-end mt-4">
            <Button variant="secondary" onClick={onModalHide} className="mr-2">
              Cancel
            </Button>
            <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
              Add partner
            </ButtonWithSpinner>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
