import React from 'react';
import InlineWarning from './InlineWarning';
import TransactionDisbursementAchDetails from './TransactionDisbursementAchDetails';
import TransactionDisbursementCheckDetails from './TransactionDisbursementCheckDetails';
import { PURCHASE_METHODS, DISBURSEMENT_TYPE } from '../utils/constants';
import { PurchaseMethod } from '../utils/transactionUtils';

type Props = {
  purchaseMethod: PurchaseMethod;
  disbursementRequest: {
    type?: string;
    _check?: {
      check_number?: string;
    }
  };
  reloadData: () => void;
  ['data-qa']?: string;
};

function TransactionDisbursementDetails (props: Props) {
  const { purchaseMethod, disbursementRequest } = props;
  if (disbursementRequest && disbursementRequest.type === DISBURSEMENT_TYPE.CHECK) {
    return <TransactionDisbursementCheckDetails {...props} />;
  }
  if (!purchaseMethod || purchaseMethod.type !== PURCHASE_METHODS.DISBURSEMENT_TO_BORROWER) {
    return <InlineWarning>No account details</InlineWarning>;
  }

  return <TransactionDisbursementAchDetails {...props} />;
}

export default React.memo(TransactionDisbursementDetails);
