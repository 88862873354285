import React from "react";
import { Card } from "react-bootstrap";
import LabelValueGrouping from "../../LabelValueGrouping";
import formatDollars from "../../../utils/formatDollars";
import { formatDateCommon } from "../../../utils/formatDate";
import AddressDisplay from "../../AddressDisplay";
import { PropertyLiability } from './HomeCardApplicationTab';

function humanBool (boolean: boolean | undefined) {
  if (boolean === undefined) { return undefined; }
  return boolean ? 'Yes' : 'No';
}

export default function PropertyLiabilitiesInfo ({ className, debt }: { className: string, debt: PropertyLiability }) {

  return (
    <Card className={className}>
      <Card.Header>Property Liabilities</Card.Header>
      <Card.Body>
        <LabelValueGrouping
          data={{
            Type: debt.typeOfDebt,
            Name: debt.name,
            "Date Opened": formatDateCommon(debt.dateOpened),
            "Monthly Payment": formatDollars(debt.monthlyPayment),
            "Net Rental Income": formatDollars(debt.netRentalIncomeAmount),
            "Verified Gross Rental Income": formatDollars(debt.grossRentalIncomeVerifiedAmount),
            "Occupancy Type": debt.occupancyType,
            "Will be sold before closing?": humanBool(debt.willBeSoldBeforeClosing),
            Address: debt.propertyAddress && (
              <AddressDisplay
                line1={debt.propertyAddress.address1}
                line2={debt.propertyAddress.address2 ? debt.propertyAddress.address2 : ''}
                city={debt.propertyAddress.city}
                state={debt.propertyAddress.state}
                zip={debt.propertyAddress.zip}
              />
            ),
          }}
          condensed={true}
          removeBlanks={true}
        />
      </Card.Body>
    </Card>
  );
}
