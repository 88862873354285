import React from 'react';
import GenericModal from '../GenericModal';
import useExpandableState from '../../../utils/useExpandableState';
import { Loan } from '../../BorrowerTabs/ServicingTab/Activity';
import Spinner from '../../Spinner';
import CreateDisbursementForm from './CreateDisbursementForm';
import useDisbursementLimits from '../../../api/queries/loans/useDisbursementLimits';
import useIdempotencyKey from '../../../utils/useIdempotencyKey';

type Props = {
  loan: Loan;
  modal: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
};

export function CreateDisbursementModal(props: Props) {
  const { loan, modal, onSubmit } = props;
  const limitsReq = useDisbursementLimits(loan._id);
  const requestKey = useIdempotencyKey([modal.isExpanded]);

  return (
    <GenericModal expandable={modal} title="Disbursement Details" size="lg">
      {limitsReq.data ? (
        <CreateDisbursementForm
          loan={loan}
          limits={limitsReq.data}
          requestKey={requestKey}
          onSubmit={onSubmit}
          onCancel={modal.hideExpand}
        />
      ) : (
        <div style={{ width: '100%', height: 40 }}>
          <Spinner />
        </div>
      )}
    </GenericModal>
  );
}
