import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Form from '../../Form/Form';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import { Loan } from '../../BorrowerTabs/ServicingTab/Activity';
import {
  DisbursementLimit,
  DISBURSEMENT_TYPE,
  VENDOR_TYPE,
} from '../../../api/queries/loans/useDisbursementLimits';
import RadioGroupField from '../../Form/RadioGroupField';
import DisbursementDetailForm from './DisbursementDetailForm';
import { Alert } from 'react-bootstrap';

type Props = {
  loan: Loan;
  limits: DisbursementLimit;
  requestKey: string;
  onCancel: () => void;
  onSubmit: () => void;
};

type FieldValues = {
  payType: string;
  vendorType: string;
};

const defaultPayTypeOptions = [
  { label: 'Bank Transfer', value: 'ach' },
  { label: 'Physical Check', value: 'check' },
];

const defaultVendorTypeOptions = [
  { label: 'Single Vendor', value: 'SINGLE' },
  { label: 'Multiple Vendors', value: 'MULTI' },
];

function CreateDisbursementForm(props: Props) {
  const { loan, limits, requestKey, onCancel, onSubmit } = props;

  const { callAPI, loadPending } = useAdminAPICall({
    endpoint: `/notebook/loans/${loan._id}/disbursement-requests`,
    method: 'POST',
  });

  const payTypeOptions = defaultPayTypeOptions.filter((option) =>
    limits.supportedTypes.SUPPORTED_PAY_TYPES.includes(option.value as DISBURSEMENT_TYPE)
  );

  const vendorTypeOptions = defaultVendorTypeOptions.filter((option) =>
    limits.supportedTypes.SUPPORTED_VENDOR_TYPES.includes(option.value as VENDOR_TYPE)
  );

  const schema = yup.object({
    payType: yup.string().required(),
    vendorType: yup.string().required(),
  });

  const form = useForm<FieldValues>({
    defaultValues: {
      payType: payTypeOptions[0]?.value ?? 'ach',
      vendorType: vendorTypeOptions[0]?.value ?? 'SINGLE',
    },
    resolver: yupResolver(schema),
  });

  const values = form.watch();

  const handleSubmit = async (data: any) => {
    await callAPI({
      data: {
        ...data,
        pay_type: values.payType,
        vendor_type: values.vendorType,
        request_key: requestKey,
      },
    });
    onSubmit();
    onCancel();
  };

  useEffect(() => {
    if (values.payType === DISBURSEMENT_TYPE.CHECK && values.vendorType !== VENDOR_TYPE.SINGLE) {
      form.setValue('vendorType', VENDOR_TYPE.SINGLE);
    }
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      { limits.alert && <Alert variant="warning">{ limits.alert }</Alert>}
      <Form {...form}>
        <RadioGroupField name="payType" options={payTypeOptions} label="Type" />
        {values.payType === 'ach' && vendorTypeOptions.length > 1 && (
          <RadioGroupField name="vendorType" options={vendorTypeOptions} label="Purpose" />
        )}
      </Form>
      <DisbursementDetailForm
        loanId={loan._id}
        availableFunds={Number(limits.loanAvailableFundsCents)}
        quickDepositAvailableFunds={0}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        payType={values.payType as DISBURSEMENT_TYPE}
        vendorType={values.vendorType as VENDOR_TYPE}
        isQuickDeposit={false}
        loading={loadPending}
      />
    </>
  );
}

export default CreateDisbursementForm;
