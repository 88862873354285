import React from 'react';
import { Badge, Button } from 'react-bootstrap';
import LabelValueGrouping from './LabelValueGrouping';
import { useExpandableState } from '../utils';
import StaticAddress from './StaticAddress';
import VerifyInvoiceModal from './Modals/VerifyInvoiceModal';
import styles from './DisbursementVendorDetail.module.scss';
import { DisbursementRequest, getDisbursementDetail } from '../utils/transactionUtils';

type Props = {
  disbursementRequest: DisbursementRequest;
  reloadData: () => void;
  'data-qa'?: string;
};

function DisbursementVendorDetail(props: Props) {
  const { disbursementRequest, reloadData } = props;
  const verifyModal = useExpandableState();

  const isInvoiceOpen =
    disbursementRequest.status !== 'canceled' && disbursementRequest.invoice_status === 'open';

  const { modalData, data } = getDisbursementDetail(disbursementRequest);
  data['Status'] = (
    <>
      {disbursementRequest.invoice_status === 'open' && (
        <Badge pill variant="warning">
          Unverified
        </Badge>
      )}
      {disbursementRequest.invoice_status === 'verified' && (
        <Badge pill variant="success">
          Verified
        </Badge>
      )}
      {disbursementRequest.invoice_status === 'rejected' && (
        <Badge pill variant="danger">
          Rejected
        </Badge>
      )}
    </>
  );
  const { _vendor: vendor } = disbursementRequest;

  if (vendor) {
    data['Vendor Address'] = modalData['Vendor Address'] = <StaticAddress address={vendor} />;
  }

  return (
    <>
      <div className={styles.block} data-qa={props['data-qa']}>
        <div className="w-100">
          <h5 className={styles.title}>VENDOR</h5>
          <LabelValueGrouping data={data} condensed />
        </div>
        <Button
          className={styles.actionButton}
          variant={isInvoiceOpen ? 'primary' : 'secondary'}
          onClick={() => verifyModal.showExpand()}
        >
          {isInvoiceOpen ? 'Review Invoice' : 'View Invoice'}
        </Button>
      </div>
      <VerifyInvoiceModal
        disbursementRequest={disbursementRequest}
        infoData={modalData}
        expandable={verifyModal}
        onSubmit={() => {
          verifyModal.hideExpand();
          reloadData();
        }}
      />
    </>
  );
}

export default React.memo(DisbursementVendorDetail);
