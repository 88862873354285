import React from 'react';
import { Badge } from 'react-bootstrap';

import { FULFILLMENT_STATUSES } from '../../utils/constants';

type Card = {
  fulfillmentStatus: string;
};

export default function FulfillmentStatusBadge ({ card }: { card: Card }) {
  const fulfillmentStatus = card.fulfillmentStatus || FULFILLMENT_STATUSES.ISSUED;
  const cardBadgeVariant = (fulfillmentStatus: string) => {
    if (fulfillmentStatus === FULFILLMENT_STATUSES.REJECTED) {
      return 'danger';
    } else if (fulfillmentStatus === FULFILLMENT_STATUSES.SHIPPED) {
      return 'primary';
    } else {
      // ISSUED or ORDERED
      return 'secondary';
    }
  }

  return (
    <Badge pill variant={ cardBadgeVariant(fulfillmentStatus) } style={{ fontSize: 10 }} className="text-uppercase mr-2 pr-2 pb-1 pl-2 d-inline">
      { fulfillmentStatus }
    </Badge>
  )
}
