import React, { useState } from 'react';
import { ExternalActivityDetailContent } from './ExternalActivityDetailContent';
import { useExpandableState } from '../../../../utils';
import UpdateExternalActivityModal from './UpdateExternalActivityModal';
import { ServicingActivity } from '../../../../api/queries/loans/useActivity';
import { TimelineListLoader } from '../TimelineTab';
import useExternalActivity from '../../../../api/queries/loans/useExternalActivity';
import { LedgerImpactsLoader } from '../LedgerImpacts';

type Props = {
  activity: ServicingActivity;
  onChange: () => void;
  loanId: string;
};

export enum EXTERNAL_ACTIVITY_ACTIONS {
  VOIDED = 'voided',
  RETURNED = 'returned',
}

export const ExternalActivityDetail: React.FC<Props> = ({ activity, loanId, onChange }) => {
  const [actionType, setActionType] = useState<EXTERNAL_ACTIVITY_ACTIONS>();
  const actionModal = useExpandableState();

  const txnRequest = useExternalActivity(activity.base_id, {
    shouldRetryOnError: false,
  });

  const handleAction = async (type: EXTERNAL_ACTIVITY_ACTIONS) => {
    setActionType(type);
    actionModal.showExpand();
  };

  const handleSubmitted = () => {
    txnRequest.mutate();
    onChange();
  };

  if (!txnRequest.data) {
    return null;
  }

  return (
    <>
      <ExternalActivityDetailContent data={txnRequest.data} onAction={handleAction} />
      <TimelineListLoader
        scopeId={ loanId }
        entityId={ activity.base_id }
      />
      <LedgerImpactsLoader
        sourceId={ activity.base_id }
      />
      {txnRequest.data && (
        <UpdateExternalActivityModal
          type={actionType!}
          modal={actionModal}
          activityId={txnRequest.data._id!}
          onSubmitted={handleSubmitted}
        />
      )}
    </>
  );
};
