import React from 'react';
import GenericModal from './GenericModal';
import useAdminAPICall from "../../utils/useAdminAPICall";
import { Button, FormGroup, FormLabel } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../Form/Form';
import Field from '../Form/Field';
import CardCheckbox from '../Form/CardCheckbox';
import SubmitError from '../Form/SubmitError';
import useExpandableState from '../../utils/useExpandableState';
import ButtonWithSpinner from '../ButtonWithSpinner';

type FieldValues = {
  notes: string;
  notifyBorrower: boolean;
  notifyAgent: boolean;
};

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  title: string;
} & DecisionConfirmationFormProps;

export default function DecisionConfirmationModal (props: Props) {
  const { expand, onSubmit, title, submitUrl, submitText, notifyBorrowerOption, notifyAgentOption } = props;
  return (
    <GenericModal expandable={expand} title={title} size="lg">
      <DecisionConfirmationForm
        onHide={expand.hideExpand}
        onSubmit={onSubmit}
        submitUrl={submitUrl}
        submitText={submitText}
        notifyBorrowerOption={notifyBorrowerOption}
        notifyAgentOption={notifyAgentOption}
      />
    </GenericModal>
  );
}

type DecisionConfirmationFormProps = {
  onHide?: () => void;
  onSubmit: () => void;
  submitUrl: string;
  submitText: string;
  notifyBorrowerOption: boolean;
  notifyAgentOption: boolean;
};

function DecisionConfirmationForm (props: DecisionConfirmationFormProps) {
  const { onHide, onSubmit, submitUrl, submitText, notifyBorrowerOption, notifyAgentOption } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: submitUrl,
    method: 'POST',
  });

  async function handleSubmit(data: FieldValues) {
    await callAPI({ data });
    onSubmit();
    onHide && onHide();
  }

  const schema = yup.object().shape({
    notes: yup.string(),
    notifyBorrower: yup.boolean(),
    notifyAgent: yup.boolean(),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      notes: '',
      notifyBorrower: true,
      notifyAgent: true,
    },
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });
  const { formState: { isSubmitting } } = methods;

  return (
    <>
      <Form {...methods} onSubmit={handleSubmit}>
        <Field name='notes' label='Internal Notes' controlProps={{ as: 'textarea', rows: '4' }}/>

        {
          (notifyBorrowerOption || notifyAgentOption) &&
          <FormGroup>
            <FormLabel>Notifications</FormLabel>
            { notifyBorrowerOption &&
              <CardCheckbox name='notifyBorrower' label='Notify Borrower (by text & email)' />
            }
            { notifyAgentOption &&
              <CardCheckbox name='notifyAgent' label='Notify Agent (by text & email)' />
            }
          </FormGroup>
        }

        <SubmitError/>

        <div className="d-flex justify-content-end mt-4">
          <Button variant="secondary" onClick={onHide} className="mr-2">
            Cancel
          </Button>
          <ButtonWithSpinner variant="primary" type="submit" loading={isSubmitting}>
            { submitText }
          </ButtonWithSpinner>
        </div>
      </Form>
    </>
  );
}
