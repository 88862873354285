import React from 'react';
import { Partner } from '../../../api/queries/partners/usePartner';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import SubmitError from '../../Form/SubmitError';
import { Button } from 'react-bootstrap';
import ButtonWithSpinner from '../../ButtonWithSpinner';

type PartnerFields = {
  supportEmail: string;
  supportPhoneNumber: string;
  slaResponseDays: string;
};

type Props = {
  partner: Partner;
  onSubmit: () => void;
  onHide: () => void;
}

export default function EditPartnerContactInfo (props: Props) {
  const { partner, onSubmit, onHide } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/partners/${partner._id}`,
    method: 'PUT',
  });

  async function handleSubmit(data: PartnerFields) {
    await callAPI({
      data: {
        supportEmail: data.supportEmail,
        supportPhoneNumber: data.supportPhoneNumber,
        slaResponseDays: Number(data.slaResponseDays),
      },
    });
    onSubmit();
    onHide();
  }

  const schema = yup.object();

  const methods = useForm<PartnerFields>({
    defaultValues: {
      supportEmail: partner.supportEmail,
      supportPhoneNumber: partner.supportPhoneNumber,
      slaResponseDays: partner.slaResponseDays.toString(),
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty } } = methods;

  return <div>
    <Form {...methods} onSubmit={handleSubmit}>
      <Field name={'supportEmail'} label={'Support Email'} required/>
      <Field
        name={'supportPhoneNumber'}
        label={'Support Phone Number'}
        required
        subLabel={'Use the exact format you desire the phone to show up in funnel, portal, or emails. Example: "1 (833) 615-0252"'}
      />
      <Field name={'slaResponseDays'} label={'SLA Response Days'} controlProps={{ type: 'number' }} required/>

      <SubmitError/>

      <div className="d-flex justify-content-end mt-4">
        <Button variant="secondary" onClick={onHide} className="mr-2">
          Cancel
        </Button>
        <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
          Confirm
        </ButtonWithSpinner>
      </div>
    </Form>
  </div>;
}
