import React from 'react';

function insecureRandomKey () {
  return (Date.now() + Math.random()).toString(32);
}

/**
 * Generate a random key to be used by a form submission, to ensure duplicate
 * requests are not processed twice (such as double-clicking the submit button).
 * Use the timestamp plus random and NOT guaranteed to be unique globally.
 * Database constraints MUST be combined with loan_id or some other unique
 * identifier.
 *
 * This hook SHOULD be used at the point at which the form state is initialized,
 * so that it is unique for the given form state and submission. See the
 * RequestTransferFlow for an example.
 *
 * https://ieftimov.com/post/understand-how-why-add-idempotent-requests-api/
 *
 * @param deps - An array of dependencies that will cause the key to be refreshed if changed
 * @returns a semi-random string identifier
 *
 * @example const requestKey = useIdempotencyKey([modal.isExpanded]);
 */
export default function useIdempotencyKey (deps: Array<unknown>) {
  const key = React.useMemo(insecureRandomKey, deps);
  return key;
}
