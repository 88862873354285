import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import SystemLayout from '../../Layouts/SystemLayout';
import ShowInvestorLayout from '../../Layouts/ShowInvestorLayout';
import { titleCase } from '../../../utils/string';
import { Card, Col, Row } from 'react-bootstrap';
import { Portfolio, usePortfolio } from '../../../api/queries/portfolios/usePortfolio';
import AdminContext from '../../../adminContext';
import { useExpandableState } from '../../../utils';
import InlineButton from '../../../components/InlineButton';
import EditActiveStatesModal from '../../../components/Modals/EditActiveStatesModal';
import { State } from '../../../api/queries/partners/usePartner';
import { mutate } from 'swr';

export default function InvestorsShowInfoPage () {
  const params = useParams<{ investorKey: string, portfolioKey: string }>();
  const investorKey = params.investorKey as string;
  const portfolioKey = params.portfolioKey as string;
  const portfolio = usePortfolio(portfolioKey);

  return (
    <SystemLayout selectedTab='investors' heading={titleCase(investorKey)}>
      { portfolio.data ? <>
        <ShowInvestorLayout
          investorKey={ investorKey }
          selectedTab='portfolios'
          heading={ portfolio.data.name }
        >
          <PortfolioInfo portfolio={portfolio.data} />
        </ShowInvestorLayout>
      </> : <div>Loading…</div> }
    </SystemLayout>
  );
}

function PortfolioInfo ({ portfolio }: { portfolio: Portfolio }) {
  return <Row>
    <Col>
      <ActiveStates states={portfolio.states} portfolio={portfolio.key}/>
    </Col>
  </Row>
}

function ActiveStates ({ states, portfolio }: { states: Array<State>, portfolio: string }) {
  const editActiveStates = useExpandableState();

  return <Card className='mt-4' data-qa='states'>
    <Card.Header>
      Active States
      <EditLink onClick={editActiveStates.showExpand}/>
    </Card.Header>

    <Card.Body>
      <StateCodes states={states}/>
    </Card.Body>

    <EditActiveStatesModal
      expand={editActiveStates}
      states={states}
      endpoint={`/notebook/portfolios/${portfolio}/states`}
      onSubmit={() => mutate(`/notebook/portfolios/${portfolio}`)}
    />
  </Card>;
}

function EditLink ({ onClick }: { onClick: () => void }) {
  const adminContext = useContext(AdminContext);

  return (
    <>
      {
        adminContext.canEditSystemSettings && (
          <InlineButton onClick={onClick} className='float-right'>
            Edit
          </InlineButton>
        )
      }
    </>
  );
}

function StateCodes ({ states }: { states: Array<State> }) {
  return <>
    {
      states.map((state, index) => (
        <span key={state.code}>
          <Link to={`/system/states/${state._id}`}>{state.code}</Link>
          {states.length === index + 1 ? null : ', '}
        </span>
      ))
    }
  </>;
}
