import React, { useEffect } from 'react';
import Form from '../Form/Form';
import Field from '../Form/Field';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useMutateAgentLandingPage from '../../api/mutations/agents/useMutateAgentLandingPage';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Agent } from '../../api/queries/agents/useAgents';
import { Partner } from '../../api/queries/partners/usePartner';
import { useExpandableState } from '../../utils';
import copy from 'clipboard-copy';
import styles from './AgentSlugModal.module.scss';

type ErrorMessage = {
  key: string;
  message: string;
};

type Props = {
  agent: Agent;
  partner: Partner;
  expand: ReturnType<typeof useExpandableState>;
  onChange: () => void;
};

export default function AgentSlugModal ({ agent, partner, expand, onChange }: Props) {
  const { mutate: mutateLandingPage, error } = useMutateAgentLandingPage(agent._id);

  const schema = yup.object({
    slug: yup.string().required('Please provide a URL'),
  });

  const methods = useForm<{ slug: string }>({
    defaultValues: { slug: agent.slug },
    resolver: yupResolver(schema),
  });

  const { slug } = methods.watch();

  const handleSubmit = async (data: { slug: string }) => {
    await mutateLandingPage({ slug: data.slug, landingPageEnabled: true });
    onChange();
    expand.hideExpand();
  }

  useEffect(() => {
    const errors = (error as any)?.response?.data?.errors;
    if (errors) {
      errors.forEach((error: ErrorMessage) => {
        methods.setError(error.key as any, { message: error.message });
      })
    }
  }, [error, methods]);

  return (
    <Modal show={expand.isExpanded} onHide={expand.hideExpand}>
      <Form {...methods} onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>
            { agent.firstName } { agent.lastName }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Field
            name='slug'
            label='Landing Page URL'
            prepend={`${partner.url}/`}
          />
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          <CopyURLButton url={`${partner.url}/${slug}`}  />
          <div>
            <Button className='mr-2' variant='secondary' onClick={expand.hideExpand}>Cancel</Button>
            <Button type='submit'>Confirm</Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

function CopyURLButton ({ url }: { url: string }) {
  const [animating, setAnimating] = React.useState(false);
  const [timer, setTimer] = React.useState<NodeJS.Timeout>();

  const handleCopy = () => {
    copy(url);
    setAnimating(true);

    if (timer) {
      clearTimeout(timer);
    }
    const timeout = setTimeout(() => setAnimating(false), 2000);
    setTimer(timeout);
  };

  return (
    <Button className={styles.copyButton} variant='link' onClick={handleCopy}>
      {animating ? 'Copied!' : 'Copy URL'}
    </Button>
  );
}
