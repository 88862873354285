import React from "react";
import { Card } from "react-bootstrap";
import LabelValueGrouping from "../../LabelValueGrouping";
import formatDollars from "../../../utils/formatDollars";
import { titleCase } from '../../../utils/string';
import { LOAN_APPLICATION_SOURCES } from "../../../utils/constants";
import { Liability } from './HomeCardApplicationTab';

export default function OtherLiabilitiesInfo ({ className, liability }: { className: string, liability: Liability }) {
  function formatStrWithSpaces (debtName?: string) {
    if (!debtName) { return ''; }

    return debtName.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  return (
    <Card className={className}>
      <Card.Header>Debt</Card.Header>
      <Card.Body>
      <LabelValueGrouping
        data={{
          Source: titleCase(LOAN_APPLICATION_SOURCES.BETTER),
          Type: formatStrWithSpaces(liability.typeOfDebt),
          "Monthly Payment": formatDollars(liability.monthlyPayment),
        }}
        condensed={true}
      />
      </Card.Body>
    </Card>
  );
}
