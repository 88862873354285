import React from "react";
import { Col, Row } from "react-bootstrap";
import RuleDecisionBadge from "./RuleDecisionBadge";
import VerificationBadge from "./VerificationBadge";
import { RULE_TYPE } from "./constants";
import style from "./UnderwritingTab.module.scss";
import { Rule } from './index';

function RuleRow ({ rule, type }: { rule: Rule, type: string }) {
  const renderValue = (rule: Rule) => {
    if (type === RULE_TYPE.VERIFICATION) {
      return <VerificationBadge verificationResult={rule.value} />;
    }
    const values = rule.value.split('\n');
    if (values.length !== 1) {
      return values.map(val =>
        <Row key={val} className='pl-3'>{val}</Row>
      );
    }

    return rule.value;
  };

  const renderCriteria = (rule: Rule) => {
    const criteria = rule.criteria.split('\n');
    if (criteria.length !== 1) {
      return criteria.map(crit =>
        <Row key={crit} className='pl-3'>{crit}</Row>
      );
    }
    return rule.criteria;
  }

  return (
    <Row className="border-top py-3" key={rule.name}>
      <Col sm={3} className="font-weight-bold">
        {rule.name}
      </Col>
      <Col sm={3} className={rule.criteria === '--' ? style.unknownData : ''}>
        {renderCriteria(rule)}
      </Col>
      <Col sm={3} className={rule.value === '--' ? style.unknownData : ''}>
        {renderValue(rule)}
      </Col>
      <Col sm={3}>
        <RuleDecisionBadge ruleDecision={rule.outcome} />
      </Col>
    </Row>
  )
}

function RulesTable ({ type, title, description, rules }: { type: string, title: string, description?: string, rules: Array<Rule> }) {
  if (rules.length === 0) { return null; }
  const failRules = rules.filter(rule => rule.outcome === 'fail');
  const undecidedRules = rules.filter(rule => rule.outcome === 'undecided');
  const passRules = rules.filter(rule => rule.outcome === 'pass');

  return (
    <div className='mt-4'>
      <h2 className={style.title}>{title}</h2>
      <p className={style.description}>{description}</p>

      <div className="pt-2 underwriting-rules">
        <Row className="border-bottom pb-3">
          <Col sm={3} className="font-weight-bold">
            Rule
          </Col>
          <Col sm={3} className="font-weight-bold">
            { type === RULE_TYPE.VERIFICATION ? "Verification Criteria" : "Criteria" }
          </Col>
          <Col sm={3} className="font-weight-bold">
            { type === RULE_TYPE.VERIFICATION ? "Verification Status" : "Value" }
          </Col>
          <Col sm={3} className="font-weight-bold">
            Rule Decision
          </Col>
        </Row>
        { failRules.map(rule => <RuleRow key={rule._id} rule={rule} type={type}/>) }
        { undecidedRules.map(rule => <RuleRow key={rule._id} rule={rule} type={type}/>) }
        { passRules.map(rule => <RuleRow key={rule._id} rule={rule} type={type}/>) }
      </div>
    </div>
  );
}

export default RulesTable;
