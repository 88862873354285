import React from "react";
import VerificationBadge from "../VerificationBadge";
import { Col, Row } from "react-bootstrap";
import { VERIFICATION_RESULTS } from '../constants';
import { Fact } from '../index';
import InlineButton from "../../../InlineButton";
import { useExpandableState } from "../../../../utils";
import EditListingPriceFactModal from "../../../Modals/EditListingPriceFactModal";

export type PropertyListingPriceFactData = {
  value_in_cents: number;
  source: string;
};

function PropertyListingPriceFact ({ fact, showEditButton }: { fact: Fact<PropertyListingPriceFactData>, showEditButton?: boolean }) {
  const propertyListingPriceModal = useExpandableState();

  return (
    <Row className="border-top py-3" data-qa='fact-row'>
      <Col sm={4} className="font-weight-bold pl-0">{fact.human_name}</Col>
      <Col sm={4}>{fact.value}</Col>
      <Col sm={4} className="pr-0">
        <VerificationBadge
          verificationResult={fact.verified ? VERIFICATION_RESULTS.VERIFIED : VERIFICATION_RESULTS.UNVERIFIED}
          unverifiedReasons={fact.unverified_reasons}
        />
        {
          showEditButton &&
          <InlineButton onClick={propertyListingPriceModal.showExpand} className='float-right'>
            Edit
          </InlineButton>
        }
      </Col>
      <EditListingPriceFactModal
        expand={propertyListingPriceModal}
        fact={fact}
        onSubmit={() => window.location.reload()}
        submitUrl='/property-listing-price'
        admitsZero={false}
      />
    </Row>
  );
}

export default PropertyListingPriceFact;
