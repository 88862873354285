import React from 'react';
import { Card } from 'react-bootstrap';
import { upperCase } from 'lodash';
import LabelValueGrouping from '../../LabelValueGrouping';
import { LegacyLoanApplication } from '../../../api/queries/users/useLoanApplications';
import { formatDateWithTime } from '../../../utils/formatDate';

const HUMAN_SUBMISSION_ERRORS: any = {
  PARTIAL_ACCOUNT_MATCH: 'Info on this application matches multiple accounts (same phone, email or SSN)',
  PARTIAL_ACCOUNT_MATCH_LOAN_APP_ACTIVE: 'Info on this application partially matches another that is linked to an open application',
  PARTIAL_ACCOUNT_MATCH_LOAN_APP_INACTIVE: 'Info on this application partially matches another that is linked to a closed application',
  PARTIAL_ACCOUNT_MATCH_LOAN_ACTIVE: 'Info on this application partially matches another that is linked to an active loan',
  PARTIAL_ACCOUNT_MATCH_LOAN_INACTIVE: 'Info on this application partially matches another that is linked to a repaid or expired loan',
  POA_BORROWER_USER_MATCH: 'Applicant applied as POA but Owner info matches an account with same phone or email as the applicant',
  MISSING_FIELD: 'Missing Field',
  INVALID_DATA: 'Invalid Data',
}

function SubmissionError ({ loanApplication }: { loanApplication: LegacyLoanApplication }) {
  if (!loanApplication.submissionError) return <>None</>;
  const errorText = HUMAN_SUBMISSION_ERRORS[loanApplication.submissionError] || loanApplication.submissionError;

  return (
    <>
      { errorText }
      {
        loanApplication.matchedUsers.length || loanApplication.matchedPoaUsers.length ? (
          <>
            { ' - ' }
            {
              loanApplication.matchedUsers.concat(loanApplication.matchedPoaUsers).map((user, index) => {
                return <>
                  <a
                    href={`/users/${user._id}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {user.fullName}
                  </a>
                  { index < loanApplication.matchedUsers.length - 1 ? ', ' : null }
                </>;
              })
            }
          </>
        ) : null
      }
    </>
  );
}

export default function ApplicationOverview ({ className, loanApplication }: { className?: string, loanApplication: LegacyLoanApplication }) {

  return (
    <Card className={className}>
      <Card.Header>
        Application Overview
      </Card.Header>
      <Card.Body>
        <LabelValueGrouping
          data={{
            "Current View": upperCase(loanApplication.currentView),
            "Submission Error": <SubmissionError loanApplication={loanApplication} />,
            "Submission Error Reasons": loanApplication.submissionErrorReasons.length ? loanApplication.submissionErrorReasons.join(', ') : undefined,
            "Created On": formatDateWithTime(loanApplication.createdOn),
            "Plan Selected On": formatDateWithTime(loanApplication.planSelectedAt) || 'N/A',
          }}
          condensed={true}
        />
      </Card.Body>
    </Card>
  )
}
