import React from 'react';
import { Badge } from 'react-bootstrap';
import ExplainerPopover from '../ExplainerPopover';
import { LOAN_STATES } from '../../utils/constants';

export function LoanStatusText (props: { loan: { status: string, withdrawalReason: string } }) {
  let status = props.loan.status;
  if (status === LOAN_STATES.EXPIRED) {
    status = `CANCELED (${ props.loan.withdrawalReason })`
  }
  return <>{ status }</>
}

const EXPLANATIONS = {
  [LOAN_STATES.APPROVED]: 'The borrower has been conditionally approved for the loan. The next step is their acceptance of the terms.',
  [LOAN_STATES.ACCEPTED]: 'The borrower has accepted the loan terms. The next step is confirmation of the application info and authorization.',
  [LOAN_STATES.AUTHORIZED]: 'The borrower has been fully approved and disbursement now allowed. Disbursements may be in progress, however, no funds have been transmitted yet.',
  [LOAN_STATES.FUNDED]: 'Some or all of the loan has been disbursed and the loan is accruing a balance. More may be disbursed depending on the available funds.',
  [LOAN_STATES.COMPLETED]: 'The loan has matured and is now due. No more funds may be disbursed. The next step is for the borrower to repay according to the terms.',
  [LOAN_STATES.REPAID]: 'The loan is repaid.',
  [LOAN_STATES.PASTDUE]: 'The borrower has missed a payment deadline. Penalty fees may be assessed depending on the terms of the loan.',
  [LOAN_STATES.EXPIRED]: 'The loan has been withdrawn and the contract cancelled. This may happen automatically after failure to accept the loan or use funds, depending on program.',
}

export default function LoanStatusBadge (props: { status: string, className?: string }) {
  let pillVariant: React.ComponentProps<typeof Badge>['variant'];
  let status = props.status;
  switch (props.status) {
    case LOAN_STATES.APPROVED:
    case LOAN_STATES.ACCEPTED:
      pillVariant = 'info';
      break;
    case LOAN_STATES.AUTHORIZED:
    case LOAN_STATES.FUNDED:
      pillVariant = 'warning';
      break;
    case LOAN_STATES.REPAID:
      pillVariant = 'success';
      break;
    case LOAN_STATES.PASTDUE:
      pillVariant = 'danger';
      break;
    case LOAN_STATES.EXPIRED:
      pillVariant = 'secondary';
      status = 'CANCELED';
      break;
    default:
      pillVariant = 'primary';
  }
  return (
    <ExplainerPopover explanation={ EXPLANATIONS[props.status] }>
      <Badge pill variant={ pillVariant } className={ props.className }>
        { status }
      </Badge>
    </ExplainerPopover>
  );
}
