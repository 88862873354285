import React from 'react';

type Props = {
  address: Record<string, string | null>;
  prefix?: string;
};

export default function StaticAddress ({ address, prefix = '' }: Props) {
  const fields = ['address_line1', 'address_line2', 'city', 'state', 'zip'];
  const values = fields.map(x => address[`${prefix}${x}`])
  if (!values.some(x => x)) {
    return null;
  }

  return (<>
    { address[`${prefix}address_line1`] }<br/>
    { address[`${prefix}address_line2`] }{ address[`${prefix}address_line2`] ? (<br/>) : null }
    { address[`${prefix}city`] }, { address[`${prefix}state`] } { address[`${prefix}zip`]}
  </>);
}
