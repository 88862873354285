import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SystemLayout from '../../Layouts/SystemLayout';
import { usePartner } from '../../../api/queries/partners/usePartner';
import ShowPartnerLayout from '../../Layouts/ShowPartnerLayout';
import { Card, Col, Collapse, Row, Table } from 'react-bootstrap';
import { Product } from '../../../api/queries/products/useProduct';
import moment from 'moment';
import useProducts from '../../../api/queries/products/useProducts';
import { CollapsableIcon } from '../../../components/CollapsableIcon';
import { Portfolio } from '../../../api/queries/partners/usePortfolios';
import InlineButton from '../../../components/InlineButton';
import AdminContext from '../../../adminContext';
import useExpandableState from '../../../utils/useExpandableState';
import ReplaceProductModal from '../../../components/Modals/ReplaceProductModal';
import useProductSummaries from '../../../api/queries/products/useProductSummaries';

export default function PartnerShowProductsPage () {
  const { partnerId } = useParams<{ partnerId: string }>();

  const { data: partner } = usePartner(partnerId);
  if (!partner) {
    return null;
  }
  return (
    <SystemLayout selectedTab='partners' heading={partner.short_name}>
      <ShowPartnerLayout partner={partner} selectedTab='products'>
        <section className='mb-4 mt-4'>
          <Row>
            <Col>
              <Products partnerId={partner._id}/>
            </Col>
          </Row>

          <Row>
            <Col>
              <Portfolios portfolios={partner.portfolios}/>
            </Col>
          </Row>
        </section>
      </ShowPartnerLayout>
    </SystemLayout>
  );
}

function ProductsTable ({ products, allowReplacing }: { products: Array<Product>, allowReplacing?: boolean }) {
  const allProductsReq = useProductSummaries();
  const allProducts = allProductsReq.data || [];
  const [productId, setProductId] = useState('');
  const navigate = useNavigate();
  const adminContext = useContext(AdminContext);
  const replaceProduct = useExpandableState();

  const onReplaceClick = (e: React.MouseEvent, productId: string) => {
    e.stopPropagation();
    setProductId(productId);
    replaceProduct.showExpand();
  }

  return <Table>
    <thead>
    <tr>
      <th>Name</th>
      <th>Partner</th>
      <th>Offered #</th>
      <th>Activated</th>
      <th></th>
    </tr>
    </thead>

    <tbody>
    {
      products.map(product => (
        <tr key={product._id} className='cursor-pointer' onClick={ () => navigate(`/system/products/${product._id}`) }>
          <td>{product.name}</td>
          <td>{product.partner}</td>
          <td>{product.loanApplicationsCount}</td>
          <td>{moment(product.activatedAt).format('lll')}</td>
          <td>
            {
              adminContext.canEditSystemSettings && allowReplacing &&
              <InlineButton onClick={(e) => onReplaceClick(e, product._id)} >
                Replace
              </InlineButton>
            }
          </td>
        </tr>
      ))
    }
    </tbody>

    <ReplaceProductModal
      expand={replaceProduct}
      productId={productId}
      products={allProducts}
      onSubmit={() => window.location.reload()}
    />
  </Table>;
}

function Products ({ partnerId }: { partnerId: string }) {
  const [open, setOpen] = useState(false);
  const productsRequest = useProducts(partnerId);
  if (!productsRequest.data) {
    return null;
  }
  const activeProducts = productsRequest.data.filter(product => product.active);
  const inactiveProducts = productsRequest.data.filter(product => !product.active);

  return <Card>
    <Card.Header>
      Products
    </Card.Header>

    <Card.Body>
      <ProductsTable products={activeProducts} allowReplacing/>

      <h5 onClick={() => setOpen(!open)} className='cursor-pointer'>
        Inactive Products
        <CollapsableIcon open={open}/>
      </h5>
      <Collapse in={open}>
        <div>
          <ProductsTable products={inactiveProducts}/>
        </div>
      </Collapse>
    </Card.Body>
  </Card>;
}

function Portfolios ({ portfolios }: { portfolios: Array<Portfolio> }) {
  return <Card className='mt-4'>
    <Card.Header>
      Portfolios
    </Card.Header>

    <Card.Body>
      <Table>
        <thead>
        <tr>
          <th>Portfolio</th>
          <th>Investor</th>
          <th>Draw Periods</th>
          <th>Purchase Premium</th>
          <th>Servicing Fee</th>
          <th>Transfer Review Days</th>
          <th>Fallback Portfolio</th>
          <th>Collection Account</th>
        </tr>
        </thead>

        <tbody>
        {
          portfolios.map((portfolio) => (
            <tr key={portfolio.key}>
              <td>{portfolio.name}</td>
              <td>{portfolio.investor.key}</td>
              <td>{portfolio.drawPeriods}</td>
              <td>{`${portfolio.purchasePremiumBps / 100}%`}</td>
              <td>{`${portfolio.servicingFeeAprBps / 100}%`}</td>
              <td>{portfolio.transferReviewDays}</td>
              <td>{portfolio.fallbackPortfolio}</td>
              <td>...{portfolio.collectionAccount?.lastFour}</td>
            </tr>
          ))
        }
        </tbody>
      </Table>
    </Card.Body>
  </Card>;
}
