import InlineError from '../InlineError';
import React from 'react';

type Props = {
  error: string | { errors: Array<{ message: string }> } | null;
  className?: string;
};

export default function ApiError ({ error, className }: Props) {
  if (!error) { return null; }
  let errorMessage: React.ReactNode = 'There was an error. Please try again.';
  if (typeof error === 'string') {
    errorMessage = error;
  } else if (error.errors && error.errors.length > 0) {
    errorMessage = (
      <ul className="mb-0">
        {
          error.errors.map(({ message }) => (
            <li key={ message }>{ message }</li>
          ))
        }
      </ul>
    );
  }
  return <InlineError className={ className }>
    { errorMessage }
  </InlineError>;
}
