import React from "react";
import { LOAN_APPLICATION_STATUSES } from "../../../utils/constants";
import UnderwritingFacts from "./UnderwritingFacts";
import { LegacyLoanApplication, UNDERWRITING_VERSIONS } from '../../../api/queries/users/useLoanApplications';
import UnderwritingSummaryV2 from './v2/UnderwritingSummary';
import UnderwritingRuns from './v4/UnderwritingRuns';
import { UNDERWRITING_RUN_OUTCOMES } from './constants';
import UnderwritingSummary from './UnderwritingSummary';

export enum FLAG_STATUS {
  DENY = 'deny',
  DISREGARD = 'disregard',
  NEEDS_REVIEW = 'needs_review',
}

export type Flag<TData = unknown> = {
  _id: string;
  type: string;
  human_name: string;
  loan_application_id: string;
  value: string;
  verified: boolean;
  data: TData;
  flag_status: FLAG_STATUS;
}

export type Fact<TData = unknown> = {
  _id: string;
  type: string;
  human_name: string;
  category: string;
  loan_application_id: string;
  value: string;
  verified: boolean;
  unverified_reasons: Array<string>;
  data: TData;
  flag_status: FLAG_STATUS;
  is_flag: boolean;
};

export type Rule = {
  _id: string;
  category: string;
  notebookGroup: string;
  criteria: string;
  name: string;
  value: string;
  outcome: string;
  required: boolean;
}

export type Product = {
  name: string;
  maxCreditAmount: string;
  availableCreditAmount: string;
  requestedCreditAmount: string;
  selected: boolean;
  preQualDecision: string;
  strictDecision: string;
}

export type UnderwritingRun = {
  _id: string;
  loanApplicationId: string;
  confirmedAt: string;
  decisionDate: string;
  type: string;
  portfolioUnderwritingResults: Array<PortfolioUnderwritingResult>;
}

export type PortfolioUnderwritingResult = {
  _id: string;
  outcome: UNDERWRITING_RUN_OUTCOMES;
  product: PortfolioUnderwritingResultProduct;
  portfolio: PortfolioUnderwritingResultPortfolio;
  underwritingRules: Array<Rule>;
}

type PortfolioUnderwritingResultProduct = {
  _id: string;
  name: string;
}

type PortfolioUnderwritingResultPortfolio = {
  key: string;
  name: string;
}

export default function UnderwritingTab ({ loanApplication }: { loanApplication: LegacyLoanApplication }) {
  return (
    <div>
      <UnderwritingSummary loanApplication={loanApplication} />
      <UnderwritingFacts loanApplication={loanApplication} allowFactsEdit={loanApplication.status === LOAN_APPLICATION_STATUSES.PENDING} />
      {
        loanApplication.underwritingVersion === UNDERWRITING_VERSIONS.V2 && (
          <UnderwritingSummaryV2 loanApplication={loanApplication} />
        )
      }
      {
        loanApplication.underwritingVersion === UNDERWRITING_VERSIONS.V4 && (
          <UnderwritingRuns loanApplication={loanApplication} />
        )
      }
    </div>
  );
}
