import React from 'react';
import { Partner } from '../../../api/queries/partners/usePartner';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import SubmitError from '../../Form/SubmitError';
import { Button } from 'react-bootstrap';
import ButtonWithSpinner from '../../ButtonWithSpinner';
import BooleanRadioField from '../../Form/BooleanRadioField';

type PartnerInfoFields = {
  agentSupport: string;
  agentStrategy: string;
  agentPricingPaymentType: string;
  promoStartDate: string;
  promoEndDate: string;
  hasRemoteAgentList: string;
  remoteAgentGoogleSheetId: string;
  emailDomain: string;
  agentsUrl: string;
  agentLandingPageEnabled: string;
};

type Props = {
  partner: Partner;
  onSubmit: () => void;
  onHide: () => void;
}

export default function EditPartnerAgentSettings (props: Props) {
  const { partner, onSubmit, onHide } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/partners/${partner._id}`,
    method: 'PUT',
  });

  async function handleSubmit(data: PartnerInfoFields) {
    await callAPI({
      data: {
        agentSupport: data.agentSupport === 'true',
        agentStrategy: data.agentStrategy,
        agentPricingPaymentType: data.agentPricingPaymentType,
        promoStartDate: data.promoStartDate,
        promoEndDate: data.promoEndDate,
        hasRemoteAgentList: data.hasRemoteAgentList === 'true',
        remoteAgentGoogleSheetId: data.remoteAgentGoogleSheetId,
        emailDomain: data.emailDomain,
        agentsUrl: data.agentsUrl,
        agentLandingPageEnabled: data.agentLandingPageEnabled === 'true',
      },
    });
    onSubmit();
    onHide();
  }

  const schema = yup.object();

  const methods = useForm<PartnerInfoFields>({
    defaultValues: {
      agentSupport: partner.agentSupport.toString(),
      agentStrategy: partner.agentStrategy,
      agentPricingPaymentType: partner.agentPricingPaymentType,
      promoStartDate: partner.promoStartDate,
      promoEndDate: partner.promoEndDate,
      hasRemoteAgentList: partner.hasRemoteAgentList.toString(),
      remoteAgentGoogleSheetId: partner.remoteAgentGoogleSheetId,
      emailDomain: partner.emailDomain,
      agentsUrl: partner.agentsUrl,
      agentLandingPageEnabled: partner.agentLandingPageEnabled.toString(),
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty } } = methods;

  return <div>
    <Form {...methods} onSubmit={handleSubmit}>
      <BooleanRadioField label='Supports Agents' name='agentSupport'/>
      <BooleanRadioField label='Automated Agent Pull' name='hasRemoteAgentList'/>
      <Field name='remoteAgentGoogleSheetId' label='Remote Agent Google Sheet ID'/>
      <Field label='Storage Strategy' name='agentStrategy' controlProps={{ as: 'select' }}>
        <option value=''>None</option>
        <option value='local'>Local</option>
        <option value='compass_api'>Compass API</option>
      </Field>
      <Field label='Agent Pricing Payment Type' name='agentPricingPaymentType' controlProps={{ as: 'select' }}>
        <option value=''>None</option>
        <option value='deferred'>Deferred</option>
        <option value='origination'>Origination</option>
        <option value='free_to_agent'>Free-To-Agent</option>
        <option value='no_fee'>No Fee</option>
      </Field>
      <Field name='promoStartDate' label='Promo Start Date' controlProps={{ type: 'date' }}/>
      <Field name='promoEndDate' label='Promo End Date' controlProps={{ type: 'date' }}/>
      <Field label='Email Domain' name='emailDomain'/>
      <Field name='agentsUrl' label='Agents URL'/>
      <BooleanRadioField label='Personalized Landing Pages' name='agentLandingPageEnabled'/>

      <SubmitError/>

      <div className="d-flex justify-content-end mt-4">
        <Button variant="secondary" onClick={onHide} className="mr-2">
          Cancel
        </Button>
        <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
          Confirm
        </ButtonWithSpinner>
      </div>
    </Form>
  </div>;
}
