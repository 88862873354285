import React from 'react';
import LabelValueGrouping from './LabelValueGrouping';

import styles from './TransactionDisbursementDetails.module.scss';

type Props = {
  ['data-qa']?: string;
  disbursementRequest: {
    _check?: {
      check_number?: string;
    };
  };
};

function TransactionDisbursementCheckDetails (props: Props) {
  const { disbursementRequest } = props;

  const data = {
    'Disbursement Type': 'Physical Check',
    'Check Number': disbursementRequest._check ? disbursementRequest._check.check_number : '',
  };

  return (
    <div className={styles.block} data-qa={props['data-qa']}>
      <div className="w-100">
        <h5 className={styles.title}>DISBURSEMENT INFO</h5>
        <LabelValueGrouping condensed data={data} />
      </div>
    </div>
  );
}

export default TransactionDisbursementCheckDetails;
