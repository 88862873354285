import useQuery from '../../shared/useQuery';

export type RefundDetail = {
  _id: string;
  type: string;
  amount: number;
  status: string;
  effective_date: string;
  deposit_date?: string;
  created_at: Date;
  submitted_by: string;
  approved_by: string;
  destinationAccount: {
    _id: string;
    lastFour: string;
    accountType: string;
  };
  internal_account_last_four?: string;
  notes: string;
}

export default function useRefundDetail (loanId: string, refundId: string) {
  return useQuery<RefundDetail>(`/notebook/loans/${loanId}/refunds/${refundId}`);
}
