import React from 'react'
import { usePartner } from '../api/queries/partners/usePartner';
import useExpandableState from '../utils/useExpandableState';
import InlineButton from './InlineButton';
import { Col, Row } from 'react-bootstrap';
import { Loan } from '../api/queries/types';

export default function CurrentPortfolio ({
  loan,
  cancelPortfolioTransferModal,
  modifyPortfolioModal,
  superadmin,
}: {
  loan: Loan,
  cancelPortfolioTransferModal: ReturnType<typeof useExpandableState>,
  modifyPortfolioModal: ReturnType<typeof useExpandableState>,
  superadmin: boolean,
}) {
  const partner = usePartner(loan.partnerId);
  const defaultPortfolios = partner.data?.portfolios ? partner.data.portfolios.map(p => p.fallbackPortfolio)  : [];

  const buildCurrent = () => {
    let portfolio = loan.portfolio ? (loan.portfolio.name + (defaultPortfolios.includes(loan.portfolio.key) ? ' (default)' : '')) : '';
    let transfer = null;
    let action = null;
    if (loan.pendingPortfolioTransfer) {
      transfer = <><br/><i>(Transferring to {loan.pendingPortfolioTransfer.to_portfolio.name})</i></>;
      action = <>
        {superadmin && (
          <InlineButton
            onClick={() => { cancelPortfolioTransferModal.showExpand() }}
            className='my-auto'
          >
            Cancel Transfer
          </InlineButton>
        )}
      </>;
    } else if (superadmin) {
      action =
        <InlineButton
          onClick={() => { modifyPortfolioModal.showExpand() }}
          className='my-auto'
        >
          Edit
        </InlineButton>;
    }
    return <Row className='h-100'>
      <Col className='pr-0'>
        {portfolio}
        {transfer}
      </Col>
      <Col className='col-2 pl-0 d-flex'>
        {action}
      </Col>
    </Row>;
  };

  return (
    loan.portfolio ?
    <>{ buildCurrent() }</> :
    <>—</>
  );
}
