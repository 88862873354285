import React from "react";
import { Badge } from "react-bootstrap";
import { PRE_QUALIFICATION_RESULTS } from "../constants";

function PrequalificationBadge ({ prequalificationResult }: { prequalificationResult: string }) {
  switch (prequalificationResult) {
    case PRE_QUALIFICATION_RESULTS.PRE_QUALIFIED:
      return (
        <Badge variant="success">
          PRE-QUALIFIED
        </Badge>
      );
    case PRE_QUALIFICATION_RESULTS.DENY:
      return (
        <Badge variant="danger">
          DENY
        </Badge>
      );
    case PRE_QUALIFICATION_RESULTS.INELIGIBLE:
      return (
        <Badge variant="secondary">
          INELIGIBLE
        </Badge>
      );
    default:
      return <Badge variant="dark">
        {prequalificationResult}
      </Badge>;
  }
}

export default PrequalificationBadge;
