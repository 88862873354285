import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext, useController } from 'react-hook-form';
import cx from 'classnames';
import styles from './RadioGroupField.module.scss';

type RadioOption = {
  label: string;
  value: string;
};

type Props = {
  name: string;
  label: string;
  subLabel?: React.ReactNode;
  options: Array<RadioOption>;
};

export default function RadioGroupField({ name, label, subLabel, options }: Props) {
  const { Group, Label, Text } = Form;
  const Control = Form.Control as any;

  const { control } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  return (
    <Group controlId={name}>
      <Label>{label}</Label>

      {subLabel && <Text className={cx('text-muted', styles.indentLabel)}>{subLabel}</Text>}

      <div className={styles.radioGroup}>
        {options.map((option) => (
          <div key={option.value} className={styles.radioOption}>
            <Control
              type="radio"
              {...field}
              label={option.label}
              value={option.value}
              checked={field.value === option.value}
              className={styles.radioButton}
            />
            <label className={styles.radioLabel} htmlFor={`${name}${option.value}`}>
              {option.label}
            </label>
          </div>
        ))}
      </div>

      {error && error.message && (
        <Control.Feedback className="d-block" type="invalid">
          {error.message}
        </Control.Feedback>
      )}
    </Group>
  );
}
