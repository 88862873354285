import React from 'react';
import moment from 'moment';
import { Button, Table } from 'react-bootstrap';
import GenericModal from './GenericModal';
import useAchHistory from '../../api/queries/loans/useAchHistory';
import OutboundLink from '../OutboundLink';
import CONFIG from '../../config';
import { Loan } from '../../api/queries/types';
import useExpandableState from '../../utils/useExpandableState';

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  loan: Loan;
  bankAccount: { _id: string };
  onSubmit?: () => void;
};

export default function AchHistoryModal(props: Props) {
  const { expand, loan, bankAccount } = props;

  const fileReq = useAchHistory(loan._id, bankAccount._id);

  if (!fileReq.data || !Array.isArray(fileReq.data)) {
    return null;
  }

  return (
    <GenericModal expandable={expand} title="Bank Account History" size="lg">
      <div className="text-right mt-4">
        <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Account</th>
              <th>Routing</th>
              <th>Documentation</th>
            </tr>
          </thead>
          <tbody>
            {fileReq.data.map(row => {
              const voidedCheckPhotoUrl = row.voided_check_photo
              ? `${ CONFIG.API_BASE_URL }/notebook/s3/user-docs/img/${ encodeURIComponent(row.voided_check_photo) }`
              : null;

              return (
                <tr key={`edit-history-${row._id}`}>
                  <td>{moment((row as any).updated_at).format('M/DD/YYYY')}</td>
                  <td>{row.account_number}</td>
                  <td>{row.routing_number}</td>
                  <td>
                    {voidedCheckPhotoUrl && (
                      <OutboundLink href={voidedCheckPhotoUrl}>Voided Check</OutboundLink>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Button variant="secondary" onClick={expand.hideExpand} className="mr-2">
          Close
        </Button>
      </div>
    </GenericModal>
  );
}
