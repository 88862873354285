import React from 'react';
import { useFormContext } from "react-hook-form";
import { Alert } from 'react-bootstrap';

type Props = {
  className?: string;
};

export default function SubmitError ({ className }: Props) {
  const { formState: { errors } } = useFormContext<{ submitError: string }>();

  return errors.submitError ? (
    <Alert className={className} variant='danger'>
      {errors.submitError?.message || 'There was an error submitting the form. Please try again.'}
    </Alert>
  ) : null;
}
