import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import LabelValueGrouping from '../../LabelValueGrouping';
import formatDollars from '../../../utils/formatDollars';
import { titleCase } from '../../../utils/string';
import { LOAN_APPLICATION_SOURCES } from "../../../utils/constants";

export default function CreditReportDebt ({ className, reportedMonthlyPayment }: { className?: string, reportedMonthlyPayment: number }) {

  return (
    <Card className={className}>
      <Card.Header>
        Debt
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <LabelValueGrouping
              data={{
                Source: titleCase(LOAN_APPLICATION_SOURCES.CREDIT_REPORT),
                'Monthly Total': formatDollars(reportedMonthlyPayment),
              }}
              condensed={ true }
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
