import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SystemLayout from '../../Layouts/SystemLayout';
import ShowInvestorLayout from '../../Layouts/ShowInvestorLayout';
import { Button, Modal, Table } from 'react-bootstrap';
import { usePendingInvestorRemittances } from '../../../api/queries/investors/usePendingInvestorRemittances';
import { formatCents, useExpandableState } from '../../../utils';
import { postAPIEndpoint } from '../../../utils/useAdminAPIData';
import CONFIG from '../../../config';
import { InlineError } from '../../../components';
import { titleCase } from '../../../utils/string';
import Pagination from '../../../components/Pagination';
import querystring from 'qs';


export default function InvestorsShowRemitsPage () {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [submissionError, setSubmissionError] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const today = (new Date()).toISOString().split('T')[0];
  const { investorKey} = useParams<{ investorKey: string }>();
  const parsedParams = querystring.parse(location.search, { ignoreQueryPrefix: true });
  const report_date = (parsedParams.report_date as string) || today;
  const page = parsedParams.page ? parseInt(parsedParams.page as string) : 1;
  const pendingRemits = usePendingInvestorRemittances(investorKey as string, { report_date, page });
  const releaseModal = useExpandableState(false);

  const pagination = (
    <div className='flex-grow-1 d-flex justify-content-end'>
      <Pagination
        page = { pendingRemits.data?.page || 1 }
        maxPages  = { pendingRemits.data?.maxPages || 0 }
        onPageChange={ (p) => navigate(`?report_date=${report_date}&page=${p}`) }
      />
    </div>
  );
  return (
    <SystemLayout selectedTab='investors' heading={titleCase(investorKey)}>
      <ShowInvestorLayout
        investorKey={ investorKey as string }
        selectedTab='pending-remits'
      >
        <div className="py-3">
          <div className='d-flex'>
            <div>
              <input
                disabled  = { !Boolean(pendingRemits.data?.items.length) }
                className = "mr-2"
                type      = "date"
                value     = { report_date }
                onChange  = { (e) => navigate(`?report_date=${e.target.value}&page=1`) }
                min       = { pendingRemits.data?.earliestDate || undefined }
                max       = { pendingRemits.data?.latestDate || undefined }
              />
              <Button disabled={ !Boolean(pendingRemits.data?.items.length) } as='a' className="mr-2" href={ `${ CONFIG.API_BASE_URL }/notebook/investors/${ investorKey }/remittances/pending/report-preview.tgz?report_date=${ report_date }` } download>
                Preview Report
              </Button>
              <Button disabled={ !Boolean(pendingRemits.data?.items.length) } variant="danger" onClick={ releaseModal.toggleExpand }>
                Release Report
              </Button>
            </div>
            { pagination }
          </div>
          <Modal show={ releaseModal.isExpanded } onHide={ releaseModal.hideExpand }>
            <Modal.Header closeButton>
              <Modal.Title>Release Remittance Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Confirm Release of remittance report for {report_date}.
              { submissionError && <InlineError className='mt-3'>There was a problem releasing the report. Please try again.</InlineError> }
            </Modal.Body>
            <Modal.Footer>
              <Button disabled={ isSubmitting } variant='secondary' onClick={ releaseModal.hideExpand }>
                Cancel
              </Button>
              <Button disabled={ isSubmitting } variant='danger' onClick={ async () => {
                setIsSubmitting(true);
                setSubmissionError(false);
                try {
                  await postAPIEndpoint(`/notebook/investors/${ investorKey }/remittances/pending/release`, { report_date });
                  pendingRemits.mutate();
                  releaseModal.hideExpand();
                } catch (error: any) {
                  console.error(error);
                  setSubmissionError(true);
                }
                setIsSubmitting(false);
              } }>
                Release
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Slug</th>
              <th className="text-right">Collected<br />Principal</th>
              <th className="text-right">Collected<br />Interest</th>
              <th className="text-right">Collected<br />Origination Fees</th>
              <th className="text-right">Notable Fees (-)</th>
              <th className="text-right">Net Transfer</th>
            </tr>
            <tr>
              <th colSpan={2}>Pending totals through {report_date}:</th>
              {
                pendingRemits.data ? (<>
                  <th className="text-right">{ formatCents(pendingRemits.data.columnTotals.investor_collected_principal_delta) }</th>
                  <th className="text-right">{ formatCents(pendingRemits.data.columnTotals.investor_collected_interest_delta) }</th>
                  <th className="text-right">{ formatCents(pendingRemits.data.columnTotals.collected_origination_fees_delta) }</th>
                  <th className="text-right">{ formatCents(pendingRemits.data.columnTotals.notable_servicing_fee_delta) }</th>
                  <th className="text-right">{ formatCents(pendingRemits.data.columnTotals.tape_net_transfer) }</th>
                </>) : <th colSpan={5}>Loading…</th>
              }
            </tr>
          </thead>
          <tbody>
            {
              pendingRemits.data?.items.map(remittance => (
                <tr key={remittance._id}>
                  <td>{remittance.remittance_date}</td>
                  <td>{remittance.loan_slug}</td>
                  <td className="text-right">{formatCents(remittance.investor_collected_principal_delta)}</td>
                  <td className="text-right">{formatCents(remittance.investor_collected_interest_delta)}</td>
                  <td className="text-right">{formatCents(remittance.collected_origination_fees_delta)}</td>
                  <td className="text-right">{formatCents(remittance.notable_servicing_fee_delta)}</td>
                  <td className="text-right">{formatCents(remittance.tape_net_transfer)}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
        <div className="d-flex">
          { pagination }
        </div>
      </ShowInvestorLayout>
    </SystemLayout>
  );
}
