import React from 'react';

import Dropdown from 'react-bootstrap/Dropdown';
import OutboundButton from './OutboundButton';
import { LegacyLoanApplication } from '../api/queries/users/useLoanApplications';
import useTrelloCards, { TrelloCard, TrelloCards } from '../api/queries/loan-applications/useTrelloCards';

type Props = {
  loanApplication: LegacyLoanApplication;
  className?: string;
}

const notificationTypeToText = (type: string) => {
  switch (type) {
    case 'AGENT_AUTH_UNDER_REVIEW_TRELLO':
      return 'Agent Authorization Under Review';
    case 'BETTER_HOME_DISQUALIFIED':
      return 'Better Disqualified';
    case 'BETTER_HOME_APPLICATION_INELIGIBLE':
      return 'Underwriting - Ineligible';
    case 'BETTER_HOME_NOT_APPROVED':
      return 'Underwriting - Not Approved';
    case 'BETTER_HOME_NOT_ME_TRELLO':
      return 'Identity Details Not Correct';
    case 'BETTER_HOME_UNDER_REVIEW_TRELLO':
      return 'Underwriting - Under Review';
    case 'KUSTOMER_MULTIPLE_AGENT_PROFILES':
      return 'Kustomer - Multiple Agent Profiles';
    case 'KUSTOMER_MULTIPLE_BORROWER_PROFILES':
      return 'Kustomer - Multiple Borrower Profiles';
    case 'USER_PARTIAL_MATCH':
      return 'Partial match error';
    case 'ACCELERATION_NOT_CONFIRMED':
      return 'Acceleration Not Confirmed';
    case 'BETTER_HOME_LOAN_ACCEPTED':
      return 'Loan Accepted';
    case 'CHECK_FAILED':
      return 'Check Failed';
    default:
      return type;
  }
}

function TrelloDropdown ({ trelloCards, className }: { trelloCards: TrelloCards, className?: string}) {
  return (
    <Dropdown alignRight={true}>
      <Dropdown.Toggle id="trello-links-dropdown" variant="outline-dark" size="sm" className={className}>
        Trello
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {
          trelloCards.map(trelloCard => (
            <Dropdown.Item
              key={trelloCard.externalId}
              href={trelloCard.externalUrl}
              target='_blank'
              className='small-dropdown-item'
            >
              {notificationTypeToText(trelloCard.userNotificationType)}
            </Dropdown.Item>
          ))
        }
      </Dropdown.Menu>
    </Dropdown>
  );
}

function TrelloSingleButton ({ trelloCard, className }: { trelloCard: TrelloCard, className?: string}) {
  return <OutboundButton
    href={trelloCard.externalUrl}
    block={ true }
    className={className}
    size="sm"
  >
    Trello
  </OutboundButton>;
}

export default function TrelloLinks (props: Props) {

  const { loanApplication, className } = props;

  const { data: trelloCards } = useTrelloCards(loanApplication._id);

  if (!trelloCards || trelloCards.length === 0) return null;

  return (
    <>
      {
        trelloCards.length === 1 ?
        <TrelloSingleButton trelloCard={ trelloCards[0] } className={ className }/> :
        <TrelloDropdown trelloCards={ trelloCards } className={className}/>
      }
    </>
  )
}
