import useQuery from '../../shared/useQuery';

enum UNDERWRITING_OUTCOMES {
  APPROVED      = 'APPROVED',     // Application is approved and loan is offered
  COUNTEROFFER  = 'COUNTEROFFER', // Application is denied, but could be approved under different terms
  DENIED        = 'DENIED',       // Application is denied (adverse action)
  INELIGIBLE    = 'INELIGIBLE',   // Application is out of bounds and rejected (not adverse action)
  UNDECIDED     = 'UNDECIDED',    // Decision attempt was made but could not reach a conclusion with current rules (system marked application for manual review)
  PREQUALIFIED  = 'PREQUALIFIED', // Application is pre-qualified
  INCOMPLETE    = 'INCOMPLETE',   // Application has un-verified facts
}

enum UNDERWRITING_DECISION_TYPES {
  PRE_QUALIFICATION = 'pre_qualification',
  STRICT_DECISION   = 'strict_decision',
}

export type UnderwritingDecision = {
  _id: string;
  admin_id?: string; // The staff user who recorded the decision, if manual
  confirmed_at: string;
  confirmed_by_admin_id: string;
  decision_date: string;
  loan_application_id: string;
  notes?: string;
  outcome: UNDERWRITING_OUTCOMES;
  system_rules_version?: string; // Track the underwriting rules used for the decision (indicates decision created by system)
  ineligibility_codes?: Array<string>; // @deprecated in infra_version 2
  infra_version: number; // 1 = DENIAL_CODE-based underwriting, 2 = Facts + Rules flow
  type: UNDERWRITING_DECISION_TYPES;
}

export default function useLatest (loanAppId: string, shouldFetch=true) {
  return useQuery<UnderwritingDecision>(shouldFetch ? `/notebook/loan-applications/${loanAppId}/underwriting-decisions/latest` : null);
}
