import React from 'react';
import { useParams } from 'react-router-dom';
import SystemLayout from '../../Layouts/SystemLayout';
import { usePartner } from '../../../api/queries/partners/usePartner';
import ShowPartnerLayout from '../../Layouts/ShowPartnerLayout';
import { Col, Row } from 'react-bootstrap';
import { Partner } from '../../../api/queries/partners/usePartner';
import { TimelineList } from '../../../components/BorrowerTabs/ServicingTab/TimelineTab';
import useTimeline from '../../../api/queries/loans/useTimeline';
import { InlineError } from '../../../components';

export default function PartnerShowHistoryPage () {
  const { partnerId } = useParams<{ partnerId: string }>();

  const { data: partner } = usePartner(partnerId);
  if (!partner) {
    return null;
  }
  return (
    <SystemLayout selectedTab='partners' heading={partner.short_name}>
      <ShowPartnerLayout partner={partner} selectedTab='history'>
        <section className='mb-4 mt-4'>
          <Row>
            <Col>
              <History partner={partner}/>
            </Col>
          </Row>
        </section>
      </ShowPartnerLayout>
    </SystemLayout>
  );
}

function History ({ partner }: { partner: Partner }) {
  const request = useTimeline(partner._id);

  return request.data ? (
      <TimelineList events={ request.data } />
    ) : request.error ? (
      <InlineError>
        There was a problem loading the history. Please try again.
      </InlineError>
    ) : (
      <>Loading…</>
    );
}
