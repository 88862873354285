import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { LegacyLoanApplication } from "../../../api/queries/users/useLoanApplications";
import { useExpandableState } from '../../../utils';
import InlineButton from '../../InlineButton';
import LabelValueGrouping from '../../LabelValueGrouping';
import EditAgentModal from '../../Modals/EditAgentModal';

export default function AgentInfo ({ className, loanApplication }: { className: string, loanApplication: LegacyLoanApplication}) {
  const editAgent = useExpandableState();

  const dataForValueGrouping: Record<string, React.ReactNode> = {
    'Agent Name': <>
      <span>{`${loanApplication.agent?.displayName} `}</span>
      <InlineButton onClick={editAgent.showExpand}>
        Edit
      </InlineButton>
    </>,
    'Agent Phone': loanApplication.agent?.phone,
    'Agent Email': loanApplication.agent?.email,
  };

  return (
    <>
      <Card className={className}>
        <Card.Header>
          Agent
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <LabelValueGrouping
                data = {dataForValueGrouping}
                condensed
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <EditAgentModal
        expand={editAgent}
        loanApplication={loanApplication}
        onSubmit={() => window.location.reload()}
      />
    </>
  );
}
