import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import AdminContext from '../../adminContext';
import useAdminAPICall from '../../utils/useAdminAPICall';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { AccelerationEvent as Event } from '../../api/queries/loans/useAccelerationEvent';

type Props = {
  event: Event;
  show: boolean;
  onHide: () => void;
  onSubmit: (values: any) => void;
  loan: { _id: string };
};

export default function RemoveModal(props: Props) {
  const { event, show, onHide, onSubmit, loan } = props;
  const { _id: loanId } = loan;
  const adminContext = React.useContext(AdminContext);

  const { callAPI, loadError, loadPending } = useAdminAPICall({
    endpoint: `/notebook/loans/${loanId}/acceleration-events`,
    method: 'DELETE',
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    callAPI({ data: { admin_user_id: adminContext.adminId } }).then(onSubmit);
  };

  const eventDate = moment(event.date);
  const now = moment().startOf('day');

  return <Modal show={show} onHide={onHide} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Remove Acceleration Event</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      {
        loadError && <p className="text-danger">
          An error has ocurred. Please try again or contact support.
        </p>
      }

      <p>
        Please confirm you wish to remove this acceleration event.
      </p>

      {
        eventDate < now && (
          <p>
            <b>This event is in the past</b><br />

            As a result, the borrower's ability to spend loan funds (if available) will be reinstated and the maturity
            date will return to the natural term limit defined in the lending agreement.
          </p>
        )
      }

      <form onSubmit={handleSubmit} className="text-right mt-5">
        <div className="d-flex justify-content-end">
          <Button className="mr-2" variant="secondary" onClick = { onHide }>Cancel</Button>
          <ButtonWithSpinner variant="danger" type="submit" loading={loadPending}>Remove Acceleration Event</ButtonWithSpinner>
        </div>
      </form>
    </Modal.Body>
  </Modal>
}

RemoveModal.propTypes = {
  event: PropTypes.object,
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loan: PropTypes.object.isRequired,
};
