import useQuery from '../../shared/useQuery';

type Response = {
  latestPaidObligation: { dueDate: string } | null;
  latestPayment: { effectiveDate: string; amountCents: number } | null;
  outstandingAmountCents: number;
  paidAmountCents: number;
  repaidRatio: number;
  scheduledAmountCents: number;
  nextUnpaidObligation: {
    obligationId: string,
    periodEndDate: string,
    amount: number,
    dueDate: string,
  } | null;
  pastDueAmountCents: number;
  daysPastDue: number;
};

export default function usePaymentSummary (loanId: string) {
  return useQuery<Response>(`/notebook/loans/${loanId}/payment/summary`);
}
