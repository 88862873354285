import React, { useEffect } from 'react';
import { useSWRConfig } from 'swr';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../Form/Form';
import Field from '../Form/Field';
import PhoneField from '../Form/PhoneField';
import SubmitError from '../Form/SubmitError';
import useAdminAPICall from '../../utils/useAdminAPICall';
import cleanPhoneNumber from '../../utils/formatPhone';
import useExpandableState from '../../utils/useExpandableState';
import ButtonWithSpinner from '../ButtonWithSpinner';

type FirstNoticeFieldValues = {
  phone: string;
  email: string;
};

type ErrorMessage = {
  key: 'phone' | 'email';
  message: string;
};

type Props = {
  expandable: ReturnType<typeof useExpandableState>;
  onSubmit: (form: FirstNoticeFieldValues) => void;
  user: {
    is_temporary: boolean;
    phone: string;
    email: string;
    _id: string;
  };
  errors?: ErrorMessage[];
};

function EditContactInfoModal (props: Props) {
  const { expandable, onSubmit, user, errors } = props;

  const schema = yup.object({
    phone: user.is_temporary ? yup.string() : yup.string().required('Please provide a phone number'),
    email: user.is_temporary ? yup.string() : yup.string().required('Please provide an email address'),
  });

  const form = useForm<FirstNoticeFieldValues>({
    defaultValues: {
      phone: cleanPhoneNumber(user.phone),
      email: user.email?.trim() || '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (errors) {
      errors.forEach(error => {
        form.setError(error.key, { message: error.message });
      })
    }
  }, [errors, form]);

  const { formState: { isSubmitting } } = form;

  return <Modal show={ expandable.isExpanded } onHide={ expandable.hideExpand }>
    <Form {...form} onSubmit={onSubmit}>
      <Modal.Header>
        <Modal.Title>
          Edit Contact Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PhoneField name='phone' label='Phone Number'  placeholder='Phone'/>
        <Field name='email' label='Email Address'  placeholder='Email'/>

        <SubmitError/>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ expandable.hideExpand } className="mr-2">
          Cancel
        </Button>
        <ButtonWithSpinner variant="primary" type="submit" loading={ isSubmitting }>
          Save
        </ButtonWithSpinner>
      </Modal.Footer>
    </Form>
  </Modal>
}

export default function ConnectedEditContactInfoModal ({ ...props }: Pick<Props, 'expandable' | 'user'>) {
  const { callAPI, data, loadError } = useAdminAPICall({
    endpoint: `/notebook/users/${props.user._id}`,
    method: 'PUT',
  });
  const { mutate } = useSWRConfig();

  const handleSubmit = (form: FirstNoticeFieldValues) => {
    callAPI({ data: {
      phone: form.phone,
      email: form.email.trim(),
    } }).then(() => {
      mutate(`/notebook/users/${ props.user._id }`);
      props.expandable.hideExpand();
    });
  };
  return <EditContactInfoModal
    { ...props }
    onSubmit={ handleSubmit }
    errors={!!loadError ? data?.errors : []}
  />;
}
