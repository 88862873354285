import React from 'react';
import { Col, Container, Row, ProgressBar, Tooltip, OverlayTrigger } from 'react-bootstrap';
import usePaymentSummary from '../../../api/queries/loans/usePaymentSummary';
import InlineLoadingIndicator from '../../InlineLoadingIndicator';
import InlineError from '../../InlineError';
import { formatCents } from '../../../utils/index';
import Decimal from 'decimal.js';
import moment from 'moment';
import styles from './Overview.module.scss';

type Loan = {
  _id: string;
  autopayAccountId?: string;
}

export default function PaymentOverview ({ loan }: { loan: Loan }) {
  const { data, error } = usePaymentSummary(loan._id);
  if (!data) { return <InlineLoadingIndicator /> }
  if (error) { return <InlineError>Error loading payment summary.</InlineError> }

  const progress = new Decimal(data.repaidRatio).times(100).toNumber();
  const nextObligationAmount = data.nextUnpaidObligation ? data.nextUnpaidObligation.amount : 0;
  const tooltipText = data.nextUnpaidObligation && !data.nextUnpaidObligation.obligationId &&
  <Tooltip id="calculated-tooltip">
    Amount may change as funds are used. Statement period ends { moment(data.nextUnpaidObligation.periodEndDate).format('MMMM D') }
  </Tooltip>;
  return (
    <>
      <h5>Payment Overview</h5>
      <Container className='mb-3'>
        <Row className='border rounded'>
          <Col sm={8}>
            <Row className='py-2'>
              <Col sm={3}>
                <div><b>Paid</b></div> { formatCents(data.paidAmountCents) }
              </Col>
              <Col sm={3}>
                <div><b>Outstanding</b></div> { formatCents(data.outstandingAmountCents) }
              </Col>
              <Col sm={3} className='text-secondary'>
                <div><b>Scheduled</b></div> { formatCents(data.scheduledAmountCents) }
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col className='align-self-center'>
                <ProgressBar now={progress} className={styles.progressBar}></ProgressBar>
              </Col>
              <Col md='auto' className='text-secondary pl-0 small'>{`${progress.toFixed(1)}% repaid`}</Col>
            </Row>
          </Col>
          <Col sm={4} className='py-2 border-left '>
            <div><b>Autopay:</b> { loan.autopayAccountId ? 'On' : 'Off' }</div>
            <div><b>Paid through:</b> { data.latestPaidObligation ? moment(data.latestPaidObligation.dueDate).format('ll') : '-' }</div>
            <div><b>Last Payment:</b> { data.latestPayment ? moment(data.latestPayment.effectiveDate).format('ll') : '-' }</div>
            <div><b>Last Payment Amount:</b> { data.latestPayment ? formatCents(data.latestPayment.amountCents) : '-' }</div>
          </Col>
        </Row>
      </Container>

      <Container className='mb-5'>
        <Row className='border rounded py-3'>
          <Col sm={2}>
            <div><b>Next Due</b></div>
            { data.nextUnpaidObligation ?
              moment(data.nextUnpaidObligation.dueDate).format('MMMM D') : <span>--</span>
            }
          </Col>
          <Col sm={2}>
            <div><b>Amount</b></div>
            {
              data.nextUnpaidObligation ?
                data.nextUnpaidObligation.obligationId === null ?
                  <OverlayTrigger placement="top" overlay={ tooltipText }>
                    <span>{ formatCents(nextObligationAmount) }*</span>
                  </OverlayTrigger>
                  : <span>{ formatCents(nextObligationAmount) }</span>
                : <span>--</span>
            }
          </Col>
          <Col sm={2}>
            <div><b>Past Due</b></div>
            { data.pastDueAmountCents ? <span className='text-danger'>{ formatCents(data.pastDueAmountCents) }</span> : '--' }
          </Col>
          <Col sm={2}>
            <div><b>Total Due</b></div>
            {
              data.nextUnpaidObligation && data.nextUnpaidObligation.obligationId === null ?
                <OverlayTrigger placement="top" overlay={ tooltipText }>
                  <span>{ formatCents(nextObligationAmount + data.pastDueAmountCents) }*</span>
                </OverlayTrigger>
                : <span>{ formatCents(nextObligationAmount + data.pastDueAmountCents) }</span>
            }
          </Col>
          <Col sm={2}>
            <div><b>Days Past Due</b></div>
            { data.pastDueAmountCents ? <span className='text-danger'>{data.daysPastDue}</span> : '--' }
          </Col>
        </Row>
      </Container>
    </>
  );
}
