import React from 'react';
import Timeline from '../Lens/Timeline';
import InlineLoadingIndicator from '../InlineLoadingIndicator';
import InlineError from '../InlineError';
import LensListingInfo from '../Lens/ListingInfo';
import useManualDebtVerification from '../../api/queries/loan-applications/useManualDebtVerification';
import useLoanApplication from '../../api/queries/loan-applications/useLoanApplication';
import TheNumberLink from '../TheNumberLink';
import { LegacyLoanApplication } from '../../api/queries/users/useLoanApplications';

export default function LensTab ({ loanApplication }: { loanApplication: LegacyLoanApplication }) {
  // Fetch a more detailed loan application that includes property debt information
  const laReq = useLoanApplication(loanApplication._id);
  const mdvReq = useManualDebtVerification(loanApplication._id);

  if (laReq.error || mdvReq.error) {
    return (
      <InlineError>Error loading debt details.</InlineError>
    );
  }
  if (!laReq.data || mdvReq.data === undefined) {
    return (
      <div><InlineLoadingIndicator>Loading debt details…</InlineLoadingIndicator></div>
    );
  }
  return (
    <div>
      <div className='d-flex'>
        <LensListingInfo loanApplication={ laReq.data! }/>
        <TheNumberLink loanApplication={loanApplication} className='ml-auto my-auto' />
      </div>
      <hr/>
      <Timeline
        loanApplication={ laReq.data }
        reportedDebts={ laReq.data && laReq.data.reportedDebts }
        manualDebtVerification={ mdvReq.data || null }
      />
    </div>
  );
}
