import React from 'react';
import './CardList.css';

export default function CardList (props: { children: React.ReactNode }) {
  return (
    <ul className='CardList__'>
      {
        React.Children.map(props.children, (item: any) => (
          <li className='_CardListItem__' key={ item.key }>
            { item }
          </li>
        ))
      }
    </ul>
  );
}
