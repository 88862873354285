import React from 'react';
import { Button, FormGroup, FormLabel } from 'react-bootstrap';
import { useFormContext, useFieldArray } from "react-hook-form";
import Field from '../Form/Field';
import { SUPPORTING_DOCUMENT_TYPES } from '../../utils/constants';
import S3FileUploadField from '../Form/S3FileUploadField';

type Props = { userId: string };

export default function SupportingDocumentation ({ userId }: Props) {
  const { control, watch, resetField } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: 'supportingDocuments' });
  const supportingDocuments = watch('supportingDocuments');

  return (
    <>
      <h4 className='mt-4'>Supporting Documentation</h4>
      <p className='text-muted small'>All changes must have accompanying documentation.</p>

      <FormGroup className='mb-4'>
        <FormLabel>Documents</FormLabel>
      </FormGroup>

      { fields.map((item, index) => <div className='mb-5' key={item.id}>
          <Field name={`supportingDocuments.${index}.type`} controlProps={{ as: 'select' }}>
            <option value="" disabled hidden>Please select</option>
            {SUPPORTING_DOCUMENT_TYPES.map(({ label, value }) => <option value={value} key={value}>{label}</option>)}
          </Field>

          { supportingDocuments[index].type && <S3FileUploadField
              name={`supportingDocuments.${index}.filename`}
              bucket='supporting-docs'
              s3_path={`user_${userId}`}
              remove={() => supportingDocuments.length > 1 ? remove(index) : resetField(`supportingDocuments.${index}`) }
              removeInput
            />
          }
        </div>)
      }

      { supportingDocuments.length && supportingDocuments[supportingDocuments.length - 1].filename.s3Filename &&
          <Button variant="primary" onClick={() => append({ type: '', filename: { s3Filename: '', originalFilename: '' } })}>Add Additional Documentation</Button>
      }
    </>
  );
}
