import React, { useState, useEffect } from 'react';
import Spinner from './Spinner';
import './ComponentWithSpinner.css';

type Props<T extends ComponentProps> = {
  component: React.ComponentType<T>;
} & T;


type ComponentProps = {
  children?: React.ReactNode;
  loading?: boolean;
};

export default function ComponentWithSpinner<T extends ComponentProps> ({ loading, component: Component, children, ...props }: Props<T>) {
  const [delayedLoading, setDelayedLoading] = useState(loading);

  useEffect(() => {
    let delayTimeout: NodeJS.Timeout;

    if (loading) {
      delayTimeout = setTimeout(() => setDelayedLoading(true), 50);
    } else {
      setDelayedLoading(false);
    }

    return () => clearTimeout(delayTimeout);
  }, [loading]);


  return (
    <div className={`ComponentWithSpinner ${delayedLoading ? 'loading' : ''}`}>
      <Component {...props as unknown as T} loading={delayedLoading}>
        <div className='_Children'>{children}</div>

        {delayedLoading && <div className='_Spinner'><Spinner /></div>}
      </Component>
    </div>
  );
}
