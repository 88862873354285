import React from 'react';

type Props = {
  line1?: string;
  line2: string;
  city: string;
  state: string;
  zip: string;
};

export default function AddressDisplay (props: Props) {
  const {
    line1,
    line2,
    city,
    state,
    zip,
  } = props;

  if (!line1) {
		return <>–</>;
	}

	return (<>
		{ line1 }<br />
		{ line2 }{ line2 ? (<br />) : null }
		{ city }, { state } { zip }
	</>);
}
