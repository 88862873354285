import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Button, Table, Pagination, InputGroup, FormControl, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import querystring from 'qs';
import InlineLoadingIndicator from '../../components/InlineLoadingIndicator';
import InlineError from '../../components/InlineError';
import useAdminAPICall from '../../utils/useAdminAPICall';
import { useForm } from 'react-hook-form';
import formatDollars from '../../utils/formatDollars';
import { trim } from 'lodash';
import './Search.css';

const SEARCH_PAGE_SIZE = 50;

type LoanApplication = {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  partnerShortName: string;
  agentDisplayName: string;
  _id: string;
  principal: number;
  loanSlug: string | null;
  status: string;
};

function TooltipTd ({ content }: { content: string }) {
  return <td>
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={content}>{content}</Tooltip>}
      trigger='hover'
    >
      <span>{content}</span>
    </OverlayTrigger>
  </td>
}

type SearchPaginationProps = {
  activePage: number;
  nextPage: boolean;
  onClick: (b: boolean) => void;
};

function SearchPagination(props: SearchPaginationProps) {
  const { activePage, nextPage, onClick } = props;

  return (
    <Pagination>
      <Pagination.Prev onClick={() => onClick(false)} disabled={activePage === 1} />
      <Pagination.Next onClick={() => onClick(true)} disabled={!nextPage} />
    </Pagination>
  );
}

function cleanValue(val: string | null) {
  return !val ? '--' : val;
}

export default function Search() {
  const navigate = useNavigate();
  const location = useLocation();
  const parsedParams = querystring.parse(location.search, { ignoreQueryPrefix: true });
  const [searchTerms, setSearchTerms] = useState('');
  const [searchResults, setSearchResults] = useState<Array<LoanApplication>>([]);
  const [activePage, setActivePage] = useState(1);
  const { register, handleSubmit, setValue, resetField, formState: { errors } } = useForm<{ searchText: string }>();
  const { callAPI, loadError, loadPending, loadComplete } = useAdminAPICall<Array<LoanApplication>>({
    endpoint: `/notebook/loan-applications/search`,
  });

  useEffect(() => { document.title = 'Home | Notebook' }, []);

  // eslint react-hooks/exhaustive-deps is disabled bc the effect should only fire when the url updates
  useEffect(() => {
    let canceled = false;
    const page = parsedParams.page ? parseInt(parsedParams.page as string) : 1;
    const text = parsedParams.searchText ? parsedParams.searchText as string : '';
    const config = {params: {searchText: text, page: page, perPage: SEARCH_PAGE_SIZE}};

    if(text) {
      callAPI(config).then((resp) => {
        if (!canceled) { setSearchResults(resp.data) }
      });

    } else {
      setSearchResults([])
    }

    setValue('searchText', text);
    setSearchTerms(text as string);
    setActivePage(page);

    return () => { canceled = true }
  }, [parsedParams.searchText, parsedParams.page]); // eslint-disable-line react-hooks/exhaustive-deps

  const setSearchUrl = (searchText = '', page = 1, clear = false) => {
    if(searchText) {
      const encodedSearchText = encodeURIComponent(searchText);
      const pageParam = page > 1 ? '&page=' + page : '';
      if(location.search !== `?searchText=${encodedSearchText}${pageParam}`) {
        navigate(`?searchText=${encodedSearchText}${pageParam}`);
      }

    } else if (clear) {
      navigate(``)
    }
  }

  const handlePagination = (nextPage: boolean) => {
    const newActivePage = nextPage ? activePage + 1 : activePage - 1;
    setSearchUrl(searchTerms, newActivePage)
  }

  const clearSearch = () => {
    resetField('searchText');
    setSearchUrl('', 1, true);
    setSearchResults([]);
    setActivePage(1);
    setSearchTerms('');
  }

  const getSearchTable = () => {
    return (
      <div className={`resultsTable mb-5`}>
        {searchResults.length === 0 ?
          <h4 data-emptyresults>
            {parsedParams.page ? 'No more results for ' : 'Sorry, nothing matches your search criteria: '}
            "{searchTerms}"
          </h4>
        : <>
            <p>Search results for "{searchTerms}"</p>
            <Table responsive striped bordered hover size="small">
              <thead>
                <tr>
                    <th>Loan Slug</th>
                    <th>Partner</th>
                    <th>Status</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Agent Name</th>
                    <th>Principal Amount</th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map(result => {
                  return (<tr
                    key={`searchResult-${ result.userId + '-' + result._id}`}
                    className='cursor-pointer'
                    onClick={() => { window.location.href = `/loan-applications/${result._id}${result.loanSlug ? '/loan' : ''}`; } }
                  >
                    <td>{cleanValue(result.loanSlug)}</td>
                    <td>{result.partnerShortName}</td>
                    <td>{result.status}</td>
                    <TooltipTd content={cleanValue(trim(`${result.firstName ?? ''} ${result.lastName ?? ''}`))}/>
                    <TooltipTd content={cleanValue(result.phone)}/>
                    <TooltipTd content={cleanValue(result.email)}/>
                    <TooltipTd content={cleanValue(result.agentDisplayName)}/>
                    <td>{formatDollars(result.principal)}</td>
                  </tr>);
                })}
              </tbody>
            </Table>
          </>
        }
        {(searchResults.length === SEARCH_PAGE_SIZE || parsedParams.page) &&
          <SearchPagination
            onClick={handlePagination}
            activePage={activePage}
            nextPage={searchResults.length === SEARCH_PAGE_SIZE}
          />
        }
      </div>
    );
  }

  return (
    <Container className={`searchPage mt-5 mb-5`}>
      <Row>
        <Col>
          <h2 className={`pt-3`}>Search</h2>
          <form className="pb-5" onSubmit={handleSubmit(data => setSearchUrl(data.searchText, 1, true))}>
            <Form.Group
              controlId="searchBar"
            >
              <InputGroup>
                <FormControl
                  className="form-control-lg"
                  placeholder='Search by loan slug, user id, first or last name, email, or phone'
                  autoFocus
                  {...register('searchText', { required: true })}
                />
                <InputGroup.Append>
                  <Button variant="secondary" onClick={clearSearch}>Clear</Button>
                  <Button variant="primary" type="submit">Search</Button>
                </InputGroup.Append>
              </InputGroup>

              {
                !!errors.searchText && <Form.Text className="text-danger">
                  Please enter at least one term for search
                </Form.Text>
              }
            </Form.Group>
          </form>
        </Col>
      </Row>
      <Row>
        <Col>
          {loadPending && <h4>Loading... <InlineLoadingIndicator /></h4> }
          {loadError && <InlineError>Error loading search results. Please clear your search and try again.</InlineError>}
          {(loadComplete && searchTerms) && getSearchTable() }
        </Col>
      </Row>
    </Container>
  );
}
