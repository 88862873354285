import React from 'react';
import { Form as ReactBootstrapForm } from 'react-bootstrap';
import { FormProvider, UseFormReturn, FieldValues, SubmitHandler } from "react-hook-form";
import * as Sentry from '@sentry/react';

/*

Usage:

```jsx
  import React from 'react';
  import { useForm } from "react-hook-form";
  import { yupResolver } from '@hookform/resolvers/yup';
  import * as yup from "yup";
  import Form from './Form';
  import Field from './Field';
  import SubmitError from './SubmitError';
  import { Button } from 'react-bootstrap';

  function MyComponent (props) {
    const schema = yup.object().shape({
      firstName: yup.string().required('Please enter a first name'),
      lastName: yup.string().required('Please enter a last name'),
    });

    const methods = useForm({ defaultValues: { firstName: 'John', lastName: '' }, resolver: yupResolver(schema) });
    const { formState: { isSubmitting }, reset } = methods;

    const onSubmit = async (values) => {
      await callAPI({ data: values });
    };

    return <Form {...methods} onSubmit={onSubmit}>
      <Field name='firstName' label='First Name' placeholder='First Name' required />
      <Field name='lastName' label='Last Name' placeholder='Last Name' required />

      <SubmitError/>

      <Button variant='secondary' onClick={reset} disabled={isSubmitting}>Cancel</Button>
      <Button type='submit' variant='primary' disabled={isSubmitting}>Submit</Button>
    </Form>;
  }
```
*/

type Props<TFieldValues extends FieldValues = FieldValues> = {
  children: React.ReactNode;
  onSubmit?: SubmitHandler<TFieldValues>;
} & UseFormReturn<TFieldValues>;

export default function Form<TFieldValues extends FieldValues> ({ children, onSubmit, ...methods }: Props<TFieldValues>) {
  const { handleSubmit, clearErrors, setError } = methods;

  const customSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    clearErrors('submitError' as any);

    try {
      onSubmit && await handleSubmit(onSubmit)(event);
    } catch (error) {
      Sentry.captureException(error);
      setError('submitError' as any, { type: 'server' });
    }
  };

  return <FormProvider {...methods}>
    <ReactBootstrapForm onSubmit={customSubmit}>
      {children}
    </ReactBootstrapForm>
  </FormProvider>;
}
