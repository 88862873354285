import React from 'react';
import { Partner } from '../../../api/queries/partners/usePartner';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import SubmitError from '../../Form/SubmitError';
import { Button } from 'react-bootstrap';
import ButtonWithSpinner from '../../ButtonWithSpinner';

type PartnerInfoFields = {
  trelloAccelerationNotConfirmedListId: string;
  trelloDeniedLoanAppListId: string;
  trelloIneligibleLoanAppListId: string;
  trelloUnderReviewLoanAppListId: string;
};

type Props = {
  partner: Partner;
  onSubmit: () => void;
  onHide: () => void;
}

export default function EditTrelloLists (props: Props) {
  const { partner, onSubmit, onHide } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/partners/${partner._id}`,
    method: 'PUT',
  });

  async function handleSubmit(data: PartnerInfoFields) {
    await callAPI({
      data: {
        trelloUnderReviewLoanAppListId: data.trelloUnderReviewLoanAppListId,
        trelloDeniedLoanAppListId: data.trelloDeniedLoanAppListId,
        trelloIneligibleLoanAppListId: data.trelloIneligibleLoanAppListId,
        trelloAccelerationNotConfirmedListId: data.trelloAccelerationNotConfirmedListId,
      },
    });
    onSubmit();
    onHide();
  }

  const schema = yup.object();

  const methods = useForm<PartnerInfoFields>({
    defaultValues: {
      trelloUnderReviewLoanAppListId: partner.trelloUnderReviewLoanAppListId,
      trelloDeniedLoanAppListId: partner.trelloDeniedLoanAppListId,
      trelloIneligibleLoanAppListId: partner.trelloIneligibleLoanAppListId,
      trelloAccelerationNotConfirmedListId: partner.trelloAccelerationNotConfirmedListId,
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty } } = methods;

  return <div>
    <Form {...methods} onSubmit={handleSubmit}>
      <Field name='trelloUnderReviewLoanAppListId' label='Under Review Loan App'/>
      <Field name='trelloDeniedLoanAppListId' label='Denied Loan App'/>
      <Field name='trelloIneligibleLoanAppListId' label='Ineligible Loan App'/>
      <Field name='trelloAccelerationNotConfirmedListId' label='Acceleration Not Confirmed'/>

      <SubmitError/>

      <div className="d-flex justify-content-end mt-4">
        <Button variant="secondary" onClick={onHide} className="mr-2">
          Cancel
        </Button>
        <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
          Confirm
        </ButtonWithSpinner>
      </div>
    </Form>
  </div>;
}
