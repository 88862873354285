import React, { useState } from 'react';
import { Partner } from '../../../api/queries/partners/usePartner';
import * as yup from 'yup';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import Form from '../../Form/Form';
import SubmitError from '../../Form/SubmitError';
import { Button, Col, Row, Form as FormReact } from 'react-bootstrap';
import ButtonWithSpinner from '../../ButtonWithSpinner';
import { formatDollars } from '../../../utils';
import FormattedDollarField from '../../Form/FormattedDollarField';
import _ from 'lodash';
import styles from './EditPartnerAgentPricings.module.scss';
import CloseIcon from '@material-ui/icons/Close';

type Pricing = {
  minListPrice: string;
  rate: string;
};

type PartnerInfoFields = {
  pricings: Array<Pricing>;
};

type Props = {
  partner: Partner;

  onSubmit: () => void;
  onHide: () => void;
}

export default function EditPartnerAgentPricings (props: Props) {
  const [showAddButton, setShowAddButton] = useState(true);
  const { partner, onSubmit, onHide } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/partners/${partner._id}/agent-pricings`,
    method: 'PUT',
  });

  async function handleSubmit(data: PartnerInfoFields) {
    await callAPI({
      data: {
        pricings: data.pricings.map(pricing => ({
          minListPriceCentsBucket: stringToInt(pricing.minListPrice) * 100,
          rateCents: stringToInt(pricing.rate) * 100,
          })
        )
      },
    });
    onSubmit();
    onHide();
  }

  const schema = yup.object({
    pricings: yup.array(
      yup.object({
        minListPrice: yup.string().required('Please enter a min list price.'),
        rate: yup.string().required('Please enter a rate.'),
      })
    ).test('unique', 'Min list price must be unique', (pricings) => {
      if (!pricings) return true;
      const minListPrices = pricings.map(pricing => pricing.minListPrice);
      return _.uniq(minListPrices).length === minListPrices.length;
    }),
  });

  const methods = useForm<PartnerInfoFields>({
    defaultValues: {
      pricings: partner.agentPricing.map(pricing => ({
        minListPrice: formatDollars(pricing.minListPriceCentsBucket / 100, { precision: 0 }),
        rate: formatDollars(pricing.rateCents / 100, { precision: 0 }),
      })),
    },
    resolver: yupResolver(schema),
  });

  const { control, formState: { isSubmitting, isDirty, errors } } = methods;
  const { fields, append, remove, move } = useFieldArray({ control, name: 'pricings' });

  return <div>
    <Form {...methods} onSubmit={handleSubmit}>
      <Row className='mb-2'>
        <Col sm={11}>
          <Row>
            <Col>
              <b>Min List Price</b>
            </Col>
            <Col>
              <b>Max List Price</b>
            </Col>
            <Col className='pr-0'>
              <b>Rate Cents</b>
            </Col>
          </Row>
        </Col>
        <Col sm={1}></Col>
      </Row>

      { fields.map((pricing, index) => <PricingRow key={pricing.id} index={index} move={move} remove={remove} onBlur={() => setShowAddButton(true)}/> )}

      {
        showAddButton &&
        <Row>
          <Col sm={12}>
            <Button variant="link" onClick={() => {
              append({ minListPrice: '', rate: '' });
              setShowAddButton(false);
            }}>
              Add
            </Button>
          </Col>
        </Row>
      }

      { errors.pricings && <div className="text-danger mb-3 text-center">{errors.pricings.message}</div> }
      <SubmitError/>

      <div className="d-flex justify-content-end mt-4">
        <Button variant="secondary" onClick={onHide} className="mr-2">
          Cancel
        </Button>
        <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
          Confirm
        </ButtonWithSpinner>
      </div>
    </Form>
  </div>;
}

function stringToInt (str: string) {
  return parseInt(str.replace(/[^\d.-]+/g,''));
}

function PricingRow ({ index, move, remove, onBlur }: { index: number, move: (a: number, b: number) => void, remove: (a: number) => void, onBlur: () => void }) {
  const { Control } = FormReact;
  const { watch } = useFormContext();

  const watchedPricings: Array<Pricing> = watch('pricings');
  const pricings = _.sortBy(watchedPricings, (p) => stringToInt(p.minListPrice));
  const minListPrice: string = watch(`pricings.${index}.minListPrice`);

  const maxListPrice = !pricings[index].minListPrice ? '' :
    !pricings[index + 1]?.minListPrice ? '∞' :
    formatDollars(stringToInt(pricings[index + 1].minListPrice) - 0.01);

  const handleOnBlur = () => {
    const newIndex = pricings.findIndex((pricing) => stringToInt(pricing.minListPrice) === stringToInt(minListPrice));

    if (newIndex === -1) {
      move(index, pricings.length - 1);
    } else {
      move(index, newIndex);
    }
    onBlur();
  }

  return <Row className='mb-2'>
      <Col>
        <FormattedDollarField name={`pricings.${index}.minListPrice`} onBlur={handleOnBlur}/>
      </Col>
      <Col>
        <Control disabled value={maxListPrice}/>
      </Col>
      <Col className='pr-0'>
        <FormattedDollarField name={`pricings.${index}.rate`}/>
      </Col>
      <Col sm={1} className='d-flex'>
        <CloseIcon onClick={() => remove(index)} className={`my-auto mx-auto ${styles.close}`}/>
      </Col>
    </Row>;
}
