import React from 'react';
import _ from 'lodash';
import { amortizeTransactionFormatted, IncludeCheckbox } from '../DebtCalculation';
import NameList from '../NameList';
import { FirstAmerican } from '../SourceTag';
import Highlight from '../../Highlight';
import LabelValueGrouping from '../../LabelValueGrouping';
import { useAPICall } from '../../../utils';
import formatDollars from '../../../utils/formatDollars';
import { TIMELINETYPE } from '../../../utils/constants';
import useDebtCalculation from '../DebtCalculation/useDebtCalculation';
import { LegacyLoanApplication } from '../../../api/queries/users/useLoanApplications';

type Lien = {
  date: string;
  value: number;
};

type Transaction = {
  _id: string;
  _loanApplication: { firstName: string; lastName: string; };
  Amount: number;
  TxDate: string;
  mortgageAmount: number;
  mortgageOriginationDate: string;
  liens: Array<Lien>;
  Borrower1?: string;
  Borrower2?: string;
  Borrower3?: string;
  Borrower4?: string;
  MortgageLoanType: unknown;
  MortgageTerm: unknown;
  SellerLender: string;
  TransactionType: string;
  TypeDescription: unknown;
  UnpaidBalance: string;
};

export function useLienReportForLoanApplicationIfRequested (loanApplication: LegacyLoanApplication) {
  const propertyPayload = loanApplication && JSON.stringify({
    type: 'property',
    firstamerican: true,
    street_address: `${loanApplication.listing_address_line1} ${loanApplication.listing_address_line2 || ''}`.trim(),
    city: loanApplication.listing_city,
    state: loanApplication.listing_state,
    zip: loanApplication.listing_zip,
  });
  const targetURL = `/property.america.sources.first_american.report?org=slate&portfolio=production_records&america=${encodeURIComponent(propertyPayload)}&preset=Date`;
  const lienReq = useAPICall({
    endpoint: `/notebook/proxies/thenumber?url=${encodeURIComponent(targetURL)}`,
    parseResponse: (rawData) => _.get(rawData, ['property.america.sources.first_american.report'], []),
  });
  return lienReq;
}

type PropertyLienTransactionsProps = {
  calculator: ReturnType<typeof useDebtCalculation>;
  records: { [key: string]: Array<{ item: Transaction }> };
  disabledEdit: boolean;
};

export default function PropertyLienTransactions (props: PropertyLienTransactionsProps): any {
  const { calculator, records, disabledEdit } = props;
  return records[TIMELINETYPE.LIENREPORT].map(({ item }) => (
    <PropertyLienTransaction
      transaction={item}
      calculator={calculator}
      disabledEdit={disabledEdit}
    />
  ));
}

type PropertyLienTransactionProps = {
  calculator: ReturnType<typeof useDebtCalculation>;
  transaction: Transaction;
  disabledEdit: boolean;
};

function PropertyLienTransaction (props: PropertyLienTransactionProps) {
  const { transaction, calculator, disabledEdit } = props;
  const borrowerNames = [
    transaction.Borrower1,
    transaction.Borrower2,
    transaction.Borrower3,
    transaction.Borrower4,
  ].filter(Boolean) as Array<string>;
  return (
    <div className='bg-grey p-2'>
      <h4 className='d-flex'>
        <span className='flex-grow-1'>{ transaction.TransactionType }</span>
        <FirstAmerican />
      </h4>

      <LabelValueGrouping data={{
        'Amount': <>
          <label><Highlight><strong>{formatDollars(transaction.Amount)}</strong></Highlight>
            <IncludeCheckbox calculator={calculator} id={transaction._id} disabled={disabledEdit} />
          </label>
        </>,
        'Borrowers': <NameList loanApplication={transaction._loanApplication} names={borrowerNames} />,
        'Lender': <NameList loanApplication={transaction._loanApplication} names={[transaction.SellerLender]} />,
        'Transaction Date': transaction.TxDate,
        'Mortgage Type': transaction.MortgageLoanType,
        'Mortgage Term': transaction.MortgageTerm,
        'Type Description': transaction.TypeDescription,
        'Unpaid Balance': formatDollars(transaction.UnpaidBalance),
      }} condensed />

      <LabelValueGrouping data={{
        'Estimated current balance': !transaction.Amount || transaction.Amount === 0 ? <>&mdash;</> : <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Rate</th>
              <th>30-yr</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>High</td>
              <td>
                <label>
                  {amortizeTransactionFormatted(transaction, 'high_30', TIMELINETYPE.LIENREPORT)}
                  <IncludeCheckbox id={transaction._id + '.high_30'} calculator={calculator} />
                </label>
              </td>
            </tr>
            <tr>
              <td>Avg</td>
              <td>
                <label>
                  {amortizeTransactionFormatted(transaction, 'avg_30', TIMELINETYPE.LIENREPORT)}
                  <IncludeCheckbox id={transaction._id + '.avg_30'} calculator={calculator} />
                </label>
              </td>
            </tr>
            <tr>
              <td>Low</td>
              <td>
                <label>
                  {amortizeTransactionFormatted(transaction, 'low_30', TIMELINETYPE.LIENREPORT)}
                  <IncludeCheckbox id={transaction._id + '.low_30'} calculator={calculator} />
                </label>
              </td>
            </tr>
          </tbody>
        </table>,
      }} />
    </div>
  )
}
