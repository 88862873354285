import React from 'react';
import { Alert } from 'react-bootstrap';

import useManualDebtVerification from '../../api/queries/loan-applications/useManualDebtVerification';
import { lensTabUrl } from '../../utils/url';
import { formatCents } from '../../utils';
import AdminUserBadge from '../AdminUserBadge';

type LoanApplication = {
  _id: string;
  propertyDebt: string;
  loan?: { _id: string } | null;
  userId: string;
};

export default function ManualDebtVerificationAlert ({ loanApplication }: { loanApplication: LoanApplication }) {
  const { data: manualDebtVerification } = useManualDebtVerification(loanApplication._id);

  if (!manualDebtVerification) { return null; }

  let percentage;
  const propertyDebt = parseFloat(loanApplication.propertyDebt);
  if (propertyDebt > 0) {
    percentage = (manualDebtVerification.total_amount_cents / 100 - propertyDebt) / propertyDebt;
  }

  return <Alert variant='info'>
    Debt of { formatCents(manualDebtVerification.total_amount_cents) } { percentage ? `(${(percentage * 100).toFixed(0)}%) ` : null }manually
    verified at { manualDebtVerification.created_at.split('T')[0] } <AdminUserBadge admin_id={ manualDebtVerification.admin_user_id } addBy={ true } />.
    &nbsp;<a href= { lensTabUrl(loanApplication) }>View »</a>
  </Alert>;
}
