import React, { CSSProperties } from 'react';
import ComponentWithSpinner from './ComponentWithSpinner';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import uniqueId from 'lodash/uniqueId';
import './ButtonWithSpinner.css';

type Props = {
  block?: string;
  tooltip?: string;
} & Omit<React.ComponentProps<typeof Button>, 'block'>;

const Component = ({ block, loading, disabled, className, tooltip, style, ...rest}: Props) => {
  const cx = ['ButtonWithSpinner', className];
  if (block) {
    if (block === 'fluid') {
      cx.push('-block--fluid');
    } else {
      cx.push('-block--fixed');
    }
  }

  const buttonStyle: CSSProperties = {
    pointerEvents: (disabled && tooltip) ? 'none' : undefined, // Reset this to none so the overlay trigger works: https://react-bootstrap.github.io/components/overlays/#disabled-elements
    ...(style ?? {}),
  };

  let content = (
    <div className={ cx.join(' ') }>
      <Button {...rest} style={buttonStyle} block={ block ? true : false } disabled={loading || disabled} />
    </div>
  );

  if (tooltip) {
    content = (
      <OverlayTrigger
        delay={ 500 /* ms */ }
        overlay={
          <Tooltip id={ uniqueId('buttonwithspinner-tooltip-') /* The Tooltip requires an ID, so make sure it gets a unique one. */ }>
            { tooltip }
          </Tooltip>
        }
        placement="top"
      >
        { content }
      </OverlayTrigger>
    );
  }
  return content;
};

export type ButtonWithSpinnerProps = {
  children?: React.ReactNode;
  loading: boolean;
  disabled?: boolean;
  [k: string]: any
};

/**
 * ButtonWithSpinner
 *
 * @param loading required, set to true when the button should show the spinner indicating activity, false when not.
 * @param block Set to 'fluid' for more flexible filling behavior with a constrained max-width, true for default bootstrap block button behavior.
 * @param tooltip Set this to add a tooltip, such as why the button is disabled.
 */
const ButtonWithSpinner = (props: ButtonWithSpinnerProps) => (
  <ComponentWithSpinner component={Component} {...props} />
)

export default ButtonWithSpinner;
