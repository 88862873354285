import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext, useController } from "react-hook-form";
import { formatDollars } from '../../utils';

type Props = {
  name: string;
  label?: string;
  placeholder?: string;
  precision?: number;
  showErrorBorder?: boolean;
  onBlur?: () => void;
};

export default function FormattedDollarField ({ name, onBlur }: Props) {
  const { Control } = Form;

  const { control, register } = useFormContext();
  const { fieldState: { error } } = useController({ name, control });
  if (error) { console.log('error', error); }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleanValue = (e.target.value ?? '').replace(/[^\d.-]+/g,'');
    const cents = formatDollars(cleanValue, { precision: 0 });
    e.target.value = cents;
  }

  return <>
    <Control
      isInvalid={!!error}
      {...register(name, {
        onChange: handleChange,
        onBlur: () => onBlur?.(),
      })}
    />

    { error && error.message && <Control.Feedback type="invalid">{error.message}</Control.Feedback> }
  </>;
}
