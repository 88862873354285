import _ from 'lodash';
import React, { useState } from 'react';
import { ServicingActivity } from '../../../../api/queries/loans/useActivity';
import useCardActivity from '../../../../api/queries/loans/useCardActivity';
import styles from './ActivityDetail.module.scss';
import cx from 'classnames';
import { formatCents, formatDate } from '../../../../utils';
import { Table } from 'react-bootstrap';
import ExplainerPopover from '../../../ExplainerPopover';
import InlineError from '../../../InlineError';
import { TimelineListLoader } from '../TimelineTab';
import { postAPIEndpoint } from '../../../../utils/useAdminAPIData';
import InlineButton from '../../../InlineButton';
import { LedgerImpactsLoader } from '../LedgerImpacts';

type Props = {
  activity: ServicingActivity;
  onChange: () => void;
  loanId: string;
};

function getAuthStatusColor (status: string) {
  switch (status) {
    case 'approved':
      return 'text-success';
    case 'failure':
      return 'text-warning';
    case 'declined':
      return 'text-danger';
  }
}

export const CardActivityDetail: React.FC<Props> = ({ activity, loanId, onChange }) => {

  const txnRequest = useCardActivity(activity.base_id, {
    shouldRetryOnError: false,
  });

  const [isSyncingWithMarqeta, setIsSyncingWithMarqeta] = useState(false);
  async function syncWithMarqeta () {
    setIsSyncingWithMarqeta(true);
    await postAPIEndpoint(`/notebook/card-activities/${ activity.base_id }/sync-with-marqeta`, undefined);
    setIsSyncingWithMarqeta(false);
    txnRequest.mutate();
  }

  if (!txnRequest.data) {
    return <>Loading…</>;
  }

  if (txnRequest.error) {
    return <InlineError>There was a problem loading the details. Please try again.</InlineError>
  }

  const {
    events,
    amount,
    cash_back_amount,
    status,
    date,
    card,
    merchant,
    marqeta_token,
    swipe_details,
    decline_details,
  } = txnRequest.data;

  return (
    <>
      <div className={styles.line}>
        <span>Date</span>
        <span>{ formatDate(date) }</span>
      </div>
      <div className={styles.line}>
        <span>Amount</span>
        <span>{ formatCents(amount) }</span>
      </div>
      <div className={styles.line}>
        <span>Cash Back Amount</span>
        <span>{ formatCents(cash_back_amount, '—') }</span>
      </div>
      <div className={styles.line}>
        <span>Status</span>
        <span className={
          status === 'cleared' ? 'text-success'
          : status === 'voided' ? 'text-danger'
          : 'text-warning'
        }>
          { _.capitalize(status) }
        </span>
      </div>

      <div className={cx(styles.line, 'mt-4')}>
        <span className={styles.title}>Point-of-Sale</span>
      </div>
      <div className={styles.line}>
        <span>
          <ExplainerPopover explanation="The initial authorization decision we made. The transaction may later be declined or reversed by the card processors or merchant even if we initially approve it.">
            Initial Auth
          </ExplainerPopover>
        </span>
        <span>{
          swipe_details.is_force_capture ?
            <ExplainerPopover explanation="The merchant captured the funds using a force capture, bypassing our authorization gateway. This is typical if their original hold expires due to a delay in shipping merchandise, or the card was presented in an offline scenario">
                <span className="text-danger">Force Capture</span>
              </ExplainerPopover>
            : <span className={ getAuthStatusColor(swipe_details.auth_status) }>
              { _.capitalize(swipe_details.auth_status || '—') }
            </span>
          }</span>
      </div>
      {
        swipe_details.auth_decline_reason_type && <div className={styles.line}>
          <span>
            <ExplainerPopover explanation="Our internal code describing the reason we declined the card transaction.">
              Decline Reason
            </ExplainerPopover>
          </span>
          <span>{ swipe_details.auth_decline_reason_type }</span>
        </div>
      }
      {
        decline_details && (<>
          <div className={styles.line}>
            <span><abbr title="Address Verification System">AVS</abbr></span>
            <span>{ decline_details.avs || '—' }</span>
          </div>
          <div className={styles.line}>
            <span>Response</span>
            <span>{ decline_details.memo || '—' }</span>
          </div>

        </>)
      }
      <div className={styles.line}>
        <span>
          <ExplainerPopover explanation="Describes how the card was presented, such as chip, manual entry of the number, tap, etc">
            Entry Mode
          </ExplainerPopover>
        </span>
        <span>{ swipe_details.method || '—' }</span>
      </div>
      <div className={styles.line}>
        <span>
          <ExplainerPopover explanation="If the card was used online or not">
            Channel
          </ExplainerPopover>
        </span>
        <span>{ swipe_details.channel || '—' }</span>
      </div>

      <div className={cx(styles.line, 'mt-4')}>
        <span className={styles.title}>Card Details</span>
      </div>
      <div className={styles.line}>
        <span>Number</span>
        <span>**{card.last_four}</span>
      </div>
      <div className={styles.line}>
        <span>Type</span>
        <span>{card.type}</span>
      </div>
      <div className={styles.line}>
        <span>Current Status</span>
        <span>{card.status}</span>
      </div>


      {merchant && (
        <>
          <div className={cx(styles.line, 'mt-4')}>
            <span className={styles.title}>Merchant Details</span>
          </div>
          <div className={styles.line}>
            <span>Name</span>
            <span>{merchant.name}</span>
          </div>
          <div className={styles.line}>
            <span>
              <ExplainerPopover explanation="The raw address from the card network which may include a phone number or otherwise be incomplete. It may also be different than the actual location of the purchase, depending on the merchant's configuration — especially for online purchases.">
                Address
              </ExplainerPopover>
            </span>
            <span>
              {merchant.street_address}{ merchant.street_address && <br />}
              {merchant.city}, {merchant.state} {merchant.postal_code}
            </span>
          </div>
          <div className={styles.line}>
            <span>
              <ExplainerPopover explanation="Merchant Identifier: a not-really unique number assigned by the merchant's card processing provider.">
                MID
              </ExplainerPopover>
            </span>
            <span>{merchant.mid}</span>
          </div>
          <div className={styles.line}>
            <span>
              <ExplainerPopover explanation={
                <>Merchant Category Code: a broad categorization of
                the merchant’s business.
              </>}>
                MCC (<a target="_blank" href="https://www.web-payment-software.com/online-merchant-accounts/mcc-codes/" rel="noreferrer noopener">list</a>)
              </ExplainerPopover>
            </span>
            <span>{merchant.mcc}</span>
          </div>
          <div className={styles.line}>
            <span>
              <ExplainerPopover explanation="The internal identifier matching this card acceptor to the set of merchants we have cash back agreements with.">
                Known Merchant Key
              </ExplainerPopover>
            </span>
            <span>{merchant.known_merchant_key || '—'}</span>
          </div>
        </>
      )}

      <TimelineListLoader
        scopeId={ loanId }
        entityId={ activity.base_id }
      />

      <LedgerImpactsLoader
        sourceId={ activity.base_id }
      />

      <div className={cx(styles.line, 'mt-4')}>
        <span className={styles.title}>Card Processing Details</span>
      </div>
      <div className={styles.line}>
        <span>
          <ExplainerPopover explanation="The identifier of the initial event for this card purchase.">
            Token
          </ExplainerPopover>
        </span>
        <span>
          <a href={`https://app.marqeta.com/program/user/transaction?transactionToken=${marqeta_token}#RelatedTransactions`}>
            { marqeta_token }
          </a>
        </span>
      </div>
      <div className={styles.line}>
        <span>
          <ExplainerPopover explanation="An identifier assigned by the card network for use in tracing the transaction. May be shared with merchants.">
            Network Reference ID
          </ExplainerPopover>
        </span>
        <span>
          { swipe_details.network_reference_id || '—' }
        </span>
      </div>
      <div className={cx(styles.line, 'mt-4')}>
        <span className={styles.title}>
          <ExplainerPopover explanation={<>
            The raw events from the card processor for troubleshooting.
            <br /><br />
            <strong>Be careful trying to interpret these events.</strong> They
            have a different meaning of “cleared” than our usage,
            and need to be combined in specific ways to arrive at the final
            transaction.
          </>}>
            Raw Events
          </ExplainerPopover>
          <ExplainerPopover explanation={
            <>Check for events that may have been missed by the system. Useful for stuck-processing card purchases, or suspected chargebacks.</>
          }>
            <InlineButton className='ml-3' disabled={ isSyncingWithMarqeta } onClick={ syncWithMarqeta }>
              <small>{ isSyncingWithMarqeta ? 'Syncing…' : 'Sync with Marqeta' }</small>
            </InlineButton>
          </ExplainerPopover>
        </span>
        <span>
          (<a target="_blank" rel="noopener noreferrer" href="https://www.marqeta.com/docs/developer-guides/about-transactions#_the_transaction_lifecycle">
            Marqeta docs
          </a>)
        </span>
      </div>
      <Table>
        <tbody>
          {
            events.map(event => (
              <tr key={ event.token } className={ event.type.includes('gpa') ? 'text-muted' : '' }>
                <td><time title={event.issuer_received_time} dateTime={event.issuer_received_time}>{ formatDate(event.issuer_received_time) }</time></td>
                <td>{ event.type }</td>
                <td>{ event.state }</td>
                <td className="text-right">{ event.amount ? formatCents(event.amount) : '—' }</td>
                <td>
                  <a href={`https://app.marqeta.com/program/user/transaction?transactionToken=${event.token}`}>
                    { event.token.slice(0,8) }…
                  </a>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </>
  );
};
