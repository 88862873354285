import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext, get } from "react-hook-form";
import S3FileUploadField from '../../Form/S3FileUploadField';

export default function AgentFileField ({ partnerSlug }: { partnerSlug: string }) {
  const { formState: { errors, isValidating } } = useFormContext();
  const error = get(errors, 'documents');

  return (
    <>
      <Form.Label>
        Agent CSV
      </Form.Label>
      <S3FileUploadField
        name='document'
        bucket='partner-docs'
        s3_path={`${partnerSlug}/agent-csvs`}
      />

      { !isValidating && error && error.message && <div className="text-danger ml-1 mt-1 small">{error.message}</div> }
    </>
  );
}
