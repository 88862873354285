import { LOAN_STATES, USE_OF_FUND_OPTIONS } from './constants';
import formatDollars from './formatDollars';
import cleanPhoneNumber from './formatPhone';

export type Vendor = {
  _id: string;
  name: string;
  phone: string;
  email: string;
  address_line1: string;
  address_line2: string | null;
  city: string;
  state: string;
  zip: string;
};

export type Address = {
  address_line1: string;
  address_line2: string | null;
  city: string;
  state: string;
  zip: string;
}

export type CheckActivity = {
  _id: string;
  check_number: number;
  created_at: string;
  mailing_status: string | null;
  submittedAt?: Date;
  markedStoppedAt?: Date;
  markedCanceledAt?: Date;
  markedClearedAt?: Date;
  recipient_address?: Address;
  external_id?: string;
};

export type LedgerTransaction = {
  _id: string;
  status: string;
  effective_date?: string;
  created_at: string;
};

export type PurchaseMethod = {
  _id: string;
  type: string;
  isAch: boolean;
  isActive: boolean;
  approvedAt: string | null;
  plaidAccountId: string | null;
  plaidItemId: string | null;
  voidedCheckPhoto: string | null;
  approvedByAdminId: string | null;
  isExpired: boolean;
  accountType: string;
  accountNumber: string;
  routingNumber: string;
  lastFour: string;
  isVerified: boolean;
  createdByAdminId: string;
  loanId: string;
  plaidAccessToken: string;
  userId: string;

  // Card
  fulfillmentAddressId: string;
  fulfillmentStatus: string;
  trackingLink: string;
  marqetaCardToken: string;
  marqetaId: string;
  marqetaProgramSlug: string;
  status: string;
};

export type Document = {
  _id: string;
  name: string;
  s3: string;
}

export type DisbursementRequest = {
  _id: string;
  loan_id: string;
  user_id: string;
  amount: number;
  created_at: string;
  quick_deposit: boolean;
  vendor_type: string;
  use_of_funds: string[];
  invoice_status: string | null;
  invoice_info: string | null;
  request_reason: string | null;
  invoice_status_changed_at: string | null;
  adminName?: string;
  type: 'ach' | 'check';
  status: string;
  postedOn?: string;
  _vendor: Vendor | null;
  _checkActivity: CheckActivity | null;
  active_ledger_transaction_id: string;
  workStatements: Document[];
  purchase_method_id: string | null;
};

export type ExternalActivity = {
  _id?: string;
  amount: number;
  method: string;
  type: string;
  voided_at?: Date;
  marked_returned_at?: Date;
  status?: string;
  effective_date?: string;
  accounting_date?: string;
  posting_bank?: string;
  internal_sender_account?: string;
  internal_recipient_account?: string;
  external_ref?: string;
  impacts?: {
    accumulated_principal_repayments: number;
    accumulated_interest_repayments: number;
    accumulated_fee_repayments: number;
  };
};

type ACHActivity = {
  _id: string;
  submitted_at?: string;
  canceled_at?: string;
  deposit_date: string;
  internal_account_last_four: string;
};

type RequestedByUser = {
  _id: string;
  firstName: string;
  lastName: string;
}

export type Loan = {
  _id: string;
  userId: string;
  availableFunds: number;
  quickDepositAvailableFunds: number;
}

export type DisbursementData = {
  latest: LedgerTransaction;
  purchaseMethod?: PurchaseMethod;
  disbursementRequest: DisbursementRequest;
  externalActivity?: ExternalActivity;
  achActivity?: ACHActivity
  requestedByUser?: RequestedByUser;
  loan: Loan,
  loanApplication: unknown;
};

export type Transaction = {
  effective_date: string;
  amount: number;
  status: string;
  loan_id: string;
  disbursement_request_id: string | null;
  vendor_type: string;
  invoice_status: string;
  type: string;
  bank_account_verified: boolean;
  bank_account_not_added: boolean;
};

export const getNeedsReview = (transaction: Transaction) => {
  if (transaction.status === 'voided') {
    return false;
  }

  const isDirect = transaction.type === 'ach';

  if (isDirect && transaction.bank_account_not_added) {
    return false;
  }

  return (
    ((transaction.vendor_type === 'SINGLE' || transaction.vendor_type === 'MULTI') &&
      transaction.invoice_status === 'open') ||
    (isDirect && !transaction.bank_account_verified)
  );
};

export const getPMNeedsReview = (pm?: PurchaseMethod) => {
  if (!pm) {
    return false;
  }

  return !pm.approvedAt && !pm.plaidAccountId;
};

export const getPMStatus = (pm: PurchaseMethod) => {
  if (pm.isExpired) {
    return { text: 'Expired', variant: 'danger' };
  }
  if (getPMNeedsReview(pm)) {
    return { text: 'Unverified', variant: 'warning' };
  }
  return { text: 'Verified', variant: 'success' };
};

export const getPMVerificationMethod = (pm: PurchaseMethod) => {
  if (pm.plaidAccountId) {
    return 'Plaid';
  } else if (pm.approvedByAdminId && !pm.voidedCheckPhoto) {
    return 'Manual';
  }
  return 'Voided Check';
};

export const getDisbursementDetail = (dr: DisbursementRequest) => {
  const modalData: Record<string, unknown> = {};
  const data: Record<string, unknown> = {};

  modalData['Amount Requested'] = formatDollars(dr.amount / 100);
  const { _vendor: vendor } = dr;

  const useOfFunds = dr.use_of_funds
    .map((x) => {
      const option = USE_OF_FUND_OPTIONS.find((option) => option.value === x);
      return option ? option.label : x;
    })
    .join(', ');

  if (dr.vendor_type === 'SINGLE') {
    data['Request Type'] = 'Single Vendor';
    if (vendor) {
      data['Vendor Name'] = modalData['Vendor Name'] = vendor.name;
      data['Vendor Email'] = modalData['Vendor Email'] = vendor.email;
      data['Vendor Phone Number'] = modalData['Vendor Phone Number'] = cleanPhoneNumber(vendor.phone);
    }
  } else if (dr.vendor_type === 'MULTI') {
    data['Request Type'] = 'Multiple Vendors';
  }
  data['Stated Use of Funds'] = modalData['Stated Use of Funds'] = useOfFunds;
  if (dr.invoice_info) {
    data['Vendor Invoice Number'] = dr.invoice_info;
  }

  return { modalData, data };
};

export const getRequestedByUserInfo = (loan: Loan, user?: RequestedByUser) => {
  if (!user) {
    return null;
  }

  const userName = `${user.firstName} ${user.lastName}`;
  const userType = user._id === loan.userId ? 'Borrower' : 'Auth. User';
  return `${userName} (${userType})`;
}

export function isInDrawPeriod (loan?: { status: string }) {
  return loan && (
    loan.status === LOAN_STATES.AUTHORIZED ||
    loan.status === LOAN_STATES.FUNDED
  );
}
