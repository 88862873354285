import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

type Props = {
  children: React.ReactNode;
  delay?: number;
  explanation: React.ReactNode;
  id?: string;
  title?: string;
};

export default function ExplainerPopover (props: Props) {
  if (!props.explanation) {
    return <>{ props.children }</>;
  }
  return (
    <OverlayTrigger delay={ props.delay } trigger={['hover', 'focus']} placement='top' overlay={
      <Popover id={ `${ props.id }_popover` }>
        <Popover.Title>{ props.title !== undefined ? props.title : 'Explanation' }</Popover.Title>
        <Popover.Content>
          { props.explanation }
        </Popover.Content>
      </Popover>
    }>
      <span>{ props.children }</span>
    </OverlayTrigger>
  )
}
